import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import TransferWithinAStationSharpIcon from "@mui/icons-material/TransferWithinAStationSharp";
import Shop2SharpIcon from "@mui/icons-material/Shop2Sharp";
import WarehouseSharpIcon from "@mui/icons-material/WarehouseSharp";
import LuggageSharpIcon from "@mui/icons-material/LuggageSharp";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import FastForwardIcon from "@mui/icons-material/FastForward";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import WorkspacesSharpIcon from "@mui/icons-material/WorkspacesSharp";

const ConsigneeTabData = [
  {
    id: 0,
    parentnav: "Dashboard",
    icon: <DashboardSharpIcon />,
    access: true,
    route: "/",
    
  },
  {
    id: 1,
    parentnav: "PO's",
    icon: <StickyNote2OutlinedIcon />,
    children: [
      {
        cid: 0,
        title: "PURCHASE ORDER",
        icon: <StickyNote2OutlinedIcon />,
      },
      {
        cid: 1,
        childnav: "Upload Purchase Order",
        route: "/upload_purchase_order",
        access: true,
      },
      {
        cid: 2,
        childnav: "Pending For Confirmation",
        route: "/pending_for_approval",
        access: true,
      },

      {
        cid: 3,
        childnav: "Accepted Purchase Order ",
        route: "/accepted_purchase_order",
        access: true,
      },
      {
        cid: 4,
        childnav: "Rejected Purchase Order",
        route: "/rejected_purchase_order",
        access: true,
      },
    ],
    access: true,
  },
  {
    id: 2,
    parentnav: "Shipments",
    icon: <LocalShippingSharpIcon />,
    children: [
      {
        cid: 0,
        title: "FORWARDER",
        icon: <FastForwardIcon />,
      },
      {
        cid: 1,
        childnav: "Requested Shipments",
        route: "/requested_shipments",
        access: true,
      },
      {
        cid: 2,
        childnav: "Confirmed By Forwarder",
        route: "/confirmed_by_forwarder",
        access: true,
      },

      {
        cid: 3,
        childnav: "Rejected By Forwarder",
        route: "/rejected_by_forwarder",
        access: true,
      },
      {
        cid: 0,
        title: "CARRIER",
        icon: <Shop2SharpIcon />,
      },
      {
        cid: 4,
        childnav: "Carrier Confirmation",
        route: "/carrier_confirmation",
        access: true,
      },
      {
        cid: 5,
        childnav: "Confirmed By Carrier",
        route: "/confirmed_by_carrier",
        access: true,
      },
      {
        cid: 6,
        childnav: "Rejected By Carrier",
        route: "/rejected_by_carrier",
        access: true,
      },
      // {
      //   cid: 7,
      //   childnav: "Booking Request Upload",
      //   access: true,
      // },
    ],
    access: true,
  },
  {
    id: 3,
    parentnav: "InTransit ",
    icon: <TransferWithinAStationSharpIcon />,
    children: [
      {
        cid: 0,
        title: "INTRANSIT",
        icon: <TransferWithinAStationSharpIcon />,
      },
      {
        cid: 1,
        childnav: "InTransit Shipments",
        route: "/intransit_shipments",
        access: true,
      },
      {
        cid: 2,
        childnav: "Confirm Goods Reciept",
        route: "/confirm_goods_reciept",
        access: true,
      },
    ],

    access: true,
  },

  {
    id: 4,
    parentnav: "WareHouse",
    icon: <WarehouseSharpIcon />,
    children: [
      {
        cid: 0,
        title: "WAREHOUSE",
        icon: <WarehouseSharpIcon />,
      },
      {
        cid: 1,
        route: "/wareHouse_request_upload",
        childnav: "WareHouse Request Upload",
        access: "true",
      },

      {
        cid: 4,
        route: "/pending_requests",
        childnav: "Pending Requests",
        access: "true",
      },
      {
        cid: 2,
        route: "/confirmed_shipments",
        childnav: "Confirmed Shipments",
        access: "true",
      },
      {
        cid: 5,
        route: "/rejected_shipments",
        childnav: "Rejected Shipments",
        access: "true",
      },

      {
        cid: 6,
        route: "/inward_truckdetails",
        childnav: "InWard TruckDetails",
        access: "true",
      },
      {
        cid: 7,
        route: "/shipments_at_warehouse",
        childnav: "Shipments At WareHouse",
        access: "true",
      },

      {
        cid: 8,
        route: "/outward_shipments",
        childnav: "OutWard Shipments",
        access: "true",
      },

      {
        cid: 1,
        route: "/space_utilization_report",
        childnav: "Space Utilization Report",
        access: "true",
      },
    ],
  },

  {
    id: 5,
    parentnav: "Returns",
    icon: <LuggageSharpIcon />,
    children: [
      {
        cid: 0,
        title: "RETURNS",
        icon: <Shop2SharpIcon />,
      },
      {
        cid: 1,
        childnav: "Upload Return Order",
        route: "/upload_return_order",
        access: true,
      },
      {
        cid: 1,
        childnav: "Return Orders For Acknowledgement",
        route: "/return_orders_for_acknowledgement",
        access: true,
      },
      {
        cid: 3,
        childnav: "Accepted Return Orders ",
        route: "/confirmed_return_orders",
        access: true,
      },
      {
        cid: 4,
        childnav: "Rejected Return Order ",
        route: "/rejected_return_orders",
        access: true,
      },
    ],
    access: true,
  },
  {
    id: 6,
    parentnav: "Delivered Report",
    icon: <AllInboxIcon />,
    route: "/delivered_report",
    access: true,
  },
  {
    id: 7,
    parentnav: "UploadDoc",
    icon: <UploadFileOutlinedIcon />,
    access: true,
    children: [
      {
        cid: 0,
        title: "UPLOAD DOCUMENTS",
        icon: <UploadFileOutlinedIcon />,
      },
      {
        id: 1,
        childnav: "Upload Documents",
        route: "/upload_documents",
        access: true,
      },
      {
        id: 2,
        childnav: "Manage Documents",
        route: "/manage_documents",
        access: true,
      },
    ],
  },
];
export default ConsigneeTabData;
