import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";

import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";

import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";

import BrLineItems from "./BrLineItems";

import { getRequest } from "../../../Services/BaseService";
import sizeConfigs from "../../../Config/sizeConfigs";
import colorConfigs from "../../../Config/colorConfig";
import "../../../Styles.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import { set } from "react-hook-form";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function AcceptedByForwarder() {
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);
    setOpenDetail(true);
  };

  const nav = useNavigate();
  const managedoc = (params: any) => {
    console.log(params.data.purchaseOrderNumber);

    nav("/manage_documents", { state: params.data.invoiceNumber });
  };

  const [columnDefs] = useState([
    {
      headerName: "INVOICE NUMBER",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellClass: "ag-left-aligned-cell",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{ color: "blue", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "INVOICE DATE",
      field: "brInvoiceDate",
      filter: true,
      headerTooltip: "INVOICE DATE",
      tooltipField: "brInvoiceDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      filter: "true",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
    },

    {
      headerName: "FORWARDER ID",
      filter: true,
      headerTooltip: "FORWARDER ID",
      tooltipField: "forwarderID",
      field: "forwarderID",
    },
    {
      headerName: "FORWARDER NAME",
      filter: true,
      headerTooltip: "FORWARDER NAME",
      tooltipField: "forwarderName",
      field: "forwarderName",
      columnGroupShow: "closed",
    },
    {
      headerName: "FORWARDER BOOKING REFERENCE",
      filter: true,
      headerTooltip: "FORWARDER BOOKING REFERENCE",
      tooltipField: "forwarderBookingReference",
      field: "forwarderBookingReference",
      columnGroupShow: "closed",
    },

    // {
    //   headerName: "PICK UP LOCATION",
    //   field: "pickupLocation",
    //   filter: true,
    //   headerTooltip: "PICK UP LOCATION",
    //   tooltipField: "pickupLocation",
    // },
    {
      headerName: "PICK UP LOCATION(GLN)",
      field: "pickupLocation",
      filter: true,
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
    },

    {
      headerName: "PICKUP COUNTRY",
      filter: true,
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupLocation",
      field: "pickupCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "PICKUP DATE",
      filter: true,
      headerTooltip: "PICKUP DATE",
      tooltipField: "brPickupDate",
      field: "brPickupDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP ZIP CODE",
      filter: true,
      headerTooltip: "PICKUP ZIP CODE",
      tooltipField: "brPickupDate",
      field: "pickupZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CONSIGNEE ID",
      filter: true,
      headerTooltip: "CONSIGNEE ID",
      tooltipField: "consigneeID",
      field: "consigneeID",
    },
    {
      headerName: "CONSIGNEE NAME",
      filter: true,
      headerTooltip: "CONSIGNEE NAME",
      tooltipField: "consigneeName",
      field: "consigneeName",
      columnGroupShow: "closed",
    },
    {
      headerName: "CONSIGNEE EMAIL ADDRESS",
      filter: true,
      headerTooltip: "CONSIGNEE EMAIL ADDRESS",
      tooltipField: "consigneeEamilAddress",
      field: "consigneeEamilAddress",
    },
    {
      headerName: "CONSIGNEE CONTACT NUMBER",
      filter: true,
      headerTooltip: "CONSIGNEE CONTACT NUMBER",
      tooltipField: "consingneeContactNumber",
      field: "consingneeContactNumber",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    // {
    //   headerName: "DROPOFF LOCATION",
    //   filter: true,
    //   headerTooltip: "DROPOFF LOCATION",
    //   tooltipField: "dropoffLocation",
    //   field: "dropoffLocation",
    // },

    {
      headerName: "DROPOFF LOCATION(GLN)",
      filter: true,
      headerTooltip: "DROPOFF LOCATION",
      tooltipField: "dropoffLocation",
      field: "dropoffLocation",
    },
    {
      headerName: "DROPOFF COUNTRY",
      filter: true,
      headerTooltip: "DROPOFF COUNTRTY",
      tooltipField: "dropoffCountry",
      field: "dropoffCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF ZIPCODE",
      filter: true,
      headerTooltip: "DROPOFF ZIPCODE",
      tooltipField: "dropoffZipCode",
      field: "dropoffZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CHA ID",
      filter: true,
      headerTooltip: "CHA ID",
      tooltipField: "chaDetails.chaID",
      field: "chaDetails.chaID",
    },
    {
      headerName: "CHA NAME",
      filter: true,
      headerTooltip: "CHA NAME",
      tooltipField: "chaDetails.chaName",
      field: "chaDetails.chaName",
      columnGroupShow: "closed",
    },
    {
      headerName: "SHIPPER ID",
      filter: true,
      headerTooltip: "SHIPPER ID",
      tooltipField: "createdBy",
      field: "createdBy",
      columnGroupShow: "closed",
    },
    {
      headerName: "SHIPPER  NAME",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "createdByName",
      field: "createdByName",
      columnGroupShow: "closed",
    },
    // {
    //   headerName: "FBR STATUS",
    //   filter: true,
    //   headerTooltip: "FBR STATUS",
    //   tooltipField: "fbrStatus",
    //   field: "fbrStatus",
    // },
    {
      headerName: "INCO TERMS",
      field: "incoTerms",
      filter: true,
      headerTooltip: "incoTerms",
      tooltipField: "INCO TERMS",
    },
    {
      headerName: "MODE OF SHIPMENT ",
      filter: true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
    },
    {
      headerName: "ACCEPTED  BY",
      filter: true,
      headerTooltip: "ACCEPTED BY",
      tooltipField: "createsby",
      field: "createdBy",
    },
    {
      headerName: "ACCEPTED  DATE",
      filter: true,
      headerTooltip: "ACCEPTED DATE",
      tooltipField: "createsby",
      field: "createdBy",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "ViewDocs",
      filter: true,
      headerTooltip: "CREATED BY",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            className="btn btn-info"
            onClick={() => managedoc(params)}
          >
            View Doc
          </a>
        );
      },
    },
  ]);

  //

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const url = Environment_Realtraceb_Dev.BASE_URL + "/bookingRequestHeaderList";

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    fbrStatus: "open,processed",
    brStatus: "accepted",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setLoad(true);
    getAccptedShipments();
  }, [fromDate, toDate]);

  const gridRef = useRef<any>();

  function getAccptedShipments() {
    axios.get(url, config).then((res) => {
      setShowGrid(true);
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  }
  const onGridReady = useCallback((params: GridReadyEvent) => {
    // getAccptedShipments();
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              ref={gridRef}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={10}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <BrLineItems
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={PoData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
}

export default AcceptedByForwarder;
