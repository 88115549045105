import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import React, {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";

import swal from "sweetalert";
import Input from "@mui/material/Input";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// import { DropzoneDialog } from "mui-file-dropzone";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  TextField,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "70%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function CarrierConfirmation() {
  const [rowData, setRowData] = useState<any>([]);
  const domLayout = "autoHeight";
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const masterDetail = true;
  const [validationData, setValidationData] = useState<any>([]);
  const Formref = useRef<any>();
  const [validationPoNumber, setValidationPoNumber] = useState();
  const [columnDefs] = useState([
    // {
    //   headerName: "VALIDATION STATUS",
    //   field: "preCheckValidationStatus",
    //   filter: true,
    //   headerTooltip: "VALIDATION STATUS",
    //   tooltipField: "preCheckValidationStatus",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (params.value === true) {
    //       return <p className="status">TRUE</p>;
    //     } else {
    //       setDisable(false);
    //       return <p className="rstatus">FALSE</p>;
    //     }
    //   },
    // },
    // {
    //   headerName: "VALIDATION MESSAGE",
    //   field: "preCheckValidationMessage",
    //   filter: true,
    //   headerTooltip: "VALIDATION MESSAGE",
    //   tooltipField: "preCheckValidationMessage",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     let data = "";
    //     if (params.value.length > 0) {
    //       data = params.value[0];
    //     } else {
    //       data = "Header Validation Success ";
    //     }
    //     return <Button onClick={() => alerts(params.value)}>{data}</Button>;
    //   },
    // },
    {
      headerName: "INVOICE NUMBER",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      headerName: "VALIDATION STATUS",
      field: "preCheckValidationMessage",
      filter: true,
      headerTooltip: "VALIDATION STATUS",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        console.log(params, "$params");
        if (params.data.preCheckValidationStatus === true) {
          return (
            <p>
              <CheckCircleIcon sx={{ fontSize: "18px", color: "green" }} />{" "}
            </p>
          );
        } else {
          if (params.value.length > 1) {
            return (
              <Button
                className="upload-error"
                sx={{ p: 0, fontSize: "12px" }}
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                <CancelIcon
                  sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                />{" "}
                1 or more Validations
              </Button>
            );
          } else if (params.value.length === 1) {
            return (
              <Button
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                {params.value[0]}
              </Button>
            );
          } else {
            <p>
              <CheckCircleIcon
                sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
              />{" "}
              NO VALIDATION
            </p>;
          }
        }
      },
    },

    {
      headerName: "FORWRDER BOOKING REQUEST NUMBER",
      filter: true,
      headerTooltip: "FORWRDER BOOKING REQUEST NUMBER",
      tooltipField: "bookingrequestNumber",
      field: "bookingrequestNumber",
    },
    {
      headerName: "CARRIER REFERENCE #",
      field: "carrierReference",
      filter: true,
      headerTooltip: "CARRIER REFERENCE",
      tooltipField: "carrierReference",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "PICKUP DATE",
      filter: true,
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickUpDate",
      field: "pickUpDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP TIME",
      filter: true,
      headerTooltip: "PICKUP TIME",
      tooltipField: "pickUpTime",
      field: "pickUpTime",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CONSIGNEE ID",
      filter: true,
      headerTooltip: "CONSIGNEE ID",
      field: "consigneeID",
      tooltipField: "consigneeID",
    },
    {
      headerName: "CONSIGNEE NAME",
      filter: true,
      headerTooltip: "CONSIGNEE NAME",
      field: "consigneeName",
      tooltipField: "consigneeName",
      columnGroupShow: "closed",
    },

    {
      headerName: "SHIPPER ID",
      filter: true,
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "shipperId",
    },
    {
      headerName: "SHIPPER NAME",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "ShipperName",
      field: "ShipperName",
      columnGroupShow: "closed",
    },

    {
      headerName: "INCO TERMS",
      filter: true,
      headerTooltip: "INCO TERMS",
      tooltipField: "incoTerms",
      field: "incoTerms",
    },
    // {
    //   headerName: "DEPARTURE TERMINAL",
    //   filter: true,
    //   headerTooltip: "DEPARTURE TERMINAL",
    //   tooltipField: "dischargeTerminal",
    //   field: "dischargeTerminal",
    // },

    // {
    //   headerName: "MODE OF SHIPMENT ",
    //   filter: true,
    //   headerTooltip: "MODE OF SHIPMENT",
    //   tooltipField: "ModeOfShipment",
    //   field: "ModeOfShipment",
    // },
    {
      headerName: "BOOKING DATE ",
      filter: true,
      headerTooltip: "BOOKING DATE",
      tooltipField: "bookingDate",
      field: "bookingDate",
    },
  ]);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        flex: 1,
        columnDefs: [
          {
            headerName: "HBL NUMBER",
            field: "hblNumber",
            filter: true,
            headerTooltip: "HBL NUMBER",
            tooltipField: "hblNumber",
          },
          {
            headerName: "MBL NUMBER",
            field: "mblNumber",
            filter: true,
            headerTooltip: "MBL NUMBER",
            tooltipField: "mblNumber",
          },
          {
            headerName: "HS CODE",
            field: "hscode",
            filter: true,
            headerTooltip: "HS CODE",
            tooltipField: "hscode",
          },
          {
            headerName: "HS CODE DESCRIPTION",
            field: "hscode",
            filter: true,
            headerTooltip: "HS CODE DESCRIPTION",
            tooltipField: "hscode",
          },

          {
            headerName: "CONTAINER NUMBER",
            field: "containerNumber",
            filter: true,
            headerTooltip: "CONTAINER NUMBER",
            tooltipField: "containerNumber",
          },
          {
            headerName: "CONTAINER TYPE",
            field: "containerType",
            filter: true,
            headerTooltip: "CONTAINER TYPE",
            tooltipField: "containerType",
          },
          {
            headerName: "CONTAINER SIZE",
            field: "containerSize",
            filter: true,
            headerTooltip: "CONTAINER SIZE",
            tooltipField: "containerSize",
          },
          {
            headerName: "CONTAINER TARE WEIGHT",
            field: "payloadCapacity",
            filter: true,
            headerTooltip: "CONTAINER TARE WEIGHT",
            tooltipField: "payloadCapacity",
          },
          {
            headerName: "CONTAINER GROSS WEIGHT",
            field: "containerGrossWeight",
            filter: true,
            headerTooltip: "CONTAINER GROSS WEIGHT",
            tooltipField: "containerGrossWeight",
          },
          {
            headerName: "CONTAINER NET WEIGHT",
            field: "containerNetWeight",
            filter: true,
            headerTooltip: "CONTAINER NET WEIGHT",
            tooltipField: "containerNetWeight",
          },
          {
            headerName: "EMPTY CONTAINER PICKUP DEPOT",
            field: "containerPickUpDepot",
            filter: true,
            headerTooltip: "EMPTY CONTAINER PICKUP DEPOT",
            tooltipField: "containerPickUpDepot",
          },

          {
            headerName: "EMPTY CONTAINER PICKUP DATE",
            field: "containerPickUpDepot",
            filter: true,
            headerTooltip: "EMPTY CONTAINER PICKUP DATE",
            tooltipField: "containerPickUpDepot",
          },

          {
            headerName: "EMPTY CONTAINER PICKUP TIME",
            field: "containerPickUpDepot",
            filter: true,
            headerTooltip: "EMPTY CONTAINER PICKUP TIME",
            tooltipField: "containerPickUpDepot",
          },

          {
            headerName: "PORT OF ORIGIN",
            filter: true,
            tooltipField: "portOfOrigin",
            field: "portOfOrigin",
            headerTooltip: "PORT OF ORIGIN",
          },
          {
            headerName: "VESSEL ID",
            filter: true,
            tooltipField: "vessel",
            field: "vessel",
            headerTooltip: "VESSEL ID",
          },
          {
            headerName: "VESSEL NAME",
            filter: true,
            tooltipField: "vesselName",
            field: "vesselName",
            headerTooltip: "VESSEL NAME",
          },
          {
            headerName: "VOYAGE #",
            filter: true,
            tooltipField: "voyage",
            field: "voyage",
            headerTooltip: "VOYAGE #",
          },
          {
            headerName: "DEPARTURE OF TERMINAL",
            filter: true,
            tooltipField: "departureTerminal",
            field: "departureTerminal",
            headerTooltip: "DEPARTURE OF TERMINAL",
          },
          {
            headerName: "VGA CUT OFF TIME",
            filter: true,
            tooltipField: "vgaCutOffTime",
            field: "vgaCutOffTime",
            headerTooltip: "VGA CUT OFF TIME",
          },

          {
            headerName: "VESSEL DEPARTURE TIME",
            filter: true,
            tooltipField: "vesselDepartureTime",
            field: "vesselDepartureTime",
            headerTooltip: "VESSEL DEPARTURE TIME",
          },
          {
            headerName: "OCEAN TRANSMIT TIME",
            filter: true,
            headerTooltip: "OCEAN TRANSMIT TIME",
            tooltipField: "oceanTransitTime",
            field: "oceanTransitTime",
          },

          {
            headerName: "PORT OF  DISCHARGE",
            filter: true,
            tooltipField: "portOfDischarge",
            field: "portOfDischarge",
            headerTooltip: "PORT OF  DISCHARGE",
          },

          {
            headerName: "DISCHARGE TERMINAL",
            filter: true,
            tooltipField: "dischargeTerminal",
            field: "dischargeTerminal",
            headerTooltip: "DISCHARGE TERMINAL",
          },

          // {
          //   headerName: "SHIPMENT VOLUME",
          //   filter: true,
          //   headerTooltip: "SHIPMENT VOLUME",
          //   tooltipField: "shipmentVolume",
          //   field: "shipmentVolume",
          // },
          // {
          //   headerName: "SHIPMENT WEIGHT",
          //   filter: true,
          //   headerTooltip: "SHIPMENT WEIGHT",
          //   tooltipField: "shipmentWeight",
          //   field: "shipmentWeight",
          // },
          // {
          //   headerName: "SHIPMENT DATE",
          //   filter: true,
          //   headerTooltip: "SHIPMENT DATE",
          //   tooltipField: "shipmentDate",
          //   field: "shipmentDate",
          // },
          {
            headerName: "ETD",
            filter: true,
            headerTooltip: "ETD",
            tooltipField: "etd",
            field: "etd",
            cellClass: "ag-left-aligned-cell",
          },

          {
            headerName: "ETA",
            filter: true,
            headerTooltip: "ETA",
            tooltipField: "eta",
            field: "eta",
            cellClass: "ag-left-aligned-cell",
          },

          {
            headerName: "MODE OF SHIPMENT",
            filter: true,
            headerTooltip: "MODE OF SHIPMENT",
            tooltipField: "ModeOfShipment",
            field: "ModeOfShipment",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headeName: "TRANSHIP",
            filter: true,
            field: "tranship",
          },
          // {
          //   headerName: "VALIDATION STATUS",
          //   field: "preCheckValidationStatus",
          //   filter: true,
          //   headerTooltip: "VALIDATION STATUS",
          //   tooltipField: "preCheckValidationStatus",
          //   cellRenderer: (params: ICellRendererParams) => {
          //     if (params.value === true) {
          //       return <p className="status">TRUE</p>;
          //     } else {
          //       setDisable(false);
          //       console.log("Button Disable", disable);
          //       return <p className="rstatus">FALSE</p>;
          //     }
          //   },
          // },
          // {
          //   headerName: "VALIDATION MESSAGE",
          //   field: "preCheckValidationMessage",
          //   filter: true,
          //   headerTooltip: "VALIDATION MESSAGE",
          //   tooltipField: "preCheckValidationMessage",
          //   cellRenderer: (params: ICellRendererParams) => {
          //     if (params.value.length > 0) {
          //       return (
          //         <Button onClick={() => alerts(params.value)}>
          //           {params.value[0]}
          //         </Button>
          //       );
          //     } else {
          //       <p>NO VALIDATION</p>;
          //     }
          //   },
          // },
          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationMessage",
            filter: true,
            headerTooltip: "VALIDATION STATUS",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              console.log(params, "$params");
              if (params.data.preCheckValidationStatus === true) {
                return (
                  <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green" }}
                    />
                  </p>
                );
              } else {
                if (params.value.length > 1) {
                  return (
                    <Button
                      className="upload-error"
                      sx={{ p: 0, fontSize: "12px" }}
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      <CancelIcon
                        sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                      />{" "}
                      1 or more Validations
                    </Button>
                  );
                } else if (params.value.length === 1) {
                  return (
                    <Button
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      {params.value[0]}
                    </Button>
                  );
                } else {
                  <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
                    />{" "}
                    NO VALIDATION
                  </p>;
                }
              }
            },
          },
        ],

        defaultColDef: {
          sortable: true,
          flex: 1,
        },
      },
      getDetailRowData: (params: any) => {
        console.log(params.data.lines);

        params.successCallback(params.data.lines);
        params.node.beans.columnApi.autoSizeAllColumns(false);
      },
    };
  }, []);

  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setExcelName(e.target.files[0].name);
    }
  }

  //   const onGridReady = useCallback((params: GridReadyEvent) => {}, []);
  //PreView Api

  const Preview = () => {
    // setDisable(true);
    console.log(file, "$file");
    if (fileExtError == false) {
      if (file === undefined) {
        toast.warning("Please Select a File...");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      let url =
        "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/uploadFile/CarrierConfirmation";
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-date",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };
      axios.post(url, formData, config).then((res: any) => {
        console.log(res);
        setPreviewData(res.data);
        setRowData(res.data.data);
        res.data.data.map((disables: any) => {
          if (disables.preCheckValidationStatus === false) {
            setDisable(false);
          } else {
            setDisable(true);
          }
        });
      });
      setShowFile(true);
    }
  };

  function alerts(params: any, poNum: any) {
    setValidationPoNumber(poNum);
    setAlertOpen(true);
    setValidationData(params);
  }
  const closeAlert = () => setAlertOpen(false);

  //File Submit Api
  const SubmitFile = () => {
    let url =
      "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/carrierconfirmationSubmit";
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    let obj: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    axios
      .post(url, obj, config)
      .then((res: any) => {
        console.log(res.data.status);
        console.log(res);
        if (res.data.status === true) {
          swal("Success", res.data.msg, "success");
        }
      })
      .catch((err: any) => {
        if (err.res.data.status === false) {
          swal("Error", err.res.data.msg, "error");
        }
      });
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
    // setFile();
  };
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
    setFile(undefined);
  };
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState(false);

  function handleAlert() {
    setShowAlert(false);
  }

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + "/carrierConfirmation.xlsx"}
            download={"carrierConfirmation.xlsx"}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton>{" "}
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={Formref}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                marginLeft: "inherit",
              }}
              onClick={(event) => Preview()}
            >
              Preview
            </LoadingButton>
            <span>&nbsp; &nbsp;</span>

            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              color="warning"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box className="ag-theme-balham" sx={{ mt: 2 }}>
            <AgGridReact
              animateRows={true}
              defaultColDef={defaultColDef}
              detailCellRendererParams={detailCellRendererParams}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              masterDetail={masterDetail}
              paginationPageSize={10}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {disable ? (
                <Button
                sx={{marginBottom:'52px'}}
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                >
                  Submit
                </Button>
              ) : (
                <Button 
                sx={{marginBottom:'52px'}}variant="contained" type="submit" disabled>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Validation Messages"}</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                >
                  <Typography>{data}</Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showAlert} onClose={handleAlert}>
          <Box sx={{ padding: "25px", borderRadius: "5px" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              {/* <img
              src={require("../asserts/sucess.png")}
              alt="sucess Image"
              width={60}
              height={60}
            /> */}
            </Box>
            <Typography sx={{ marginBottom: "15px" }}>{message}</Typography>

            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" color="success" onClick={handleAlert}>
                Ok
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Paper>
    </div>
  );
}

export default CarrierConfirmation;
