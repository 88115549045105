import React, { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  PolylineF,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import { Container } from "@mui/system";

const containerStyle = {
  width: "49.5vw",
  height: "80vh",
  top: '20px',
  mapTypeId: "roadmap",
};
function MyMapComponent(props: any) {
  useEffect(() => {
    // console.log("......................................", locationDataa);
    console.log("Map Rendersss");
    // MapLegData();
  }, []);

  //Map Markers
  let icon: any =
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|42f59b";
  let iconbetween: any =
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|42f59b";
  let iconorigin: any =
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff9000";

  const locationDataa: any = [];

  // function MapLegData() {
  // if (props.locations.length == 1) {
  //   props.locations.map((data: any, index: number) => {
  //     let obj = {
  //       name: data.origin,
  //       location: {
  //         lat: parseFloat(data.originLatitude),
  //         lng: parseFloat(data.originLongitude),
  //         // lat: parseFloat(data.OriginLatitude),
  //         // lng: parseFloat(data.OriginLongitude)
  //       },
  //     };
  //     locationDataa.push(obj);
  //     let obj2 = {
  //       name: data.origin,
  //       location: {
  //         lat: parseFloat(data.destinationLatitude),
  //         lng: parseFloat(data.destinationLongitude),

  //         // lat: parseFloat(data.DestinationLatitude),
  //         // lng: parseFloat(data.DestinationLongitude)
  //       },
  //     };
  //     locationDataa.push(obj2);
  //   });
  // }
  const containerStyle = {
    height: "600px",
    width: "1000px",
    top: '30px'
  };

  const center = {
    lat: 51.9492,
    lng: 1.3239
  };
  const destination = {
    lat: 33.9028,
    lng: 35.5177
  }
  // if (props.locations.length > 1) {
  //   props.locations.map((data: any, index: number) => {
  //     let obj = {
  //       name: data.origin,
  //       location: {
  //         lat: parseFloat(data.originLatitude),
  //         lng: parseFloat(data.originLongitude),
  //       },
  //     };
  //     locationDataa.push(obj);
  //   });
  // }

  // }

  //Map Api Key
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q",
  });
  const path = [
    { lat: 25.260595, lng: 51.613767 },
  ];

  const locationarray = [
    // {
    //   name: 'Hamad',
    //   location: {
    //     lat: 25.260595,
    //     lng: 51.613767
    //   }
    // },
    {
      name: 'United Kingdom',
      location: {
        lat: 51.9492,
        lng: 1.3239
      }
    },
    {
      name: 'Lebanon',
      //blue
      location: {
        lat: 33.9028,
        lng: 35.5177
      }
    },

  ]

  const options = {
    strokeColor: "#000000",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#000000",
    fillOpacity: 0.35,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
  };

  const handleOnLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    props.locations.forEach((location: any) => bounds.extend(location));
    map.fitBounds(bounds);
  };
  // const handlemouseover = (data: any) => {
  //   setSelectedElement(true);
  //   setLocwindow(data.location);
  //   setWndowName(data.name);
  // };

  console.log("Final Locations.....", locationarray);

  const paths = locationarray.map((item: any) => item.location);
  console.log(paths);

  const [map, setMap] = React.useState(null)



  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])


  // return isLoaded ? (
  //   <GoogleMap
  //     onLoad={handleOnLoad}
  //     mapContainerStyle={containerStyle}
  //     center={locationarray[0].location}
  //     zoom={2}

  //   >
  //     {locationarray
  //       .slice(1, locationarray.length - 1)
  //       .map((item: any, index: any) => {
  //         // if (index == 0) {
  //         //   setMarkerIcon(iconorigin);
  //         // } else {
  //         //   setMarkerIcon(icon);
  //         // }
  //         return (
  //           <MarkerF
  //             title={item.name}
  //             position={item.location}
  //             visible={true}
  //             icon={iconbetween}
  //             options={options}
  //             animation={2}
  //             // onClick={() => handlemouseover(item)}
  //           />
  //         );
  //       })}

  //     <MarkerF
  //       title={locationarray[0].name}
  //       position={locationarray[0].location}
  //       options={options}
  //       animation={2}
  //       visible={true}
  //       icon={iconorigin}
  //     />
  //     <MarkerF
  //       title={locationarray[locationarray.length - 1].name}
  //       position={locationarray[locationarray.length - 1].location}
  //       options={options}
  //       animation={2}
  //       visible={true}
  //       icon={icon}
  //     />
  //     <PolylineF path={paths} options={{ path: paths, strokeOpacity: 1 }} />
  //   </GoogleMap>
  // ) : null;
  return isLoaded ? (
    <GoogleMap
      id="marker-example"
      mapContainerStyle={containerStyle}
      zoom={2.2}
      center={center}

    >
      <MarkerF
        visible={true}
        icon={iconorigin}
        options={options}
        animation={2}
        title={locationarray[0].name}
        position={locationarray[0].location}
      />
      <MarkerF
        visible={true}
        icon={icon}
        options={options}
        animation={2}
        title={locationarray[locationarray.length - 1].name}
        position={locationarray[locationarray.length - 1].location}
      />
      {/* {locationarray.slice(1,locationarray.length-1).map((item:any)=>
      (
        <MarkerF
        visible={true}
        icon={iconbetween}
        options={options}
        animation={2}
        position={item.location}
      />
      ))}
       */}
      <PolylineF path={paths} options={{ path: paths, strokeOpacity: 1 }} />
    </GoogleMap>
  ) : <></>
}



// const containerStyle = {
//   width: "68vw",
//   height: "78vh",
// };

// function MyMapComponent(props: any) {
//   useEffect(() => {
//     // console.log("......................................", props.locations);
//     console.log("Map Rendersss");
//     // MapLegData();
//   }, []);

//   //Map Markers
//   let icon: any =
//     "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000";
//   let iconbetween: any =
//     "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ffa500";
//   let iconorigin: any =
//     "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|f4c919";

//   const locationDataa: any = [];
// if(props.locations?.length >0){
//   // function MapLegData() {
//   if (props.locations.length == 1) {
//     props.locations.map((data: any, index: number) => {
//       let obj = {
//         name: data.origin,
//         location: {
//           lat: parseFloat(data.originLatitude),
//           lng: parseFloat(data.originLongitude),
//         },
//       };
//       locationDataa.push(obj);
//       let obj2 = {
//         name: data.origin,
//         location: {
//           lat: parseFloat(data.destinationLatitude),
//           lng: parseFloat(data.destinationLongitude),
//         },
//       };
//       locationDataa.push(obj2);
//     });
//   }

//   if (props.locations.length > 1) {
//     props.locations.map((data: any, index: number) => {
//       let obj = {
//         name: data.origin,
//         location: {
//           lat: parseFloat(data.originLatitude),
//           lng: parseFloat(data.originLongitude),
//         },
//       };
//       locationDataa.push(obj);
//     });
//   }
// }

//   // }

//   //Map Api Key
//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: "AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q",
//   });

//   const options = {
//     strokeColor: "#000000",
//     strokeOpacity: 1,
//     strokeWeight: 3,
//     fillColor: "#000000",
//     fillOpacity: 0.35,
//     clickable: true,
//     draggable: false,
//     editable: false,
//     visible: true,
//   };

//   // const handleOnLoad = (map: any) => {
//   //   const bounds = new google.maps.LatLngBounds();
//   //   props.locations.forEach((location: any) => bounds.extend(location));
//   //   map.fitBounds(bounds);
//   // };
//   // const handlemouseover = (data: any) => {
//   //   setSelectedElement(true);
//   //   setLocwindow(data.location);
//   //   setWndowName(data.name);
//   // };

//   // console.log("Final Locations.....", locationDataa);

//   const paths =locationDataa.length>0? locationDataa.map((item: any) => item.location):[]
//   console.log(paths);

//   return isLoaded&&locationDataa.length>0 ? (
//   <div>
  
//     <GoogleMap
//       //onLoad={handleOnLoad}
//       mapContainerStyle={containerStyle}
//       center={locationDataa[0].location}
//       zoom={2}
      
//     >
//       {locationDataa
//         .slice(1, locationDataa.length - 1)
//         .map((item: any, index: any) => {
//           // if (index == 0) {
//           //   setMarkerIcon(iconorigin);
//           // } else {
//           //   setMarkerIcon(icon);
//           // }
//           return (
//             <MarkerF
//               title={item.name}
//               position={item.location}
//               visible={true}
//               icon={iconbetween}
//               options={options}
//               animation={2}
//               // onClick={() => handlemouseover(item)}
//             />
//           );
//         })}

//       <MarkerF
//         title={locationDataa[0].name}
//         position={locationDataa[0].location}
//         options={options}
//         animation={2}
//         visible={true}
//         icon={iconorigin}
//       />
//       <MarkerF
//         title={locationDataa[locationDataa.length - 1].name}
//         position={locationDataa[locationDataa.length - 1].location}
//         options={options}
//         animation={2}
//         visible={true}
//         icon={icon}
//       />
//       <PolylineF path={paths} options={{ path: paths, strokeOpacity: 1 }} />
//     </GoogleMap>
//     </div>
    
//   ) : null;
// }

export default MyMapComponent;
