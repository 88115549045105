import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Alert, AlertTitle, Box, Grid, Step, StepContent, Stepper } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import { TransitionProps } from "@mui/material/transitions";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MyMapComponent from "../Helpers/Maps";
import Charts from "../Helpers/Charts";
import IntransitFileViewer from "../Helpers/IntransitFileViewer";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useState } from "react";
import '../Styles.css'

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import axios from "axios";
import { display } from "@mui/system";
const steps = [
  // {
    // label: 'Houston',
    // subLabel: '(Destination Port)',
    // subData: [
      // {
      //   label: 'Empty Container Gate In',
      //   date: "01/20/2023 07:29 AM",
      //   duration: '1 day',
      //   location: 'Houston',
      // },
      // {
      //   label: 'Full Container Gate Out',
      //   date: "01/19/2023 10:38 AM",
      //   duration: '15 days',
      //   location: 'Houston',
      // },
      // {
      //   label: 'Discharge from Vessel',
      //   date: "01/04/2023 07:39 PM",
      //   duration: '2 days',
      //   location: 'Houston',
      // },
      // {
      //   label: 'Vessel Arrival',
      //   date: "01/02/2023 06:24 PM",
      //   duration: '35 days',
      //   location: 'Houston',
      // },

    // ]
  // },
  // {
  //   label: 'Hamburg',
  //   subLabel: '(Transshipment Port)',
  //   subData: [
      {
        label: 'Vessel Departure',
        date: "11/28/2022 01:19 PM",
        duration: '1 day',
        location: 'Hamburg',
      },
      {
        label: 'Load on Vessel',
        date: "11/27/2022 07:52 PM",
        duration: '8 days',
        location: 'Hamburg',
      },
      {
        label: 'Discharge from Vessel',
        date: "11/19/2022 03:11 PM",
        duration: '5 hrs',
        location: 'Hamburg',
      },
      {
        label: 'Vessel Arrival',
        date: "11/19/2022 10:06 AM",
        duration: '4 days',
        location: 'Hamburg',
      },

  //   ]
  // },
  // {
  //   label: 'Hamad',
  //   subLabel: '(Origin Port)',
  //   subData: [
      {
        label: 'Vessel Departure',
        date: "11/15/2022 04:50 PM",
        duration: '1 day',
        location: 'Hamad',
      },
      {
        label: 'Load on Vessel',
        date: "11/15/2022 12:35 AM",
        duration: '2 days',
        location: 'Hamad',
      },
      {
        label: 'Full Container Gate In',
        date: "11/13/2022 07:56 AM",
        duration: '3 days',
        location: 'Hamad',
      },

  //   ]
  // },
  // {
  //   label: 'Shenyang',
  //   subData: [{
    {
      label: 'Empty Container Gate Out',
      date: "11/10/2022 11:00 AM",
      duration: '',
      location: 'Shenyang',
    }
  //   }]
  // }
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Full(props: any) {
  let locations: { name: any; location: { lat: any; lng: any } }[] = [];
  console.log("Full screen Data 123456789", props.data);
  const [value, setValue] = useState("1");
  const [sort, setSort] = useState(false);
  const [mapData, setMapData] = useState<any>(props.line.legInfo);
  const [shipmentEventData, setShipmentEventData] = useState<any>([]);
  const [response,setResponse]=useState([]);

  // ------------- events ----------------

  const handleNext = () => {
  };

  const handleBack = () => {
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  React.useEffect(() => {
    console.log("data from Intransit Shipments", props);
    getEventData(
      props.line.containerNumber,
      props.line.hscode,
      props.line.invoiceNumber
    );
  }, []);

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  
  const getEventData = (data: any, hs: any, inv: any) => {
    let value =props.line.shipmentReference?props.line.shipmentReference:""
    console.log("shipment Reference Number.....",props.line.shipmentReference,props.line,data)
    axios
      .post(
        "https://api.ext.realtrace.realware.tech/po/eventQuery",
        {
          containerNumber: data,
          hscode: hs,
          invoiceNumber: inv,
        },
        config
      )
      .then((evedata) => {
        if (evedata.data.primary.length > 0) {
          console.log("Event Data.....", evedata);
          setShipmentEventData(evedata.data.primary);
        } else {
        }
      });
    // axios.get(`https://api.ext.realtrace.realware.tech/cargoEs/fetchShipments/${value}`,config).then((res:any)=>{
    //   console.log(res.data.primary[0])
    //   setResponse(res.data.primary[0])
    //   setShipmentEventData(res.data.primary[0].shipmentEvents)
    // }).catch((error:any)=>{
    //   console.log(error)
    // })
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  console.log("1566666666", shipmentEventData);
  for (let i = 0; i < shipmentEventData.length; i++) {
    if (shipmentEventData[i].originLatitide > 0) {
      let obj = {
        name: shipmentEventData[i].origin,
        location: {
          lat: parseFloat(shipmentEventData[i].originLatitide),
          lng: parseFloat(shipmentEventData[i].originLongitude),
        },
      };
      locations.push(obj);
      let obj2 = {
        name: shipmentEventData[i].destination,
        location: {
          lat: parseFloat(shipmentEventData[i].desitinationLatitude),
          lng: parseFloat(shipmentEventData[i].destinationLongitude),
        },
      };
      locations.push(obj2);
      break;
    }
  }

  const reverse = () => {
    setShipmentEventData(shipmentEventData.reverse());
    setSort(sort ? false : true);
  };
  console.log(locations);
  const [activeStep, setActiveStep] = React.useState(shipmentEventData.length);
  return (
    <div className="dialog">
     
      <Dialog
        fullScreen
        open={props.onOpen}
        onClose={props.onClose}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ display: "flex", width: "70%", alignItems: "center" }}>
              <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
                HBL # :
                <span className="dialogTitle">{props.line.hblNumber}</span>{" "}
                &nbsp;&nbsp; MBL # :{" "}
                <span className="dialogTitle blueColor">
                  {props.line.mblNumber}
                </span>{" "}
                &nbsp;&nbsp;
                {sessionStorage.getItem("role") === "carrier" ? (
                  <span>
                    CARRIER REF. # :
                    <span className="dialogTitle greyColor ">
                      {props.line.carrierReference}
                    </span>{" "}
                  </span>
                ) : 
                   (
                    <span>
                      FORWARD REF. # :{" "}
                      <span className="dialogTitle greyColor">
                        {props.line.bookingReference}
                      </span>{" "}
                    </span>
                  )
                }
              </Typography>
               <Box
                sx={{
                  display: "flex",

                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <LocationOnIcon fontSize="medium" sx={{ color: "#ff9000" }} />
                  <p style={{ fontSize: "14px" }}>United Kingdom</p>
                  {/* <p style={{ fontSize: "14px" }}>{props.line.pickupCountry}</p> */}
                </Box>
                &nbsp;
                <Box sx={{ marginBottom: "18px", marginRight: "15px" }}>
                  <span>
                    <ArrowRightAltIcon fontSize="large" />
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <LocationOnIcon sx={{ color: "#42f59b" }} fontSize="medium" />
                  <p style={{ fontSize: "14px" }}>
                    Lebanon
                   
                  </p>
                  {/* <p style={{ fontSize: "14px" }}>
                    {props.line.dropoffCountry}
                   
                  </p> */}
                </Box>
              </Box> 
            </Box>
            <Button
              autoFocus
              color="inherit"
              onClick={props.onClose}
              sx={{ marginLeft: "auto" }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            margin: "3px",
          }}
        >
          <Box
            sx={{
              margin: "5px",
              width: "30%",
              height: "inherit",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography
                sx={{
                 
                  textAlign: "left",
                  fontSize: "18px",
                  marginLeft: "20px",
                }}
              >
                EVENTS
              </Typography>

              <IconButton>
                {sort ? (
                  <ArrowUpwardIcon onClick={() => reverse()} />
                ) : (
                  <ArrowDownwardIcon onClick={() => reverse()} />
                )}
              </IconButton>
            </Box>
           
           
              <Stepper
                 activeStep={shipmentEventData.length - 1}
                orientation="vertical"
                sx={{
                  display: "flex",
                  border: "2px soild red",
                  paddingLeft: "10px",
                }}
              >
                {shipmentEventData.map((step: any, index: any) => (
                  <Step key={index}>
                    <StepLabel>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {step.shipmentEventName}
                        <br></br>
                        {step.shipmentEventDate}
                        <br></br>
                      
                      </Typography>
                      {/* <Typography
                        sx={{
                          fontSize: "12px",
                          color: "black",
                        
                        }}
                      >
                        {step.actualTime}
                      </Typography> */}
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "black",
                        
                        }}
                      >
                        {step.ShipmentEventTime}
                      </Typography>
                     
                    </StepLabel>
                    {/* <StepContent>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "300px",
                          textTransform: "capitalize",
                        }}
                      >
                        {step.location}
                      </Typography>
                    </StepContent> */}
                  </Step>
                ))}
              </Stepper>

          
          </Box>

          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Maps" value="1" />
                  <Tab label="Track Temperature" value="2" />
                  <Tab label="Shipment Documents" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <MyMapComponent
                  locations={mapData}
                  sx={{ height: "fit-content" }}
                />
              </TabPanel>
              <TabPanel value="2">
                <Charts />
              </TabPanel>
              <TabPanel value="3">
                <IntransitFileViewer data={props.line.invoiceNumber} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box> 
        

       
      </Dialog>
    </div>
  );
}
