import { Box, Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import axios from "axios";
import { Button, TextField } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import { IconButton, Paper } from "@mui/material";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { Alert, AlertTitle } from "@mui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function IntransitFileViewer(props: any) {
  useEffect(() => {
    console.log("Intrnasit File Viewer Screen .....");
  }, []);

  console.log(props.data);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const [referencenumber, setReferenceNumber] = useState<any>("");
  const [showgrid, setShowGrid] = useState(true);
  const handleRefNumber = (e: any) => {
    setReferenceNumber(e.target.value.toLowerCase());
  };
  const parameters = props.data.split("-");
  const docref = parameters[0] === "INV" ? "INVOICE" : parameters[0];
  const goptions = {
    docAgainst: docref,
    documentReference: props.data,
  };

  const gconfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: goptions,
  };
const dummyData = [{
  documentAgainstId:'PO-24A00754',
  docExtension:'PDF',
  originalName:'Purchase Order',
  documentReference:'EXIM001',
  uploadedBy:'Consignee001'
},{
  documentAgainstId:'INV-0903',
  docExtension:'PDF',
  originalName:'Proforma Invoice',
  documentReference:'EXIM002',
  uploadedBy:'Shipper001'

},
{
  documentAgainstId:'INV-0903',
  docExtension:'PDF',
  originalName:'Commercial Invoice',
  documentReference:'EXIM003',
  uploadedBy:'Shipper001'

},{
  documentAgainstId:'HBL-002',
  docExtension:'PDF',
  originalName:'Packing List',
  documentReference:'EXIM004',
  uploadedBy:'Shipper001'

},{
  documentAgainstId:'MBL-002',
  docExtension:'PDF',
  originalName:'Export and Import License',
  documentReference:'EXIM005',
  uploadedBy:'Shipper001'

},
{
  documentAgainstId:'CRR-0903',
  docExtension:'PDF',
  originalName:'Certificate Of Origin',
  documentReference:'EXIM006',
  uploadedBy:'Shipper001'

},
{
  documentAgainstId:'HBL-002',
  docExtension:'PDF',
  originalName:'Dangerouse Goods Declaration',
  documentReference:'EXIM007',
  uploadedBy:'Shipper001'

},{
  documentAgainstId:'MBL-002',
  docExtension:'PDF',
  originalName:'Technical Certificate',
  documentReference:'EXIM008',
  uploadedBy:'Shipper001'

},
{
  documentAgainstId:'HBL-002',
  docExtension:'PDF',
  originalName:'Insurance Certificate',
  documentReference:'EXIM009',
  uploadedBy:'Shipper001'

},
{
  documentAgainstId:'HBL-002',
  docExtension:'PDF',
  originalName:'Bill Of Lading',
  documentReference:'EXIM010',
  uploadedBy:'Forwarder001'

},
{
  documentAgainstId:'CRR-0903',
  docExtension:'PDF',
  originalName:'Export Declaration Document',
  documentReference:'EXIM011',
  uploadedBy:'cha001'

},
{
  documentAgainstId:'CRR-0903',
  docExtension:'PDF',
  originalName:'Import Declaration Document',
  documentReference:'EXIM012',
  uploadedBy:'cha001'

},
{
  documentAgainstId:'MBL-002',
  docExtension:'PDF',
  originalName:'Duties & Tax Payment Receipt',
  documentReference:'EXIM013',
  uploadedBy:'cha001'

},
{
  documentAgainstId:'WH-0903',
  docExtension:'PDF',
  originalName:'Ware House GRN',
  documentReference:'EXIM014',
  uploadedBy:'warehouse001'

},
{
  documentAgainstId:'WH-0903',
  docExtension:'PDF',
  originalName:'Ware House Payment Receipt',
  documentReference:'EXIM015',
  uploadedBy:'warehouse001'

},
{
  documentAgainstId:'WH-0903',
  docExtension:'PDF',
  originalName:'Ware House Dispatch Document',
  documentReference:'EXIM016',
  uploadedBy:'warehouse001'

},
]
  const url =
    // "http://192.168.1.129/api/v1/documents/getDocs?docAgainst=INVOICE&documentReference=INV-101";
    "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/documents/getDocs";

  // function getDocument() {
  //   axios
  //     .get(
  //       "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/documents/getDocs",
  //       gconfig
  //     )
  //     .then((res: any) => {
  //       setRowData(res.data.data);
  //       console.log(res.data.data);
  //     });
  // }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>(dummyData);
  const [columnDefs] = useState([
    {
      headerName: "DOCUMNET AGAINST ID",
      filter: true,
      headerTooltip: "DOCUMNET AGAINST ID",
      tooltipField: "documentAgainstId",
      field: "documentAgainstId",
    },
    {
      headerName: "DOCUMNET TYPE",
      filter: true,
      headerTooltip: "DOCUMNET TYPE",
      tooltipField: "docExtension",
      field: "docExtension",
    },
    {
      headerName: "FILE NAME",
      filter: true,
      headerTooltip: "FILE NAME",
      tooltipField: "originalName",
      field: "originalName",
    },
    {
      headerName: "REFERENCE #",
      filter: true,
      headerTooltip: "REFERENCE #",
      tooltipField: "documentReference",
      field: "documentReference",
    },
    {
      headerName: "UPLOADED BY",
      filter: true,
      headerTooltip: "UPLOADED BY",
      tooltipField: "uploadedBy",
      field: "uploadedBy",
    },
    {
      headerName: "VIEW DOC",
      filter: true,
      headerTooltip: "VIEW DOC",
      tooltipField: "action",
      field: "docPath",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a href={process.env.PUBLIC_URL + "/Bill of Lading Form.pdf"} target="_blank">
            <IconButton
              sx={{ padding: "0", margin: "0" }}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <VisibilitySharpIcon />
            </IconButton>
          </a>
        );
      },
    },
  ]);
  const gridRef = useRef<any>();

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    axios
      .get(url, gconfig)
      .then((res) => {
        console.log("MANAGE DOCUMNETS API RESPONSE..", res);
        if (res.data.data.length === 0) {
          setShowGrid(false);
        } else {
          setRowData(res.data.data);
        }
        //autosize();
        
      })
      .catch((err: any) => {
        setShowGrid(false);
      });
    // setRowData(dummyData)
    // setShowGrid(true)
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
        
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={dummyData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
              ref={gridRef}
              onGridReady={onGridReady}
              // onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          {/* ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No {docref} Douments Found On Reference Number {props.data}
            </Alert>
          )} */}
        </Box>
      </Paper>
    </div>
  );
}

export default IntransitFileViewer;
