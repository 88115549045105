import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'

import ReactECharts from 'echarts-for-react';
import axios from 'axios';
export default function (props: any) {
    let url = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/deliveredShipmentReliability'
    let data = props.data === 'overall' ? ['Overall Reliability', 'Other Reliability'] :
        props.data === 'pickUp' ? ['Pick Up Reliability', 'Other Reliability'] :
            ['Drop Off Reliability', 'Other Reliability']

    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const [shipmentInfo, setShipmentInfo] = useState(false)

    const onEvents = {
        click: onChartClick,
    };
    function onChartClick(params: any) {

        shipmentsMoreInfoList()

    }
    const shipmentsMoreInfoList = () => {
        setShipmentInfo(true);
        props.parentCallback(true)
    }
    useEffect(() => {
        let status = props.data1
        console.log(props, "status")
        reliablityInfo(status)
    }, [])
    const reliablityInfo = (status: any) => {
        config.params = status

        axios.get(url, config).then((res: any) => {
            console.log(res.data.data, "respppppp")
        })
    }
    return (
        <div>
            <Paper>
                <ReactECharts
                    option={{
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            bottom: 0,

                            data: data
                        },
                        color: ['#004F92', '#008DC0'],
                        series: [
                            {
                                type: 'pie',
                                data: [
                                    {
                                        value: 335,
                                        name: data[0]
                                    },
                                    {
                                        value: 234,
                                        name: data[1]
                                    }

                                ],
                                radius: ['40%', '70%']
                            }
                        ],
                    }}
                    onEvents={onEvents} />
            </Paper>
        </div>
    )
}
