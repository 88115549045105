import { AgGridReact } from "ag-grid-react";
import {
  GridReadyEvent,
  ICellRendererParams,
  FirstDataRenderedEvent,
} from "ag-grid-community";

import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Alert, AlertTitle, Button, Paper } from "@mui/material";
import CrLineItems from "./CarrierLineItems";
import { Typography } from "@material-ui/core";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import "../../../Styles.css";
import { useOutletContext } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function RejectedByCarrier() {
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);
    setOpenDetail(true);
  };

  const [columnDefs] = useState([
    {
      headerName: "INVOICE NUMBER",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{ color: "blue", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "INVOICE DATE",
      field: "invoiceDate",
      filter: true,
      headerTooltip: "INVOICE DATE",
      tooltipField: "invoiceDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      filter: "true",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
    },
    {
      headerName: "FORWARDER BOOKING REFERENCE NUMBER",
      filter: true,
      headerTooltip: "FORWARDER BOOKING REFERENCE NUMBER",
      tooltipField: "bookingReference",
      field: "bookingReference",
    },
    {
      headerName: "CARRIER REFERENCE",
      field: "carrierReference",
      filter: true,
      headerTooltip: "CARRIER REFERENCE",
      tooltipField: "carrierReference",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },

    // {
    //   headerName: "PICK UP LOCATION",
    //   field: "pickupLocation",
    //   filter: true,
    //   headerTooltip: "PICK UP LOCATION",
    //   tooltipField: "pickupLocation",
    // },
    {
      headerName: "PICK UP LOCATION(GLN)",
      field: "pickupLocation",
      filter: true,
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
    },

    {
      headerName: "PICKUP COUNTRY",
      filter: true,
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupLocation",
      field: "pickupCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "PICKUP DATE",
      filter: true,
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickupDate",
      field: "pickupDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP ZIP CODE",
      filter: true,
      headerTooltip: "PICKUP ZIP CODE",
      tooltipField: "pickupZipCode",
      field: "pickupDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CONSIGNEE ID",
      filter: true,
      headerTooltip: "CONSIGNEE ID",
      field: "consigneeId",
      tooltipField: "consigneeId",
    },
    {
      headerName: "CONSIGNEE NAME",
      filter: true,
      headerTooltip: "CONSIGNEE NAME",
      field: "consigneeName",
      tooltipField: "consigneeName",
      columnGroupShow: "closed",
    },
    {
      headerName: "CONSIGNEE MOBILE",
      filter: true,
      headerTooltip: "CONSIGNEE MOBILE",
      field: "consingneeContactNumber",
      tooltipField: "consingneeContactNumber",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CONSIGNEE EMAIL",
      filter: true,
      headerTooltip: "CONSIGNEE EMAIL",
      field: "consigneeEamilAddress",
      tooltipField: "consigneeEamilAddress",
      columnGroupShow: "closed",
    },

    {
      headerName: "SHIPPER ID",
      filter: true,
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "shipperId",
    },
    {
      headerName: "SHIPPER NAME",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "shipperName",
      field: "shipperName",
      columnGroupShow: "closed",
    },
    {
      headerName: "SHIPPER DATE",
      filter: true,
      headerTooltip: "SHIPPER DATE",
      tooltipField: "requestedDateOfDelivery",
      field: "requestedDateOfDelivery",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    // {
    //   headerName: "SHIPPER VOLUME",
    //   filter: true,
    //   headerTooltip: "SHIPPER VOLUME",
    //   tooltipField: "shipperVolume",
    //   field: "shipperVolume",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "SHIPPER WEIGHT",
    //   filter: true,
    //   headerTooltip: "SHIPPER WEIGHT",
    //   tooltipField: "shipperWeight",
    //   field: "shipperWeight",
    //   columnGroupShow: "closed",
    // },
    {
      headerName: "MODE OF SHIPMENT",
      filter: true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
      columnGroupShow: "closed",
    },

    // {
    //   headerName: "DROPOFF LOCATION",
    //   filter: true,
    //   headerTooltip: "DROPOFF LOCATION",
    //   tooltipField: "dropoffLocation",
    //   field: "dropoffLocation",
    // },
    {
      headerName: "DROPOFF LOCATION(GLN)",
      filter: true,
      headerTooltip: "DROPOFF LOCATION",
      tooltipField: "dropoffLocation",
      field: "dropoffLocation",
    },
    {
      headerName: "DROPOFF COUNTRY",
      filter: true,
      headerTooltip: "DROPOFF COUNTRTY",
      tooltipField: "dropoffCountry",
      field: "dropoffCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF ZIP CODE",
      filter: true,
      headerTooltip: "DROPOFF ZIP CODE",
      tooltipField: "dropoffZipCode",
      field: "dropoffZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "MODE OF SHIPMENT",
      filter: true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
      columnGroupShow: "closed",
    },
    {
      headerName: "CARRIER CREATE DATE",
      filter: true,
      headerTooltip: "CARRIER CREATE DATE",
      tooltipField: "crCreatedDate",
      field: "crCreatedDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "BOOKING REQUEST UPDATE DATE",
      filter: true,
      headerTooltip: "BOOKING REQUEST UPDATE DATE",
      tooltipField: "brPickupDate",
      field: "brPickupDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "REJECTED BY",
      filter: true,
      headerTooltip: "REJECTED BY",
      tooltipField: "modifiedBy",
      field: "modifiedBy",
    },
    {
      headerName: "REJECTED DATE",
      filter: true,
      headerTooltip: "REJECTED DATE",
      tooltipField: "crModifiedDate",
      field: "crModifiedDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "INCO TERMS",
      filter: true,
      headerTooltip: "INCO TERMS",
      tooltipField: "incoTerms",
      field: "incoTerms",
    },

    // {
    //   headerName: "STATUS ",
    //   filter: true,
    //   headerTooltip: "ACTIVE STATUS",
    //   tooltipField: "activeStatus",
    //   field: "activeStatus",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     let val = params.value.charAt(0).toUpperCase();
    //     let ans = val + params.value.slice(1).toLowerCase();
    //     return (
    //       <Box className="Active">
    //         <Typography>{ans}</Typography>
    //         <span className="active"></span>
    //       </Box>
    //     );
    //   },
    // },
  ]);

  //

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const url = Environment_Realtraceb_Dev.BASE_URL + "/carrierHeaderList";
  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    cbrStatus: "open,processed",
    brStatus: "rejected",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  useEffect(() => {
    setLoad(true);
    setShowGrid(true);
    axios.get(url, config).then((res) => {
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);

      //autosize();
    });
  }, [fromDate, toDate]);

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const gridRef = useRef<any>();

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={10}
              ref={gridRef}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <CrLineItems
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={PoData}
              status={"rejected"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
}

export default RejectedByCarrier;
