import React, { useEffect, useMemo, useState } from 'react'
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { Alert, AlertTitle, Box } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
export default function DeliveredShipmentsCharts(props:any) {
    const [shipmentInfo, setShipmentInfo] = useState(false)
    const [chartResp, setChartResp] = useState([])
    const [chartInfo, setChartInfo] = useState(false)
    const [data, setData] = useState([])
    const [yAxisData, setYAxisData] = useState([])
    const [avgMin, setAvgMin] = useState([])
    console.log(props, "prooppppppp")
    const yAxisNames = props.mapInfo ? ['Shipments'] : ''
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            }
        },
        color: ['#004F92', '#e19907'],
        legend: {
            bottom: 0,
            data: yAxisNames
        },
        xAxis: [
            {
                type: 'category',
                data: props.mapInfo.xAxisData,
                axisPointer: {
                    type: 'shadow'
                },
                // name: yAxisNames[0]
            },
        ],
        yAxis: [
            {
                type: 'value',
                name: yAxisNames[0],
                min: 0,
                max: 100,
                interval: 25,
                axisLabel: {
                    formatter: '{value}'
                }
            },
           
        ],
        series: [
            {
                name: yAxisNames[0],
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value: any) {
                        return (value as number);
                    }
                },
                data:props.mapInfo.yAxisData
            },
            {
                name: yAxisNames[1],
                data: props.mapInfo.xAxisData
            }
        ],
    }
    const onEvents = {
        click: onChartClick,
    };
    function onChartClick(params: any) {
        console.log(params, "$params")
        shipmentsMoreInfoList(params)

    }
    const shipmentsMoreInfoList = (parmas: any) => {
        setShipmentInfo(true);
        chartResp.filter((item: any) => {
            if (parmas.name === item.carrierName) {
                props.parentCallback({ status: true, carrier: item.shipmentsData, carrierName: parmas.name })
            }
        })

    }
    return (
        <div>
            <Box >
                <ReactECharts option={options}
                    onEvents={onEvents} />

            </Box>


        </div>
    )
}
