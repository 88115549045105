//Screen For Purchase Order Item Details It shows details of item and  details of PO with Qr scanner
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { makeStyles, Theme } from "@material-ui/core/styles";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import "./ItemDetail.css";
import { DesgService } from "../Services/DesgService";
import { IServerSideDatasource } from "ag-grid-enterprise";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import colorConfigs from "../Config/colorConfig";
import sizeConfigs from "../Config/sizeConfigs";
import "../Styles.css";
import axios from "axios";
import { Environment_Realtraceb_Dev } from "../Enviornment/Environment_Realtraceb_Dev";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "400",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

//Transition for For Full Screen Dialogue

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Component

function ItemDetail(props: any) {
  const classes = useStyles();
  const [showgrid, setShowGrid] = useState(true);
  const [rowData, setRowData] = useState([]);
  const desgService = new DesgService();
  let obj: any = {};
  let rowdata: any = [];
  let prevstart: any;
  let finalImage: any;
  const qrimage = "https://api.ext.realtrace.realware.tech/qrImage/";
  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    // poStatus: "accepted",
    poID:props.data.poID
   
  };
  let url = Environment_Realtraceb_Dev.BASE_URL + "/purchaseOrderItemList";
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };
  useEffect(() => {
    console.log("Props of ItemDetails.. ", props.data);
    axios.get(url, config).then((res) => {
      setShowGrid(true);
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        // setLoad(false);
      }
      setRowData(res.data.primary);
      // setLoad(false);
      // console.log(res.data.primary);
    });
  }, []);
  let link = props.data.qrImage.split("/");
  console.log("ugfawefgak", link);
  finalImage = qrimage + link[3];
  //Logic To Build Dynamic Ag-Grid
 
 
  const [columnDefs] = useState([
    {
      headerName: "LINE #",
      field: "number",
      filter: true,
      headerTooltip: "LINE #",
      tooltipField: "number",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "SHIPMENT #",
      field: "shipmentNumber",
      filter: true,
      headerTooltip: "SHIP #",
      tooltipField: "shipmentNumber",
      cellClass: "ag-left-aligned-cell",
    },
    // {
    //   headerName: "ITEM TYPE",
    //   field: "partType",
    //   filter: true,
    //   headerTooltip: "ITEM TYPE",
    //   tooltipField: "partType",
    // },
    {
      headerName: "DRUG TYPE",
      field: "partType",
      filter: true,
      headerTooltip: "DRUG TYPE",
      tooltipField: "partType",
    },
    {
      headerName: "CATEGORY",
      field: "category",
      filter: true,
      headerTooltip: "CATEGORY",
      tooltipField: "category",
    },
    // {
    //   headerName: "ITEM ID",
    //   field: "partId",
    //   filter: true,
    //   headerTooltip: "ITEM ID",
    //   tooltipField: "partId",
    // },
    {
      headerName: "NDC",
      field: "partId",
      filter: true,
      headerTooltip: "NDC",
      tooltipField: "partId",
    },

    {
      headerName: "DRUG NAME",
      field: "partDescription",
      filter: true,
      headerTooltip: "DRUG NAME",
      tooltipField: "partDescription",
    },
    {
      headerName: "QUANTITY ",
      field: "quantity",
      filter: true,
      headerTooltip: "QTY (UNITS)",
      tooltipField: "quantity",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "UOM ",
      field: "secondaryUom",
      filter: true,
      headerTooltip: "QTY (UNITS)",
      tooltipField: "quantity",
    },
    {
      headerName: "NEED BY DATE",
      field: "poNeedByDate",
      filter: true,
      headerTooltip: "NEED BY DATE",
      tooltipField: "poNeedByDate",
      cellClass: "ag-left-aligned-cell",
    },
  ]);
 
  const onGridReady = useCallback((params: GridReadyEvent) => {
    console.log(params, "$$");
    params.api!.setServerSideDatasource(dataSorce);
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const dataSorce: IServerSideDatasource = {
    getRows: (params) => {
      obj = {};
      if (rowdata === undefined) {
        console.log(rowdata, "$row");
      } else {
        console.log(rowdata[rowdata.length - 1], "length data");
      }
      console.log(params, "$params");
      console.log(rowdata, "rowdata length");
      obj["start"] = params.request.startRow;
      console.log("startRow", Object.keys(params.request.filterModel));
      obj["end"] = params.request.endRow;
      obj["prevStart"] = prevstart !== undefined ? prevstart : "";
      obj["poID"] = props.data.poID;
      // console.log('rowData::: ', params, this.rowData,this.rowData[this.rowData.length - 1])
      // obj['objectEnd'] = (rowdata[rowdata.length - 1] !== undefined) && (rowdata[rowdata.length - 1] !== null) ? rowdata[rowdata.length - 1]._id : '';
      // obj['objectEnd'] = rowdata != undefined ? '63983d11ab35a6bd260018ef' : '',
      // obj["poStatus"] = "open";

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };
      desgService.getPoOrderItemsList(obj, headers).then((res: any) => {
        console.log(res.data.primary, "resp");
        console.log(rowdata, "rowdaata");

        if (res.data.status.code === "SUCCESS") {
          if (res.data.primary.length === 0) {
            setShowGrid(false);
          }
          params.success({
            rowData: res.data.primary,
            rowCount: res.data.totalRowsCount,
          });
        } else {
          params.fail();
          setShowGrid(false);
        }
        rowdata = res.data.primary;
      });
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };
  const domLayout = "autoHeight";

  return (
    <div className="dialog">
      <Dialog
        fullScreen
        open={props.onOpen}
        onClose={props.onClose}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
              PURCHASE ORDER # :{" "}
              <span className="dialogTitle lightGreenColor">
                {" "}
                {props.data.purchaseOrderNumber}
              </span>
            </Typography>
            <Button autoFocus color="inherit" onClick={props.onClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        {/* po details ,customer detais and Qr scnner */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            width: "99%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px",
              width: "35%",
              justifyContent: "space-around",
              backgroundColor: "#f5f4f7",
            }}
          >
            <Box sx={{ height: "fit-content" }}>
              <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
                PURCHASE ORDER DETAILS
              </Typography>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                    Purchase Order Number
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {props.data.purchaseOrderNumber}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                    Purchase Order Date
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {" "}
                    {props.data.poDate}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                    Purchase Order Version
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {" "}
                    {props.data.purchaseOrderVersion}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                height: "fit-content",
                paddingTop: "5%",
                paddingBottom: "5%",
              }}
            >
              {sessionStorage.getItem("role") === "consignee" ? (
                <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
                  SUPPLIER DETAILS
                </Typography>
              ) : (
                <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
                  CUSTOMER DETAILS
                </Typography>
              )}

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                    {sessionStorage.getItem("role") === "consignee"
                      ? "Supplier Id"
                      : "Customer Id"}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {props.data.customerId}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                    {sessionStorage.getItem("role") === "consignee"
                      ? "Supplier Name"
                      : "Customer Name"}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {" "}
                    {props.data.customerName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                    Shipping To
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {props.data.shipToAddress}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: "#f5f4f7",
                paddingTop: "2%",
              }}
            >
              <Typography sx={{ textAlign: "left", paddingBottom: "2%" }}>
                QR CODE
              </Typography>
              <img
                src={finalImage}
                width={"65%"}
                height={"80%"}
                alt="Qr Scanner"
                className="qrcode"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "60%",
              width: "100%",
            }}
          >
            {/* REACT AG GRID */}
            <Box
              sx={{
                width: "100%",
                padding: "10px",
                height: "fit-content",
              }}
            >
              <div className={classes.root}>
                <Box style={{ width: "100%", marginTop: "2%" }}>
                  {/* <div>
                    <h2>LINE ITEMS</h2>
                  </div> */}
                  <Typography>LINE ITEMS</Typography>
                  {showgrid ? (
                    <AgGridReact
                      className="ag-theme-balham"
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      animateRows={true}
                      domLayout={domLayout}
                      // rowModelType={"serverSide"}
                      rowData={rowData}
                      // serverSideInfiniteScroll={true}
                      // rowData={rowdata}
                      // masterDetail={true}
                      detailRowAutoHeight={false}
                      pagination={true}
                      paginationPageSize={4}
                      sideBar={sideBar}
                      onFirstDataRendered={onFirstDataRendered}
                      onGridReady={onGridReady}
                    ></AgGridReact>
                  ) : (
                    <Alert severity="info">
                      <AlertTitle>Info</AlertTitle>
                      No Data Found
                    </Alert>
                  )}
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

export default ItemDetail;
