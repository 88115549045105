import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogTitle,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "./trcuk.css";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

import axios from "axios";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { useFormik } from "formik";
import swal from "sweetalert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useOutletContext } from "react-router-dom";
import TooltipCom from "../../../Helpers/ToolTipComponent";
import BrLineItems from "../Forwarder/BrLineItems";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function ShipmentSideWh() {
  const [truckopen, setTruckOpen] = useState(false);
  const [load, setLoad, fromDate, toDate, setTd, setFd] =
    useOutletContext<any>();
  const [showgrid, setShowGrid] = useState(true);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [god, setGod] = useState<Dayjs | any>(null);
  const [got, setGot] = useState<Dayjs | any>(null);
  const [poNumber, setPoNumber] = useState<any>([]);
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);
    setOpenDetail(true);
  };

  // const [itemdesc,setItemDesc]=useState("")
  const handletruckOpen = () => {
    let data = gridRef.current.api.getSelectedRows();
    console.log(data);
    if (data.length === 0) {
      swal("info", "Please Select WareHouse Shipments", "info");
    } else {
      setTruckOpen(true);
      let pos: any = [];
      data.map((ele: any, index: number) => {
        pos.push(ele.itemName);
      });
      setPoNumber(pos);
    
    }
    setGod(null);
    setGot(null);
  };

  const updategateinfo = (payload: any) => {
    axios
      .post(
        "https://api.ext.realtrace.realware.tech/po/updateGateOutInfo",
        payload,
        postconfig
      )
      .then((res: any) => {
        if (res.status === 200) {
          swal("Update", res.data.primary, "success");
        }
      });
    // fileref.current.reset();
    setTruckOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      gateOutDate: "",
      gateOutTime: "",
    },
    onSubmit: (values) => {
      console.log(values);
      setDate(values.gateOutDate);
      setTime(values.gateOutTime);
      handleUpdate(values.gateOutDate, values.gateOutTime);
    },
  });
  const [columnDefs] = useState([
    {
      // headerName: "SELECT ",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      hide: sessionStorage.getItem("role") === "wh" ? false : true,
    },
    {
      headerName: "INVOICE NUMBER",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      // cellRenderer: (params: ICellRendererParams) => {
      //   return (
      //     <a
      //       style={{ color: "blue", lineHeight: 0.5 }}
      //       onClick={() => handleData(params)}
      //       className="btn btn-info"
      //     >
      //       {params.value}
      //     </a>
      //   );
      // },
    },
    {
      headerName: "CHECKIN DATE",
      filter: true,
      headerTooltip: "CHECKIN DATE",
      tooltipField: "checkInDate",
      field: "checkInDate",
    },
    {
      headerName: "GATEIN DATE",
      filter: true,
      headerTooltip: "GATEIN DATE",
      tooltipField: "gateInDate",
      field: "gateInDate",
    },
    {
      headerName: "GATEIN TIME",
      filter: true,
      headerTooltip: "GATEIN TIME",
      tooltipField: "gateInTime",
      field: "gateInTime",
    },
    {
      headerName: "WAREHOUSE ID",
      filter: true,
      headerTooltip: "WAREHOUSE ID",
      tooltipField: "whID",
      field: "whId",
    },
    {
      headerName: "WAREHOUSE NAME",
      filter: true,
      headerTooltip: "WAREHOUSE NAME",
      tooltipField: "whName",
      field: "whName",
    },
    {
      headerName: "WAREHOUSE BOKKING REFERENCE",
      filter: true,
      headerTooltip: "WAREHOUSE BOKKING REFERENCE",
      tooltipField: "whID",
      field: "whBookingReference",
    },

    // {
    //   headerName: "ETD FROM WAREHOUSE",
    //   filter: true,
    //   headerTooltip: "ETD FROM WAREHOUSE",
    //   tooltipField: "etdFromWareHouse",
    //   field: "etdFromWareHouse",
    // },
    {
      headerName: "SHIPPER ID",
      filter: true,
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "carrierHeaderDetails.shipperId",
    },
    {
      headerName: "SHIPPER NAME",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "shipperName",
      field: "carrierHeaderDetails.shipperName",
    },
    {
      headerName: "COMMODITY TYPE",
      filter: true,
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
      field: "commodityType",
    },
    {
      headerName: "ITEM NAME",
      filter: true,
      field: "itemName",
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "ITEM DESCRIPTION",
      filter: true,
      field: "itemDescription",
      headerTooltip: "ITEM DESCRIPTION",
      tooltipField: "itemDescription",
    },
    {
      headerName: "BATCH NUMBER",
      filter: true,
      field: "batchNumber",
      headerTooltip: "BATCH NUMBER",
      tooltipField: "batch number",
    },
    {
      headerName: "LOT NUMBER",
      filter: true,
      field: "lotNumber",
      headerTooltip: "LOT NUMBER",
      tooltipField: "lotNumber",
    },
    {
      headerName: "SERIAL NUMBER",
      filter: true,
      field: "serialNumber",
      headerTooltip: "SERIAL NUMBER",
      tooltipField: "serialNumber",
    },
    {
      headerName: "PACKAGE TYPE",
      filter: true,
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "packageType",
      field: "packageDetails.type",
    },
    {
      headerName: "PACKAGE WEIGHT",
      filter: true,
      headerTooltip: "PACKAGE WEIGHT",
      tooltipField: "packageWeight",
      field: "packageDetails.weight",
    },

    {
      headerName: "PACKAGE LENGTH",
      filter: true,
      headerTooltip: "LENGTH",
      tooltipField: "length",
      field: "packageDetails.length",
    },
    {
      headerName: "PACKAGE WIDTH",
      filter: true,
      headerTooltip: "WIDTH",
      tooltipField: "width",
      field: "packageDetails.width",
    },
    {
      headerName: "PACKAGE HEIGHT",
      filter: true,
      headerTooltip: "HEIGHT",
      tooltipField: "height",
      field: "packageDetails.height",
    },

    {
      headerName: "HAZARDOUS GOODS",
      filter: true,
      headerTooltip: "HAZARDOUS GOODS",
      tooltipField: "hazardousGoods",
      field: "hazardousGoods",
    },
    {
      headerName: "SPECIAL INSTRUCTIONS",
      field: "packageDetails.specialInstructions",
      filter: true,
      headerTooltip: "SPECIAL INSTRUCTIONS",
      tooltipField: "packagespecialinstructions",
    },
  ]);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };
  const [role, setRole] = useState<any>(sessionStorage.getItem("role"));
  let options = {
    whTruckStatus: "gateIn",
    type: role,
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
  };
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };
  const postconfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    setLoad(true);
    shipmentsatwh();
  }, [fromDate, toDate]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  const gridRef = useRef<any>();

  const url = Environment_Realtraceb_Dev.BASE_URL + "/shipmentsAtWh";
//useeffect api
  const shipmentsatwh = () => {
    setShowGrid(true);
    axios.get(url, config).then((res) => {
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
      // params.columnApi.autoSizeAllColumns(false);
    });
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // shipmentsatwh();
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  //update submit btn function
  const handleUpdate = (date: any, time: any) => {
    let data = gridRef.current.api.getSelectedRows();
    let payload: any = [];
    data.map((data: any, index: any) => {
      let obj = {
        bookingReference: data.bookingReference,
        carrierReference: data.carrierHeaderDetails.carrierReference,
        invoiceNumber: data.invoiceNumber,
        itemName: data.itemName,
        itemDescription: data.itemDescription,
        gateOutDate: moment(god).format("YYYY-MM-DD"),
        gateOutTime: moment(got.$d).format("HH:mm"),
      };
      payload.push(obj);
    });

    axios
      .post(
        "https://api.ext.realtrace.realware.tech/po/updateGateOutInfo",
        payload,
        postconfig
      )
      .then((res: any) => {
        if (res.status === 200) {
          swal("Update", res.data.primary, "success");
          shipmentsatwh();
        }
      });
    // fileref.current.reset();
    setTruckOpen(false);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.maincontent}>
        {sessionStorage.getItem("role") === "wh" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              sx={{ marginBottom: "10px" }}
              onClick={() => handletruckOpen()}
            >
              Update
            </Button>
          </div>
        ) : null}
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              paginationPageSize={10}
              pagination={true}
              ref={gridRef}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Box>
        <div>
          <Dialog
            fullWidth
            open={truckopen}
            onClose={()=>setTruckOpen(false)}
            sx={{ minWidth: "500px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <DialogTitle>
                Update Gate Out Details For Shipments :{" "}
                {poNumber.length > 1 ? (
                  <Tooltip title={<TooltipCom data={poNumber} />}>
                    <p> 1 or More Items</p>
                  </Tooltip>
                ) : (
                  <Typography>{poNumber[0]}</Typography>
                )}
              </DialogTitle>
              <Button onClick={()=>setTruckOpen(false)}>
                <CloseIcon />
              </Button>
            </Box>
            <Box
              sx={{
                paddingLeft: "30px",
                paddingBottom: "30px",
                paddingRight: "30px",
              }}
            >
              <form
                onSubmit={formik.handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "5px",
                }}
              >
                <Box sx={{ marginBottom: "15px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="GateOut Date"
                      inputFormat="DD/MM/YYYY"
                      value={god}
                      onChange={(newvalue: any) => setGod(newvalue)}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          required
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ marginBottom: "15px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="GateOut Time"
                      value={got}
                      onChange={(newvalue: any) => setGot(newvalue)}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          required
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ maxWidth: "25%" }}
                >
                  Submit
                </Button>
              </form>
            </Box>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="MM/DD/YYYY"
                // value={formik.values.gateInDate}
                // onChange={formik.handleChange}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider> */}
          </Dialog>
        </div>
      </Container>
      <div className="dialog">
        {openDetail ? (
          <BrLineItems
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={PoData}
            status={"accepted"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ShipmentSideWh;
