import { AgGridReact } from "ag-grid-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";

import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// import { Environment_Realtraceb_Dev } from "../../Enviornment/Environment_Realtraceb_Dev";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Paper,
  TextField,
  Tab,
  AppBar,
  Toolbar,
  Divider,
  Tooltip,
} from "@mui/material";

// import CrLineItems from "./CarrierLineItems";
import { Typography } from "@material-ui/core";
import DialogContentText from "@mui/material/DialogContentText";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import CrLineItems from "../Carrier/CarrierLineItems";
import swal from "sweetalert";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "../../../Styles.css";
import TooltipCom from "../../../Helpers/ToolTipComponent";
import moment from "moment";
// import "./Car_ack.css";

// import SimpleBar from "simplebar-react";
// import { Environment_Realtraceb_Dev } from "../Enviornment/Environment_Realtraceb_Dev";
// import CrLineItems from "../Components/Carrier/CarrierLineItems";
// import { PostRequest } from "../Services/BaseService";
// import swal from "sweetalert";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function ExportCustomClearence() {
  const inputRef = useRef<any>();
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState("");
  const [masterData, setMasterData] = useState<any>([]);
  const [masterdata] = useState([
    "Export Custom Declaration",
    "Export Physical Inspection",
    "Export Customs Released",
    "Export Goods On Hold",
  ]);
  const [exchastatus, setExChaStatus] = useState<any>("");
  const [remarks, setRemarks] = useState<any>("");
  const [PoData, setPoData] = useState<any>([]);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState(false);
  const gridRef = useRef<any>();
  const [openTrans, setOpenTrans] = useState(false);
  const [tranship, setTranship] = useState("");
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);

    setOpenDetail(true);
  };

  function chastatusUpdate() {}

  function handleEvent(e: any) {
    setEvent(e.target.value);
  }

  function closeUpdate() {
    setOpenUpdate(false);
  }

  function handletreanship() {
    let data = gridRef.current.api.getSelectedRows();
    let no = data.findIndex((key: any) => key.tranship === "no");
    let yes = data.findIndex((key: any) => key.tranship === "yes");
    console.log("Validation msg", no, yes);
    console.log(data);
    if (yes === -1 || no === -1) {
      setTranship(data);
      setOpenTrans(true);
    } else {
      toast.success("Select Either Yes or No Tranships !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  const nav = useNavigate();
  const managedoc = (params: any) => {
    console.log(params.data.purchaseOrderNumber);

    nav("/manage_documents", { state: params.data.invoiceNumber });
  };
  const [columnDefs] = useState([
    {
      //   headerName: "SELECT SHIPMENTS",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      // hide: sessionStorage.getItem("role") === "carrier" ? false : true,
    },

    {
      headerName: "INVOICE NUMBER",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button
            sx={{ height: 20, width: "100%", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      headerName: "INVOICE DATE",
      field: "invoiceDate",
      filter: true,
      headerTooltip: "INVOICE DATE",
      tooltipField: "invoiceDate",
    },
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      filter: "true",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
    },
    {
      headerName: "CUSTOMS STATUS",
      field: "exportChaStatus",
      filter: true,
      headerTooltip: "CUSTOMS STATUS",
      tooltipField: "exportChaStatus",
      // cellRenderer: (params: ICellRendererParams) => {
      //   return (
      //     <Tooltip title={params.data.}>

      //     </Tooltip>
      //   )
      // }
    },

    {
      headerName: "PICK UP LOCATION",
      field: "pickupLocation",
      filter: true,
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
    },

    {
      headerName: "PICKUP COUNTRY",
      filter: true,
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupLocation",
      field: "pickupCountry",
    },
    {
      headerName: "PICKUP DATE",
      filter: true,
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickupDate",
      field: "pickupDate",
    },
    {
      headerName: "PICKUP ZIP CODE",
      filter: true,
      headerTooltip: "PICKUP ZIP CODE",
      tooltipField: "pickupZipCode",
      field: "pickupDate",
    },

    {
      headerName: "CONSIGNEE ID",
      filter: true,
      headerTooltip: "CONSIGNEE ID",
      field: "consigneeId",
      tooltipField: "consigneeId",
    },
    {
      headerName: "CONSIGNEE NAME",
      filter: true,
      headerTooltip: "CONSIGNEE NAME",
      field: "consigneeName",
      tooltipField: "consigneeName",
    },
    {
      headerName: "CONSIGNEE MOBILE",
      filter: true,
      headerTooltip: "CONSIGNEE MOBILE",
      field: "consingneeContactNumber",
      tooltipField: "consingneeContactNumber",
    },
    {
      headerName: "CONSIGNEE EMAIL",
      filter: true,
      headerTooltip: "CONSIGNEE EMAIL",
      field: "consigneeEamilAddress",
      tooltipField: "consigneeEamilAddress",
    },

    {
      headerName: "SHIPPER ID",
      filter: true,
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "shipperId",
    },
    {
      headerName: "SHIPPER NAME",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "shipperName",
      field: "shipperName",
    },

    {
      headerName: "DROPOFF LOCATION",
      filter: true,
      headerTooltip: "DROPOFF LOCATION",
      tooltipField: "dropoffLocation",
      field: "dropoffLocation",
    },
    {
      headerName: "DROPOFF COUNTRY",
      filter: true,
      headerTooltip: "DROPOFF COUNTRTY",
      tooltipField: "dropoffCountry",
      field: "dropoffCountry",
    },
    {
      headerName: "DROPOFF ZIP CODE",
      filter: true,
      headerTooltip: "DROPOFF ZIP CODE",
      tooltipField: "dropoffZipCode",
      field: "dropoffZipCode",
    },
    {
      headerName: "MODE OF SHIPMENT",
      filter: true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
    },

    {
      headerName: "ViewDocs",
      filter: true,
      headerTooltip: "CREATED BY",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            className="btn btn-info"
            onClick={() => managedoc(params)}
          >
            View Doc
          </a>
        );
      },
    },

    // {
    //   headerName: "STATUS ",
    //   filter: true,
    //   headerTooltip: "ACTIVE STATUS",
    //   tooltipField: "activeStatus",
    //   field: "activeStatus",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     let val = params.value.charAt(0).toUpperCase();
    //     let ans = val + params.value.slice(1).toLowerCase();
    //     return (
    //       <Box className="Active">
    //         <Typography>{ans}</Typography>
    //         <span className="active"></span>
    //       </Box>
    //     );
    //   },
    // },
  ]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const url = Environment_Realtraceb_Dev.BASE_URL + "/chaCustomList";

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    cbrStatus: "open,processed",
    brStatus: "accepted",
    chaStatus: "export",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  useEffect(() => {
    setLoad(true);
    axios.get(url, config).then((res) => {
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  }, [fromDate, toDate]);

  const updateurl =
    "https://api.ext.realtrace.realware.tech/po/updateExportCustomClearance";

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  const [poNumber, setPoNumber] = useState<any>([]);

  const handleUpdate = () => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    console.log("480", selectedRowsData);
    if (selectedRowsData.length <= 0) {
      swal("info", "Plese Select shipments", "info");
    } else {
      let pos: any = [];
      selectedRowsData.map((ele: any, index: number) => {
        pos.push(ele.invoiceNumber);
      });
      setPoNumber(pos);
      setExChaStatus(selectedRowsData[0].exportChaStatus);
      setMasterData([]);
      console.log("UPdate clicked ......", selectedRowsData);
      selectedRowsData.map((data: any, index: number) => {
        console.log("chaStatus...", data.chaStatus);
        if (data.hasOwnProperty("exportChaStatus")) {
          if (data.exportChaStatus === "Export Custom Declaration") {
            setMasterData(["Export Physical Inspection"]);
          }
          if (data.exportChaStatus === "Export Physical Inspection") {
            setMasterData(["Export Customs Released", "Export Goods On Hold"]);
          }
          if (data.exportChaStatus === "Export Goods On Hold") {
            setMasterData(["Export Customs Released", "Export Goods On Hold"]);
          }
          if (data.exportChaStatus === "Export Customs Released") {
            setMasterData([]);
          }
        } else {
          setMasterData(["Export Custom Declaration"]);
        }
      });
      setOpenUpdate(true);
    }
  };



  const handleRemarks = (e: any) => {
    setRemarks(e.target.value);
  };

  const handleSubmit = (data: any) => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    console.log("Selected rows...", selectedRowsData);
    let payload: any = { data: [] };
    
    selectedRowsData.map((data: any, index: number) => {
      
      data.carrierLines.map((sub: any, index: number) => {
        let obj = {
          invoiceNumber: data.invoiceNumber,
          bookingReference: data.bookingReference,
          carrierId: data.carrierId,
          carrierName: data.carrierName,
          containerId: sub.containerNumber,
          hblNumber: sub.hblNumber,
          mblNumber: sub.mblNumber,
          shipperId: data.shipperId,
          shipperName: data.shipperName,
          consigneeId: data.consigneeId,
          consigneeName: data.consigneeName,
          hscode: sub.hscode,
          status: event,
          remarks: remarks,
          carrierReference: data.carrierReference,
        };
        payload.data.push(obj);
      });
    });

    console.log("Update data....", payload);
    
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    };
    axios.post(updateurl, payload, { headers }).then((sucees: any) => {
      console.log("handle api called")
      axios.get(url, config).then((res) => {
        setRowData(res.data.primary);
        console.log(res.data.primary);
      });
      swal("Success", sucees.data.primary, "success");

      setMasterData([]);
    });

    inputRef.current.reset();
    setRemarks("");
    setMasterData([]);
    closeUpdate();
  };

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "5px",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Update
          </Button>
        </div>
        <Box className="ag-theme-balham">
          <AgGridReact
            ref={gridRef}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            domLayout={domLayout}
            columnDefs={columnDefs}
            sideBar={sideBar}
            onGridReady={onGridReady}
            paginationPageSize={10}
            pagination={true}
            onFirstDataRendered={onFirstDataRendered}
            rowSelection="multiple"
          ></AgGridReact>
        </Box>
        <div className="dialog">
          {openDetail ? (
            <CrLineItems
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={PoData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        {openUpdate ? (
          <Dialog fullWidth open={openUpdate} onClose={closeUpdate}>
            <Box sx={{ padding: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",

                    // alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "8px",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    {poNumber.length > 1 ? (
                      <Tooltip title={<TooltipCom data={poNumber} />}>
                        <p
                          style={{
                            marginBottom: "8px",
                            fontSize: "18px",
                            fontWeight: "bolder",
                          }}
                        >
                          Custom Status: 1 or More Invoices
                        </p>
                      </Tooltip>
                    ) : (
                      <p
                        style={{
                          marginBottom: "8px",
                          fontSize: "18px",
                          fontWeight: "bolder",
                        }}
                      >
                        Custom Status: {poNumber[0]}
                      </p>
                    )}
                  </p>
                  <Typography>
                    Previous State &nbsp;: &nbsp;
                    <span
                      style={{ color: "white" }}
                      className="dialogTitle blueColor"
                    >
                      {exchastatus}
                    </span>
                  </Typography>
                </Box>
                <Button onClick={closeUpdate}>Close</Button>
              </Box>
              <Divider />
              <form ref={inputRef} style={{ marginTop: "10px" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <FormControl sx={{ marginRight: "10px", width: "50%" }}>
                    <InputLabel id="select-File">Customs Status</InputLabel>
                    <Select
                      labelId="select-File"
                      id="demo-simple-select"
                      value={event}
                      label="Customs Status"
                      onChange={handleEvent}
                      name="Hbl"
                      size="small"
                      MenuProps={{disableScrollLock:true}}
                    >
                      {masterData.length
                        ? masterData.map((value: any, index: any) => (
                            <MenuItem value={value} key={index}>
                              {value}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: "50%" }}
                    id="outlined-helper-text"
                    label="Remarks"
                    placeholder="Remarks"
                    value={remarks}
                    onChange={handleRemarks}
                    size="small"
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ marginLeft: "auto" }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Dialog>
        ) : null}
        <ToastContainer style={{ zIndex: 10000 }} />
      </Paper>
    </div>
  );
}

export default ExportCustomClearence;
