import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import BarEcharts from './BarEcharts'
import { AgGridReact } from 'ag-grid-react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
export default function (props: any) {
    console.log("Tab Name", props.pageInfo.tab)
    const WidgetData = {
        BrData: {
            last12Month: {
                TotalShipments: 48512,
                pendingBooks: 2982,
                avgResponseTime: 6.12,
            },
            last6Month: {
                TotalShipments: 22137,
                pendingBooks: 1412,
                avgResponseTime: 7.41,
            },
            last3Month: {
                TotalShipments: 10192,
                pendingBooks: 712,
                avgResponseTime: 5.54,
            },
            currentMonth: {
                TotalShipments: 5400,
                pendingBooks: 500,
                avgResponseTime: 6.32,
            },
        },
        AcceptedByForwrder: {
            last12Month: {
                TotalShipments: 48513,
                pendingBooks: 2982,
                avgResponseTime: 6.12,
                brResponse: 9702
            },
            last6Month: {
                TotalShipments: 22137,
                pendingBooks: 1412,
                avgResponseTime: 7.14,
                brResponse: 4472
            },
            last3Month: {
                TotalShipments: 10192,
                pendingBooks: 712,
                avgResponseTime: 5.54,
                brResponse: 2038
            },
            currentMonth: {
                TotalShipments: 5400,
                pendingBooks: 500,
                avgResponseTime: 6.32,
                brResponse: 1080
            },
        },
        ConfirmedByCarrier: {
            last12Month: {
                TotalShipments: 45530,
                avgResponseTime: 6.12,
                brResponse: 9222
            },
            last6Month: {
                TotalShipments: 21076,
                avgResponseTime: 7.41,
                brResponse: 4187
            },
            last3Month: {
                TotalShipments: 11537,
                avgResponseTime: 5.54,
                brResponse: 1918
            },
            currentMonth: {
                TotalShipments: 4700,
                avgResponseTime: 6.32,
                brResponse: 940
            },
        },
    }
    const [childData, setChildData] = useState(false)
    const [widgetsList, setWidgetList] = useState([])
    const [last6Months, setLast6Months] = useState<any>()
    const [last12Months, setLast12Months] = useState<any>()
    const [last3Months, setLast3Months] = useState<any>()
    const [currentMonth, setCurrentMonth] = useState<any>()
    const [rowData, setRowData] = useState();
    let url = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/shipmentLifeCycleWidgets'
    const myRef = useRef<any>();
    const [columnDefs] = useState([
        {
            headerName: "HBL #",
            field: "hblNumber",
            filter: true,
            headerTooltip: "HBL #",
            tooltipField: "hblNumber",
        },
        {
            headerName: "SHIPPER NAME",
            field: "hblNumber",
            filter: true,
            headerTooltip: "SHIPPER NAME",
            tooltipField: "hblNumber",
        },
        {
            headerName: "COMMODITY TYPE",
            field: "hblNumber",
            filter: true,
            headerTooltip: "COMMODITY TYPE",
            tooltipField: "hblNumber",
        },
        {
            headerName: "ITEM NAME",
            field: "hblNumber",
            filter: true,
            headerTooltip: "ITEM NAME",
            tooltipField: "hblNumber",
        },
        {
            headerName: "DESCRIPTION",
            field: "hblNumber",
            filter: true,
            headerTooltip: "DESCRIPTION",
            tooltipField: "hblNumber",
        },
        {
            headerName: "QUANTITY",
            field: "hblNumber",
            filter: true,
            headerTooltip: "QUANTITY",
            tooltipField: "hblNumber",
        },
        {
            headerName: "ETA",
            field: "hblNumber",
            filter: true,
            headerTooltip: "ETA",
            tooltipField: "hblNumber",
        },
        {
            headerName: "CHANGE IN ETA",
            field: "hblNumber",
            filter: true,
            headerTooltip: "CHANGE IN ETA",
            tooltipField: "hblNumber",
        },
        {
            headerName: "DELAY IN DAYS",
            field: "hblNumber",
            filter: true,
            headerTooltip: "DELAY IN DAYS",
            tooltipField: "hblNumber",
        },
        {
            headerName: "DROP OF LOCATION",
            field: "hblNumber",
            filter: true,
            headerTooltip: "DROP OF LOCATION",
            tooltipField: "hblNumber",
        },


    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            floatingFilter: true,
            searchable: true,
            resizable: true,
            sortable: true,
            filter: true,
            domLayout: "autoHeight",
        };
    }, []);
    const sideBar = {
        toolPanels: [
            {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                },
            },
            {
                id: "filters",
                labelKey: "filters",
                labelDefault: "Filters",
                iconKey: "menu",
                toolPanel: "agFiltersToolPanel",
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                },
            },
        ],
    };
    const handleCallback = (childData: any) => {
        console.log(childData, "chield data")
        setChildData(childData)
    }
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    useEffect(() => {
        let obj = {
            status: props.pageInfo.tab
        }
        bookingRequestWidgets(obj)
    }, [])

    const bookingRequestWidgets = (status: any) => {
        config.params = status;
        axios.get(url, config).then((res: any) => {
            setWidgetList(res.data.data)
            setCurrentMonth(res.data.data.currentMonthData)
            setLast3Months(res.data.data.last3MonthsData)
            setLast6Months(res.data.data.last6MonthsData)
            setLast12Months(res.data.data.last12MonthsData)
        })
    }
    return (
        <div>
            <Grid container spacing={2} sx={{ display: 'flex' }}>
                <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                    <Paper elevation={0} className="card">
                        <Box className="card-body">
                            <Grid item xs={9}>
                                <Typography variant='h5' sx={{ fontSize: '1rem' }}>Last 12 Months</Typography>
                                {props.pageInfo.tab === 'bookingRequests' ?
                                    <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                        {/* {last12Months != undefined ? last12Months.last12MonthsTotalBookings : 0} <span style={{ fontSize: '12px' }}>Bookings</span> */}
                                        {WidgetData.BrData.last12Month.TotalShipments} <span style={{fontSize:'12px'}}>Bookings</span>
                                    </Typography>
                                    :
                                    <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                        {/* {last12Months != undefined ? last12Months.last12MonthsShipmentCount : 0} <span style={{ fontSize: '12px' }}>Bookings</span> */}
                                        {WidgetData.ConfirmedByCarrier.last12Month.TotalShipments} <span style={{ fontSize: '12px' }}>Bookings</span>
                                    </Typography>
                                }
                            </Grid>
                            {props.pageInfo.tab === 'bookingRequests' ?
                                <Grid item xs={9} sx={{ display: 'flex' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Pending Bookings: </Typography>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last12Months != undefined ? last12Months.last12MonthsPendingBookings : 0} */}
                                        {WidgetData.BrData.last12Month.pendingBooks}
                                    </Typography>
                                </Grid>
                                : null
                            }
                            <Grid item xs={9} sx={{ display: 'flex' }}>
                                <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Avg. Booking Response Time:  </Typography>
                                {props.pageInfo.tab === 'bookingRequests' ?
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last12Months != undefined ? last12Months.last12MonthsAvgApprovalTimeInhrs.toFixed(2) : 0} HR */}
                                        {WidgetData.AcceptedByForwrder.last12Month.avgResponseTime} HR
                                    </Typography> :
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last12Months != undefined ? last12Months.last12MonthsAvgTimeForApprovalInhrs.toFixed(2) : 0} HR */}
                                        {WidgetData.ConfirmedByCarrier.last12Month.avgResponseTime} HR
                                    </Typography>
                                }

                            </Grid>
                            {props.pageInfo.tab != 'bookingRequests' ?
                                <Grid item xs={9} sx={{ display: 'flex' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Booking Response In &#60;3 HR: </Typography>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {last12Months != undefined ? last12Months.last12MonthslessThan3hrsApprovedShipmentPerc.toFixed(2) : 0} HR
                                        {WidgetData.AcceptedByForwrder.last12Month.brResponse} HR
                                    </Typography>

                                </Grid>
                                : null}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                    <Paper elevation={0} className="card">
                        <Box className="card-body">
                            <Grid item xs={9}>
                                <Typography variant='h5' sx={{ fontSize: '1rem' }}>Last 6 Months</Typography>
                                {props.pageInfo.tab === 'bookingRequests' ?
                                    <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                        {/* {last6Months != undefined ? last6Months.last6MonthsTotalBookings : 0} <span style={{ fontSize: '12px' }}>Bookings</span> */}
                                        {WidgetData.BrData.last6Month.TotalShipments} <span style={{fontSize:'12px'}}>Bookings</span>
                                    </Typography>
                                    : 
                                        <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                            {/* {last6Months != undefined ? last6Months.last6MonthsShipmentCount : 0} <span style={{ fontSize: '12px' }}>Bookings</span> */}
                                            {WidgetData.ConfirmedByCarrier.last6Month.TotalShipments} <span style={{ fontSize: '12px' }}>Bookings</span>
                                        </Typography>
                                }



                            </Grid>
                            {props.pageInfo.tab === 'bookingRequests' ?
                                <Grid item xs={9} sx={{ display: 'flex' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Pending Bookings: </Typography>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last6Months != undefined ? last6Months.last6MonthsPendingBookings : 0} */}
                                        {WidgetData.BrData.last6Month.pendingBooks}
                                    </Typography>


                                </Grid>
                                : null
                            }
                           <Grid item xs={9} sx={{ display: 'flex' }}>
                                <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Avg. Booking Response Time:  </Typography>
                                {props.pageInfo.tab === 'bookingRequests' ?
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last6Months != undefined ? last6Months.last6MonthsAvgApprovalTimeInhrs.toFixed(2) : 0} HR */}
                                        {WidgetData.AcceptedByForwrder.last12Month.avgResponseTime} HR
                                    </Typography> :
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last6Months != undefined ? last6Months.last6MonthsAvgTimeForApprovalInhrs.toFixed(2) : 0} HR */}
                                        {WidgetData.ConfirmedByCarrier.last12Month.avgResponseTime} HR
                                    </Typography>
                                }

                            </Grid>
                            {props.pageInfo.tab != 'bookingRequests' ?
                                <Grid item xs={9} sx={{ display: 'flex' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Booking Response In &#60;3 HR: </Typography>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last6Months != undefined ? last6Months.last6MonthslessThan3hrsApprovedShipmentPerc.toFixed(2) : 0} HR */}
                                        {WidgetData.AcceptedByForwrder.last12Month.brResponse} HR
                                    </Typography>

                                </Grid>
                                : null}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                    <Paper elevation={0} className="card">
                        <Box className="card-body">
                            <Grid item xs={9}>
                                <Typography variant='h5' sx={{ fontSize: '1rem' }}>Last 3 Months</Typography>
                                {props.pageInfo.tab === 'bookingRequests' ?
                                    <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                        {/* {last3Months != undefined ? last3Months.last3MonthsTotalBookings : 0} <span style={{ fontSize: '12px' }}>Bookings</span> */}
                                        {WidgetData.BrData.last6Month.TotalShipments} <span style={{fontSize:'12px'}}>Bookings</span>
                                    </Typography>
                                    : 
                                        <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                            {/* {last3Months != undefined ? last3Months.last3MonthsShipmentCount : 0} <span style={{ fontSize: '12px' }}>Bookings</span> */}
                                            {WidgetData.ConfirmedByCarrier.last6Month.TotalShipments} <span style={{ fontSize: '12px' }}>Bookings</span>
                                        </Typography>
                                }



                            </Grid>
                            {props.pageInfo.tab === 'bookingRequests' ?
                                <Grid item xs={9} sx={{ display: 'flex' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Pending Bookings: </Typography>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last3Months != undefined ? last3Months.last3MonthsPendingBookings : 0} */}
                                        {WidgetData.BrData.last6Month.pendingBooks}
                                    </Typography>


                                </Grid>
                                : null
                            }
                           <Grid item xs={9} sx={{ display: 'flex' }}>
                                <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Avg. Booking Response Time:  </Typography>
                                {props.pageInfo.tab === 'bookingRequests' ?
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last3Months != undefined ? last3Months.last3MonthsAvgApprovalTimeInhrs.toFixed(2) : 0} HR */}
                                        {WidgetData.AcceptedByForwrder.last12Month.avgResponseTime} HR
                                    </Typography> :
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last3Months != undefined ? last3Months.last3MonthsAvgTimeForApprovalInhrs.toFixed(2) : 0} HR */}
                                        {WidgetData.ConfirmedByCarrier.last12Month.avgResponseTime} HR
                                    </Typography>
                                }

                            </Grid>
                            {props.pageInfo.tab != 'bookingRequests' ?
                                <Grid item xs={9} sx={{ display: 'flex' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Booking Response In &#60;3 HR: </Typography>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {last3Months != undefined ? last3Months.last3MonthslessThan3hrsApprovedShipmentPerc.toFixed(2) : 0} HR */}
                                        {WidgetData.AcceptedByForwrder.last12Month.brResponse} HR
                                    </Typography>

                                </Grid>
                                : null}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                    <Paper elevation={0} className="card">
                        <Box className="card-body">
                            <Grid item xs={9}>
                                <Typography variant='h5' sx={{ fontSize: '1rem' }}>Current Month</Typography>
                                {props.pageInfo.tab === 'bookingRequests' ?
                                    <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                        {/* {currentMonth != undefined ? currentMonth.currentMonthTotalBookings : 0} <span style={{ fontSize: '12px' }}>Bookings</span> */}
                                        {WidgetData.BrData.last6Month.TotalShipments} <span style={{fontSize:'12px'}}>Bookings</span>
                                    </Typography>
                                    : 
                                        <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                            {/* {currentMonth != undefined ? currentMonth.currentMonthShipmentCount : 0} <span style={{ fontSize: '12px' }}>Bookings</span> */}
                                            {WidgetData.ConfirmedByCarrier.last6Month.TotalShipments} <span style={{ fontSize: '12px' }}>Bookings</span>
                                        </Typography>
                                }



                            </Grid>
                            {props.pageInfo.tab === 'bookingRequests' ?
                                <Grid item xs={9} sx={{ display: 'flex' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Pending Bookings: </Typography>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {currentMonth != undefined ? currentMonth.currentMonthPendingBookings : 0} */}
                                        {WidgetData.BrData.last6Month.pendingBooks}
                                    </Typography>


                                </Grid>
                                : null
                            }
                           <Grid item xs={9} sx={{ display: 'flex' }}>
                                <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Avg. Booking Response Time:  </Typography>
                                {props.pageInfo.tab === 'bookingRequests' ?
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {currentMonth != undefined ? currentMonth.currentMonthAvgApprovalTimeInhrs.toFixed(2) : 0} HR */}
                                        {WidgetData.AcceptedByForwrder.last12Month.avgResponseTime} HR
                                    </Typography> :
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {/* {currentMonth != undefined ? currentMonth.currentMonthAvgTimeForApprovalInhrs.toFixed(2) : 0} HR */}
                                        {WidgetData.ConfirmedByCarrier.last12Month.avgResponseTime} HR
                                    </Typography>
                                }

                            </Grid>
                            {props.pageInfo.tab != 'bookingRequests' ?
                                <Grid item xs={9} sx={{ display: 'flex' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>Booking Response In &#60;3 HR: </Typography>
                                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'black' }}>
                                        {currentMonth != undefined ? currentMonth.currentMonthlessThan3hrsApprovedShipmentPerc.toFixed(2) : 0} HR
                                        {WidgetData.AcceptedByForwrder.last12Month.brResponse} HR
                                    </Typography>

                                </Grid>
                                : null}
                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Grid>
                <BarEcharts data={props.pageInfo.tab === 'bookingRequests' ? 'totalBookings' : 'avgTime'} parentCallback={handleCallback} pageInfo={props.pageInfo.tab} />
            </Grid>

            {/* <Grid container spacing={2} sx={{ display: 'flex', mt: 0 }}>

                <Grid item md={6} lg={6} sm={6}>

                    <Paper>

                        {alignment != undefined ?
                            <BarEcharts data={props.pageInfo.tab === 'bookingRequests' ? 'totalBookings' : 'avgTime'} parentCallback={handleCallback} pageInfo={props.pageInfo.tab} month={alignment}/> : ''}
                    </Paper>

                </Grid>
                <Grid item md={6} lg={6} sm={6}>
                    <Paper>
                        {alignment != undefined ?
                            <BarEcharts
                             tabData ={props.pageInfo.tab}
                             data={props.pageInfo.tab === 'bookingRequests' ? 'pendingBookings' : 'avgHr'}
                             parentCallback={handleCallback} 
                             pageInfo={props.pageInfo.tab}
                             month={alignment} /> : ''}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ fontSize: '1rem' }}>MONTH ON MONTH TREND</Typography>
                    <Paper>
                        {props.pageInfo != undefined ?
                            <Echarts data={'months'} page={props.pageInfo.page} pageInfo={props.pageInfo.tab} /> : ''}
                    </Paper>

                </Grid>
            </Grid> */}
            <Grid sx={{ mt: 3 }} id="moreInfo" >
                {childData ?
                    <Box>
                        <Box>
                            <Typography variant='h6' sx={{ fontSize: '1rem' }}>SHIPMENTS</Typography>
                        </Box>
                        <AgGridReact
                            className="ag-theme-balham"
                            animateRows={true}
                            rowSelection={"multiple"}
                            defaultColDef={defaultColDef}
                            rowData={rowData}
                            domLayout={"autoHeight"}
                            columnDefs={columnDefs}
                            sideBar={sideBar}
                            pagination={true}
                            paginationPageSize={10}

                        ></AgGridReact>
                    </Box>
                    : ''}
            </Grid>

            <a href="#" id="roll_back" className="animate">
                <KeyboardArrowUpIcon />
            </a>

        </div>
    )
}
