import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  TextField,
  Container,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Paper,
  Grid,
} from "@mui/material";
import * as Yup from "yup";
import swal from "sweetalert";

import { Formik, Form, Field } from "formik";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useOutletContext } from "react-router-dom";
const FileUploadValidation = Yup.object().shape({
  Hbl: Yup.string().required("HBL Number Required"),
  doctype: Yup.string().required("Doctype Required"),
  Reference: Yup.string().required("Reference Required"),
  FileName: Yup.string().required("FileName is Required"),
});
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function CommonFileUploader() {
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [ExcelName, setExcelName] = useState<string>("");
  const [masterData, setMaterData] = useState<any>([]);
  const [docData, setDocData] = useState<any>([]);
  const [masterDataId, setMasterDataid] = useState<string>("");
  const classes = useStyles();
  const [refNumber, setRefNumber] = useState<any>("");
  const [doctype, setDocType] = useState<any>("");
  const [doctypeid, setDocTypeId] = useState<any>("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loginRole, setLoginRolre] = useState<any>(
    sessionStorage.getItem("role")
  );
  const [role, setRole] = useState<string>("");
  const [reference, setReference] = useState("Enter Reference Number");
  const FormRef = useRef<any>();

  const goptions = {
    documentReference: "9703196928",
  };

  const gconfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: goptions,
  };

  function getDocument() {
    axios
      .get(
        "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/documents/getDocs",
        gconfig
      )
      .then((res: any) => console.log(res));
  }

  const options = {
    _id: masterDataId,
  };

  let getConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

  useEffect(() => {
    setRole(loginRole);
    
    console.log(typeof sessionStorage.getItem("role"));
    getmasterlist();
  }, []);

  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setExcelName(e.target.files[0].name);
    }
  }
  function getmasterlist() {
    axios
      .get(
        "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/documents/getMasterDocAgainst",
        getConfig
      )
      .then((res: any) => {
        setMaterData(res.data.data);
        console.log("1234567899876543", res.data.data);
        getDocument();
      });
  }
  function getchildlist() {
    console.log(config);
    if (masterDataId) {
      axios
        .get(
          "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/documents/getMasterDocTypes",
          config
        )

        // /getMasterDocTypes?_id=63bcf9bd3e00ddef757819e2
        .then((res: any) => {
          setDocData(res.data.data);
          console.log("1234567899876543", res.data.data);
        });
    }
  }

  function handlehbltype(e: any) {
    let ref = "Enter " + e.target.name + " Reference Number";
    setReference(ref);
    console.log("TargetData:", e.target);

    setMasterDataid(e.target.value);
  }
  function handledoctype(e: any) {
    setDocTypeId(e.target.value);
  }
  function handlefiletype(e: any) {
    setMasterDataid(e.target.value);
  }
  function handlereftype(e: any) {
    console.log("234567987654345678", e);
    setRefNumber(e.target.value);
  }

  function uploadfile() {
    // let role=sessionStorage.getItem("role");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentAgainstId", masterDataId);
    formData.append("documentTypeId", doctypeid);
    formData.append("documentReference", refNumber);
    formData.append("uploadedUserRole", loginRole);
    // let options = {
    //   documentAgainstId: masterDataId,
    //   documentTypeId: doctypeid,
    //   documentReference: refNumber,
    //   uploadedUserRole: sessionStorage.getItem("role"),
    //   file: formData,
    // };

    const config = {
      headers: {
        "Content-Type": "multipart/form-date",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    axios
      .post(
        "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/docUpload",
        // "http://192.168.1.129:3000/api/v1/docUpload",
        formData,
        config
      )
      .then((res: any) => {
        if (res.data.status) {
          swal("Sucess", res.data.msg, "success");
        }
      });

    clearFields();
  }

  const validatefields = () => {
    if (masterDataId && doctypeid && refNumber && file) {
      return false;
    }
    return true;
  };

  const clearFields = () => {
    const formData = new FormData();
    FormRef.current.reset();
    setMasterDataid("");
    setDocTypeId("");
    setRefNumber("");
    file([]);
  };
  function handleAlert() {
    setShowAlert(false);
  }
  const reset = () => {
    // setRowData([]);
    // setShowFile(false);
    // FormRef.current.reset();
    clearFields();
    setExcelName("Selcet File");
    setFile(undefined);
  };
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <form ref={FormRef}>
          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="select-File">
                  Upload Document Against
                </InputLabel>
                <Select
                  labelId="select-File"
                  id="demo-simple-select"
                  value={masterDataId}
                  label="Upload Document Against"
                  onChange={handlehbltype}
                  name="hbl"
                  MenuProps={{disableScrollLock:true}}
                >
                  {masterData.length
                    ? masterData.map((value: any, index: any) => (
                        <MenuItem value={value._id} key={value.name}>
                          {value.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="select-File">Document Type</InputLabel>
                <Select
                  labelId="select-File"
                  id="demo-simple-select"
                  value={doctypeid}
                  label="Upload Document Against"
                  onChange={handledoctype}
                  name="doctypeid"
                  onOpen={getchildlist}
                  MenuProps={{disableScrollLock:true}}
                >
                  {docData.length
                    ? docData.map((value: any, index: any) => (
                        <MenuItem value={value._id} key={value._id}>
                          {value.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size="small">
                <TextField
                  type="text"
                  label="Enter Reference Number"
                  name="Reference"
                  value={refNumber}
                  size="small"
                  onChange={handlereftype}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={3}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                onChange={filevalidation}
                size="small"
              />
              <Box>
                <strong>* Please Upload Only PDF's</strong>
              </Box>
            </Grid>
            <Grid item>
              {validatefields() === true ? (
                <Button variant="contained" disabled>
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={uploadfile}
                >
                  Submit
                </Button>
              )}
            </Grid>
            <Grid item>
              <LoadingButton
                loading={false}
                variant="contained"
                type="submit"
                className="submitbtn"
                sx={{
                  justifyContent: "end",
                  fontWeight: 500,
                  ml: 2,
                  backgroundColor: "#555a64",
                  color: "#fff",
                  "& .MuiButtonBase-root:hover": {
                    backgroundColor: "#555a64",
                    color: "#fff",
                  },
                }}
                onClick={(event) => reset()}
              >
                Reset
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
        <Dialog open={showAlert} onClose={handleAlert}>
          <Box sx={{ padding: "25px", borderRadius: "5px" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              {/* <img
              src={require("../asserts/sucess.png")}
              alt="sucess Image"
              width={60}
              height={60}
            /> */}
            </Box>
            <Typography sx={{ marginBottom: "15px" }}>{message}</Typography>

            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" color="success" onClick={handleAlert}>
                Ok
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Paper>
    </div>
  );
}

export default CommonFileUploader;
