import { Avatar, ListItemText, Toolbar } from "@material-ui/core";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  ListItemButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import asserts from "../../asserts";
import colorConfigs from "../../Config/colorConfig";
import sizeConfigs from "../../Config/sizeConfigs";
import TabData from "../Routes/appRoutes";
import "../../Styles.css";
import ConsigneeTabData from "../../Data/ConsigneeTabsData";
import ChaTabData from "../../Data/ChaTabs";
import ForwrderTabData from "../../Data/ForwarderTabs";
import CarrierTabData from "../../Data/CarrierTabs";
import WHTabs from "../../Data/WH_Tabs";

type Props = {};

const Sidebar = (props: Props) => {
  const [anchorEl, SetAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, SetSelected] = useState<Number>(0);
  const [open, SetOpen] = useState(false);
  const [filterOpen, SetFilterOpen] = useState(false);
  const [isMenuOpen, SetIsOpenMenu] = useState(false);
  const [id, SetId] = useState(" ");
  const [SubHeader, SetSubHeader] = useState<string>("");
  const [isDarkMode, SetIsDarkMode] = useState(false);
  const [Tabdata, setTabData] = useState<any>(TabData);
  const [selectedkey, SetSelectedKey] = useState(1);
  const [drawerData, SetDrawerData] = useState<any>([]);
  const location = useLocation();
  const nav = useNavigate();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any
  ) => {
    console.log("clicked data.....", data);
    if (data.hasOwnProperty("route")) {
      console.log("routeeee", data.route);
      SetSelected(data.id);
      nav(data.route);
    } else {
      console.log(event.currentTarget, "$event xtarget");
      SetAnchorEl(event.currentTarget);
      let value: any = event.currentTarget.attributes[3].value;
      SetOpen(true);
      SetId(value);
      console.log("ID.....", value);
      SetSelected(selected);
      SetDrawerData([]);
    }
  };
  const handlebtn = (index: any) => {
    if (Tabdata[index].hasOwnProperty("children")) {
      let data = Tabdata[index].children;
      SetDrawerData(data);
      SetSelected(index);
      console.log("67qe5r87q6r8q65867", index);
    }
  };
  const handleFilters = () => {
    console.log("click eve");

    SetOpen(!open);
  };
  const handleClose = (event: any) => {
    console.log(event.currentTarget.textContent, "$event");
    SetAnchorEl(null);
    SetId("");
    SetOpen(false);
    SetDrawerData([]);
    SetSubHeader(
      event.currentTarget.textContent
        ? event.currentTarget.textContent
        : SubHeader
    );
    sessionStorage.setItem(
      "header",
      event.currentTarget.textContent
        ? event.currentTarget.textContent
        : SubHeader
    );

    // SetSelected(index);
    // console.log(index, "index");
  };
  useEffect(() => {
    setTabData(() =>
      sessionStorage.getItem("role") === "shipper"
        ? TabData
        : sessionStorage.getItem("role") === "forwarder"
        ? ForwrderTabData
        : sessionStorage.getItem("role") === "carrier"
        ? CarrierTabData
        : sessionStorage.getItem("role") === "wh"
        ? WHTabs
        : sessionStorage.getItem("role") === "cha"
        ? ChaTabData
        : ConsigneeTabData
    );
    // nav(props.data);
    // setSubHeader(() =>
    //   sessionStorage.getItem("role") === "shipper"
    //     ? "Pending Purchase Order"
    //     : sessionStorage.getItem("role") === "forwarder"
    //     ? "Pending Forwarder Confirmation"
    //     : sessionStorage.getItem("role") === "carrier"
    //     ? "Pending Carrier Confirmation"
    //     : "Purchase Order Confirmation"
    // );
  }, []);
  return (
    <Box className="Container">
      <Box
        sx={{
          width: sizeConfigs.sidebar.width,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          backgroundColor: colorConfigs.sidebar.bg,
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "5%",
            flexDirection: "row",
            alignContent: "center",
            marginTop: "10%",
          }}
        >
          <img
            src={asserts.images.logo}
            alt="Real_variable_image"
            height={50}
            className="logo"
          />
        </Box>
        <Box
          sx={{
            height: "70%",
            display: "flex",
            flexDirection: "column",
            marginTop: "30%",
          }}
        >
          {Tabdata.map((key: any, index: any) => {
            return (
              <>
                <Button
                  id={`basic-button-${key.id}`}
                  onClick={(event) => {
                    handleClick(event, key);
                    handlebtn(index);
                    console.log(event);
                  }}
                  sx={{
                    backgroundColor: selected === index ? "#1976d2" : "",
                    color: "#444746",
                    fontWeight: 500,
                    fontSize: "12px",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: 0,
                  }}
                >
                  <Box
                    sx={{
                      disaply: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textTransform: "capitalize",
                      marginBottom: 2,
                      color: colorConfigs.sidebar.color,
                    }}
                  >
                    <div className="tabs">{key.icon}</div>
                    <div>{key.parentnav}</div>
                  </Box>
                </Button>
              </>
            );
          })}
          <Drawer
            open={drawerData.length !== 0 ? open : false}
            anchor={"left"}
            onClose={handleFilters}
            sx={{
              "& .MuiDrawer-paper": {
                left: sizeConfigs.sidebar.width,
                overflow: "hidden",
              },
            }}
          >
            {drawerData.length !== 0 ? (
              <Box sx={{ marginTop: "60px", width: "330px" }}>
                {drawerData.length !== 0
                  ? drawerData.map((key: any, index: any) => {
                      console.log(drawerData);
                      return (
                        <div>
                          {key.title != undefined ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                fontSize: "14px",
                                marginTop: 2,
                                marginBottom: 2,
                                padding: "0px 20px",
                                width: "100%",
                                color: "#333",
                                backgroundColor: "#eee",
                                borderBottom: "2px solid #eee",
                              }}
                            >
                              <Typography sx={{ color: "#333" }}>
                                {key.title}{" "}
                              </Typography>{" "}
                              <IconButton sx={{ color: "#333" }}>
                                {key.icon}
                              </IconButton>
                            </Box>
                          ) : (
                            <List
                              className="list"
                              sx={{
                                background:
                                  location.pathname === key.route
                                    ? "#f7f7f7"
                                    : "",
                                color:
                                  location.pathname === key.route ? "blue" : "",
                                borderRight:
                                  location.pathname === key.route
                                    ? "4px solid blue"
                                    : "",
                              }}
                            >
                              <ListItem
                                onClick={(event) => handleClose(event)}
                                className="listItem"
                              >
                                <Link
                                  className="listItemtext"
                                  to={`${key.route}`}
                                >
                                  {key.childnav}
                                </Link>
                              </ListItem>
                            </List>
                          )}
                        </div>
                      );
                    })
                  : null}
              </Box>
            ) : null}
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
