// Icons Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CommentIcon from "@mui/icons-material/Comment";
import dayjs, { Dayjs } from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
// import moment from "moment";

import "./addLegInfo.css";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  TextField,
  Container,
  Box,
  Divider,
  InputLabel,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import axios from "axios";
import swal from "sweetalert";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type FormValues = {
  legsinfo: {
    legNo: "";
    legID: "";
    origin: "";
    destination: "";
    voyage: "";
    vessel: "";
    vesselName: "";
    etd: "";
    eta: "";
  };
};

function LegInfo(props: any) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [legs, setLegs] = useState<number>(0);
  const [showleg, setShowleg] = useState(false);
  // const [eta, setEta] = useState<any>("");

  const [etd, setEtd] = React.useState<Dayjs | any>(dayjs());
  const [eta, setEta] = React.useState<Dayjs | any>(dayjs());

  const handleChange = (newValue: Dayjs | any) => {
    let data = newValue.$d;
    // let date = newValue.$D + "-" + "" + newValue.$M + "-" + newValue.$y;
    // console.log(date);
    var responseDate = moment(data).format("DD-MM-YYYY");
    console.log(responseDate);
    setEta(newValue);
    // SetEtaa(responseDate);
  };
  const handleetd = (newValue: Dayjs | any) => {
    // let date = newValue.$D + "-" + "" + newValue.$M + "-" + newValue.$y;
    setEtd(newValue);
    let data = newValue.$d;
    // let date = newValue.$D + "-" + "" + newValue.$M + "-" + newValue.$y;
    // console.log(date);
    var responseDate = moment(data).format("DD-MM-YYYY");
    console.log(responseDate);
    // SetEtdd(responseDate);
  };

  console.log(props.data.tranship);
  const {
    register,
    resetField,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      legsinfo: [
        {
          legID: "",
          voyage: "",
          origin: "",
          destination: "",
          vessel: "",
          vesselName: "",
          etd: "",
          eta: "",
        },
      ],
    },
  });

  const { fields, append } = useFieldArray({
    name: "legsinfo",
    control,
    rules: {
      required: "Enter All Legs",
    },
  });

  function addlegs() {
    resetField("legsinfo");
    decrlegs();
  }

  function createData(formdata: any) {
    let data: any = {
      payload: [],
    };
    props.mbl.map((value: any, index: number) => {
      const obj = {
        mblNumber: value,
        legs: formdata.legsinfo,
      };
      data.payload.push(obj);
    });
    console.log("Legs Payload.......", data);
    axios
      .post("https://api.ext.realtrace.realware.tech/po/updateLegInfo", data, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          swal("success", res.data.primary, "success");
          props.onClose();
        }
        console.log(res);
      })
      .catch((error) => {
        console.log(" response error " + JSON.stringify(error));
        return error;
      });
  }

  function singleleg(formdata: any) {
    console.log("single legggg", formdata);

    let data: any = {
      payload: [],
    };
    let etadate = eta.$d;
    let etddate = etd.$d;
    let obj = {
      destination: formdata.legsinfo[0].destination,
      eta: moment(etadate).format("DD-MM-YYYY"),
      etd: moment(etddate).format("DD-MM-YYYY"),
      legID: formdata.legsinfo[0].legId,
      origin: formdata.legsinfo[0].origin,
      vessel: formdata.legsinfo[0].vessel,
      vesselName: formdata.legsinfo[0].vesselName,
      voyage: formdata.legsinfo[0].voyage,
    };

    data.payload.push(obj);

    console.log("payload for single leg", data);
    // axios
    //   .post(
    //     "https://api.ext.realtrace.realware.tech/po/updateLegInfo",
    //     data,
    //     {
    //       headers,
    //     }
    //   )
    //   .then((res) => {
    //     if (res.status === 200) {
    //       swal("success", res.data.primary, "success");
    //       props.onClose();
    //     }
    //     console.log(res);
    //   })
    //   .catch((error) => {
    //     console.log(" response error " + JSON.stringify(error));
    //     return error;
    //   });
  }

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  };

  function decrlegs() {
    let count = legs;
    setShowleg(true);
    for (let i = 0; i < count - 1; i++) {
      append({
        legID: "",
        voyage: "",
        origin: "",
        destination: "",
        vessel: "",
        vesselName: "",
        etd: "",
        eta: "",
      });
    }
  }
  console.log("Tranship...........", props.data[0].tranship);
  console.log("errors", errors.legsinfo?.[0]?.legID?.message);
  return props.data[0].tranship.toLowerCase() === "yes" ? (
    <Dialog
      fullScreen
      open={props.onOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-root": {},
        zIndex: 10000,
      }}
    >
      <AppBar sx={{ position: "static", top: 0 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Legs Information
          </Typography>
          <Button autoFocus color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: "15px",
          ".MuiContainer-root": { marginLeft: "0px" },
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            marginTop: "15px",
            width: "25%",
            minHeight: "91vh",
            // borderRight: "2px solid grey",
          }}
        >
          <Typography>Selected Shipments Mbl Number:</Typography>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {props.mbl.map((value: any, index: number) => (
              <ListItem key={index} disableGutters>
                <ListItemText primary={`${index + 1} :  ${value}`} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ marginLeft: "18px", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "end",
              marginBottom: "10px",
              marginTop: "10px",
              marginRight: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <Box>
                {/* <InputLabel sx={{ color: "black" }}>
                  Enter No Of Legs
                </InputLabel> */}
                <TextField
                  size="small"
                  sx={{ height: "fit-content", marginRight: "5px" }}
                  label=" Enter No Of Legs"
                  placeholder="Enter No Of Legs"
                  variant="outlined"
                  onChange={(e) => setLegs(parseInt(e.target.value))}
                />
              </Box>

              <Button
                color="primary"
                variant="contained"
                onClick={addlegs}
                sx={{ width: "fit-content", height: "fit-content" }}
              >
                AddLegs
              </Button>
            </Box>
          </Box>

          <form
            onSubmit={handleSubmit((data) => {
              createData(data);
            })}
          >
            {showleg ? (
              <Box
                sx={{
                  // padding: "15px",
                  marginBottom: "5px",
                  // backgroundColor: "whitesmoke",
                }}
              >
                {fields.map((_, index) => {
                  return (
                    <Box sx={{ marginBottom: "10px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // backgroundColor: "whitesmoke",
                          backgroundColor: "",
                          // padding: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <Box sx={{ marginBottom: "10px" }}>
                          <Typography variant="h6" color="initial">
                            LEG #{index + 1}
                          </Typography>
                        </Box>
                        <Box className="align1">
                          <Box className="first">
                            <Box className="fields">
                              <InputLabel sx={{ color: "black" }}>
                                {" "}
                                Enter LegID:
                              </InputLabel>
                              <TextField
                                placeholder="Enter LegID"
                                variant="outlined"
                                size="small"
                                {...register(`legsinfo.${index}.legID`, {
                                  required: "Leg is required",
                                  minLength: {
                                    value: 2,
                                    message: "Legid Should greater than 2",
                                  },
                                })}
                              />

                              <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.legsinfo?.[index]?.legID?.message}
                              </span>
                            </Box>
                            <Box className="fields">
                              <InputLabel sx={{ color: "black" }}>
                                Enter Origin:
                              </InputLabel>
                              <TextField
                                size="small"
                                placeholder="Enter Origin"
                                variant="outlined"
                                {...register(`legsinfo.${index}.origin`, {
                                  required: "Origin is required",
                                  minLength: {
                                    value: 2,
                                    message: "Origin Should greater than 2",
                                  },
                                })}
                              />

                              <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.legsinfo?.[index]?.origin?.message}
                              </span>
                            </Box>
                            <Box className="fields">
                              <InputLabel sx={{ color: "black" }}>
                                Enter Destination:
                              </InputLabel>
                              <TextField
                                placeholder="Enter Destination"
                                variant="outlined"
                                size="small"
                                {...register(`legsinfo.${index}.destination`, {
                                  required: "Destination is required",
                                  minLength: {
                                    value: 2,
                                    message:
                                      "Destination Should greater than 2",
                                  },
                                })}
                              />

                              <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.legsinfo?.[index]?.destination?.message}
                              </span>
                            </Box>

                            <Box className="fields">
                              <InputLabel sx={{ color: "black" }}>
                                Enter Voyage:
                              </InputLabel>
                              <TextField
                                size="small"
                                placeholder="Enter Voyage"
                                variant="outlined"
                                {...register(`legsinfo.${index}.voyage`, {
                                  required: "Voyage is required",
                                })}
                              />

                              <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.legsinfo?.[index]?.voyage?.message}
                              </span>
                            </Box>
                          </Box>
                          <Box className="second">
                            <Box className="fields">
                              <InputLabel sx={{ color: "black" }}>
                                Enter Vessel:
                              </InputLabel>
                              <TextField
                                size="small"
                                placeholder="Enter Vessel"
                                variant="outlined"
                                {...register(`legsinfo.${index}.vessel`, {
                                  required: "Vessel is required",
                                })}
                              />

                              <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.legsinfo?.[index]?.vessel?.message}
                              </span>
                            </Box>
                            <Box className="fields">
                              <InputLabel sx={{ color: "black" }}>
                                Enter Vessel Name:
                              </InputLabel>
                              <TextField
                                size="small"
                                placeholder="Enter Vessel Name"
                                variant="outlined"
                                {...register(`legsinfo.${index}.vesselName`, {
                                  required: " Vessel Name is required",
                                })}
                              />

                              <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.legsinfo?.[index]?.vesselName?.message}
                              </span>
                            </Box>

                            <Box className="fields" sx={{ width: "227px" }}>
                              <InputLabel sx={{ color: "black" }}>
                                Enter ETD:
                              </InputLabel>
                              <TextField
                                size="small"
                                sx={{ width: "100%" }}
                                type="date"
                                variant="outlined"
                                // value={`legsinfo.${index}.etd`}
                                {...register(`legsinfo.${index}.etd`, {
                                  required: "ETD is required",
                                })}
                              />

                              <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.legsinfo?.[index]?.etd?.message}
                              </span>
                            </Box>

                            <Box className="fields" sx={{ width: "227px" }}>
                              <InputLabel sx={{ color: "black" }}>
                                Enter ETA:
                              </InputLabel>

                              <TextField
                                size="small"
                                sx={{ width: "100%" }}
                                type="date"
                                placeholder="Enter ETA"
                                variant="outlined"
                                {...register(`legsinfo.${index}.eta`, {
                                  required: "ETA is required",
                                })}
                              />

                              <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.legsinfo?.[index]?.eta?.message}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                        {/* <Divider /> */}
                      </Box>
                    </Box>
                  );
                })}

                <p>{errors.legsinfo?.root?.message}</p>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Button type="submit" color={"primary"} variant={"contained"}>
                    CONFIRM PICKUP
                  </Button>
                </Box>
              </Box>
            ) : null}
          </form>
        </Box>
      </Container>
    </Dialog>
  ) : (
    <Dialog
      open={props.onOpen}
      maxWidth="xl"
      onClose={props.onClose}
      sx={{ backgroundColor: "none" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          // margin: "10px",
          // padding: "10px",
          padding: "15px ",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h6">
          Leg Information for Mbl # :{props.mbl[0]}
        </Typography>
        <Button onClick={props.onClose}>
          <CloseIcon />
        </Button>
      </Box>
      <Divider />

      <form
        onSubmit={handleSubmit((formdata: any) => {
          singleleg(formdata);
        })}
      >
        <Box
          sx={{
            marginBottom: "5px",
            backgroundColor: "none",
            padding: "15px",
            paddingTop: "10px",
          }}
        >
          {fields.map((_, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // padding: "15px",
                  marginBottom: "5px",
                }}
              >
                <Box sx={{ marginBottom: "15px" }}>
                  <Typography variant="h6" color="initial">
                    LEG #{1}
                  </Typography>
                </Box>
                <Box className="align1">
                  <Box className="first">
                    <Box className="fields">
                      <TextField
                        label="Enter LegID"
                        size="small"
                        variant="outlined"
                        {...register(`legsinfo.${index}.legID`, {
                          required: "Leg is required",
                          minLength: {
                            value: 2,
                            message: "Legid Should greater than 2",
                          },
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.legID?.message}
                      </span>
                    </Box>
                    <Box className="fields">
                      <TextField
                        size="small"
                        label="Enter Origin"
                        variant="outlined"
                        {...register(`legsinfo.${index}.origin`, {
                          required: "Origin is required",
                          minLength: {
                            value: 2,
                            message: "Origin Should greater than 2",
                          },
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.origin?.message}
                      </span>
                    </Box>
                    <Box className="fields">
                      <TextField
                        size="small"
                        label="Enter Destination"
                        variant="outlined"
                        {...register(`legsinfo.${index}.destination`, {
                          required: "Destination is required",
                          minLength: {
                            value: 2,
                            message: "Destination Should greater than 2",
                          },
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.destination?.message}
                      </span>
                    </Box>

                    <Box className="fields">
                      <TextField
                        size="small"
                        label="Enter Voyage"
                        variant="outlined"
                        {...register(`legsinfo.${index}.voyage`, {
                          required: "Voyage is required",
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.voyage?.message}
                      </span>
                    </Box>
                  </Box>
                  <Box className="second">
                    <Box className="fields">
                      <TextField
                        size="small"
                        label="Enter Vessel"
                        variant="outlined"
                        {...register(`legsinfo.${index}.vessel`, {
                          required: "Vessel is required",
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.vessel?.message}
                      </span>
                    </Box>
                    <Box className="fields">
                      <TextField
                        size="small"
                        label="Enter Vessel Name"
                        variant="outlined"
                        {...register(`legsinfo.${index}.vesselName`, {
                          required: " Vessel Name is required",
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.vesselName?.message}
                      </span>
                    </Box>

                    {/* <Box className="fields" sx={{ width: "227px" }}>
                      <InputLabel sx={{ color: "black" }}>Enter ETD</InputLabel>
                      <TextField
                        size="small"
                        sx={{ width: "100%" }}
                        label="Enter ETD"
                        type="date"
                        variant="outlined"
                        {...register(`legsinfo.${index}.etd`, {
                          required: "ETD is required",
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.etd?.message}
                      </span>
                    </Box> */}

                    <Box className="fields" sx={{ width: "227px" }}>
                      {/* <InputLabel sx={{ color: "black" }}>Enter ETA</InputLabel>
                      <TextField
                        size="small"
                        sx={{ width: "100%" }}
                        type="date"
                        // placeholder="Enter ETA"
                        // value={"Add ETD"}
                        variant="outlined"
                        {...register(`legsinfo.${index}.eta`, {
                          required: "ETA is required",
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.eta?.message}
                      </span>  */}
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Enter ETD"
                            inputFormat="DD/MM/YYYY"
                            value={etd}
                            disablePast
                            onChange={handleetd}
                            renderInput={(params: any) => (
                              <TextField {...params} size="small" />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                    <Box className="fields" sx={{ width: "227px" }}>
                      {/* <InputLabel sx={{ color: "black" }}>Enter ETA</InputLabel>
                      <TextField
                        size="small"
                        sx={{ width: "100%" }}
                        type="date"
                        // placeholder="Enter ETA"
                        // value={"Add ETD"}
                        variant="outlined"
                        {...register(`legsinfo.${index}.eta`, {
                          required: "ETA is required",
                        })}
                      />

                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.legsinfo?.[index]?.eta?.message}
                      </span>  */}
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // size="small"
                            label="Enter ETA"
                            inputFormat="DD/MM/YYYY"
                            value={eta}
                            disablePast
                            onChange={handleChange}
                            renderInput={(params: any) => (
                              <TextField {...params} size="small" />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}

          <p>{errors.legsinfo?.root?.message}</p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "10px",
              paddingRight: "8px",
            }}
          >
            <Button type="submit" color={"primary"} variant={"contained"}>
              CONFIRM PICKUP
            </Button>
          </Box>
        </Box>

        {/* <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date desktop"
              inputFormat="DD/MM/YYYY"
              value={eta}
              onChange={handleChange}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box> */}
      </form>
    </Dialog>
  );
}

export default LegInfo;
