import { Accordion, AccordionDetails, AccordionSummary, AppBar, Autocomplete, Box, Breadcrumbs, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, Slide, Slider, Stack, Step, StepButton, StepLabel, Stepper, TextField, Theme, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import '../../../Styles.css'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
    DateTimePicker,
    DatePicker,
    LocalizationProvider,
    DesktopDatePicker,
} from "@mui/x-date-pickers";
import * as Yup from "yup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TransitionProps } from '@mui/material/transitions';
import AnchorOutlinedIcon from '@mui/icons-material/AnchorOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import dayjs, { Dayjs } from "dayjs";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import axios from 'axios';
import { SeaRatesServices } from '../../../Services/SeaRatesService';
import { LanTwoTone } from '@mui/icons-material';
import moment from 'moment';
import { Bars } from "react-loader-spinner";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SeaRatesMap from './SeaRatesMap';
function SeaRateCard({item,index}:{item:any,index:any}) {
  
    const [activeIndex, setActiveIndex] = useState(0);
    const [value, setValue] = React.useState('Truck');
    const [shipment, setShipment] = useState('fcl')
    const [container, setContainer] = useState('')
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [checked, setChecked] = React.useState(false);
    const [isSubmit, setSubmit] = useState(false)
    const [transporatationName, setTransportationName] = useState('sea')
    const [toggleValue, setToggleValue] = useState<any>('tariff')
    const [typeOfContainer, setTypeOfContainer] = useState<any>("ST20")
    const [loadDate, setLoadDate] = React.useState<Dayjs | any>(null);
    const [isViewDetailsShow, setViewDetailsShow] = useState(false)
    const [sliderValue, setSliderValue] = useState(1)
    const [sliderValue1, setSliderValue1] = useState(1);
    const [open1, setOpen1] = React.useState(false);
    const [options, setOptions] = React.useState<any>([]);
    const loading = open1 && options.length === 0;
    const [level, setLevel] = useState<any>(0)
    const [loader, setLoader] = useState(false)
    const [activeStep, setActiveStep] = React.useState(2);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    let arr: any = []
    let arr1: any = []
    let hsCodesData: any = [];
    let containerType: any = 'ST20'
  
    const [origin, setOrigin] = useState<any>({})
    const [dest, setdest] = useState<any>({})
    const seaRatesServices = new SeaRatesServices()
    const [OriginLatLang, setOriginLatLang] = useState<any>()
    const [ratesResp, setRatesResp] = useState<any>([])
    const [products, setProducts] = useState<any>([])
    const [subProducts, setSubProducts] = useState<any>([])
    const [openDialog, setOpenDialog] = useState(false)
    const [isPickUpActive, setPickUpActive] = useState(false)
    const [isPortOfOriginActive, setPortOfOriginActive] = useState(false)
    const [isPortOfDischargeActive, setPortOfDischargeActive] = useState(false)
    const [isDeliveryActive, setDeliveryActive] = useState(false)
    const [PRoductsByHsCode, setProductsByHsCode] = useState<any>(['Main Categories'])
    const [totalBookNowPrice, setTotalBookNowPrice] = useState('')
    const [totalCo2Count, setTotalCo2Count] = useState('')
    const seafclresp = {
        "data": {
            "shipment": [
                {
                    "shipmentId": "10586438",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 17204,
                        "name": "Port Everglades",
                        "code": "ChIJW6IPGb2q2YgR7hkgfFscvXc",
                        "countryCode": "US",
                        "lat": 26.066611640665,
                        "lng": -80.118827819824
                    },
                    "cityTo": {
                        "id": 50145,
                        "name": "Hazira",
                        "code": "ChIJ_W1Cmqut4TsR_bnYlU-pLaA",
                        "countryCode": "IN",
                        "lat": 21.1076,
                        "lng": 72.651155
                    },
                    "portFrom": {
                        "id": 17204,
                        "name": "Port Everglades",
                        "code": "USPEF",
                        "countryCode": "US",
                        "lat": 26.066611640665,
                        "lng": -80.118827819824,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 50145,
                        "name": "Hazira",
                        "code": "INHZA",
                        "countryCode": "IN",
                        "lat": 21.1076,
                        "lng": 72.651155,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "ZIMU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/13.jpg",
                            "containerCode": "ST20",
                            "validTo": "2023-12-31",
                            "containerType": "20 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1040,
                            "originalCurrency": "USD",
                            "price": 1040,
                            "distance": "16192.15 km",
                            "transitTime": "33 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2671705,
                            "co2Price": 9,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 450,
                                    "originalCurrency": "USD",
                                    "price": 450,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 965,
                                "originalCurrency": "USD",
                                "price": 965,
                                "distance": "7.48 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 20526,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 165,
                                "originalCurrency": "USD",
                                "price": 165,
                                "distance": "1.36 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3733,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10586439",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 17204,
                        "name": "Port Everglades",
                        "code": "ChIJW6IPGb2q2YgR7hkgfFscvXc",
                        "countryCode": "US",
                        "lat": 26.066611640665,
                        "lng": -80.118827819824
                    },
                    "cityTo": {
                        "id": 50145,
                        "name": "Hazira",
                        "code": "ChIJ_W1Cmqut4TsR_bnYlU-pLaA",
                        "countryCode": "IN",
                        "lat": 21.1076,
                        "lng": 72.651155
                    },
                    "portFrom": {
                        "id": 17204,
                        "name": "Port Everglades",
                        "code": "USPEF",
                        "countryCode": "US",
                        "lat": 26.066611640665,
                        "lng": -80.118827819824,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 50145,
                        "name": "Hazira",
                        "code": "INHZA",
                        "countryCode": "IN",
                        "lat": 21.1076,
                        "lng": 72.651155,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "MSC",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/1.jpg",
                            "containerCode": "ST20",
                            "validTo": "2023-12-31",
                            "containerType": "20 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 2366,
                            "originalCurrency": "USD",
                            "price": 2366,
                            "distance": "16192.15 km",
                            "transitTime": "33 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2671705,
                            "co2Price": 9,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 450,
                                    "originalCurrency": "USD",
                                    "price": 450,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 965,
                                "originalCurrency": "USD",
                                "price": 965,
                                "distance": "7.48 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 20526,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 165,
                                "originalCurrency": "USD",
                                "price": 165,
                                "distance": "1.36 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3733,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                }
            ],
            "default": {
                "services": [
                    "ocean"
                ],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const seafcl1resp = {
        "data": {
            "shipment": [
                {
                    "shipmentId": "10593346",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "MSC",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/1.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1833,
                            "originalCurrency": "USD",
                            "price": 1833,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593347",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "COSU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/5.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 996,
                            "originalCurrency": "USD",
                            "price": 996,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593348",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "YMLU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/18.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 644,
                            "originalCurrency": "USD",
                            "price": 644,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593349",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "HLCU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/7.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1172,
                            "originalCurrency": "USD",
                            "price": 1172,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593350",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "ONE",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/134.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 884,
                            "originalCurrency": "USD",
                            "price": 884,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593351",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "OOCL",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/17.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 622,
                            "originalCurrency": "USD",
                            "price": 622,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593352",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "Evergreen",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/6.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 773,
                            "originalCurrency": "USD",
                            "price": 773,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                }
            ],
            "default": {
                "services": [
                    "fees [import]",
                    "truck [import]",
                    "ocean"
                ],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const sealclresp = {
        "data": {
            "shipment": [],
            "default": {
                "services": [],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
   
    const handleClose = () => {
        setOpen1(false);
        setOpenDialog(false)
    };
    const handleStep = (index: any) => {
        console.log(index)
        setActiveStep(index);
    }
    const typesOfData = [
        {
            transportationType: transporatationName,
            shipmentType: shipment,
        }
    ]
    const sliderHandleChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };
    const sliderHandleChange1 = (event: Event, newValue: number | number[]) => {
        setSliderValue1(newValue as number);
    };
    const handleSubmit = () => { }
    const handleChange = (e: any) => {
        console.log(e.target.value, "value")
        setShipment(e.target.value)

    }
    const handleChangeaccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    React.useEffect(() => {
        let active = true;
        seaRatesServices.getGraphqlProducts().then((res: any) => {
            setProducts(res.data.data.hs_codes_list)
        })
        
        if (!loading) {
            return undefined;
        } (async () => {

            // await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...products]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open1) {
            setOptions([]);
        }
    }, [open1]);

    const submit = () => {
        setSubmit(true)
        if (!sessionStorage.getItem('seaRates_Token')) {
            //---------------------- platform token api --------------
            seaRatesServices.getPlatFormToken().then((res: any) => {
                sessionStorage.setItem('seaRates_Token', res.data['s-token'])
            })
        }
        latlngAddress()

    }
    const latlngAddress = () => {
        console.log(typeOfContainer, typeof (origin), "container type")
        geocodeByAddress(origin.value.description).then((results: any) => getLatLng(results[0])).then((data) => {
            setOriginLatLang(data)
            let RequestObj = {
                originLat: data.lat,
                originLng: data.lng,

            }
            arr.push(RequestObj)
            getLatLngData()
            console.log(data,"dattttttttta")
        });
        geocodeByAddress(dest.value.description).then((results: any) => getLatLng(results[0])).then((data) => {
            let RequestObj = {
                destLat: data.lat,
                destLng: data.lng,

            }
            arr1.push(RequestObj)
            getLatLngData()
        });
    }
    const getLatLngData = () => {
        // ---------------------- graphql Rates ---------------------
        if (transporatationName === 'sea') {
            if (shipment === 'fcl') {
                let RequestObject = {
                    shipment: shipment,
                    originLat: arr[0].originLat,
                    originLng: arr[0].originLng,
                    destinationLat: arr1[0].destLat,
                    destinationLng: arr1[0].destLng,
                    containerType: containerType,
                    transport: transporatationName,
                    date: loadDate
                }
                console.log(RequestObject, "request obj")
                setLoader(true)
                let PortOfFrom: any = []
                if(seafclresp.data.shipment)
                setRatesResp(seafclresp.data.shipment)
                let totalKgs: any = [];
                    for (let i = 0; i < seafclresp.data.shipment.length; i++) {
                        totalKgs.push(seafclresp.data.shipment[i].freight[0].co2 / 1000 + seafclresp.data.shipment[i].freight[0].truckFrom.co2 / 1000 + seafclresp.data.shipment[i].freight[0].truckTo.co2 / 1000 + seafclresp.data.shipment[i].freight[0].portFeesFrom[0].co2 / 1000 + seafclresp.data.shipment[i].freight[0].portFeesTo[0].co2 / 1000)

                        PortOfFrom.push(seafclresp.data.shipment[i].portFrom.name)
                        PortOfFrom.push(seafclresp.data.shipment[i].portFrom.code)
                    }
                seaRatesServices.getGraphqlRates(RequestObject).then((res: any) => {
                    console.log(res, "resp")
                    setLoader(false)
                    setRatesResp(res.data.data.shipment)
                    // let totalKgs: any = [];
                    // for (let i = 0; i < res.data.data.shipment.length; i++) {
                    //     totalKgs.push(res.data.data.shipment[i].freight[0].co2 / 1000 + res.data.data.shipment[i].freight[0].truckFrom.co2 / 1000 + res.data.data.shipment[i].freight[0].truckTo.co2 / 1000 + res.data.data.shipment[i].freight[0].portFeesFrom[0].co2 / 1000 + res.data.data.shipment[i].freight[0].portFeesTo[0].co2 / 1000)

                    //     PortOfFrom.push(res.data.data.shipment[i].portFrom.name)
                    //     PortOfFrom.push(res.data.data.shipment[i].portFrom.code)
                    // }
                    console.log(PortOfFrom, "total kgs")
                })
            } else if (shipment === 'lcl') {
                let RequestObject = {
                    shipment: shipment,
                    originLat: arr[0].originLat,
                    originLng: arr[0].originLng,
                    destinationLat: arr1[0].destLat,
                    destinationLng: arr1[0].destLng,
                    weight: sliderValue + '000',
                    volume: sliderValue1,
                    transport: transporatationName,
                    date: loadDate
                }
                console.log(RequestObject, "request obj")
                setRatesResp(sealclresp.data.shipment)
                seaRatesServices.getGraphqlRates(RequestObject).then((res: any) => {
                    setRatesResp(res.data.data.shipment)
                    console.log(res.data.data.shipment, "shipment")
                })
            }
        }

    }
    const addChip = (value: any, index: any) => {
        console.log(index, "index", value)
        setActiveIndex(index)
    }
    const readyToLoad = (newValue: Dayjs | any) => {
        let data = newValue.$d;
        var responseDate = moment(data).format("YYYY-MM-DD");
        console.log(responseDate);
        setLoadDate(responseDate);

    };
    const transportationChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        console.log(newAlignment, "namee")
        setTransportationName(newAlignment);

    };
    const TypeOfContainerChange = (
        event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value, "target value")
        setTypeOfContainer(event.target.value);
        containerType = event.target.value
        console.log(containerType, "typeofcontainer")
        latlngAddress()
    };
    const viewDetails = (index: any) => {
        setViewDetailsShow(!isViewDetailsShow)
    }
    const valuehandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSliderValue(Number(event.target.value));
    };
    const valuehandleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSliderValue1(Number(event.target.value));
    };
    const hsCodeDetails = () => {
        console.log("scroll", products)
        setSubProducts(products)
        setOpenDialog(true)
    }
    const onSelectProducts = (item: any) => {
        console.log(PRoductsByHsCode, "hs data1111111111")
        let levelCount = level + 1
        hsCodesData.push(item.description)
        console.log(hsCodesData, "hs data")
        setLevel(levelCount)
        setProductsByHsCode((oldArray: any) => [...oldArray, item.description])
        let objRequest = {
            level: levelCount,
            hsCode: item.code
        }
        seaRatesServices.getrGraphqlProductsByHsCode(objRequest).then((res: any) => {
            console.log("scroll res", res.data.data)
            setProducts(res.data.data.hs_codes_list)
        })
    }
    const handleSelect = (item: any) => {
        console.log(item, "item")
        let objRequest = {
            hsCode: item
        }
        seaRatesServices.getrGraphqlProductsByHsCode(objRequest).then((res: any) => {
            setOpenDialog(false)
            setProducts(res.data.data.hs_autocomplete)

        })
    }
    const OnpickupClick = (item: any) => {
        console.log(item, "pickup click")
        setPickUpActive(!isPickUpActive)

        setPickUpActive((state) => {
            console.log(state, "state")
            console.log(isPickUpActive, "ispickup")
            if (state) {
                console.log(totalBookNowPrice, "total price")
                setPortOfOriginActive(true)
                if (totalBookNowPrice != '') {
                    let price: any = !isPortOfOriginActive ? item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice : 0
                    let co2: any = !isPortOfOriginActive ? item.freight[0].portFeesFrom[0].co2 / 1000 : 0
                    console.log(co2, "co22222222")
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].truckFrom.price + price)
                    let final:any = Number(totalCo2Count) + item.freight[0].truckFrom.co2 / 1000 + co2
                    setTotalCo2Count(Number(final).toFixed(2))

                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].truckFrom.co2 / 1000 + item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(Number(co2).toFixed(2))
                    console.log(co2,"co2 click")
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].truckFrom.price + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)

                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].truckFrom.price)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].truckFrom.co2 / 1000)
                setTotalCo2Count(Number(co2).toFixed(2))
            }
            return state
        })
    }
    const onPortOfOriginClick = (item: any) => {
        setPortOfOriginActive(!isPortOfOriginActive)
        setPortOfOriginActive((state) => {
            if (state) {
                if (totalBookNowPrice != '') {
                    let co2: any = item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(Number(totalCo2Count) + co2)
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(Number(co2).toFixed(2))
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].portFeesFrom[0].co2 / 1000)
                setTotalCo2Count(Number(co2).toFixed(2))
            }
            return state
        })
    }
    const onPortOfDischargeClick = (item: any) => {
        setPortOfDischargeActive(!isPortOfDischargeActive)
        setPortOfDischargeActive((state) => {
            if (state) {
                if (totalBookNowPrice != '') {
                    let co2: any = Number(totalCo2Count) + item.freight[0].portFeesTo[0].co2 / 1000
                    setTotalCo2Count(Number(co2).toFixed(2))
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].portFeesTo[0].co2 / 1000
                    setTotalCo2Count(Number(co2).toFixed(2))
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].portFeesTo[0].co2 / 1000)
                setTotalCo2Count(Number(co2).toFixed(2))
            }
            return state
        })
    }
    const onPortOfDeliveryClick = (item: any) => {
        setDeliveryActive(!isDeliveryActive)
        setDeliveryActive((state) => {
            // if(state){
            //     setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].truckTo[0].originalPrice + item.freight[0].truckTo[1].originalPrice)
            // }else{
            //     let price:any = Number(totalBookNowPrice) - (item.freight[0].originalPrice + item.freight[0].truckTo[0].originalPrice + item.freight[0].truckTo[1].originalPrice)
            //     setTotalBookNowPrice(price)
            // }
            return state
        })
    }
    const PickUpHandleChange = (item: any) => {
        setPickUpActive(!isPickUpActive)
        OnpickupClick(item)
    }
    const portOfOriginHandleChange = (item: any) => {
        setPortOfOriginActive(!isPortOfOriginActive)
        onPortOfOriginClick(item)
    }
    const portOfDischargeHandleChange = (item: any) => {
        setPortOfDischargeActive(!isPortOfDischargeActive)
        onPortOfDischargeClick(item)
    }
    const portOfDeliveryHandleChange = (item: any) => {
        setDeliveryActive(!isDeliveryActive)
        onPortOfDeliveryClick(item)
    }
    const toggleHandleChange = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string[],
    ) => {
        console.log(newFormats, "new formats")
        setToggleValue(newFormats)
    }
    return ( 
        <Box>
        <Paper sx={{ mt: 2 }} key={item.index}>
            <Grid sx={{ display: 'flex' }}>
                <Grid item xs={8}>
                    <Box sx={{ flexDirection: 'row', alignItems: 'center', }}>
                        <Box sx={{ display: 'flex', width: '100%', padding: '10px 20px' }}>
                            <img src={item.freight[0].logo} className='shipments_img' />
                            <Typography sx={{ padding: '10px 20px' }} className='rates_info'>{item.freight[0].shippingLine}</Typography>
                        </Box>
                        <Divider />
                        {/* <Stepper activeStep={activeStep} alternativeLabel={true} sx={{
                            padding: '10px 20px', mt: 2,
                           
                            ".MuiSvgIcon-root": {
                                borderRadius: "50%",
                                border: "1px solid #1976d2"
                            },
                            ".MuiSvgIcon-root:not(.Mui-completed)": {
                                color: "white"
                            },
                            ".MuiStepIcon-text": {
                                fill: "#1976d2",
                                fontWeight: 500
                            },
                            ".MuiSvgIcon-root.Mui-active": {
                                color: "#1976d2",
                                padding: "3px",
                                borderRadius: "50%",
                                border: "1px solid #1976d2",
                                marginY: "-3px"
                            },
                            ".Mui-active .MuiStepIcon-text": {
                                fill: "white"
                            },
                            
                        }} >

                            <Step key={item.portFrom.name} completed={completed['1']}>
                                <StepButton color="inherit" onClick={() => handleStep('1')}>
                                    <StepLabel>{item.portFrom.name}</StepLabel>
                                </StepButton>
                            </Step>
                            <Step key={item.portFrom.code} completed={completed['2']}>
                                <StepButton color="inherit" onClick={() => handleStep('2')}>
                                    <StepLabel>{item.portFrom.code}</StepLabel>
                                </StepButton>
                            </Step>
                            <Step key={item.portTo.code} completed={completed['3']}>
                                <StepButton color="inherit" onClick={() => handleStep('3')}>
                                    <StepLabel>{item.portTo.code}</StepLabel>
                                </StepButton>
                            </Step>
                            <Step key={item.portTo.name} completed={completed['4']}>
                                <StepButton color="inherit" onClick={() => handleStep('4')}>
                                    <StepLabel>{item.portTo.name}</StepLabel>
                                </StepButton>
                            </Step>
                        </Stepper> */}
                        <div className='stepper'>
                            <div className='stepper_content_top'>
                                <Typography className='step_title'>{item.portFrom.name}</Typography>
                                <Typography className='step_title'>{item.portTo.name}</Typography>
                            </div>
                            <div className='steps'>
                                <div className={isPickUpActive === true ? 'steps_main steps_cyan steps_cyan_active' : 'steps_main steps_cyan steps_cyan_inactive'}>
                                    {isPickUpActive === true ? <LocalShippingOutlinedIcon className='steps_icon' /> : ''}
                                    <div className={isPickUpActive === true ? 'steps_circle steps_circle_active' : 'steps_circle steps_circle_inactive'} onClick={() => OnpickupClick(item)}>
                                        <div className='steps_tooltip'>
                                            <div className='steps_tooltip_header'>
                                                <div className='steps_tooltip_content'>
                                                    <Typography className='steps_tooltip_content_info'>
                                                        Pick up
                                                    </Typography>
                                                    <Divider />
                                                    <Typography>Port Of Everglades</Typography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='steps_main steps_blue'>
                                    <Typography className='steps_delay_days'>18 days</Typography>
                                    <DirectionsBoatFilledOutlinedIcon className='steps_icon' />
                                    <div className={isPortOfOriginActive === true ? 'steps_circle steps_circle_active ' : 'steps_circle steps_circle_inactive'} onClick={() => isPickUpActive != true ? onPortOfOriginClick(item) : ''}>
                                        <div className='steps_tooltip'>
                                            <div className='steps_tooltip_header'>
                                                <div className='steps_tooltip_content'>
                                                    <Typography className='steps_tooltip_content_info'>
                                                        Port of origin
                                                    </Typography>
                                                    <Divider />
                                                    <Typography>Port Of Everglades</Typography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper_content_bottom'>
                                        <Typography className='step_bottom-title'>{item.portFrom.code}</Typography>
                                    </div>
                                </div>
                                <div className={isDeliveryActive === true ? 'steps_main steps_blue steps-blue-cyan_active' : 'steps_main steps_blue steps-blue-cyan_inactive'}>
                                    {isDeliveryActive ? <LocalShippingOutlinedIcon className='steps_icon' /> : ''}
                                    <div className={isPortOfDischargeActive === true ? 'steps_circle steps_circle_active' : 'steps_circle steps_circle_inactive'} onClick={() => onPortOfDischargeClick(item)}>
                                        <div className='steps_tooltip'>
                                            <div className='steps_tooltip_header'>
                                                <div className='steps_tooltip_content'>
                                                    <Typography className='steps_tooltip_content_info'>
                                                        Port of discharge
                                                    </Typography>
                                                    <Divider />
                                                    <Typography>Bremerhaven</Typography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper_content_bottom'>
                                        <Typography className='step_bottom-title'>{item.portTo.code}</Typography>
                                    </div>
                                </div>
                                <div className='steps_main steps_cyan'>
                                    <div className={isDeliveryActive === true ? 'steps_circle steps_circle_active' : 'steps_circle steps_circle_inactive'} onClick={() => onPortOfDeliveryClick(item)}>
                                        <div className='steps_tooltip'>
                                            <div className='steps_tooltip_header'>
                                                <div className='steps_tooltip_content'>
                                                    <Typography className='steps_tooltip_content_info'>
                                                        Delivery
                                                    </Typography>
                                                    <Divider />
                                                    <Typography>Bremerhaven</Typography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Box>


                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item xs={4} sx={{ ml: 2 }}>
                    <Box sx={{ display: 'flex', padding: '5px' }}>
                        <Typography sx={{ color: '#9fb1c1', fontSize: '14px' }}>
                            <img src={require('../../../asserts/leaf.png')} style={{ width: '50px' }} />
                        </Typography>
                        <span style={{ fontSize: '20px', marginTop: '10px' }}>
                            {totalCo2Count != '' ? (totalCo2Count) : (item.freight[0].co2 / 1000)}
                            {/* {item.freight[0].co2 / 1000 + item.freight[0].truckFrom.co2 / 1000 + item.freight[0].truckTo.co2 / 1000 + item.freight[0].portFeesFrom[0].co2 / 1000 + item.freight[0].portFeesTo[0].co2 / 1000} */}
                            Kg CO2</span>

                    </Box>
                    <Box sx={{ padding: '5px' }} >
                        <Button variant="contained" size="large">
                            ${totalBookNowPrice != '' ? totalBookNowPrice : item.freight[0].originalPrice}
                            {/* $ {item.freight[0].originalPrice + item.freight[0].truckFrom.originalPrice + item.freight[0].truckTo.originalPrice + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesTo[0].originalPrice}  */}
                            Book Now
                        </Button>
                        <Typography sx={{ color: '#333', fontSize: '12px' }}><small>Applicable till: {item.freight[0].validTo}</small></Typography>

                    </Box>
                    <Box sx={{ display: 'flex', padding: '5px' }}>
                        <Typography sx={{ color: '#333', fontSize: '14px', }}><small>Estimated Delivery (in days): {item.freight[0].transitTime}</small></Typography>
                    </Box>
                    <Box>
                        <Button variant="text" size="large" onClick={() => viewDetails(item.index)}>
                            View Details
                        </Button>
                    </Box>
                </Grid>

            </Grid>
            <Divider />
        </Paper>
        {isViewDetailsShow ?
            <Grid>
                <Box sx={{ textAlign: 'center' }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={toggleValue}
                        exclusive
                        onChange={toggleHandleChange}
                        aria-label="Platform"
                        sx={{ margin: '10px' }}
                    >
                        <ToggleButton value="tariff" size='small' sx={{ padding: '2px 20px', }}>Tariff</ToggleButton>
                        <ToggleButton value="map" size='small' sx={{ padding: '2px 20px' }}>Map</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {toggleValue === 'tariff' ?
                    <Box>
                        <Accordion expanded={expanded === 'panel1-1'} onChange={handleChangeaccordion('panel1-1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    "& .MuiAccordionSummary-content": {
                                        margin: '0px !important'
                                    },
                                    "& .MuiAccordionSummary-root .Mui-expanded": {
                                        minHeight: '10px !important'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                        <Checkbox
                                            checked={isPickUpActive}
                                            onChange={() => PickUpHandleChange(item)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <LocalShippingOutlinedIcon sx={{ m: '10px', }} />
                                        <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>Pick up</Typography>
                                    </Typography>
                                    <Box className="Tariff_info">
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem', }} className='info_1'>
                                            <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} />{item.freight[0].truckFrom.co2 / 1000} <span> kg CO2</span>
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px', }} className='info_2'>
                                            <strong>${item.freight[0].truckFrom.originalPrice}</strong>
                                        </Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <FormControlLabel value="Truck" control={<Radio />} label="Truck" sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }} />
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item.freight[0].truckFrom.transitTime}</Typography>
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item.freight[0].truckFrom.distance}</Typography>
                                            <Typography sx={{ fontSize: '0.75rem' }}>${item.freight[0].truckFrom.originalPrice}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <FormControlLabel value="Rail" control={<Radio />} label="Rail" sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }} />
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: '0.75rem' }}>${item.freight[0].railFrom.originalPrice === null ? 0 : item.freight[0].railFrom.originalPrice}</Typography>
                                        </Box>
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel1-2'} onChange={handleChangeaccordion('panel1-2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                sx={{
                                    "& .MuiAccordionSummary-content": {
                                        margin: '0px !important'
                                    },
                                    "& .MuiAccordionSummary-root": {
                                        minHeight: '10px !important'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                        <Checkbox
                                            checked={isPortOfOriginActive}
                                            onChange={() => portOfOriginHandleChange(item)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={isPickUpActive === true ? true : false}
                                            sx={{
                                                '&.Mui-disabled': {
                                                    pointerEvents: 'auto',
                                                    cursor: 'not-allowed',

                                                }
                                            }}
                                        />
                                        <AnchorOutlinedIcon sx={{ m: '10px' }} />
                                        <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>
                                            Port of origin (Port Everglades)
                                        </Typography>
                                    </Typography>
                                    <Box className="Tariff_info">
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem' }} className='info_1'>
                                            <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} />{item.freight[0].portFeesFrom[0].co2 / 1000} <span> kg CO2</span>
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px' }} className='info_2'>
                                            <strong>${item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice}</strong>

                                        </Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >

                                        {item.freight[0].portFeesFrom.map((item1: any) => (
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 1 }}>
                                                <Typography sx={{ fontSize: '0.75rem', width: '300px' }}>{item1.abbr} - {item1.title}</Typography>
                                                <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item1.originalPrice === 0 ? 'included' : item1.originalPrice}</Typography>
                                            </Box>
                                        ))}
                                    </RadioGroup>

                                </FormControl>

                            </AccordionDetails>

                        </Accordion>
                        <Accordion expanded={expanded === 'panel1-3'} onChange={handleChangeaccordion('panel1-3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                                sx={{
                                    "& .MuiAccordionSummary-content": {
                                        margin: '0px !important'
                                    },
                                    "& .MuiAccordionSummary-root": {
                                        minHeight: '10px !important'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                        <Checkbox
                                            checked={true}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled
                                            sx={{
                                                '&.Mui-disabled': {
                                                    pointerEvents: 'auto',
                                                    cursor: 'not-allowed',

                                                }
                                            }}
                                        />
                                        <DirectionsBoatFilledOutlinedIcon sx={{ m: '10px' }} />
                                        <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>
                                            Ocean Freight (LIFO)
                                        </Typography>
                                    </Typography>

                                    <Box className="Tariff_info">
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem' }} className='info_1'>
                                            <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} /> {item.freight[0].co2 / 1000} <span> kg CO2</span>
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px' }} className='info_2'>
                                            <strong>${item.freight[0].originalPrice}</strong>
                                        </Typography>
                                    </Box>


                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                <FormControl sx={{ width: '100%' }}>

                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '0.75rem', width: '300px' }}>{item.freight[0].containerType}</Typography>
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>${item.freight[0].originalPrice}</Typography>

                                        </Box>
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel1-4'} onChange={handleChangeaccordion('panel1-4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                                sx={{
                                    "& .MuiAccordionSummary-content": {
                                        margin: '0px !important'
                                    },
                                    "& .MuiAccordionSummary-root": {
                                        minHeight: '10px !important'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                        <Checkbox
                                            checked={isPortOfDischargeActive}
                                            onChange={() => portOfDischargeHandleChange(item)}
                                            inputProps={{ 'aria-label': 'controlled' }}

                                        />
                                        <AnchorOutlinedIcon sx={{ m: '10px' }} />
                                        <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>Port of discharge (Hazira)</Typography>

                                    </Typography>
                                    <Box className="Tariff_info">
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem' }} className='info_1'>
                                            <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} /> {item.freight[0].portFeesTo[0].co2 / 1000} <span> kg CO2</span>
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px' }} className='info_2'>
                                            <strong>${item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice}</strong>
                                        </Typography>
                                    </Box>

                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                <FormControl sx={{ width: '100%' }}>

                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        {item.freight[0].portFeesTo.map((item1: any) => (
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 1 }}>
                                                <Typography sx={{ fontSize: '0.75rem', width: '300px' }}>{item1.abbr} - {item1.title}</Typography>
                                                <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item1.originalPrice === 0 ? 'included' : item1.originalPrice}</Typography>
                                            </Box>
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel1-5'} onChange={handleChangeaccordion('panel1-5')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                                sx={{
                                    "& .MuiAccordionSummary-content": {
                                        margin: '0px !important'
                                    },
                                    "& .MuiAccordionSummary-root": {
                                        minHeight: '10px !important'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                        <Checkbox
                                            checked={isDeliveryActive}
                                            onChange={() => portOfDeliveryHandleChange(item)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <LocalShippingOutlinedIcon sx={{ m: '10px' }} />
                                        <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>Delivery</Typography>

                                    </Typography>
                                    <Box className="Tariff_info">
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem' }} className='info_1'>
                                            <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} />{item.freight[0].truckTo.co2} <span> kg CO2</span>
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', marginTop: '10px' }} className='info_2'>
                                            <strong>${item.freight[0].truckTo.originalPrice}</strong>
                                        </Typography>

                                    </Box>

                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                <FormControl sx={{ width: '100%' }}>

                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <FormControlLabel value="Truck" control={<Radio />} label="Truck" sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }} />
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item.freight[0].truckTo.transitTime}</Typography>
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item.freight[0].truckTo.distance}</Typography>
                                            <Typography sx={{ fontSize: '0.75rem' }}>${item.freight[0].truckTo.originalPrice}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <FormControlLabel value="Rail" control={<Radio />} label="Rail" sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }} />
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: '0.75rem', }}>${item.freight[0].railTo.originalPrice === null ? 0 : item.freight[0].railTo.originalPrice}</Typography>
                                        </Box>
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </Box> : <SeaRatesMap data={{ origin: { name: item.cityFrom.name, lat: item.cityFrom.lat, lng: item.cityFrom.lng }, destination: { name: item.cityTo.name, lat: item.cityTo.lat, lng: item.cityTo.lng } }} />}
            </Grid> : ''}
    </Box>

     );
}

export default SeaRateCard;