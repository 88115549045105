import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import React, {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Input from "@mui/material/Input";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  TextField,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../../../Styles.css";
import asserts from "../../../asserts";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "70%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function BrFileupload() {
  const [rowData, setRowData] = useState<any>([]);
  const [validationPoNumber, setValidationPoNumber] = useState();
  const domLayout = "autoHeight";
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const masterDetail = true;
  const [validationData, setValidationData] = useState<any>([]);
  const Formref = useRef<any>();

  const [columnDefs] = useState([
    {
      headerName: "INVOICE NUMBER",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: "agGroupCellRenderer",
    },
    // {
    //   headerName: "VALIDATION STATUS",
    //   field: "preCheckValidationStatus",
    //   filter: true,
    //   headerTooltip: "VALIDATION STATUS",
    //   tooltipField: "preCheckValidationStatus",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (params.value === true) {
    //       return <p className="status">TRUE</p>;
    //     } else {
    //       setDisable(false);
    //       return <p className="rstatus">FALSE</p>;
    //     }
    //   },
    // },
    // {
    //   headerName: "VALIDATION MESSAGE",
    //   field: "preCheckValidationMessage",
    //   filter: true,
    //   headerTooltip: "VALIDATION MESSAGE",
    //   tooltipField: "preCheckValidationMessage",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (params.value.length > 0) {
    //       return (
    //         <Button onClick={() => alerts(params.value)}>
    //           {params.value[0]}
    //         </Button>
    //       );
    //     } else {
    //       <p>NO VALIDATION</p>;
    //     }
    //   },
    // },

    {
      headerName: "VALIDATION STATUS",
      field: "preCheckValidationMessage",
      filter: true,
      headerTooltip: "VALIDATION STATUS",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        console.log(params, "$params");
        if (params.data.preCheckValidationStatus === true) {
          return (
            <p>
              <CheckCircleIcon sx={{ fontSize: "18px", color: "green" }} />{" "}
            </p>
          );
        } else {
          if (params.value.length > 1) {
            return (
              <Button
                className="upload-error"
                sx={{ p: 0, fontSize: "12px" }}
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                <CancelIcon
                  sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                />{" "}
                1 or more Validations
              </Button>
            );
          } else if (params.value.length === 1) {
            return (
              <Button
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                {params.value[0]}
              </Button>
            );
          } else {
            <p>
              <CheckCircleIcon
                sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
              />{" "}
              NO VALIDATION
            </p>;
          }
        }
      },
    },

    {
      headerName: "INVOICE DATE",
      field: "invoiceDate",
      filter: true,
      headerTooltip: "INVOICE DATE",
      tooltipField: "invoiceDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      filter: "true",
      headerTooltip: "PURCHASE ORDER #",
      cellClass: "ag-left-aligned-cell",
      tooltipField: "purchaseOrderNumber",
    },
    {
      headerName: "FORWARDER ID",
      filter: true,
      headerTooltip: "FORWARDER ID",
      tooltipField: "forwarderID",
      field: "forwarderID",
    },
    {
      headerName: "FORWARDER NAME",
      filter: true,
      headerTooltip: "FORWARDER NAME",
      tooltipField: "forwarderName",
      field: "forwarderName",
      columnGroupShow: "closed",
    },
    {
      headerName: "FORWARDER BOOKING REFERENCE",
      filter: true,
      headerTooltip: "FORWARDER BOOKING REFERENCE",
      tooltipField: "forwarderBookingReference",
      field: "forwarderBookingReference",
      columnGroupShow: "closed",
    },

    // {
    //   headerName: "CREATED DATE",
    //   field: "brCreatedDate",
    //   filter: true,
    //   headerTooltip: "CREATED DATE",
    //   tooltipField: "brCreatedDate",
    //   cellClass: "ag-left-aligned-cell",
    // },

    {
      headerName: "PICK UP LOCATION",
      field: "pickupLocation",
      filter: true,
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "PICKUP COUNTRY",
      filter: true,
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupLocation",
      field: "pickupCountry",
      cellClass: "ag-left-aligned-cell",
      columnGroupShow: "closed",
    },
    {
      headerName: "PICKUP DATE",
      filter: true,
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickupDate",
      field: "pickupDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP ZIP CODE",
      filter: true,
      headerTooltip: "PICKUP ZIP CODE",
      tooltipField: "pickupZipCode",
      field: "pickupZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CONSIGNEE ID",
      filter: true,
      headerTooltip: "CONSIGNEE ID",
      tooltipField: "consigneeID",
      field: "consigneeID",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CONSIGNEE NAME",
      filter: true,
      headerTooltip: "CONSIGNEE NAME",
      tooltipField: "consigneeName",
      field: "consigneeName",
      cellClass: "ag-left-aligned-cell",
      columnGroupShow: "closed",
    },
    {
      headerName: "CONSIGNEE EMAIL ADDRESS",
      filter: true,
      headerTooltip: "CONSIGNEE EMAIL ADDRESS",
      tooltipField: "consigneeEamilAddress",
      field: "consigneeEamilAddress",
    },
    {
      headerName: "CONSIGNEE CONTACT NUMBER",
      filter: true,
      headerTooltip: "CONSIGNEE CONTACT NUMBER",
      tooltipField: "consingneeContactNumber",
      field: "consingneeContactNumber",
      cellClass: "ag-left-aligned-cell",
      columnGroupShow: "closed",
    },

    {
      headerName: "DROPOFF LOCATION",
      filter: true,
      headerTooltip: "DROPOFF LOCATION",
      tooltipField: "dropoffLocation",
      field: "dropoffLocation",
    },
    {
      headerName: "DROPOFF COUNTRY",
      filter: true,
      headerTooltip: "DROPOFF COUNTRTY",
      tooltipField: "dropoffCountry",
      field: "dropoffCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF ZIPCODE",
      filter: true,
      headerTooltip: "DROPOFF ZIPCODE",
      tooltipField: "dropoffZipCode",
      field: "dropoffZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CHA ID",
      filter: true,
      headerTooltip: "CHA ID",
      tooltipField: "chaID",
      field: "chaID",
    },
    {
      headerName: "CHA NAME",
      filter: true,
      headerTooltip: "CHA NAME",
      tooltipField: "chaName",
      field: "chaName",
      columnGroupShow: "closed",
    },

    // {
    //   headerName: "SHIPPER ID",
    //   filter: true,
    //   headerTooltip: "SHIPPER ID",
    //   tooltipField: "createdBy",
    //   field: "createdBy",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "SHIPPER  NAME",
    //   filter: true,
    //   headerTooltip: "SHIPPER NAME",
    //   tooltipField: "createdByName",
    //   field: "createdByName",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "FBR STATUS",
    //   filter: true,
    //   headerTooltip: "FBR STATUS",
    //   tooltipField: "fbrStatus",
    //   field: "fbrStatus",
    // },
    {
      headerName: "MODE OF SHIPMENT ",
      filter: true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
    },
    {
      headerName: "INCO TERMS",
      field: "incoTerms",
      filter: true,
      headerTooltip: "incoTerms",
      tooltipField: "INCO TERMS",
    },

    // {
    //   headerName: "CREATED BY",
    //   filter: true,
    //   headerTooltip: "CREATED BY",
    //   tooltipField: "createsby",
    //   field: "createdBy",
    // },
  ]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        flex: 1,
        columnDefs: [
          {
            headerName: "DRUG TYPE",
            field: "commodityType",
            filter: true,
            headerTooltip: "DRUG TYPE",
            tooltipField: "commodityType",
          },
          {
            headerName: "NDC",
            field: "itemName",
            filter: true,
            headerTooltip: "NDC",
            tooltipField: "itemName",
          },
          {
            headerName: "DRUG NAME",
            field: "itemDescription",
            filter: true,
            headerTooltip: "DRUG NAME",
            tooltipField: "itemDescription",
          },
          {
            headerName: "BATCH NUMBER",
            filter: true,
            field: "batchNumber",
            headerTooltip: "BATCH NUMBER",
            tooltipField: "batch number",
          },
          {
            headerName: "LOT NUMBER",
            filter: true,
            field: "lotNumber",
            headerTooltip: "LOT NUMBER",
            tooltipField: "lotNumber",
          },
          {
            headerName: "SERIAL NUMBER",
            filter: true,
            field: "serialNumber",
            headerTooltip: "SERIAL NUMBER",
            tooltipField: "serialNumber",
          },
          {
            headerName: "SSCC",
            filter: true,
            field: "sscc",
            headerTooltip: "SSCC",
            tooltipField: "sscc",
          },
          {
            headerName: "GRAI",
            filter: true,
            field: "grai",
            headerTooltip: "GRAI",
            tooltipField: "grai",
          },
          // {
          //   headerName: "FORWARD BOOKING REFERENCE",
          //   field: "forwarderBookingReference",
          //   filter: true,
          //   headerTooltip: "Forward Booking Reference",
          //   tooltipField: "forwarderBookingReference",
          // },
          // {
          //   headerName: "FORWARDER NAME",
          //   field: "forwarderName",
          //   filter: true,
          //   headerTooltip: "FORWARDER NAME",
          //   tooltipField: "forwarderName",
          // },

          {
            headerName: "HS CODE",
            field: "hscode",
            filter: true,
            headerTooltip: "HS CODE",
            tooltipField: "hscode",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "HS CODE DESCRIPTION",
            field: "hscodeDescription",
            filter: true,
            headerTooltip: "HS CODE DESCRIPTION",
            tooltipField: "hscodeDescription",
          },
          {
            headerName: "QUANTITY",
            field: "quantity",
            filter: true,
            headerTooltip: "QUANTITY",
            tooltipField: "quantity",
          },
          {
            headerName: "NUMBER OF PACKAGES",
            field: "numberofPackages",
            filter: true,
            headerTooltip: "NUMBER OF PACKAGES",
            tooltipField: "number of packages",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "PACKAGE TYPE",
            field: "type",
            filter: true,
            headerTooltip: "PACKAGE TYPE",
            tooltipField: "packagetype",
          },
          {
            headerName: "PACKAGE WEIGHT",
            field: "weight",
            filter: true,
            headerTooltip: "PACKAGE WEIGHT",
            tooltipField: "packageweight",
          },
          {
            headerName: "PACKAGE LENGTH",
            field: "length",
            filter: true,
            headerTooltip: "PACKAGE LENGTH",
            tooltipField: "packagelength",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "PACKAGE WIDTH",
            field: "width",
            filter: true,
            headerTooltip: "PACKAGE WIDTH",
            tooltipField: "packagewidth",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "PACKAGE HEIGHT",
            field: "height",
            filter: true,
            headerTooltip: "PACKAGE HEIGHT",
            tooltipField: "packageheight",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "PACKAGE SPECIAL INSTRUCTIONS",
            field: "specialInstructions",
            filter: true,
            headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
            tooltipField: "packagespecialinstructions",
          },
          {
            headerName: "HAZARDOUS GOODS",
            field: "hazardousGoods",
            filter: true,
            headerTooltip: "hazardousGoods",
            tooltipField: "HAZARDOUS GOODS",
          },
          // {
          //   headerName: "SHIPMENT LINE STATUS",
          //   field: "lineShipStatus",
          //   filter: true,
          //   headerTooltip: "SHIPMENT LINE STATUS",
          //   tooltipField: "lineShipStatus",
          // },

          {
            headerName: "REQUESTED DATE OF DELIVERY",
            field: "requestedDateOfDelivery",
            filter: true,
            headerTooltip: "requestedDateOfDelivery",
            tooltipField: "REQUEST DATE OF DELIVERY",
            cellClass: "ag-left-aligned-cell",
          },
          // {
          //   headerName: "DISPATCH STATUS",
          //   field: "lineDelStatus",
          //   filter: true,
          //   headerTooltip: "DISPATCH STATUS",
          //   tooltipField: "lineDelStatus",
          // },
          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationMessage",
            filter: true,
            headerTooltip: "VALIDATION MESSAGE",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              console.log(params, "$params");
              if (params.data.preCheckValidationStatus === true) {
                return (
                  <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green" }}
                    />{" "}
                  </p>
                );
              } else {
                if (params.value.length > 1) {
                  return (
                    <Button
                      className="upload-error"
                      sx={{ p: 0, fontSize: "12px" }}
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      <CancelIcon
                        sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                      />{" "}
                      1 or more Validations
                    </Button>
                  );
                } else if (params.value.length === 1) {
                  return (
                    <Button
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      {params.value[0]}
                    </Button>
                  );
                } else {
                  <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
                    />{" "}
                    NO VALIDATION
                  </p>;
                }
              }
            },
          },
        ],

        defaultColDef: {
          sortable: true,
          flex: 1,
        },
      },
      getDetailRowData: (params: any) => {
        console.log(params.data.lines);

        params.successCallback(params.data.lines);
        params.node.beans.columnApi.autoSizeAllColumns(false);
      },
    };
  }, []);

  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setExcelName(e.target.files[0].name);
      // Formref.current.reset();
    }
  }
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
    setFile(undefined);
  };

  //   const onGridReady = useCallback((params: GridReadyEvent) => {}, []);
  //PreView Api

  const Preview = () => {
    // setDisable(true);
    console.log(file, "$file");
    if (fileExtError == false) {
      if (file === undefined) {
        toast.warning("Please Select a File...");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      let url =
        "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/uploadFile/BookingRequests";
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-date",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };
      axios.post(url, formData, config).then((res: any) => {
        console.log(res);
        setPreviewData(res.data);
        setRowData(res.data.data);
        res.data.data.map((disables: any) => {
          if (disables.preCheckValidationStatus === false) {
            setDisable(false);
          } else {
            setDisable(true);
          }
        });
      });
      setShowFile(true);
    }
  };

  function alerts(params: any, poNum: any) {
    setValidationPoNumber(poNum);
    setAlertOpen(true);
    setValidationData(params);
  }
  const closeAlert = () => setAlertOpen(false);

  //File Submit Api
  const SubmitFile = () => {
    let url =
      "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/brSubmit";
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    let obj: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    axios
      .post(url, obj, config)
      .then((res: any) => {
        if (res.data.status === true) {
          swal("Success", res.data.msg, "success");
        }
      })
      .catch((err: any) => {
        if (err.res.data.status === false) {
          swal("Error", err.res.data.msg, "error");
        }
      });
    setRowData([]);
    setShowFile(false);
    setExcelName("Selcet File");
    Formref.current.reset();
    const formData = new FormData();
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // params.columnApi.autoSizeAllColumns(false);
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + "/BRUPLOADS.xlsx"}
            download={"BRUPLOADS.xlsx"}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton>{" "}
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={Formref}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              sx={{
                fontWeight: 500,
              }}
              onClick={(event) => Preview()}
            >
              Preview
            </LoadingButton>
            <span>&nbsp; &nbsp;</span>

            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box className="ag-theme-balham" sx={{ mt: 2 }}>
            <AgGridReact
              animateRows={true}
              defaultColDef={defaultColDef}
              detailCellRendererParams={detailCellRendererParams}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              masterDetail={masterDetail}
              paginationPageSize={5}
              onGridReady={onGridReady}
              // onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {disable ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                  sx={{marginBottom:'52px'}}
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" type="submit" disabled sx={{marginBottom:'52px'}}>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Validation Messages"}</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                >
                  <Typography>{data}</Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
}

export default BrFileupload;
