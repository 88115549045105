import { Alert, AlertTitle, AppBar, Box, Button, Dialog, Slide, Stepper, Toolbar } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { FirstDataRenderedEvent, GridReadyEvent } from 'ag-grid-community';
import DetailBookingRequestInfo from './BookingLifeCycle/DetailBookingRequestInfo';
import BookingRequest from '../components/pages/Forwarder/BrAck';
import DetailConfirmedCarrierInfo from './BookingLifeCycle/DetailConfirmedCarrierInfo';
import DetailAcceptedByForwarderInfo from './BookingLifeCycle/DetailAcceptedByForwarderInfo';
import CommonBookingLifeCycleInfo from './CommonBookingLifeCycleInfo';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DashboardBookingLifeCycle(props: any) {
    const [value, setValue] = React.useState("1");
    const [childData, setChildData] = useState(false)
    const myRef = useRef<any>();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const handleCallback = (childData: any) => {
        console.log(childData, "chield data")
        setChildData(childData)
        const scrollY = myRef.current.clientHeight;
        console.log(scrollY, "scrolly11324")
        myRef.current.scrollTop = scrollY
        

        document.getElementById('moreInfo')?.scrollIntoView({behavior:'smooth'})
       
    }
    return (
        <div className="dialog">
            <Dialog
                fullScreen
                open={props.onOpen}
                onClose={props.onClose}
                TransitionComponent={Transition}
                sx={{
                    "& .MuiDialog-root": {},
                    zIndex: 10000,
                }}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' component="div" sx={{ ml: 2, flex: 1 }}>
                            Bookings Life Cycle - Insights
                        </Typography>

                        <Button autoFocus color="inherit" onClick={props.onClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box sx={{ width: "100%", typography: "body1", p: 3, overflowY: 'scroll' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab label="Booking Requests" value="1" />
                                <Tab label="Accepted By Forwarder" value="2" />
                                <Tab label="Confirmed By Carrier" value="3" />

                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{padding: '24px 0px'}}>
                            <CommonBookingLifeCycleInfo parentCallback1={handleCallback} pageInfo={{tab:'bookingRequests',page:'BookingLifeCycle'}}/>
                        </TabPanel>
                        <TabPanel value="2" sx={{padding: '24px 0px'}}>
                            <CommonBookingLifeCycleInfo parentCallback1={handleCallback} pageInfo={{tab:'acceptedByForwarder',page:'BookingLifeCycle'}}/>
                        </TabPanel>
                        <TabPanel value="3" sx={{padding: '24px 0px'}}>
                            <CommonBookingLifeCycleInfo parentCallback1={handleCallback} pageInfo={{tab:'confirmedByCarrier',page:'BookingLifeCycle'}}/>
                        </TabPanel>

                    </TabContext>

                </Box>
            </Dialog>
        </div>
    )
}
export default DashboardBookingLifeCycle;
