import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  FormControl,
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "../Styles.css";
import SearchBar from "material-ui-search-bar";
import Echarts from "./BarWithLinesEcharts";
import CachedIcon from "@mui/icons-material/Cached";
import { AgGridReact } from "ag-grid-react";
import DonutEcharts from "./DonutEcharts";
import axios from "axios";
import DeviationCharts from "./DeviationCharts";
import DeliveredShipmentsCharts from "./DeliveredShipmentsCharts";

declare var $: any;
interface Column {
  id: "count" | "percentage" | "percentage";
  label: string;
}
const columns: readonly Column[] = [
  { id: "count", label: "# Shipments" },
  // { id: 'eventName', label: 'Event Type' },
  { id: "percentage", label: "Percentage" },
  // { id: 'avgdelayindays', label: 'Avg. Delay In Days' }
];
const dummyResp = {
  status: true,
  data: {
    summary: {
      atWareHouse: 0,
      outForDelivery: 0,
      delivered: 0,
      importClearance: 0,
      gateOut: 0,
    },
    countryEvents: [
      {
        country: "india",
        events: [
          {
            eventName: "undefined",
            count: 14,
            percentage: 5,
          },
          {
            eventName: "undefined",
            count: 14,
            percentage: 5,
          },
        ],
      },
      {
        country: "Albania",
        events: [
          {
            eventName: "undefined",
            count: 14,
          },
        ],
      },
      {
        country: "Afghanistan",
        events: [
          {
            eventName: "undefined",
            count: 14,
          },
        ],
      },
    ],
    shipmentByEventType: [
      {
        country: "Afghanistan",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "Albania",
        eventMapping: "Export Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Gate Out",
        consigneeID: "consignee002",
        consigneeName: "consignee 002",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
      {
        country: "India",
        eventMapping: "Import Customs Clearance",
        consigneeID: "consignee001",
        consigneeName: "consignee 001",
        shipperID: "shipper001",
        shipperName: "shipper 001",
      },
    ],
  },
  totalShipments: 14,
};
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
export default function CommonDeliveredShipmentsInfo(props: any) {
  console.log(props, "props");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isDataShow, setIsDataShow] = useState(true);
  const [filterCountry, setfilterCountry] = useState([]);
  const [origin, setOrigin] = useState("");
  const [shipper, setShipper] = useState("");
  const [rowData, setRowData] = useState([]);
  const [childData, setChildData] = useState(false);
  const [chartPayLoad, setChartPayLoad] = useState<any>();
  const [ischartShow, setIsChartShow] = useState(true);
  const [reliablityInfo, setReliablityInfo] = useState<any>();
  const myRef = useRef<any>();
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  let totalResp: any = [];
  const [mapOrigin, setMapOrigin] = useState<any>(null);
  const [mapDestination, setMapDestination] = useState<any>(null);
  const [shipmentsCount, setShipmentsCount] = useState<any>();
  const [countryEvents, setCountryEvents] = useState<any>([]);
  const [shipmentsByOrigin, setShipmentsByOrigin] = useState<any>([]);
  const [mapleggends, setMapLeggends] = useState([]);
  const [mapSerious, setMapSerious] = useState([]);
  let url =
    "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/deliveredShipmentAnalytics";
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {},
  };
  useEffect(() => {
    let status = {
      status: props.pageInfo.tab,
    };
    etaDeviationAnalyticsEventsData(status);
  }, []);
  const etaDeviationAnalyticsEventsData = (status: any) => {
    config.params = status;
    setShipmentsCount(dummyResp);
    setCountryEvents(dummyResp.data.shipmentByEventType);
    setShipmentsByOrigin([
      ...new Set(
        dummyResp.data.shipmentByEventType.map((item: any) => item.consigneeID)
      ),
    ]);
    totalResp = dummyResp.data.shipmentByEventType;
    axios.get(url, config).then((res: any) => {
      console.log(res.data, "resppp");
      setShipmentsCount(dummyResp);
      setCountryEvents(res.data.data.shipmentByCountry);
      // totalResp = res.data.data.shipmentByCarrier
      // setShipmentsByOrigin([...new Set(res.data.data.shipmentByCarrier.map((item:any) => item.consigneeID))])
      graphData(null, null);
      if (res.data.data.shipmentByCountry.length > 0) {
        setIsDataShow(true);
      }
    });
  };
  const graphData = (consignee: any, country: any) => {
    // IF country and consignee is null, populate all
    console.log(mapDestination, "mapDestination");
    let data = totalResp;
    let filteredArray: any = [];
    let legends: any = [];
    if (mapDestination !== null) {
      filteredArray = data.filter(
        (item: any) => item.country === mapDestination
      );
      console.log(filteredArray, "filterarray");
    }
    if (mapOrigin !== null) {
      filteredArray = data.filter(
        (item: any) => item.consigneeID === mapOrigin
      );
      console.log(filteredArray, "filterarray2");
    }
    if (mapOrigin === null && mapDestination === null) {
      filteredArray = data;
    }
    legends = [...new Set(filteredArray.map((item: any) => item.eventMapping))];
    console.log(legends, "eventss");
    const values: any = [];
    for (let i = 0; i < legends.length; i++) {
      let eventCount = filteredArray.filter(
        (item: any) => item.eventMapping === legends[i]
      ).length;
      values.push(eventCount);
    }
    console.log(values, "values");
    setMapLeggends(legends);
    setMapSerious(values);
  };

  const originalRows: any = [
    {
      country: "India",
      shipments: 170,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "China",
      shipments: 98,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Italy",
      shipments: 48,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Hamburger",
      shipments: 87,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Germany",
      shipments: 15,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
  ];
  const [columnDefs] = useState([
    {
      headerName: "HBL #",
      field: "hblNumber",
      filter: true,
      headerTooltip: "HBL #",
      tooltipField: "hblNumber",
    },
    {
      headerName: "SHIPPER NAME",
      field: "hblNumber",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "hblNumber",
    },
    {
      headerName: "COMMODITY TYPE",
      field: "commodityType",
      filter: true,
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
    },
    {
      headerName: "ITEM NAME",
      field: "itemName",
      filter: true,
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "DESCRIPTION",
      field: "itemDescription",
      filter: true,
      headerTooltip: "DESCRIPTION",
      tooltipField: "itemDescription",
    },
    {
      headerName: "QUANTITY",
      field: "hblNumber",
      filter: true,
      headerTooltip: "QUANTITY",
      tooltipField: "hblNumber",
    },
    {
      headerName: "ETA",
      field: "hblNumber",
      filter: true,
      headerTooltip: "ETA",
      tooltipField: "hblNumber",
    },
    {
      headerName: "CHANGE IN ETA",
      field: "hblNumber",
      filter: true,
      headerTooltip: "CHANGE IN ETA",
      tooltipField: "hblNumber",
    },
    {
      headerName: "DELAY IN DAYS",
      field: "hblNumber",
      filter: true,
      headerTooltip: "DELAY IN DAYS",
      tooltipField: "hblNumber",
    },
    {
      headerName: "DROP OF LOCATION",
      field: "dropoffCountry",
      filter: true,
      headerTooltip: "DROP OF LOCATION",
      tooltipField: "dropoffCountry",
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const requestSearch = (searchedVal: string) => {
    console.log(searchedVal, "searched value");
    const filteredRows = rows.filter((row: any) => {
      console.log(row, "row names");
      return row.countryName.toLowerCase().includes(searchedVal.toLowerCase());
    });

    setRows(filteredRows);
  };
  const handleChanges = (event: SelectChangeEvent) => {
    setIsChartShow(false);
    console.log(event.target.value, "target");
    // setOrigin(event.target.value);
    let status = {
      filterOnConsigneeId: event.target.value,
    };
    setMapOrigin(event.target.value);
  };
  const handleChanges1 = (event: SelectChangeEvent) => {
    let obj = {
      status: props.pageInfo.tab,
      filterOnConsigneeId: event.target.value,
    };
    setChartPayLoad(obj);
    setIsChartShow(true);
    setMapDestination(event.target.value);
    console.log(chartPayLoad, "chart1 payload");
  };
  const dataRefresh = () => {
    setMapDestination(null);
    setMapOrigin(null);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const handleCallback = (childData: any) => {
    console.log(childData, "childdata");
    setChildData(childData);
    setRowData(childData.carrier);

    props.parentCallback1(true);
    console.log(props, "page info");
    if (props.pageInfo.page === "shipmentDeviation") {
      let obj = {
        status: props.pageInfo.tab,
        filterOnCarrierId: childData.carrierName,
      };
      setReliablityInfo(obj);
    }
  };
  const filtersByCountry = (country: any) => {
    console.log(country, "country");
    setMapDestination(country);
  };
  const filterByCountry = () => {};
  return (
    <div ref={myRef}>
      {props.pageInfo.tab != "reliability" ? (
        <Grid container spacing={2} sx={{ mt: 0, display: "flex" }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
              {sessionStorage.getItem("role") != "consignee"
                ? "SHIPMENTS BY DESTINATION COUNTRY"
                : "SHIPMENTS BY ORIGIN COUNTRY"}
            </Typography>
            {isDataShow ? (
              <div>
                <Box sx={{ mt: 2 }}>
                  <SearchBar
                    value={searched}
                    placeholder="Search By Country"
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                </Box>

                <Paper sx={{ mt: 2 }}>
                  <div>
                    {dummyResp.data.countryEvents.map(
                      (item: any, index: any) => {
                        return (
                          <Accordion
                            expanded={expanded === "panel" + index}
                            onChange={handleChange("panel" + index)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography
                                className="tableCellData"
                                onClick={() => filtersByCountry(item.country)}
                              >
                                {item.country}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TableContainer sx={{ maxHeight: 440, mt: 1 }}>
                                <Table aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      {columns.map((column) => (
                                        <TableCell key={column.id}>
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  {item.events ? (
                                    <TableBody>
                                      {item.events
                                        .slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any) => {
                                          return (
                                            <TableRow
                                              hover
                                              role="checkbox"
                                              tabIndex={-1}
                                              key={row.code}
                                            >
                                              {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                  <TableCell
                                                    key={column.id}
                                                    style={{ padding: "10px" }}
                                                  >
                                                    {column.label ===
                                                    "Country" ? (
                                                      <a
                                                        className="tableCellData"
                                                        onClick={() =>
                                                          filterByCountry()
                                                        }
                                                      >
                                                        {value}
                                                      </a>
                                                    ) : (
                                                      value
                                                    )}
                                                  </TableCell>
                                                );
                                              })}
                                            </TableRow>
                                          );
                                        })}
                                    </TableBody>
                                  ) : (
                                    ""
                                  )}
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    )}
                  </div>
                </Paper>
              </div>
            ) : (
              <Alert severity="info">
                <AlertTitle>Info</AlertTitle>
                No Data Found
              </Alert>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                SHIPMENTS BY EVENTS TYPE
              </Typography>
              <IconButton sx={{ top: "-8px" }}>
                <CachedIcon onClick={dataRefresh} />
              </IconButton>
            </Box>
            <Grid container spacing={2} sx={{ display: "flex", mt: 1 }}>
              <Grid item xs={6}>
                {/* <FormControl fullWidth size="small">
                  <InputLabel htmlFor="grouped-native-select">
                    Consignee
                  </InputLabel>
                  <Select
                    native
                    defaultValue="hi"
                    id="grouped-native-select"
                    label="Consignee"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={handleChanges}
                  >
                    <option aria-label="None" value="0">
                      Select
                    </option>
                    {shipmentsByOrigin.map((role: any) => {
                      return <option value={role}>{role}</option>;
                    })}
                  </Select>
                </FormControl> */}
                <FormControl fullWidth size="small">
                  <Typography>Consignee</Typography>
                  <select
                    className="custom_select"
                    placeholder="Select Consignee"
                    onChange={(e: any) => handleChanges(e)}
                  >
                    <option value="">Select Consignee</option>
                    {shipmentsByOrigin.map((role: any) => {
                      return <option value={role}>{role}</option>;
                    })}
                  </select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {/* <FormControl fullWidth size="small">
                                    <InputLabel htmlFor="grouped-native-select">Destination</InputLabel>
                                    <Select native defaultValue="" id="grouped-native-select" label="Grouping"
                                    MenuProps={{disableScrollLock:true}}
                                     placeholder='Select Destination' onChange={handleChanges1}>
                                        <option aria-label="None" value="0">Select</option>
                                        {dummyResp.data.countryEvents.map((item: any) => {
                                            return (
                                                <option value={item.country}>{item.country}</option>
                                            )
                                        })}
                                    </Select>
                                </FormControl> */}
                <FormControl fullWidth size="small">
                  <Typography>Destination</Typography>

                  <select
                    className="custom_select"
                    placeholder="Select Destination"
                    onChange={(e: any) => handleChanges1(e)}
                  >
                    <option value="">Select Destination</option>
                    {dummyResp.data.countryEvents.map((item: any) => {
                      return (
                        <option value={item.country}>{item.country}</option>
                      );
                    })}
                  </select>
                </FormControl>
              </Grid>
            </Grid>
            {/* {mapSerious.length > 0 && mapleggends.length > 0 ? */}
            <Paper sx={{ mt: 1 }}>
              {/* <DeliveredShipmentsCharts parentCallback={handleCallback} data={chartPayLoad} mapInfo={{ xAxisData: mapleggends, yAxisData: mapSerious }} page={props.pageInfo.page} /> */}
              <DeliveredShipmentsCharts
                parentCallback={handleCallback}
                data={chartPayLoad}
                mapInfo={{ xAxisData: mapleggends, yAxisData: mapSerious }}
                page={props.pageInfo.page}
              />
            </Paper>
            {/* :
                            (
                                <Alert severity="info" sx={{mt:2}}>
                                    <AlertTitle>Info</AlertTitle>
                                    No Data Found
                                </Alert>
                            )
                        } */}
            {/* <Paper sx={{ mt: 1 }}>
                        {chartPayLoad ?
                            <Echarts parentCallback={handleCallback} data={chartPayLoad} page={props.pageInfo.page} /> : ''}
                    </Paper> */}
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {props.pageInfo.tab === "reliability" ? (
        <Box sx={{ mt: 3 }}>
          {reliablityInfo ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontSize: "1rem",
              }}
            >
              RELIABILITY TRENDS
            </Typography>
          ) : (
            ""
          )}
        </Box>
      ) : (
        " "
      )}
      {props.pageInfo.tab === "reliability" ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* <Grid item xs={6}> */}
          {/* {reliablityInfo ? */}
          {/* <Typography variant='h6' sx={{
                                textAlign: 'center',
                            }}>
                                OVER ALL
                            </Typography>  */}
          {/* : ''} */}
          {/* {reliablityInfo ? */}
          {/* <DonutEcharts data={'overall'} parentCallback={handleCallback} data1={reliablityInfo} />  */}
          {/* : ''} */}
          {/* </Grid> */}
          <Grid item xs={6}>
            {/* {reliablityInfo ? */}
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
              }}
            >
              PICK UP
            </Typography>
            {/* : ''} */}
            {/* {reliablityInfo ? */}
            <DonutEcharts
              data={"pickUp"}
              parentCallback={handleCallback}
              data1={reliablityInfo}
            />
            {/* : ''} */}
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
              }}
            >
              DROP OFF
            </Typography>
            <DonutEcharts data={"dropOff"} parentCallback={handleCallback} />
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      <Grid sx={{ mt: 3 }} id="moreInfo">
        {childData ? (
          <Box>
            <Box>
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                SHIPMENTS
              </Typography>
            </Box>
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              rowSelection={"multiple"}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={"autoHeight"}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
            ></AgGridReact>
          </Box>
        ) : (
          ""
        )}
      </Grid>

      {/* <a href="#" id="roll_back" className="animate">
                <KeyboardArrowUpIcon />
            </a> */}
    </div>
  );
}
