import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  FormControl,
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "../Styles.css";
import SearchBar from "material-ui-search-bar";
import Echarts from "./BarWithLinesEcharts";
import CachedIcon from "@mui/icons-material/Cached";
import { AgGridReact } from "ag-grid-react";
import DonutEcharts from "./DonutEcharts";
import axios from "axios";
import DeviationCharts from "./DeviationCharts";

declare var $: any;
interface Column {
  id: "count" | "eventName" | "percentage";
  label: string;
}
const columns: readonly Column[] = [
  { id: "count", label: "# Shipments" },
  { id: "eventName", label: "Event Type" },
  // { id: 'percentage', label: 'Event Location' },
  // { id: 'avgdelayindays', label: 'Avg. Delay In Days' }
];
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

interface Data {
  name: string;
  code: string;
  population: number;
  size: number;
  density: number;
}

function createData(
  name: string,
  code: string,
  population: number,
  size: number
): Data {
  const density = population / size;
  return { name, code, population, size, density };
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CommonInTransitInfo(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isFirstDataReder, setIsFirstDataRender] = useState(true);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState([]);
  const [countryData, setCountryData] = useState<any>([]);
  const [mapOrigin, setMapOrigin] = useState("");
  const [mapDestination, setMapDestination] = useState("");
  const [isDataShow, setIsDataShow] = useState(true);
  const [filterCountry, setfilterCountry] = useState("");
  const [origin, setOrigin] = useState("");
  const [shipper, setShipper] = useState("");
  const [rowData, setRowData] = useState([]);
  const [childData, setChildData] = useState(false);
  const [chartPayLoad, setChartPayLoad] = useState<any>();
  const [ischartShow, setIsChartShow] = useState(true);
  const [reliablityInfo, setReliablityInfo] = useState<any>();
  const [shipmentsCount, setShipmentsCount] = useState<any>();
  const [countryEvents, setCountryEvents] = useState<any>([]);
  const [shipmentsByOrigin, setShipmentsByOrigin] = useState<any>([]);
  const myRef = useRef<any>();
  const [mapleggends, setMapLeggends] = useState([]);
  const [mapSerious, setMapSerious] = useState([]);
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [value, setValue] = useState("");
  let consignee: any;
  let country: any;
  let totalResp: any = [];
  const dummyResp =
    props.pageInfo.tab === "arrivingToday"
      ? {
          status: true,
          data: {
            summary: {
              atWareHouse: 478,
              outForDelivery: 664,
              delivered: 187,
              importClearance: 67,
              gateOut: 127,
            },
            countryEvents: [
              {
                country: "INDIA",
                Percentage: "292",
                events: [
                  {
                    eventName: "At Wharehouse",
                    count: 105,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 146,
                  },
                  {
                    eventName: "Delivered",
                    count: 41,
                  },
                ],
              },
              {
                country: "GERMANY",
                Percentage: "213",
                events: [
                  {
                    eventName: "At Wharehouse",
                    count: 76,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 106,
                  },
                  {
                    eventName: "Delivered",
                    count: 31,
                  },
                ],
              },
              {
                country: "JAPAN",
                Percentage: "240",
                events: [
                  {
                    eventName: "At Wharehouse",
                    count: 86,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 120,
                  },
                  {
                    eventName: "Delivered",
                    count: 34,
                  },
                ],
              },
              {
                country: "USA",
                Percentage: "306",
                events: [
                  {
                    eventName: "At Wharehouse",
                    count: 110,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 153,
                  },
                  {
                    eventName: "Delivered",
                    count: 43,
                  },
                ],
              },
              {
                country: "CHINA",
                Percentage: "278",
                events: [
                  {
                    eventName: "At Wharehouse",
                    count: 100,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 139,
                  },
                  {
                    eventName: "Delivered",
                    count: 39,
                  },
                ],
              },
            ],
            shipmentByEventType: [
              {
                country: "INDIA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Delivered",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Delivered",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee002",
                consigneeName: "consignee 002",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Delivered",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Delivered",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Delivered",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
            ],
          },
          totalShipments: 14,
        }
      : props.pageInfo.tab === "arrivingNextDay"
      ? {
          status: true,
          data: {
            summary: {
              atWareHouse: 487,
              outForDelivery: 293,
              delivered: 293,
              importClearance: 66,
              gateOut: 127,
            },
            countryEvents: [
              {
                country: "INDIA",
                Percentage: "214",
                events: [
                  {
                    eventName: "Import Clearance",
                    count: 15,
                  },
                  {
                    eventName: "Gate Out",
                    count: 28,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 107,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 64,
                  },
                ],
              },
              {
                country: "GERMANY",
                Percentage: "156",
                events: [
                  {
                    eventName: "Import Clearance",
                    count: 11,
                  },
                  {
                    eventName: "Gate Out",
                    count: 20,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 78,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 47,
                  },
                ],
              },
              {
                country: "JAPAN",
                Percentage: "175",
                events: [
                  {
                    eventName: "Import Clearance",
                    count: 11,
                  },
                  {
                    eventName: "Gate Out",
                    count: 23,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 88,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 53,
                  },
                ],
              },
              {
                country: "USA",
                Percentage: "224",
                events: [
                  {
                    eventName: "Import Clearance",
                    count: 15,
                  },
                  {
                    eventName: "Gate Out",
                    count: 29,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 112,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 68,
                  },
                ],
              },
              {
                country: "CHINA",
                Percentage: "204",
                events: [
                  {
                    eventName: "Import Clearance",
                    count: 14,
                  },
                  {
                    eventName: "Gate Out",
                    count: 27,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 102,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 61,
                  },
                ],
              },
            ],
            shipmentByEventType: [
              {
                country: "INDIA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out",
                consigneeID: "consignee002",
                consigneeName: "consignee 002",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
            ],
          },
          totalShipments: 14,
        }
      : {
          status: true,
          data: {
            summary: {
              atWareHouse: 89,
              outForDelivery: 27,
              delivered: 188,
              importClearance: 432,
              gateOut: 101,
              exportclearance: 94,
              vesselLoad: 128,
              vesselMovement: 113,
              vesselDischarge: 197,
            },
            countryEvents: [
              {
                country: "INDIA",
                Percentage: "260",
                events: [
                  {
                    eventName: "Export Clearance",
                    count: 21,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 28,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 25,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 43,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 95,
                  },
                  {
                    eventName: "Gate Out",
                    count: 22,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 20,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 6,
                  },
                ],
              },
              {
                country: "GERMANY",
                Percentage: "189",
                events: [
                  {
                    eventName: "Export Clearance",
                    count: 15,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 21,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 18,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 32,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 69,
                  },
                  {
                    eventName: "Gate Out",
                    count: 16,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 14,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 4,
                  },
                ],
              },
              {
                country: "JAPAN",
                Percentage: "213",
                events: [
                  {
                    eventName: "Export Clearance",
                    count: 17,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 23,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 20,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 36,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 78,
                  },
                  {
                    eventName: "Gate Out",
                    count: 18,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 16,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 5,
                  },
                ],
              },
              {
                country: "USA",
                Percentage: "271",
                events: [
                  {
                    eventName: "Export Clearance",
                    count: 22,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 29,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 26,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 45,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 99,
                  },
                  {
                    eventName: "Gate Out",
                    count: 24,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 20,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 6,
                  },
                ],
              },
              {
                country: "CHINA",
                Percentage: "248",
                events: [
                  {
                    eventName: "Export Clearance",
                    count: 19,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 27,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 24,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 41,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 91,
                  },
                  {
                    eventName: "Gate Out",
                    count: 21,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 19,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 6,
                  },
                ],
              },
            ],
            shipmentByEventType: [
              {
                country: "INDIA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },

              {
                country: "JAPAN",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Load",
                consigneeID: "consignee002",
                consigneeName: "consignee 002",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
            ],
          },
          totalShipments: 14,
        };
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  let url =
    "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/inTransitShipmentEventAnalytics";
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {},
  };
  useEffect(() => {
    let status = {
      status: props.pageInfo.tab,
    };
    sessionStorage.removeItem("consignee");
    sessionStorage.removeItem("country");
    etaDeviationAnalyticsEventsData(status);
  }, []);
  const etaDeviationAnalyticsEventsData = (status: any) => {
    config.params = status;
    totalResp = dummyResp.data.shipmentByEventType;
    setShipmentsCount(dummyResp.data);
    setCountryEvents(dummyResp.data.countryEvents);
    setCountryData(dummyResp.data.countryEvents);
    setShipmentsByOrigin([
      ...new Set(
        dummyResp.data.shipmentByEventType.map((item: any) => item.consigneeID)
      ),
    ]);

    axios.get(url, config).then((res: any) => {
      console.log(res.data, "resp");
      // setShipmentsCount(res.data)
      if (res.data.data.countryEvents.length > 0) {
        setIsDataShow(true);
      }
      // setCountryEvents(res.data.data.countryEvents)
      // setCountryData(res.data.data.countryEvents)
      console.log(countryEvents, "country events");
      // totalResp = res.data.data.shipmentByEventType
      totalResp = dummyResp.data.shipmentByEventType;
      // setShipmentsByOrigin([...new Set(res.data.data.shipmentByEventType.map((item: any) => item.consigneeID))])
      graphData(null, null);
    });
  };
  const graphData = (consignee1: any, country1: any) => {
    // IF country and consignee is null, populate all
    console.log(mapOrigin, mapDestination, "mapDestination");
    console.log(consignee1, country1, "after getting");
    console.log(consignee, country, "after getting2");
    let data = totalResp;
    let filteredArray: any = [];
    let legends: any = [];
    let values: any = [];
    if (
      sessionStorage.getItem("country") != null &&
      sessionStorage.getItem("consignee") === null
    ) {
      //   filteredArray = dummyResp.data.shipmentByEventType.filter(
      //     (item: any) => item.country === country
      //   );
      //   console.log(filteredArray, "filterarray");
      //   setMapDestination(country);
      //   legends = [
      //     ...new Set(filteredArray.map((item: any) => item.eventMapping)),
      //   ];
      //   console.log(legends, "eventss");
      //   setIsFirstDataRender(false);
      //   for (let i = 0; i < legends.length; i++) {
      //     let eventCount = filteredArray.filter(
      //       (item: any) => item.eventMapping === legends[i]
      //     ).length;
      //     values.push(eventCount);
      //   }
      //   console.log(values, "values");
      if (props.pageInfo.tab === "arrivingToday") {
        legends = ["At Wharehouse", "Out For Delivery", "Delivered"];
        if (country === "INDIA") {
          values = ["105", "146", "41"];
        } else if (country === "GERMANY") {
          values = ["76", "106", "31"];
        } else if (country === "JAPAN") {
          values = ["86", "120", "34"];
        } else if (country === "USA") {
          values = ["110", "153", "43"];
        } else {
          values = ["100", "139", "39"];
        }
      } else if (props.pageInfo.tab === "arrivingNextDay") {
        legends = [
          "Import Clearance",
          "Gate Out",
          "At Wharehouse",
          "Out For Delivery",
        ];
        if (country === "INDIA") {
          values = ["15", "28", "107", "64"];
        } else if (country === "GERMANY") {
          values = [11, 20, 78, 47];
        } else if (country === "JAPAN") {
          values = [11, 23, 88, 53];
        } else if (country === "USA") {
          values = [15, 29, 112, 68];
        } else {
          values = [14, 27, 102, 61];
        }
      } else {
        legends = [
          "Export Clearance",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (country === "INDIA") {
          values = [21, 28, 25, 43, 95, 22, 20, 6];
        } else if (country === "GERMANY") {
          values = [15, 21, 18, 32, 69, 16, 14, 4];
        } else if (country === "JAPAN") {
          values = [17, 23, 20, 36, 78, 18, 16, 5];
        } else if (country === "USA") {
          values = [22, 29, 26, 45, 99, 24, 20, 6];
        } else {
          values = [19, 27, 24, 41, 91, 21, 19, 6];
        }
      }

      setIsFirstDataRender(false);
    } else if (
      sessionStorage.getItem("consignee") !== null &&
      sessionStorage.getItem("country") === null
    ) {
      //   console.log("hiiiiiii", dummyResp.data.shipmentByEventType, data);
      //   filteredArray = dummyResp.data.shipmentByEventType.filter(
      //     (item: any) => item.consigneeID === consignee
      //   );
      //   console.log(filteredArray, "filterarray2");
      //   setMapOrigin(consignee);
      //   legends = [
      //     ...new Set(filteredArray.map((item: any) => item.eventMapping)),
      //   ];
      //   console.log(legends, "eventss");
      if (props.pageInfo.tab === "arrivingToday") {
        legends = ["At Wharehouse", "Out For Delivery", "Delivered"];
        if (consignee === "consignee001") {
          values = ["200", "400", "100"];
        } else {
          values = ["278", "264", "87"];
        }
      } else if (props.pageInfo.tab === "arrivingNextDay") {
        legends = [
          "Import Clearance",
          "Gate Out",
          "At Wharehouse",
          "Out For Delivery",
        ];
        if (consignee === "consignee001") {
          values = ["33", "100", "300", "100"];
        } else {
          values = ["33", "27", "187", "193"];
        }
      } else {
        legends = [
          "Export Clearance",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (consignee === "consignee001") {
          values = [50, 100, 50, 97, 232, 50, 80, 17];
        } else {
          values = [44, 28, 63, 100, 200, 51, 9, 10];
        }
      }

      //   for (let i = 0; i < legends.length; i++) {
      //     let eventCount = filteredArray.filter(
      //       (item: any) => item.eventMapping === legends[i]
      //     ).length;
      //     values.push(eventCount);
      //   }
      console.log(values, "values");
      setIsFirstDataRender(false);
    } else if (
      sessionStorage.getItem("country") === null &&
      sessionStorage.getItem("consignee") === null
    ) {
      filteredArray = data;
      setIsFirstDataRender(true);
      legends = [
        ...new Set(filteredArray.map((item: any) => item.eventMapping)),
      ];
      console.log(legends, "eventss");

      for (let i = 0; i < legends.length; i++) {
        let eventCount = filteredArray.filter(
          (item: any) => item.eventMapping === legends[i]
        ).length;
        values.push(eventCount);
      }
      console.log(values, "values");
    } else {
      if (props.pageInfo.tab === "arrivingToday") {
        legends = ["At Wharehouse", "Out For Delivery", "Delivered"];
        if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = ["70", "100", "20"];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = ["30", "50", "15"];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = ["40", "50", "10"];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = ["30", "53", "23"];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = ["50", "39", "10"];
        } else if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = ["35", "46", "21"];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = ["46", "56", "16"];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = ["46", "70", "24"];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = ["80", "100", "20"];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = ["50", "100", "29"];
        }
      } else if (props.pageInfo.tab === "arrivingNextDay") {
        legends = [
          "Import Clearance",
          "Gate Out",
          "At Wharehouse",
          "Out For Delivery",
        ];
        if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [10, 10, 100, 50];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [10, 10, 50, 30];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 13, 50, 25];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [5, 19, 100, 50];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [10, 17, 100, 50];
        } else if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [5, 18, 7, 14];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [1, 10, 28, 17];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [7, 10, 38, 28];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [10, 10, 12, 18];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [4, 10, 2, 11];
        }
      } else {
        legends = [
          "Export Clearance",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [10, 10, 10, 20, 55, 10, 10, 5];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [10, 11, 10, 20, 50, 10, 10, 2];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [10, 10, 10, 10, 50, 10, 10, 5];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [20, 19, 6, 5, 9, 4, 10, 5];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [10, 10, 10, 10, 10, 10, 5, 5];
        } else if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [11, 18, 15, 23, 40, 12, 10, 1];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [5, 10, 8, 12, 19, 6, 4, 2];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [7, 13, 10, 26, 28, 8, 6, 0];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [2, 10, 20, 40, 90, 20, 10, 1];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [9, 17, 14, 31, 81, 11, 14, 1];
        }
      }
      setIsFirstDataRender(false);
    }

    setMapLeggends(legends);
    setMapSerious(values);
  };
  const handleChanges = (event: SelectChangeEvent) => {
    setIsChartShow(false);
    consignee = event.target.value;
    sessionStorage.setItem("consignee", event.target.value);
    setOrigin(event.target.value);
    let status = {
      filterOnConsigneeId: event.target.value,
    };

    console.log(mapOrigin, event.target.value, mapDestination, "target");

    graphData(event.target.value, mapDestination ? mapDestination : null);
  };

  const handleChanges1 = (event: SelectChangeEvent) => {
    let obj = {
      status: props.pageInfo.tab,
      filterOnConsigneeId: event.target.value,
    };
    country = event.target.value;
    sessionStorage.setItem("country", event.target.value);
    setMapDestination(event.target.value);
    setChartPayLoad(obj);
    setIsChartShow(true);
    console.log(mapDestination, event.target.value, mapOrigin, "target");
    graphData(mapOrigin ? mapOrigin : null, event.target.value);
    console.log(chartPayLoad, mapOrigin, "chart1 payload");
  };
  const dataRefresh = () => {
    setMapDestination("");
    setMapOrigin("");
    setValue("");
    graphData(null, null);
  };
  const originalRows: any = [
    {
      country: "India",
      shipments: 170,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "China",
      shipments: 98,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Italy",
      shipments: 48,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Hamburger",
      shipments: 87,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Germany",
      shipments: 15,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
  ];
  const [columnDefs] = useState([
    {
      headerName: "HBL #",
      field: "hblNumber",
      filter: true,
      headerTooltip: "HBL #",
      tooltipField: "hblNumber",
    },
    {
      headerName: "SHIPPER NAME",
      field: "hblNumber",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "hblNumber",
    },
    {
      headerName: "COMMODITY TYPE",
      field: "commodityType",
      filter: true,
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
    },
    {
      headerName: "ITEM NAME",
      field: "itemName",
      filter: true,
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "DESCRIPTION",
      field: "itemDescription",
      filter: true,
      headerTooltip: "DESCRIPTION",
      tooltipField: "itemDescription",
    },
    {
      headerName: "QUANTITY",
      field: "hblNumber",
      filter: true,
      headerTooltip: "QUANTITY",
      tooltipField: "hblNumber",
    },
    {
      headerName: "ETA",
      field: "hblNumber",
      filter: true,
      headerTooltip: "ETA",
      tooltipField: "hblNumber",
    },
    {
      headerName: "CHANGE IN ETA",
      field: "hblNumber",
      filter: true,
      headerTooltip: "CHANGE IN ETA",
      tooltipField: "hblNumber",
    },
    {
      headerName: "DELAY IN DAYS",
      field: "hblNumber",
      filter: true,
      headerTooltip: "DELAY IN DAYS",
      tooltipField: "hblNumber",
    },
    {
      headerName: "DROP OF LOCATION",
      field: "dropoffCountry",
      filter: true,
      headerTooltip: "DROP OF LOCATION",
      tooltipField: "dropoffCountry",
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const filtersByCountry = (country: any) => {
    console.log(country, "country");
    setMapDestination(country);
    let filteredArray = dummyResp.data.countryEvents.filter(
      (item) => item.country === country
    );
    setCountryData(filteredArray);
    setfilterCountry(country);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const requestSearch = (searchedVal: string) => {
    console.log(searchedVal, "searched value");
    const filteredRows = countryEvents.filter((row: any) => {
      console.log(row.country, "row names");
      return row.country.toLowerCase().includes(searchedVal.toLowerCase());
    });

    setCountryEvents(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const handleCallback = (childData: any) => {
    console.log(childData, "childdata");
    setChildData(childData);
    setRowData(childData.carrier);

    props.parentCallback1(true);
    console.log(props, "page info");
    if (props.pageInfo.page === "shipmentDeviation") {
      let obj = {
        status: props.pageInfo.tab,
        filterOnCarrierId: childData.carrierName,
      };
      setReliablityInfo(obj);
    }
  };

  const filterByCountry = () => {};

  return (
    <div ref={myRef}>
      {!props.data ? (
        <Grid>
          <Box className="title-info">
            {/* {shipmentsCount != undefined ? <Typography>Total Shipments ({shipmentsCount.totalShipments})</Typography> : <Typography>Total Shipments (0)</Typography>} */}
            {props.pageInfo.tab === "arrivingToday" ? (
              <Typography>Total Shipments (1329)</Typography>
            ) : props.pageInfo.tab === "arrivingNextDay" ? (
              <Typography>Total Shipments (973)</Typography>
            ) : (
              <Typography>Total Shipments (1181)</Typography>
            )}
          </Box>
          <table className="common_table">
            {props.pageInfo.tab === "arrivingToday" ? (
              <tr className="common-subRows">
                {/* <th>Empty PickUp</th>
                                    <th>Gate In</th>
                                    <th>Gate Out</th>
                                    <th>Export Clearance</th>
                                    <th>Vessel Load</th>
                                    <th>Vessel Movement</th>
                                    <th>Vessel Discharge</th>
                                    <th>Import Clearance</th>
                                    <th>Gate Out</th> */}
                <th>At Warehouse</th>
                <th>Out For Delivery</th>
                <th>Delivered</th>
              </tr>
            ) : props.pageInfo.tab === "arrivingNextDay" ? (
              <tr className="common-subRows">
                <th>Import Clearance</th>
                <th>Gate Out</th>
                <th>At Warehouse</th>
                <th>Out For Delivery</th>
              </tr>
            ) : (
              <tr className="common-subRows">
                {/* <th>Empty PickUp</th>
                                <th>Gate In</th>
                                <th>Gate Out</th> */}
                <th>Export Clearance</th>
                <th>Vessel Load</th>
                <th>Vessel Movement</th>
                <th>Vessel Discharge</th>
                <th>Import Clearance</th>
                <th>Gate Out</th>
                <th>At Warehouse</th>
                <th>Out For Delivery</th>
              </tr>
            )}
            {props.pageInfo.tab === "arrivingToday" ? (
              shipmentsCount !== undefined ? (
                <tr className="common-subRows common-subData">
                  {/* <td>{shipmentsCount.data.summary.atWareHouse}</td>
                                    <td>{shipmentsCount.data.summary.outForDelivery}</td>
                                    <td>{shipmentsCount.data.summary.delivered}</td> */}

                  <td>{dummyResp.data.summary.atWareHouse}</td>
                  <td>{dummyResp.data.summary.outForDelivery}</td>
                  <td>{dummyResp.data.summary.delivered}</td>
                </tr>
              ) : (
                ""
              )
            ) : props.pageInfo.tab === "arrivingNextDay" ? (
              shipmentsCount !== undefined ? (
                <tr className="common-subRows common-subData">
                  {/* <td>{shipmentsCount.data.summary.importClearance}</td>
                                        <td>{shipmentsCount.data.summary.gateOut}</td>
                                        <td>{shipmentsCount.data.summary.atWareHouse}</td>
                                        <td>{shipmentsCount.data.summary.delivered}</td> */}
                  <td>{dummyResp.data.summary.importClearance}</td>
                  <td>{dummyResp.data.summary.gateOut}</td>
                  <td>{dummyResp.data.summary.atWareHouse}</td>
                  <td>{dummyResp.data.summary.delivered}</td>
                </tr>
              ) : (
                ""
              )
            ) : shipmentsCount !== undefined ? (
              <tr className="common-subRows common-subData">
                <td>{dummyResp.data.summary.exportclearance}</td>
                <td>{dummyResp.data.summary.vesselLoad}</td>
                <td>{dummyResp.data.summary.vesselMovement}</td>
                <td>{dummyResp.data.summary.vesselDischarge}</td>
                <td>{dummyResp.data.summary.importClearance}</td>
                <td>{dummyResp.data.summary.gateOut}</td>
                <td>{dummyResp.data.summary.atWareHouse}</td>
                <td>{dummyResp.data.summary.outForDelivery}</td>
                {/* <td>{shipmentsCount.data.summary.exportclearance}</td>
                                        <td>{shipmentsCount.data.summary.vesselLoad}</td>
                                        <td>{shipmentsCount.data.summary.vesselMovement}</td>
                                        <td>{shipmentsCount.data.summary.vesselDischarge}</td>
                                        <td>{shipmentsCount.data.summary.importClearance}</td>
                                        <td>{shipmentsCount.data.summary.gateOut}</td>
                                        <td>{shipmentsCount.data.summary.atWareHouse}</td>
                                        <td>{shipmentsCount.data.summary.outForDelivery}</td> */}
              </tr>
            ) : (
              ""
            )}
          </table>
        </Grid>
      ) : (
        ""
      )}
      <Grid container spacing={2} sx={{ mt: 0, display: "flex" }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontSize: "1rem" }}>
            {sessionStorage.getItem("role") != "consignee"
              ? "SHIPMENTS BY DESTINATION COUNTRY"
              : "SHIPMENTS BY ORIGIN COUNTRY"}
          </Typography>
          {isDataShow ? (
            <div>
              <Box sx={{ mt: 2 }}>
                <SearchBar
                  value={searched}
                  placeholder="Search By Country"
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
              </Box>

              <Paper sx={{ mt: 2 }}>
                <div>
                  {countryEvents.map((item: any, index: any) => {
                    return (
                      <Accordion
                        expanded={expanded === "panel" + index}
                        onChange={handleChange("panel" + index)}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography
                            className="tableCellData"
                            onClick={() => filtersByCountry(item.country)}
                          >
                            {item.country}
                          </Typography>
                          &nbsp;&nbsp;<span>({item.Percentage})</span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: 440, mt: 1 }}>
                            <Table aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell key={column.id}>
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              {item.events ? (
                                <TableBody>
                                  {item.events
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row: any) => {
                                      return (
                                        <TableRow
                                          hover
                                          role="checkbox"
                                          tabIndex={-1}
                                          key={row.code}
                                        >
                                          {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                              <TableCell
                                                key={column.id}
                                                style={{ padding: "10px" }}
                                              >
                                                {column.label === "Country" ? (
                                                  <a
                                                    className="tableCellData"
                                                    onClick={() =>
                                                      filterByCountry()
                                                    }
                                                  >
                                                    {value}
                                                  </a>
                                                ) : (
                                                  value
                                                )}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              ) : (
                                ""
                              )}
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </Paper>
            </div>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
              SHIPMENTS BY EVENTS TYPE
            </Typography>
            <IconButton sx={{ top: "-8px" }}>
              <CachedIcon onClick={dataRefresh} />
            </IconButton>
          </Box>
          <Grid container spacing={2} sx={{ display: "flex" }}>
            <Grid item xs={6}>
              {/* <FormControl fullWidth size="small">
                <InputLabel htmlFor="grouped-native-select">
                  Consignee
                </InputLabel>
                <Select
                  native
                  defaultValue={value}
                  id="grouped-native-select"
                  label="Consignee"
                  MenuProps={{ disableScrollLock: true }}
                  onChange={handleChanges}
                >
                  <option aria-label="None" value="0">
                    Select
                  </option>
                  {shipmentsByOrigin.map((role: any) => {
                    return <option value={role}>{role}</option>;
                  })}
                </Select>
              </FormControl> */}
              <FormControl fullWidth size="small">
                <Typography>Consignee</Typography>
                {/* <InputLabel htmlFor="grouped-native-select">
                  Destination
                </InputLabel> */}
                {/* <Select
                  native
                  defaultValue={filterCountry}
                  id="grouped-native-select"
                  label="Grouping"
                  MenuProps={{ disableScrollLock: true }}
                  placeholder="Select Destination"
                  onChange={handleChanges1}
                >
                  <option aria-label="None" value="0">
                    Select
                  </option>
                  {countryData.map((item: any) => {
                    return <option value={item.country}>{item.country}</option>;
                  })}
                </Select> */}
                <select
                  className="custom_select"
                  placeholder="Select Consignee"
                  onChange={(e: any) => handleChanges(e)}
                >
                  <option value="">Select Consignee</option>
                  {shipmentsByOrigin.map((role: any) => {
                    return <option value={role}>{role}</option>;
                  })}
                </select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <Typography>Destination</Typography>
                {/* <InputLabel htmlFor="grouped-native-select">
                  Destination
                </InputLabel> */}
                {/* <Select
                  native
                  defaultValue={filterCountry}
                  id="grouped-native-select"
                  label="Grouping"
                  MenuProps={{ disableScrollLock: true }}
                  placeholder="Select Destination"
                  onChange={handleChanges1}
                >
                  <option aria-label="None" value="0">
                    Select
                  </option>
                  {countryData.map((item: any) => {
                    return <option value={item.country}>{item.country}</option>;
                  })}
                </Select> */}
                <select
                  className="custom_select"
                  placeholder="Select Destination"
                  onChange={(e: any) => handleChanges1(e)}
                >
                  <option value="">Select Destination</option>
                  {countryData.map((item: any) => {
                    return <option value={item.country}>{item.country}</option>;
                  })}
                </select>
              </FormControl>
            </Grid>
          </Grid>
          {mapSerious.length > 0 && mapleggends.length > 0 ? (
            <Paper sx={{ mt: 1 }}>
              <DeviationCharts
                parentCallback={handleCallback}
                data={chartPayLoad}
                mapInfo={{ xAxisData: mapleggends, yAxisData: mapSerious }}
                page={props.pageInfo.page}
                tab={props.pageInfo.tab}
                dataRender={isFirstDataReder}
              />
            </Paper>
          ) : (
            <Alert severity="info" sx={{ mt: 2 }}>
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Grid>
      </Grid>
      {props.pageInfo.page === "shipmentDeviation" ? (
        <Box sx={{ mt: 3 }}>
          {reliablityInfo ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontSize: "1rem",
              }}
            >
              RELIABILITY TRENDS
            </Typography>
          ) : (
            ""
          )}
        </Box>
      ) : (
        " "
      )}
      {props.pageInfo.page === "shipmentDeviation" ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            {reliablityInfo ? (
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                }}
              >
                OVER ALL
              </Typography>
            ) : (
              ""
            )}
            {reliablityInfo ? (
              <DonutEcharts
                data={"overall"}
                parentCallback={handleCallback}
                data1={reliablityInfo}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
            {reliablityInfo ? (
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                }}
              >
                PICK UP
              </Typography>
            ) : (
              ""
            )}
            {reliablityInfo ? (
              <DonutEcharts
                data={"pickUp"}
                parentCallback={handleCallback}
                data1={reliablityInfo}
              />
            ) : (
              ""
            )}
          </Grid>
          {/* <Grid item xs={4}>
                        <Typography variant='h6' sx={{
                            textAlign: 'center',
                        }}>
                            DROP OFF
                        </Typography>
                        <DonutEcharts data={'dropOff'} parentCallback={handleCallback} />
                    </Grid> */}
        </Grid>
      ) : (
        ""
      )}

      <Grid sx={{ mt: 3 }} id="moreInfo">
        {childData ? (
          <Box>
            <Box>
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                SHIPMENTS
              </Typography>
            </Box>
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              rowSelection={"multiple"}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={"autoHeight"}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
            ></AgGridReact>
          </Box>
        ) : (
          ""
        )}
      </Grid>

      {/* <a href="#" id="roll_back" className="animate">
                <KeyboardArrowUpIcon />
            </a> */}
    </div>
  );
}
