import {
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import colorConfigs from "../../Config/colorConfig";
import sizeConfigs from "../../Config/sizeConfigs";
import NotificationsIcon from "@material-ui/icons/Notifications";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AccountCircle } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SearatesInfo from "../pages/SeaRates/SearatesInfo";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
const Topbar = (props: any) => {
  const location = useLocation();
  // console.log(props, "props");
  const [headName, setHeadName] = useState(
    props.head != "" ? props.head : "DashBoard"
  );
  const [currentTime, setCurrentTime] = useState("");
  useEffect(() => {
    // console.log("HEADER NAME", props.head);
    setHeadName(props.head);
    setInterval(currentDateTime, 1000);
  }, [props]);
  const userRole = sessionStorage.getItem("role")?.toUpperCase();
  const navigate = useNavigate();
  const [open, SetOpen] = useState(false);
  const [anchorEl, SetAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, SetIsOpenMenu] = useState(false);
  const [filterOpen, SetFilterOpen] = useState(false);
  const [ratesOpen, setseaRatesOpen] = useState(false);
  const currentDateTime = () => {
    setCurrentTime(moment().format("MMMM D YYYY H:mm:ss"));
  };
  const handleMenuClose = () => {
    SetAnchorEl(null);
    SetIsOpenMenu(false);
  };
  const handleClose = () => {
    SetOpen(false);
  };
  const handleFilters = () => SetFilterOpen(!filterOpen);

  const handleProfileMenuOpen = (event: any) => {
    SetAnchorEl(event.currentTarget);
    SetIsOpenMenu(true);
  };
  const handleLogOut = () => {
    SetAnchorEl(null);
    SetIsOpenMenu(false);
    sessionStorage.clear();
    navigate("/login");
  };
  const checkSearates = () => {
    navigate('/sea_rates')
    // setseaRatesOpen(true)

  }
  return (
    <AppBar
      position="absolute"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "0px 0px 5px gray",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '1rem' }}>
          REAL TRACE <span style={{ color: "#0b51b7" }}>(Ocean)</span>
        </Typography>
        {/* <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                    sx={{
                        marginTop: "10px",
                        marginRight: " 15px",
                        color: "#271a78",
                        boxShadow: "none",
                    }}
                >
                    <NotificationsIcon />
                </IconButton> */}
        <Typography>{currentTime}</Typography>

        <Typography
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >

Freight Quote <IconButton sx={{ mr: 1 }}><CalendarMonthIcon sx={{ cursor: "pointer" }} onClick={() => checkSearates()} /></IconButton>
          <strong
            style={{
              letterSpacing: "0.6px",
              fontWeight: "400",
              textTransform: "uppercase",
              marginRight: "10px",
              fontSize: '0.85rem'
            }}
          >
            Logged As :
          </strong>{" "}
          <Button
            variant="contained"
            endIcon={<KeyboardArrowDownOutlinedIcon />}
            sx={{ color: "white" }}
            onClick={handleProfileMenuOpen}
            
          >
            {userRole}
          </Button>
          <Menu
            elevation={0}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            disableScrollLock = {true}
            className="userMenu"
            
            sx={{}}
          >
            <MenuItem className="account_style" onClick={handleMenuClose}>
              <AccountCircle />
              <Typography sx={{ marginLeft: 2 }}>MyAccount</Typography>{" "}
            </MenuItem>
            <MenuItem className="account_style" onClick={handleLogOut}>
              <LogoutIcon />
              <Typography sx={{ marginLeft: 2 }}>Logout </Typography>{" "}
            </MenuItem>
          </Menu>
        </Typography>

        <Menu
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          className="userMenu"
          sx={{
            marginTop: "3px",
            marginRight: "5px",
          }}
        >
          <MenuItem className="account_style" onClick={handleMenuClose}>
            <AccountCircle />
            <Typography sx={{ marginLeft: 2 }}>MyAccount</Typography>{" "}
          </MenuItem>
          <MenuItem className="account_style" onClick={handleLogOut}>
            <LogoutIcon />
            <Typography sx={{ marginLeft: 2 }}>Logout </Typography>{" "}
          </MenuItem>
        </Menu>
        {/* <Button
            sx={{ marginRight: "10px" }}
            variant="contained"
            color="success"
            onClick={() => checkSearates()}
          >
            Check Searates
          </Button> */}

      </Toolbar>
      {/* <div className="dialog">
        {ratesOpen ? (
          <SearatesInfo
            onOpen={ratesOpen}
            onClose={() => setseaRatesOpen(false)}
            data={ratesOpen}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div> */}

    </AppBar>
  );
};

export default Topbar;
