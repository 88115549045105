import { Environment_Realtraceb_Dev} from "./Environment_Realtraceb_Dev";

export const Endpoints = {
  CLIENT_ID: Environment_Realtraceb_Dev.CLIENTID,
  URL : Environment_Realtraceb_Dev.URL,
  REFRESH_TOKEN: Environment_Realtraceb_Dev.GET_REFRESH_TOKEN,
  DEFAULTIMAGE: Environment_Realtraceb_Dev.DEFAULTIMAGE,
  LOGOUT: Environment_Realtraceb_Dev.GET_LOGOUT,
  KEYCLOAK_LOGOUT: Environment_Realtraceb_Dev.keycloak_logout,
  GET_LOGOUT_ALL: Environment_Realtraceb_Dev.GET_LOGOUT_ALL,
  GENERATE_OTP: Environment_Realtraceb_Dev.BASE_ROLE + '/mobileLogin/generateOTP',
  VERIFY_OTP: Environment_Realtraceb_Dev.BASE_ROLE + '/mobileLogin/validateOTP',
  RESET_PASSWORD: Environment_Realtraceb_Dev.BASE_ROLE + '/mobileLogin/resetPassword',

  BEAT_LIST: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_BEAT_LIST_BYEMPID',
  GET_BASE_URL_IMAGE: Environment_Realtraceb_Dev.GET_BASE_URL_IMAGE,
  GET_USER_INFO: Environment_Realtraceb_Dev.BASE_URL + '/Rr_get_logged_info',
  EXPENSE_USER_INFO: Environment_Realtraceb_Dev.BASE_URL + '/RR_LOGGED_USER_INFO',
  EXPENSES_CLAIM_STATUS: Environment_Realtraceb_Dev.BASE_URL + '/RW_EXPENSE_CLAIM_LIST',
  EXPENSES_CATEGORY: Environment_Realtraceb_Dev.BASE_URL + '/RW_EXPENSE_CATEGORY_LIST',
  SAVE_EXPENSES: '',
  EXPENSE_INVOICE: Environment_Realtraceb_Dev.BASE_URL_IMAGE + '/RW_EXPENSE_CLAIM_FILE_UPLOAD',
  EXPENSE_SAVE_POST: Environment_Realtraceb_Dev.BASE_URL + '/RW_EXPENSE_CLAIM_CREATE',

  DEALER_TAB_LIST: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_BEAT_LIST_BYEMPID',

  DEALER_TAB_LIST_ITEMS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DEALERLIST_BYBEAT',

  DEALER_TAB_LIST_ITEMS_NEW:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PODELALERLIST_BYEMP',

  DEALER_CATEGORY_LIST: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_ITEMCATEGORY_LIST',

  DEALER_CATEGORY_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_ITEMDETAILS_BYCATEGORY',
  DEALER_ORDER_HISTORY: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELORDER_HISTORY',
  DEALER_DELIVERY_HISTORY: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELIVERED_HISTORY',
  COLLECTIONS_DELIVER_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_COLLECTIONS_HISTORY',

  CREATE_CASH_PAYMENT_V5: Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_CASH_PAYMENT_V5',
  CREATE_PAYMENT_CHEQUE_V5:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_PAYMENT_CHEQUE_V5',
  // CREATE_PAYMENT_V5: Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_PAYMENT_V5',

  CREATE_PAYMENT_V5: Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_DIGITAL_PAYMENT',
  INVOICES: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PENDINGCOLLECIONS_LIST',
  SAVE_INOVICE_AMOUNT_BY_ID:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_PAYMENT_SAVED_CONDITIONAL',
  GET_ACTIVE_SAVED_PAYMENT_MOBILE_DEL:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_ACTIVE_SAVED_PAYMENT_MOBILE',

  GET_ACTIVE_SAVED_PAYMENT_MOBILE_RET:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_ACTIVE_SAVED_PAYMENT_MOBILE',

  RETURN_TYPE_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_ALL_RETURN_TYPE_LIST',
  IDENTIFICATION_TYPE_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_IDENTIFICATION_TYPE_LIST',

  SAVE_RETURN_WITHOUT_INVOICE:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_SAVE_RETURN_WITHOUT_INVOICE',
  RETURNS_WITHOUT_INVOICE_CONFIRM:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_RETURNS_WITHOUT_INVOICE_CONFIRM',
  RETURNS_WITHOUT_INV_DRAFTS_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_RETURNS_WITHOUT_INV_DRAFTS_LIST',

  PERFOMENCE_PRIMARYORDERS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PERFOMENCE_PRIMARYORDERS',
  PERFOMENCE_PRIMARYSALES: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PRIMARYSALES',
  PERFOMENCE_OVERDUE: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_OVERDUE',

  PERFOMENCE_RECEIVABLES: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RECEIVABLES',
  PERFOMENCE_COLLECTIONS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_COLLECTIONS',

  SAVE_TO_CART_DEALER: Environment_Realtraceb_Dev.BASE_URL + '/RR_ADD_NEW_LINE_TO_PO_V4',
  GET_CART_DEALER: Environment_Realtraceb_Dev.BASE_URL + '/RR_CURRENT_PO_V4',
  GET_CART_ITEMS_MOBILE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CURRENT_PO_V4_BASED_ON_ITEM_AND_UOM',
  APPLY_SCHEME: Environment_Realtraceb_Dev.BASE_ROLE + '/schemes/apply_scheme',
  ADD_SCHEMES: Environment_Realtraceb_Dev.BASE_ROLE + '/schemes/add_scheme_free_items',
  CART_SUBMIT: Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_PO_V4',

  DEALER_SIDE_ORDER_TO:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PURCHASE_ORDERS_TO_LIST',
  RETAILER_SIDE_ORDER_TO: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETORDERTO_BYRETID',

  COLLECTIONS_HISTORY: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_COLLECTIONS_HISTORY',
  DEALERLIST_BYEMPLOYEE:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DEALERLIST_BYEMPLOYEE',

  RR_CREATE_BRANCH_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_BRANCH_LIST',
  BRANCH_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_BRANCH_LIST_FOR_DEALER_SALES_EXE',

  SALESAPP_DELIVERED_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELIVERED_HISTORY',

  CLASSIFICATION_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_RETAILER_CATEGORY_LIST',
  REGION_LIST: Environment_Realtraceb_Dev.BASE_URL + '/GET_ALL_REGIONS',
  STATE_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_STATE_MASTER_LIST',
  STATE_LIST_BY_REGION:
    Environment_Realtraceb_Dev.BASE_URL + '/GET_STATE_DETAILS_BY_REGION_ID',
  CITIES_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_DISTRICT_BY_STATE_MASTER_LIST',
  AREAS_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_AREA_BY_DISTRICT_MASTER_LIST',
  LINK_TO_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_LOGGED_PARTY_INFO',
  CUSTOMER_DEALER: Environment_Realtraceb_Dev.BASE_URL + '/RR_DEALER_ORGANISATION_CREATION',
  CUSTOMER_RETAILOR:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_RETAILER_ORGANISATION_CREATION',
  EDIT_RETAILER: Environment_Realtraceb_Dev.BASE_URL + '/RR_EDIT_RETAILER',
  RETAILER_BEAT_MANAGEMENT_LIST:
    Environment_Realtraceb_Dev.BASE_URL + 'RR_BEAT_DROP_DOWN_LIST_FOR_DLR',

  RETAILER_TAB_LIST_ITEMS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETLIST_BYBEAT',

  RETAILER_TAB_LIST_ITEMS_NEW:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PORETAILERLIST_BYEMP',
  RETAILER_RETURN_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_SAVE_RETURN_WITHOUT_INVOICE',
  // RETAILER_PREVIOUS_CART_ITEMS:
  //   Environment_Realtraceb_Dev.BASE_URL + '/RR_RETURNS_WITHOUT_INV_DRAFTS_LIST',

  RETAILER_PREVIOUS_CART_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_RETURNS_WITHOUT_INV_DRAFTS_LIST_V2',
  RETAILER_SUBMIT_CART:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_RETURNS_WITHOUT_INVOICE_CONFIRM',
  RETAILER_ORDER_HISTORY: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETORDER_HISTORY',
  RETAILER_DELIVERY_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELIVERED_HISTORY',
  RETAILER_FILTER: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETLIST_BYRETCATEGORY',
  RETAILER_SAVE_CART_ITEMS: Environment_Realtraceb_Dev.BASE_URL + '/RR_ADD_NEW_LINE_TO_PO_V4',
  RETAILER_PROMOTIONS_BY_ITEM:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_RET_PROMOTION_VIEW_BY_ITEM',

  RETAILER_SAVE_ALL_CART_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MASTER_ADD_NEW_HEADER_LINE_TO_PO_V4',
  RETAILER_SAVE_CART_STOCK_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_RETAILER_STOCK',

  RETAILER_STOCK_SAVE_ALL_CART_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_RETURN_STOCK_HEADER_MAIN',
  SUBDISTRIBUTOR_STOCK_SAVE_ALL_CART_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_SUB_DIST_STOCK_HEADER_MAIN',

  DELETE_CART_ITEMS: Environment_Realtraceb_Dev.BASE_URL + '/RR_REMOVE_PO_LINE_V4',
  UPDATE_CART_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_EDIT_CURRENT_PURCHASE_ORDER_LINES_V4',
  CREATE_PURCHASE_ORDER: Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_PO_V4',
  DEALER_ORDER_TO: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PURCHASE_ORDERS_TO_LIST',
  DISTRIBUTOR_SS_ORDER_TO: Environment_Realtraceb_Dev.BASE_URL + '/RR_ORDER_TO_LIST',
  SUBDISTRIBUTOR_RET_DISTRIBUTOR_ORDER_TO:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_ORDER_TO_LIST_FOR_RET',
  RET_TO_SUBDISTRIBUTOR_ORDER_TO:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_ORDER_TO_LIST_FOR_SUB_RET',

  CONST_RETAILER: 'RETAILER',
  CONST_RETAILER_NAME: 'Outlet',
  CONST_DEALER: 'DEALER',
  CONST_DEALER_NAME: 'Dealer',

  SALESAPP_DELORDER_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELORDER_HISTORY',

  DEALER_INTRANSIT: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DEALERLIST_BYEMPLOYEE',
  RETAILER_INTRANSIT: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETLIST_BYEMPLOYEE',
  API_INSTRANSIT_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DEALERINTRANSIT_HISTORY',

  RVS_TOTAL_RECEIVABLES: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TOTRECEIVABLES',
  RVS_TOTAL_OVERDUES:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RECEIVABLES_TOTOVERDUES',
  RVS_ZERO_DAYS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RECEIVABLES_TOTGTECURRENTDATE',
  RVS_FIFTEEN_DAYS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_OVERDUELT15DAYS',
  RVS_THRITY_DAYS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_OVERDUE16_30DAYS',
  RVS_SIXTY_DAYS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_OVERDUEGRATER30DAYS',
  RVS_DEALERS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_CUSTDET_RECEIVABLESWIDGET',
  RVS_DEALER_DETAILS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RECEIVABLES_INVDETAILS',

  SALESAPP_RECEIVABLES_HISTORY_DETAILS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RECEIVABLES_HISTORY_DETAILS',
  SALESAPP_DATE2_AVGOVERDUEDAYS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DATE2_AVGOVERDUEDAYS',

  SALESAP_DATEDIFFAVG: Environment_Realtraceb_Dev.BASE_URL + '/SALESAP_DATEDIFFAVG',

  RETAILER_TYPE_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_RETAILER_TYPE_LIST',
  RET_CUSTOMER_TYPE_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_RETAILER_CUSTOMER_TYPES',

  DEALER_CUSTOMER_TYPE_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_DEALER_CUSTOMER_TYPE_LIST',

  
  //recent added

  SALESAPP_DISPLAYIMAGES: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DISPLAYIMAGES',

  SALESAPP_DISPLAYIMAGES_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DISPLAYIMAGES_HISTORY',

  SALESAPP_OUTLETCOVERAGE: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_OUTLETCOVERAGE',
  SALESAPP_OUTLETCOVERAGE_PRECHECK:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_OUTLETCOVERAGE_PRECHECK',
  SALESAPP_DELIVERABLESCALLS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELIVERABLESCALLS',

  DASHBOARD_TOTALCALLS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TOTCALLS_COUNT',
  DASHBOARD_PENDINGCALLS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PENDINGCALLS_COUNT',
  DASHBOARD_PRODUCTIVECALLS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TOTPROCALLS_COUNT',

  // RETURN_HISTORY: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETURN_HISTORY',
  RETURN_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETURN_HISTORY_WITH_ACTIVE_TEMS',
  DISPLAYIMAGES_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DISPLAYIMAGES_HISTORY',

  TRAGETVALUE_BYEMP: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TRAGETVALUE_BYEMP',
  TARGET_TOTSALES: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TARGET_TOTSALES',

  TARGET_INVDETAILS: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TARGET_INVDETAILS',

  TARGET_CATLIST_BYCUSTOMER:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TARGET_CATLIST_BYCUSTOMER',

  TARGET_ITEMDETBYCATE: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TARGET_ITEMDETBYCATE',

  TARGET_ALLCATLIST: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TARGET_ALLCATLIST',
  TARGET_TOTITEMDETAILS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_TARGET_TOTITEMDETAILS',

  SALESAPP_DELCOUNT_BYEMP: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELCOUNT_BYEMP',
  SALESAPP_RETCOUNT_BYEMP: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETCOUNT_BYEMP',

  SALESAPP_PROFILE_BYEMP: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PROFILE_BYEMP',
  EXPENSE_SUMMARY: Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_EXPENSE_SUMMARY',
  DB_INTRANSIT_GITINGINVDETAILS:
    Environment_Realtraceb_Dev.BASE_URL + '/DB_INTRANSIT_GITINGINVDETAILS',

  GET_IMAGE: Environment_Realtraceb_Dev.GET_BASE_URL_IMAGE,
  // GET_IMAGE: GET_BASE_URL_IMAGE,
  RR_GET_PRODUCT_BY_PRODUCT_ID:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_PRODUCT_BY_PRODUCT_ID',

  ATTENDANCE_VALIDATION:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_REQUEST_TYPE_VALIDATION_IN_EMPLOYEE',

  ATTENDANCE_CREATE: Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATTENDANCE_CREATE',
  ATTENDANCE_GET: Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATTENDANCE_LIST',
  LEAVE_APPLY: Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATTENDANCE_LEAVE_APPLY',
  LEAVE_GET: Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATTENDANCE_LEAVE_LIST',
  GEO_CODING_URL: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
  GEO_CODING_KEY: 'AIzaSyAClLwV3uheCZPVa2kcVx5GqSnwPeX6wyY',
  SALESAPP_PODELALERLIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PODELALERLIST_BYEMP',
  RR_RETAILER_EDIT_POPULATE_BY_ID:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_RETAILER_EDIT_POPULATE_BY_ID',
  SALESAPP_DISPLAYIMGRETLIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DISPLAYIMGRETLIST_BYEMP',
  ///new reports
  SALESAPP_INTRANSITRET_LIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_INTRANSITRET_LIST_BYEMP',

  SALESAPP_DLRINTRANSIT_LIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DLRINTRANSIT_LIST_BYEMP',

  SALESAPP_DELIVEREDRET_LIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELIVEREDRET_LIST_BYEMP',

  SALESAPP_DLRDELIVERED_LIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DLRDELIVERED_LIST_BYEMP',

  SALESAPP_DLRCOLLECTIONS_LIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DLRCOLLECTIONS_LIST_BYEMP',
  SALESAPP_RETCOLLECTIONS_LIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETCOLLECTIONS_LIST_BYEMP',

  SALESAPP_DLRRETURNS_LIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DLRRETURNS_LIST_BYEMP',

  SALESAPP_RETRETURNS_LIST_BYEMP:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_RETRETURNS_LIST_BYEMP',

  RR_GET_COMMERCIAL_INVOICE_BY_INVOICE_NUMBER:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_COMMERCIAL_INVOICE_BY_INVOICE_NUMBER',

  //stock

  CREATE_RETAILER_STOCK: Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_RETAILER_STOCK',
  GET_INV_VIEW_RETAILER_CART_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_INV_VIEW_RETAILER_CART_LIST',
  GET_INV_VIEW_SUBDIST_CART_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_INV_VIEW_SUB_DIST_CART_LIST',
  UPDATE_QTY_INV_VIEW_RETAILER:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_UPDATE_QTY_INV_VIEW_RETAILER',
  GET_INV_VIEW_SUB_DIST_ITEM_LIST_BY_DATE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SUB_DIST_STOCK_LIST_BY_CUST_ID_AND_DATE',

  UPDATE_QTY_INV_VIEW_SUBDIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_UPDATE_SUB_DIST_STOCK_ITEM',
  DELETE_INV_VIEW_RETAILER:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_DELETE_INV_VIEW_RETAILER',
  DELETE_INV_VIEW_SUBDIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_DELETE_INV_VIEW_SUB_DIST_ITEM',
  CONFIRM_RETAILER_STOCK: Environment_Realtraceb_Dev.BASE_URL + '/RR_CONFIRM_RETAILER_STOCK',
  CONFIRM_SUBDISTRIBUTOR_STOCK:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CONFIRM_SUB_DIST_STOCK',
  RR_RET_STOCK_LIST_BY_CUST_ID_AND_DATE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_RET_STOCK_LIST_BY_CUST_ID_AND_DATE',
  RR_SBD_STOCK_LIST_BY_CUST_ID_AND_DATE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SUB_DIST_STOCK_LIST_BY_CUST_ID_AND_DATE',

  RR_INV_VIEW_RETAILER_ITEM_LIST_BY_DATE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_INV_VIEW_RETAILER_ITEM_LIST_BY_DATE',
  RR_INV_VIEW_SUBDIST_ITEM_LIST_BY_DATE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_INV_VIEW_SUB_DIST_ITEM_LIST_BY_DATE',
  RR_INV_VIEW_RETAILER_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_INV_VIEW_RETAILER_LIST',
  RR_INV_VIEW_SUBDIST_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_INV_VIEW_SUB_DIST_LIST',

  //PROMOTIONS

  RR_PROMOTION_ITEM_LIST_MOBILE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_PROMOTION_ITEM_LIST_MOBILE',
  RR_UPDATE_PROMO_LINE_LEVEL_STATUS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_UPDATE_PROMO_LINE_LEVEL_STATUS',

  RR_OUTLET_PROMOTIONS_MOBILE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_OUTLET_PROMOTIONS_MOBILE',
  RR_PROMOTION_ACTIVE_ITEM_LIST_MOBILE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_PROMOTION_ACTIVE_ITEM_LIST_MOBILE',

  SALESAPP_DEBITNOTE_LISTBYCUSTOMER:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DEBITNOTE_LISTBYCUSTOMER',
  RR_DEBIT_NOTE_AMOUNT_POPULATE_MOBILE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_DEBIT_NOTE_AMOUNT_POPULATE_MOBILE',

  RW_SALES_ATTENDANCE_LEAVE_TYPES_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATTENDANCE_LEAVE_TYPES_LIST',

  RW_SALES_ATTENDANCE_CREATE_LEAVE_REQUEST:
    Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATTENDANCE_CREATE_LEAVE_REQUEST',

  RW_SALES_ATT_LEAVE_REQ_HISTORY_BY_EMP:
    Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATT_LEAVE_REQ_HISTORY_BY_EMP',

  RW_SALES_ATTENDANCE_ORG_HOLIDAY_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATTENDANCE_ORG_HOLIDAY_LIST',

  SALESAPP_ORDERBOOK_VALUE: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_ORDERBOOK_VALUE',

  SALESAPP_COLELCTION_VALUE:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_COLELCTION_VALUE',

  RR_REVERT_PAYMENT_AMOUNTS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_REVERT_PAYMENT_AMOUNTS',

  RR_REMOVE_PAYMENT_SAVE_V5:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_REMOVE_PAYMENT_SAVE_V5',

  GET_ORG_BY_DLR_CODE_RET: Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_ORG_BY_DLR_CODE_RET',

  RET_CATEGORY_ITEMS: Environment_Realtraceb_Dev.BASE_URL + '/RR_ITEM_LIST_WITH_AVAILABLE_QTY',

  RET_CATEGORY_LIST: Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_ITEMCATEGORY_LIST_V2',

  RET_SHECMES_DISCOUNTS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SCHEMES_BY_RETAILER_AND_ITEM',

  DEL_SHECMES_DISCOUNTS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SCHEMES_BY_DEALER_AND_ITEM',

  DELETE_RETURN_CARTITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_REMOVE_RETURN_LINE_V4',

  EDIT_RETURN_CARTITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SALESAPP_RETURN_QTY_AND_UOM_UPDATE_RET',

  SAVE_RETURN_CARTITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_SAVE_RETURN_WITHOUT_INVOICE_RET',

  RET_AVAILABLE_CREDIT_LIMIT:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CUSTOMER_AVAIL_CREDIT_LIMIT',
  ///
  //Manager APP APIs
  GET_MANAGER_PROFILE: Environment_Realtraceb_Dev.BASE_URL + '/RR_LOGGED_USER_PARTY_INFO',
  MANAGER_DASHBOARD_API:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MANAGER_APP_DASHBOARD_COUNT',
  MANAGE_EMPLOYEE_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MANAGER_APP_UNDER_EMPLOYEE_LIST',
  MANAGER_APP_EMPLOYEE_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_LOGGED_EMP_BASED_ON_DESIGNATION_EMP_LIST',

  PENDING_REQUESTS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MANAGER_APP_PENDING_REQUEST_LIST',

  PUNCH_HISTORY: Environment_Realtraceb_Dev.BASE_URL + '/RR_MANAGER_APP_PUNCH_HISTORY_LIST',

  REQUEST_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MANAGER_APP_REQUEST_HISTORY_FILTER_LIST',

  APPROVE_PENDING_REQUEST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MANAGER_APP_ATTENDANCE_APROVAL_UPDATE',
  REJECT_PENDING_REQUEST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MANAGER_APP_ATTENDANCE_APROVAL_UPDATE',

  EMPLOYEE_REQUEST_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MANAGER_APP_HISTORY_LIST',

  ATTENDANCE_LEAVE_TYPES:
    Environment_Realtraceb_Dev.BASE_URL + '/RW_SALES_ATTENDANCE_LEAVE_TYPES_LIST',

  RR_COLL_PAYMENT_ID_AUTO_GEN_EXT:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_COLL_PAYMENT_ID_AUTO_GEN_EXT',

  VERIFY_LOGIN_BY_ROLE:
    Environment_Realtraceb_Dev.BASE_ROLE + '/mobileLogin/verifyLoginByRole',

  BEAT_DEVIATION_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_DEVIATION_LIST',
  BEAT_DEVIATION_LIST_FOR_DEALER_EXEC:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_DEVIATION_LIST_BY_DEALER',
  BEAT_DEVIATION_NOTIFICATION:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SEND_NOTIFICATION_TO_REPO_HIERARCHY',
  BEAT_DEVIATION_DEALER_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_DEVIATION_DEALER_LIST_BY_BEAT',
  BEAT_DEVIATION_RET_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_DEVIATION_RET_LIST_BY_BEAT',
  BEAT_DEVIATION_RET_LIST_FOR_DEALER_EXEC:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_DEVIATION_RETAILER_LIST_BY_BEAT_V2',

  CUSTOMERS_LIST_DISTRIBUTORS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_BRANCHES_FOR_MANAGERS',
  CUSTOMERS_LIST_SUBD_RET: Environment_Realtraceb_Dev.BASE_URL + '/RR_SUB_DIST_FOR_MANAGERS',

  LINK_TO_CODES_BY_RETAILER:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CODES_BY_RETAILER_TYPE',
  GET_BRANCH_CODES_BY_ASSOC_AND_BEAT:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_LINKED_DISTR_BRANCH_OR_SUB_DISTRIBUTOR',
  RR_RETAILER_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_RETAILER_LIST',
  CREATE_SUB_DISTRIBUTOR: Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_SUB_DISTRIBUTOR',
  SUB_DISTRIBUTOR_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_SUB_DISTRIBUTOR_LIST',
  AREA_OFFICE_LIST_BY_BEAT:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_AREA_OFFICE_CODE_BY_BEAT_NAME',
  BEAT_MANAGEMENT_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_MANAGEMENT_LIST',
  BEAT_LIST_BY_DISTRICT: Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_LIST_FOR_CUSTOMERS',
  BEAT_LIST_FOR_SUBDISTRIBUTOR:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_LIST_FOR_SUB_DIST',
  BEAT_SERVICED_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_BEAT_SERVICED_LIST_BY_DISTRICT',
  BEAT_SERVICED_LIST_FOR_SUBDISTRIBUTOR:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_MAIN_BEAT_SERVICED_LIST_FOR_SUB_DIST',
  SUB_DISTRIBUTOR_EDIT: Environment_Realtraceb_Dev.BASE_URL + '/RR_SUB_DISTRIBUTOR_EDIT',
  SUB_DISTRIBUTOR_BRANCH_CODE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_SUPER_STOCKIST_BRANCH_DETAILS_SUB_DIS',
  CREATE_DEALER_BRANCHES: Environment_Realtraceb_Dev.BASE_URL + '/tmtCreateDealerWithBranches',
  GET_DEALERS_DATA:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_DEALER_DEATILS_WITH_BRANCHES',
  EDIT_DEALERS_DATA: Environment_Realtraceb_Dev.BASE_URL + '/RR_DEALER_EDIT_WITH_BRANCHES',

  BEAT_LIST_BY_EMP: Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_ALL_CURRENT_BEATS_BY_EMP',
  BEAT_LIST_BY_MANAGER: Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_ALL_BEATS_FOR_MANAGERS',
  GET_STORES_BY_BEAT_AND_CUSTOMER:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_VISIT_BY_TYPE_AND_BEAT_NAME_LIST',

  GET_PRIMARY_UOM_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_PRIMARY_UOMS_LIST',

  DISTRIBUTOR_TYPE_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_DISTRIBUTOR_TYPE_LIST',
  SUBDISTRIBUTOR_TYPE_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SUB_DISTRIBUTOR_TYPE_LIST',

  SUBDISTRIBUTOR_CUST_DELIVERY_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SUB_DIST_PO_CUST_LIST_FOR_DELIVERY',
  SUBDISTRIBUTOR_DELIVERY_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_SUB_DIST_PO_LIST_FOR_DELIVERY',
  SUBDISTRIBUTOR_DELIVERY_LINES_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_ALL_ACCEPTED_PO_LINES_FOR_SO',
  SUBDISTRIBUTOR_CREATE_DELIVERY:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CREATE_DELIVERY_FOR_SUB_DIS_TO_RET',
  GET_LAT_LAN_FROM_BRANCH:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_LAT_LON_FOR_CUSTOMERS_FROM_BRANCH',
  GET_LAT_LON_FROM_ORG:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_LAT_LON_FOR_CUSTOMERS_FROM_ORG',
  SUBDISTRIBUTOR_PO_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_PO_SUB_DIST_TO_SS_LIST_BYEMP_V2',
  DELIVERED_ORDERS_PO_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_DISTRIBUTOR_DELIVERY_ORDERS',

  DELIVERED_ORDERS_PO_HISTORY:
    Environment_Realtraceb_Dev.BASE_URL + '/SALESAPP_DELIVERY_LIST_BY_CUSTOMER',
  RETURN_REPORTS_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CUSTOMER_LIST_RETURN_REPORTS',
  RETURN_REPORTS_LIST_BY: Environment_Realtraceb_Dev.BASE_URL + '/RR_RETURN_LIST_BY_CUSTOMER',

  GET_SCHEMES_BY_ITEM_AND_CUSTOMER:
    Environment_Realtraceb_Dev.BASE_ROLE + '/schemes/get_schemes_by_customer_and_item',
  GET_CUSTOMERS_LIST_STOCK_AVAILABILITY:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_ALL_DISTRIBUTORS_BY_TYPE',
  GET_RR_DISTRIBUTOR_STOCK_DETAILS_MOB:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_DISTRIBUTOR_STOCK_DETAILS_MOB',

  SEND_LOCATION: Environment_Realtraceb_Dev.BASE_URL + '/RR_INSERT_LOCATIONS',
  CURRENT_DAY_GPS_REPORTS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CALCULATE_CURRENT_DAY_DISTANCE',
  GPS_HISTORY_REPORTS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CALCULATE_SALES_EXE_TRAVELLED_DISTANCES',
  GPS_MAP_GET_LOCATIONS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_EMPLOYEE_LOCATIONS_BY_EMPLOYEE',

  CHECK_STOCK_UPDATE_RET:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CHECK_RETAILER_STOCK_UPDATE',
  CHECK_STOCK_UPDATE_SBD:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CHECK_SUB_DISTRIBUTOR_STOCK_UPDATE',
  RR_GET_CUSTOMER_LIST_FOR_BEAT_DEVIATION:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_CUSTOMER_LIST_FOR_BEAT_DEVIATION',
  RR_CUSTOMER_SEGMNET_LIST: Environment_Realtraceb_Dev.BASE_URL + '/RR_CUSTOMER_SEGMNET_LIST',
  RR_OUTLET_COVERAGE_REPORT_BY_EMPLOYEE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_OUTLET_COVERAGE_REPORT_BY_EMPLOYEE',

  UPDATE_RET_SD_LOCATION:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_UPDATE_SD_AND_RETAILER_LOCATIONS',
  UPDATE_DIST_LOCATION:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_UPDATE_DIST_AND_SS_LOCATIONS',

  GENERATE_RETURN_NUMBER:
    Environment_Realtraceb_Dev.BASE_ROLE + '/invoice/autoGenerateReturnNumber',

  SAVE_DEALER_STOCK_BATCHES:
    Environment_Realtraceb_Dev.BASE_URL + '/rr_Stock_Update_In_Mobile',
  EDIT_PENDING_DEALER_STOCK_BATCHES:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_INVENTORY_LINE_WISE_EDIT',
  GET_DEALER_STOCK_DRAFT_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_get_Pending_OnHold_Inventory',
  SUBMIT_DEALER_STOCK_BATCHES:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_UPDATE_STATUS_IN_ON_HOLD_INVENTORY ',
  GET_DEALER_STOCK_STATUS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_CUSTOMER_CODE_UNDER_UPDATION_DETAILS',
  GET_DEALER_STOCK_STATUS_ITEMS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_UPDATE_STOCK_STATUS_IN_INVENTORY_APPROVAL',

  RR_BRAND_BY_PRODUCT_TYPE_LIST:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_BRAND_BY_PRODUCT_TYPE_LIST',
  RR_TEAM_PERFORMANCE_REPORT:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_TEAM_PERFORMANCE_REPORT',
  RR_DAILY_TEAM_PERFORMANCE_SFA:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_DAILY_TEAM_PERFORMANCE_SFA',
  RR_DAILY_TEAM_PERFORMANCE_SECONDARY:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_DAILY_TEAM_PERFORMANCE_SECONDARY',
  RR_GET_ALL_REGIONS: Environment_Realtraceb_Dev.BASE_URL + '/GET_ALL_REGIONS',
  RR_GET_AREA_OFFICE_CODE_BY_REGION:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_REGION_BY_AREA_OFFICE_CODE',
  RR_GET_CUSTOM_DESG_BY_AREA_SALES_OFFICE:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_CUSTOM_DESG_BY_AREA_SALES_OFFICE',
  RR_GET_EMPLOYEE_DETAILS_BY_CUSTOM_DESG:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_CUSTOM_DESG_DETAILS_BY_EMPLOYEE',
  RR_AREA_OFFICE_CODES_LIST_UNDER_ZSM:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_ZSM_UNDER_AREA_OFFICE_CODES_LIST',
  RR_GET_AREA_OFFICE_CODES_UNDER_RSM:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_RSM_UNDER_AREA_OFFICE_CODES',
  RR_ASM_AND_TL_UNDER_CUSTOM_DESIGNATIONS:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_ASM_AND_TL_UNDER_CUSTOM_DESIGNATIONS',
  RR_GET_CUSTOM_DESG_DETAILS_BY_EMP_ASM:
    Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_CUSTOM_DESG_DETAILS_BY_EMP_ASM',
  RR_GET_AREA_OFFICE_CODE: Environment_Realtraceb_Dev.BASE_URL + '/RR_GET_ASM_CODES_BY_REGION',
  RR_GET_ASSOCIATE_DATA_BY_ASO_AND_BEAT:
    Environment_Realtraceb_Dev.BASE_URL + '/Rr_get_customers_and_beats_by_region',
  // VALIDATION CODES
  SUCCESS: 'SUCCESS',
  ERROR: 'error',
};
