import { AgGridReact } from "ag-grid-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import Drawer from "@mui/material/Drawer";

import "../../../Styles.css";

import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// import { Environment_Realtraceb_Dev } from "../../Enviornment/Environment_Realtraceb_Dev";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
// import "./custom.css";
import CloseIcon from "@mui/icons-material/Close";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Paper,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";

// import CrLineItems from "./CarrierLineItems";
import DialogContentText from "@mui/material/DialogContentText";
import LegInfoVisual from "../Carrier/carrierLeginfoVisual";
import CrLineItems from "../Carrier/CarrierLineItems";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
// import "./Car_ack.css";

// import SimpleBar from "simplebar-react";
// import { Environment_Realtraceb_Dev } from "../Enviornment/Environment_Realtraceb_Dev";
// import CrLineItems from "../Components/Carrier/CarrierLineItems";
// import { PostRequest } from "../Services/BaseService";
// import LegInfoVisual from "../Components/Carrier/carrierLeginfoVisual";
// import swal from "sweetalert";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function GateOutShipments() {
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [openTruck, setOpenTruck] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  const inputRef = useRef<any>();
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const gridRef = useRef<any>();
  const [truckData, setTruckData] = useState<any>([]);
  const [truckref, setTruckRef] = useState("");
  const [invoiceNum, setInvoice] = useState("");
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);

    setOpenDetail(true);
  };

  useEffect(() => {
    setLoad(true);
    axios.get(url, config).then((res) => {
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  }, [fromDate, toDate]);

  const handleLegData = (params: any) => {
    console.log("Event Screen Leg Information....", params.data.legInfo);
    setLegData(params.data.legInfo);

    setLegOpen(true);
  };

  const truckurl =
    "https://api.ext.realtrace.realware.tech/po/truckDetailsView";

  const getTruckdetail = (params: any) => {
    console.log("TRUCK REFERENCE", params.value);
    setInvoice(params.data.invoiceNumber);
    setTruckRef(params.value);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      params: {
        truckRef: params.value,
      },
    };
    console.log("Configgg", config);
    axios.get(truckurl, config).then((res: any) => {
      setTruckData(res.data.primary);
      console.log("res................................", res.data.primary);
      if (res.data.primary.length > 0) {
        setOpenTruck(true);
      } else {
        // swal("Warning", "No Truck Data", "info");
      }
      // console.log("Truck Data..................108", truckData);
    });
  };

  const [columnDefs] = useState([
    {
      headerName: "INVOICE NUMBER",
      field: "invoiceNumber",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",

      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button
            sx={{ height: 20, width: "100%", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </Button>
        );
      },
    },
    // {
    //   headerName: "INVOICE NUMBER",
    //   field: "invoiceNumber",
    //   filter: true,
    //   headerTooltip: "INVOICE NUMBER",
    //   tooltipField: "invoiceNumber",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     return (
    //       <Button
    //         sx={{ height: 20, width: "100%", lineHeight: 0.5 }}
    //         onClick={() => handleLine(params)}
    //         className="btn btn-info"
    //       >
    //         {params.value}
    //       </Button>
    //     );
    //   },
    // },
    {
      headerName: "CONTAINER NUMBER",
      field: "containerNumber",
      filter: true,
      headerTooltip: "CONTAINER NUMBER",
      tooltipField: "containerNumber",
    },
    {
      headerName: "BOOKING REFERENCE DATE",
      field: "invoiceDate",
      filter: true,
      headerTooltip: "BOOKING REFERENCE DATE",
      tooltipField: "invoiceDate",
    },

    {
      headerName: "CONTAINER DETAILS",
      children: [
        {
          headerName: "CONTAINER TYPE",
          filter: true,
          headerTooltip: "CONTAINER TYPE",
          tooltipField: "containerType",
          field: "containerType",
          columnGroupShow: "closed",
        },

        {
          headerName: "CONTAINER PICKUP DEPOT",
          filter: true,
          headerTooltip: "CONTAINER PICKUP DEPOT",
          tooltipField: "containerType",
          field: "containerType",
          columnGroupShow: "closed",
        },
        {
          headerName: "CONTAINER SIZE",
          filter: true,
          headerTooltip: "CONTAINER SIZE",
          tooltipField: "containerSize",
          field: "containerSize",
          columnGroupShow: "closed",
        },
        {
          headerName: "CONTAINER TARE WEIGHT",
          filter: true,
          headerTooltip: "CONTAINER TARE WEIGHT",
          tooltipField: "containerTareWeight",
          field: "containerTareWeight",
          columnGroupShow: "closed",
        },
        {
          headerName: "CONTAINER PICKUP DEPOT",
          filter: true,
          headerTooltip: "CONTAINER PICKUP DEPOT",
          tooltipField: "containerPickUpDepot",
          field: "containerPickUpDepot",
          columnGroupShow: "closed",
        },
      ],
    },
    // {
    //     headerName: "INVOICE DATE",
    //     field: "invoiceDate",
    //     filter: true,
    //     headerTooltip: "INVOICE DATE",
    //     tooltipField: "invoiceDate",
    //   },

    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      filter: "true",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
    },

    {
      headerName: "CARRIER REFERENCE",
      field: "carrierReference",
      filter: true,
      headerTooltip: "CARRIER REFERENCE",
      tooltipField: "carrierReference",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CARRIER ID",
      field: "carrierId",
      filter: true,
      headerTooltip: "CARRIER ID",
      tooltipField: "carrierId",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CARRIER NAME",
      field: "carrierName",
      filter: true,
      headerTooltip: "CARRIER NAME",
      tooltipField: "carrierName",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "PICK UP DETAILS",
      children: [
        {
          headerName: "PICK UP LOCATION",
          field: "containerPickUpDepot",
          filter: true,
          headerTooltip: "PICK UP LOCATION",
          tooltipField: "containerPickUpDepot",
        },

        {
          headerName: "PICKUP DATE",
          filter: true,
          headerTooltip: "PICKUP DATE",
          tooltipField: "pickUpDate",
          field: "pickUpDate",
          columnGroupShow: "closed",
        },
      ],
    },

    {
      headerName: "SHIPMENT DETAILS",
      children: [
        {
          headerName: "SHIPMENT VOLUME",
          filter: true,
          headerTooltip: "SHIPMENT VOLUME",
          field: "shipmentVolume",
          tooltipField: "shipmentVolume",
        },
        {
          headerName: "SHIPMENT WEIGHT",
          filter: true,
          headerTooltip: "SHIPMENT WEIGHT",
          field: "shipmentWeight",
          tooltipField: "shipmentWeight",
          columnGroupShow: "closed",
        },
      ],
    },
    {
      headerName: "FORWARDER DETAILS",
      children: [
        {
          headerName: "FORWARDER ID",
          filter: true,
          headerTooltip: "FORWARDER ID",
          tooltipField: "forwarderId",
          field: "forwarderId",
        },
        {
          headerName: "FORWARDER NAME",
          filter: true,
          headerTooltip: "FORWARDER NAME",
          tooltipField: "forwarderName",
          field: "forwarderName",
          columnGroupShow: "closed",
        },
      ],
    },
    {
      headerName: "WEIGHT UOM",
      field: "weightUom",
      filter: true,
      headerTooltip: "WEIGHT UOM",
      tooltipField: "weightUom",
    },
    {
      headerName: "HBL ID",
      field: "hblNumber",
      filter: true,
      headerTooltip: "HBL ID",
      tooltipField: "hblNumber",
    },
    {
      headerName: "MBL ID",
      field: "mblNumber",
      filter: true,
      headerTooltip: "MBL ID",
      tooltipField: "mblNumber",
    },

    {
      headerName: "CONSIGNEE DETAILS",
      children: [
        {
          headerName: "CONSIGNEE ID",
          filter: true,
          headerTooltip: "CONSIGNEE ID",
          field: "consigneeId",
          tooltipField: "consigneeId",
        },
        {
          headerName: "CONSIGNEE NAME",
          filter: true,
          headerTooltip: "CONSIGNEE NAME",
          field: "consigneeName",
          tooltipField: "consigneeName",
          columnGroupShow: "closed",
        },
      ],
    },
    {
      headerName: "ETD",
      filter: true,
      headerTooltip: "ETD",
      tooltipField: "etd",
      field: "etd",
    },
    {
      headerName: "ETA",
      filter: true,
      headerTooltip: "ETA",
      tooltipField: "eta",
      field: "eta",
    },
    {
      headerName: "SHIPMENT DETAILS",
      children: [
        {
          headerName: "SHIPMENT ID",
          filter: true,
          headerTooltip: "SHIPMENT ID",
          tooltipField: "shipperId",
          field: "shipperId",
        },
        {
          headerName: "SHIPMENT NAME",
          filter: true,
          headerTooltip: "SHIPMENT NAME",
          tooltipField: "shipperName",
          field: "shipperName",
          columnGroupShow: "closed",
        },
        {
          headerName: "SHIPMENT DATE",
          filter: true,
          headerTooltip: "SHIPMENT DATE",
          tooltipField: "shipmentDate",
          field: "shipmentDate",
        },

        {
          headerName: "SHIPMENT VOLUME",
          filter: true,
          headerTooltip: "SHIPMENT VOLUME",
          tooltipField: "shipmentVolume",
          field: "shipmentVolume",
          columnGroupShow: "closed",
        },
        {
          headerName: "SHIPMENT WEIGHT",
          filter: true,
          headerTooltip: "SHIPMENT WEIGHT",
          tooltipField: "shipmentWeight",
          field: "shipmentWeight",
          columnGroupShow: "closed",
        },
        {
          headerName: "MODE OF SHIPMENT",
          filter: true,
          headerTooltip: "MODE OF SHIPMENT",
          tooltipField: "modeOfShipment",
          field: "modeOfShipment",
          columnGroupShow: "closed",
        },
      ],
    },

    {
      headerName: "UPLOAD DOCS",
      filter: true,
      headerTooltip: "UPLOAD DOCS",
      tooltipField: "upload docs",
      field: "tranship",
      cellRenderer: (params: ICellRendererParams) => {
        return <Button>Upload Doc</Button>;
      },
    },
    {
      headerName: "ACTION",
      filter: true,
      headerTooltip: "ACTION",
      tooltipField: "action",
      field: "truckRef",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button onClick={() => getTruckdetail(params)}>Truck detail</Button>
        );
      },
    },
  ]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const handleTruckClose = () => {
    setOpenTruck(false);
  };

  const url = "https://api.ext.realtrace.realware.tech/po/customsClearedList";

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    cbrStatus: "open",
    // fbrStatus: "open,processed",
    brStatus: "open",
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      type: "cha",
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  const updateurl =
    "https://api.ext.realtrace.realware.tech/po/chaStatusUpdate";

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box className="ag-theme-balham">
          <AgGridReact
            ref={gridRef}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            domLayout={domLayout}
            columnDefs={columnDefs}
            sideBar={sideBar}
            onGridReady={onGridReady}
            paginationPageSize={5}
            pagination={true}
            onFirstDataRendered={onFirstDataRendered}
            rowSelection="multiple"
          ></AgGridReact>
        </Box>
        <div className="dialog">
          {openDetail ? (
            <CrLineItems
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={PoData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>

        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        {openTruck ? (
          <Drawer
            open={openTruck}
            onClose={handleTruckClose}
            anchor={"right"}
            sx={{ width: "500px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                width: "500px",
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                Truck Details Of Invoice # : &nbsp;&nbsp;
                <span
                  style={{ color: "white" }}
                  className="dialogTitle blueColor"
                >
                  {invoiceNum}
                </span>
              </Typography>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={handleTruckClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Grid container sx={{ width: "465px", padding: "20px" }}>
              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key">Truck Number &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].truckNumber}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key">Driver Name &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].driverName}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key">Driver Number &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].driverNumber}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box
                  sx={{ display: "flex", flexDirection: "row", height: "24px" }}
                >
                  <p className="key">
                    GateAppointmentDate &nbsp; &nbsp; &nbsp;
                  </p>
                  <p className="value">{truckData[0].gateAppointmentDate}</p>
                </Box>
              </Grid>

              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key">
                    GateAppointmentTime &nbsp; &nbsp; &nbsp;{" "}
                  </p>
                  <p className="value">{truckData[0].gateAppointmentTime}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key"> GateInDate &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].gateInDate}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key">GateInTime &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].gateInTime}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key"> MountOnTruckDate &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].mountOnTruckDate}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p className="key">MountOnTruckTime &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].mountOnTruckTime}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key"> GateOutDate &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].gateOutDate}</p>
                </Box>
              </Grid>
              <Grid item xs={8} className="lineborder">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <p className="key"> GateOutTime &nbsp; &nbsp; &nbsp; </p>
                  <p className="value">{truckData[0].gateOutTime}</p>
                </Box>
              </Grid>
            </Grid>
          </Drawer>
        ) : null}
      </Paper>
    </div>
  );
}

export default GateOutShipments;
