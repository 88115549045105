import { createBrowserRouter, createHashRouter } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import AcceptedByCarrier from "../components/pages/Carrier/AcceptedByCarrier";
import CarrierAck from "../components/pages/Carrier/CarrierPendingAck";
import IntransitEventDisplay from "../components/pages/Carrier/IntransitEventDisplay";
import RejectedByCarrier from "../components/pages/Carrier/RejectedByCarrier";
import AcceptedByForwarder from "../components/pages/Forwarder/AcceptedBR";
import BookingRequest from "../components/pages/Forwarder/BrAck";
import RequestedByShipments from "../components/pages/Forwarder/RejectedBR";
// import BookingRequest from "../components/pages/Forwarder/BrAck";
import PoAccept from "../components/pages/Purchase/PoAccept";
import PoForAcknowledgement from "../components/pages/Purchase/POForAcknowledgement";
import RejectedPo from "../components/pages/Purchase/RejectedPo";
import BrFileupload from "../components/pages/UploadFiles/BRupload";
import CarrierConfirmation from "../components/pages/UploadFiles/CarrierConfirmation";
import CrFileupload from "../components/pages/UploadFiles/CarrierUpload";
import CommonFileUploader from "../components/pages/UploadFiles/CommonFileUploader";
import ForwarderUpload from "../components/pages/UploadFiles/ForwarderUpload";
import InTransitEventFileupload from "../components/pages/UploadFiles/intransitEvent";
import ReturnFileUpload from "../components/pages/UploadFiles/ReturnsUpload";
import PendingWhRequests from "../components/pages/WareHouse/PendingWhRequests";
import Fileupload from "../Helpers/Fileupload";
import Login from "../Layout/Login";
import PrivateRoutes from "../Utilities/PrivateRoutes";
import AccptedWHShipments from "../components/pages/WareHouse/AcceptedWhshipments";
import WhRequest from "../components/pages/UploadFiles/WhRequest";
import WhConfirmation from "../components/pages/UploadFiles/whConfirmation";

import OutWardShipments from "../components/pages/WareHouse/OutWardShipments";
import ShipmentsCha from "../components/pages/CHA/shipments";
import CustomClearence from "../components/pages/CHA/customclearencescreen";
import GateOutShipments from "../components/pages/CHA/GateOutShipments";
import AddLegs from "../components/pages/Carrier/AddLegInfo";
import SpaceUtilizatoinReport from "../components/pages/WareHouse/SpaceUtilizationReport";

import InWardTruckDetails from "../components/pages/WareHouse/InwardTruckDetails";
import RejectedShipments from "../components/pages/WareHouse/RejectedShipments";
import IntransitForDelivery from "../components/pages/Carrier/IntransitForDelivery";
import DeliveredInTransitShipments from "../components/pages/Carrier/DeliveredIntransitShipments";
import AcceptedReturns from "../components/pages/Returns/AcceptedReturns";
import AckRejected from "../components/pages/Returns/ReturnsForAck";
import RejectedReturns from "../components/pages/Returns/RejectedReturns";
import ManageDocs from "../Helpers/ManageDocs";
import ShipmentSideWh from "../components/pages/WareHouse/ShipmentSideWh";
import ExportedCustomClearence from "../components/pages/CHA/ExportedCustoms";
import ImportAndGateOut from "../components/pages/CHA/Importandgateout";
import SearatesInfo from "../components/pages/SeaRates/SearatesInfo";
const router = createHashRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <PrivateRoutes />,
    children: [
      {
        path: 'sea_Rates',
        element: <SearatesInfo />
      },
      {
        path: "/",
        element: <Dashboard />,
      },

      {
        path: "pending_for_approval",
        element: <PoForAcknowledgement />,
      },
      {
        path: "pending_purchase_order",
        element: <PoForAcknowledgement />,
      },
      {
        path: "accepted_purchase_order",
        element: <PoAccept />,
      },
      {
        path: "rejected_purchase_order",
        element: <RejectedPo />,
      },
      {
        path: "/bookingrequestack",
        element: <BookingRequest />,
      },
      {
        path: "/confirmed_by_forwarder",
        element: <AcceptedByForwarder />,
      },
      {
        path: "/rejected_by_forwarder",
        element: <RequestedByShipments />,
      },
      {
        path: "/pending_carrier_confirmation",
        element: <CarrierAck />,
      },
      {
        path: "/carrier_confirmation",
        element: <CarrierAck />,
      },
      {
        path: "/upload_booking_request",
        element: <BrFileupload />,
      },
      {
        path: "/pending_forwarder_confirmation",
        element: <BookingRequest />,
      },
      {
        path: "/confirmed_by_carrier",
        element: <AcceptedByCarrier />,
      },
      {
        path: "/rejected_by_carrier",
        element: <RejectedByCarrier />,
      },
      {
        path: "/upload_forwarder_confirmation",
        element: <ForwarderUpload />,
      },
      {
        path: "/upload_carrier_booking_request",
        element: <CrFileupload />,
      },
      {
        path: "/upload_documents",
        element: <CommonFileUploader />,
      },
      {
        path: "/carrier_confirmation_upload",
        element: <CarrierConfirmation />,
      },
      {
        path: "/intransit_events_upload",
        element: <InTransitEventFileupload />,
      },
      {
        path: "/intransit_shipments",
        element: <IntransitEventDisplay />,
      },
      {
        path: "/upload_purchase_order",
        element: <Fileupload />,
      },

      // {
      //   path: "/pending_for_approval",
      //   element: <PoForAcknowledgement />,
      // },
      {
        path: "/upload_return_order",
        element: <ReturnFileUpload />,
      },
      {
        path: "/pending_requests",
        element: <PendingWhRequests />,
      },
      {
        path: "/confirmed_shipments",
        element: <AccptedWHShipments />,
      },
      {
        path: "/wareHouse_request_upload",
        element: <WhRequest />,
      },
      {
        path: "/wareHouse_confirmation_upload",
        element: <WhConfirmation />,
      },
      {
        path: "/inward_truckdetails",
        // element: <InwardForm />,
        element: <InWardTruckDetails />,
      },
      {
        path: "/rejected_shipments",
        element: <RejectedShipments />,
      },
      {
        path: "/outward_shipments",
        element: <OutWardShipments />,
      },
      {
        path: "/space_utilization_report",
        element: <SpaceUtilizatoinReport />,
      },
      {
        path: "/pending_custom_clearance",
        element: <ShipmentsCha />,
      },
      // {
      //   path: "/shipments_for_custom_clearance",
      //   element: <ShipmentsCha />,
      // },
      {
        path: "/import_custom_clearence",
        element: <CustomClearence />,
      },
      {
        path: "/export_custom_clearence",
        element: <ExportedCustomClearence />,
      },
      {
        path: "/gateOut_shipments",
        element: <ImportAndGateOut />,
      },
      {
        path: "/requested_shipments",
        element: <BookingRequest />,
      },
      {
        path: "/add_legs",
        element: <AddLegs />,
      },
      {
        path: "/confirm_goods_reciept",
        element: <IntransitForDelivery />,
      },
      {
        path: "/delivered_report",
        element: <DeliveredInTransitShipments />,
      },
      {
        path: "/return_orders_for_acknowledgement",
        element: <AckRejected />,
      },
      {
        path: "/confirmed_return_orders",
        element: <AcceptedReturns />,
      },
      {
        path: "/rejected_return_orders",
        element: <RejectedReturns />,
      },
      {
        path: "/manage_documents",
        element: <ManageDocs />,
      },
      {
        path: "/shipments_at_warehouse",
        element: <ShipmentSideWh />,
      },

    ],
  },

]);

export default router;
