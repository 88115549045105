import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  FormControl,
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import CachedIcon from "@mui/icons-material/Cached";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "../Styles.css";
import SearchBar from "material-ui-search-bar";
import Echarts from "./BarWithLinesEcharts";

import { AgGridReact } from "ag-grid-react";
import DonutEcharts from "./DonutEcharts";
import axios from "axios";
import DeviationCharts from "./DeviationCharts";

declare var $: any;
interface Column {
  id: "count" | "eventName" | "percentage";
  label: string;
}
const columns: readonly Column[] = [
  { id: "count", label: "# Shipments" },
  { id: "eventName", label: "Event Type" },
  // { id: 'percentage', label: 'Event Location' },
  // { id: 'avgdelayindays', label: 'Avg. Delay In Days' }
];

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];
interface Data {
  name: string;
  code: string;
  population: number;
  size: number;
  density: number;
}

function createData(
  name: string,
  code: string,
  population: number,
  size: number
): Data {
  const density = population / size;
  return { name, code, population, size, density };
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CommonETADeviationInfo(props: any) {
  console.log(props, "props");
  const [page, setPage] = React.useState(0);
  const [isFirstDataReder, setIsFirstDataRender] = useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isDataShow, setIsDataShow] = useState(true);
  const [filterCountry, setfilterCountry] = useState([]);
  const [origin, setOrigin] = useState("");
  const [shipper, setShipper] = useState("");
  const [rowData, setRowData] = useState([]);
  const [childData, setChildData] = useState(false);
  const [chartPayLoad, setChartPayLoad] = useState<any>();
  const [ischartShow, setIsChartShow] = useState(true);
  const [reliablityInfo, setReliablityInfo] = useState<any>();
  const myRef = useRef<any>();
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  let totalResp: any = [];
  const [mapOrigin, setMapOrigin] = useState<any>(null);
  const [mapDestination, setMapDestination] = useState<any>(null);
  const [shipmentsCount, setShipmentsCount] = useState<any>();
  const [countryEvents, setCountryEvents] = useState<any>([]);
  const [shipmentsByOrigin, setShipmentsByOrigin] = useState<any>([]);
  const [mapleggends, setMapLeggends] = useState([]);
  const [mapSerious, setMapSerious] = useState([]);
  const dummyResp =
    props.pageInfo.tab === "between2days"
      ? {
          status: true,
          data: {
            summary: {
              atWareHouse: 18,
              outForDelivery: 7,
              delivered: 0,
              importClearance: 87,
              gateOut: 40,
              emptyPickUp: 35,
              gateIn: 27,
              exportclearance: 22,
              vesselLoad: 51,
              vesselMovement: 60,
              vesselDischarge: 55,
            },
            countryEvents: [
              {
                country: "INDIA",
                Percentage: "80",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 5,
                  },
                  {
                    eventName: "Gate In",
                    count: 4,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 6,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 3,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 12,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 11,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 18,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 7,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 2,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 2,
                  },
                ],
              },
              {
                country: "GERMANY",
                Percentage: "75",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 6,
                  },
                  {
                    eventName: "Gate In",
                    count: 5,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 7,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 4,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 8,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 9,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 14,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 8,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 3,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 1,
                  },
                ],
              },
              {
                country: "JAPAN",
                Percentage: "85",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 7,
                  },
                  {
                    eventName: "Gate In",
                    count: 5,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 8,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 4,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 11,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 10,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 16,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 8,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 4,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 2,
                  },
                ],
              },
              {
                country: "USA",
                Percentage: "106",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 9,
                  },
                  {
                    eventName: "Gate In",
                    count: 7,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 10,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 6,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 12,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 14,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 13,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 20,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 9,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 4,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 2,
                  },
                ],
              },
              {
                country: "CHINA",
                Percentage: "96",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 8,
                  },
                  {
                    eventName: "Gate In",
                    count: 6,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 9,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 5,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 11,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 13,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 12,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 19,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 8,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 4,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 1,
                  },
                ],
              },
            ],
            shipmentByEventType: [
              {
                country: "INDIA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },

              {
                country: "JAPAN",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate In",
                consigneeID: "consignee002",
                consigneeName: "consignee 002",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
            ],
          },
          totalShipments: 14,
        }
      : props.pageInfo.tab === "betweenWeek"
      ? {
          status: true,
          data: {
            summary: {
              atWareHouse: 14,
              outForDelivery: 4,
              // "delivered": 3,
              gateOutOnCarriage:18,
              importClearance: 82,
              gateOut: 38,
              emptyPickUp: 33,
              gateIn: 26,
              exportclearance: 20,
              vesselLoad: 49,
              vesselMovement: 57,
              vesselDischarge: 53,
            },
            countryEvents: [
              {
                country: "INDIA",
                Percentage: "87",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 7,
                  },
                  {
                    eventName: "Gate In",
                    count: 6,
                  },
                  {
                    eventName: "Gate Out",
                    count: 8,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 4,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 11,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 13,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 12,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 18,
                  },
                  {
                    eventName: "Gate Out",
                    count: 4,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 3,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 1,
                  },
                ],
              },
              {
                country: "GERMANY",
                Percentage: "61",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 5,
                  },
                  {
                    eventName: "Gate In",
                    count: 4,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 6,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 3,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 8,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 9,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 8,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 13,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 3,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 2,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "JAPAN",
                Percentage: "73",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 6,
                  },
                  {
                    eventName: "Gate In",
                    count: 5,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 7,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 4,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 9,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 10,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 15,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 3,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 3,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 1,
                  },
                ],
              },
              {
                country: "USA",
                Percentage: "91",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 8,
                  },
                  {
                    eventName: "Gate In",
                    count: 6,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 9,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 5,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 11,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 13,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 12,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 19,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 4,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 3,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 1,
                  },
                ],
              },
              {
                country: "CHINA",
                Percentage: "82",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 7,
                  },
                  {
                    eventName: "Gate In",
                    count: 5,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 8,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 4,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 12,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 11,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 17,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 4,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 3,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 1,
                  },
                ],
              },
            ],
            shipmentByEventType: [
              {
                country: "INDIA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },

              {
                country: "JAPAN",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate In",
                consigneeID: "consignee002",
                consigneeName: "consignee 002",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
            ],
          },
          totalShipments: 14,
        }
      : props.pageInfo.tab === "betweenMonth"
      ? {
          status: true,
          data: {
            summary: {
              atWareHouse: 13,
              outForDelivery: 0,
              delivered: 0,
              importClearance: 77,
              gateOut: 35,
              gateOutOnCarriage:17,
              emptyPickUp: 30,
              gateIn: 19,
              exportclearance: 18,
              vesselLoad: 46,
              vesselMovement: 56,
              vesselDischarge: 51,
            },
            countryEvents: [
              {
                country: "INDIA",
                Percentage: "80",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 7,
                  },
                  {
                    eventName: "Gate In",
                    count: 4,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 8,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 4,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 12,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 11,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 17,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 4,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 3,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "GERMANY",
                Percentage: "58",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 5,
                  },
                  {
                    eventName: "Gate In",
                    count: 3,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 6,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 3,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 7,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 9,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 8,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 12,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 3,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 2,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "JAPAN",
                Percentage: "65",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 5,
                  },
                  {
                    eventName: "Gate In",
                    count: 5,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 6,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 3,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 8,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 9,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 14,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 3,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 2,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "USA",
                Percentage: "83",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 7,
                  },
                  {
                    eventName: "Gate In",
                    count: 3,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 8,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 4,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 11,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 13,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 12,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 18,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 4,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 3,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "CHINA",
                Percentage: "76",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 6,
                  },
                  {
                    eventName: "Gate In",
                    count: 4,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 7,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 4,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 12,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 11,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 16,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 3,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 3,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
            ],
            shipmentByEventType: [
              {
                country: "INDIA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },

              {
                country: "JAPAN",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate In",
                consigneeID: "consignee002",
                consigneeName: "consignee 002",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
            ],
          },
          totalShipments: 14,
        }
      : {
          status: true,
          data: {
            summary: {
              atWareHouse: 4,
              outForDelivery: 0,
              delivered: 0,
              importClearance: 68,
              gateOut: 24, //Oncarriage
              gateOutOnCarriage:5,
              emptyPickUp: 18,
              gateIn: 15,
              exportclearance: 5,
              vesselLoad: 36,
              vesselMovement: 44,
              vesselDischarge: 39,
            },
            countryEvents: [
              {
                country: "INDIA",
                Percentage: "57",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 4,
                  },
                  {
                    eventName: "Gate In",
                    count: 3,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 5,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 1,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 8,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 9,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 15,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 1,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 1,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "GERMANY",
                Percentage: "42",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 3,
                  },
                  {
                    eventName: "Gate In",
                    count: 3,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 4,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 1,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 6,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 7,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 6,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 11,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 1,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 0,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "JAPAN",
                Percentage: "46",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 3,
                  },
                  {
                    eventName: "Gate In",
                    count: 3,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 4,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 1,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 6,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 8,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 7,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 12,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 1,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 1,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "USA",
                Percentage: "59",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 4,
                  },
                  {
                    eventName: "Gate In",
                    count: 3,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 6,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 1,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 8,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 10,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 9,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 16,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 1,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 1,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
              {
                country: "CHINA",
                Percentage: "54",
                events: [
                  {
                    eventName: "Empty Pick Up",
                    count: 4,
                  },
                  {
                    eventName: "Gate In",
                    count: 3,
                  },
                  {
                    eventName: "Gate Out(Pre-Carriage)",
                    count: 5,
                  },
                  {
                    eventName: "Export Clearance",
                    count: 1,
                  },
                  {
                    eventName: "Vessel Load",
                    count: 8,
                  },
                  {
                    eventName: "Vessel Movement",
                    count: 9,
                  },
                  {
                    eventName: "Vessel Discharge",
                    count: 8,
                  },
                  {
                    eventName: "Import Clearance",
                    count: 14,
                  },
                  {
                    eventName: "Gate Out(On-Carriage)",
                    count: 1,
                  },
                  {
                    eventName: "At Wharehouse",
                    count: 1,
                  },
                  {
                    eventName: "Out For Delivery",
                    count: 0,
                  },
                ],
              },
            ],
            shipmentByEventType: [
              {
                country: "INDIA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "INDIA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "GERMANY",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },

              {
                country: "JAPAN",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate In",
                consigneeID: "consignee002",
                consigneeName: "consignee 002",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "JAPAN",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "USA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Empty Pick Up",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate In",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out(Pre-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Export Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Load",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Movement",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Vessel Discharge",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Import Clearance",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Gate Out(On-Carriage)",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "At Wharehouse",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
              {
                country: "CHINA",
                eventMapping: "Out For Delivery",
                consigneeID: "consignee001",
                consigneeName: "consignee 001",
                shipperID: "shipper001",
                shipperName: "shipper 001",
              },
            ],
          },
          totalShipments: 14,
        };
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const [personName, setPersonName] = React.useState<string[]>([]);
  // let url = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/etaDeviationAnalyticsEventsData';
  // let url1 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/etaDeviationShipmentByCountryData';
  // let url2 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/etaDeviationShipmentByCarrierData';
  // let url3 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/etaAnalyticsFilterForRole';
  // let url4 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/etaAnalyticsFilterForCountry';
  // let url5 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/deliveredShipmentByCountryDeviationAnalytics';
  // let url6 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/inTransitShipmentByCountry';
  // let url7 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/inTransitShipmentByCountryCarrier';
  // let url8 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/deliveredShipmentByCarrierAnalytics'
  let url =
    "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/etaDeviationAnalytics";
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {},
  };
  useEffect(() => {
    let status = {
      status: props.pageInfo.tab,
    };
    sessionStorage.removeItem("consignee");
    sessionStorage.removeItem("country");
    etaDeviationAnalyticsEventsData(status);
  }, []);
  const etaDeviationAnalyticsEventsData = (status: any) => {
    config.params = status;
    axios.get(url, config).then((res: any) => {
      console.log(res.data, "resp dattaa");
      // totalResp = res.data.data.shipmentByEventType;
      totalResp = dummyResp.data.shipmentByEventType;
      // setShipmentsCount(res.data)
      setShipmentsCount(dummyResp);
      if (res.data.data.countryEvents.length > 0) {
        setIsDataShow(true);
      }
      // setCountryEvents(res.data.data.countryEvents)
      setCountryEvents(dummyResp.data.countryEvents);
      setShipmentsByOrigin([
        ...new Set(
          dummyResp.data.shipmentByEventType.map(
            (item: any) => item.consigneeID
          )
        ),
      ]);

      // setShipmentsByOrigin([...new Set(res.data.data.shipmentByEventType.map((item: any) => item.consigneeID))])
      graphData(null, null);
    });
  };
  const graphData = (consignee: any, country: any) => {
    // IF country and consignee is null, populate all
    console.log(mapDestination, "mapDestination");
    let data = totalResp;
    let filteredArray: any = [];
    let legends: any = [];
    let values: any = [];
    if (
      sessionStorage.getItem("country") != null &&
      sessionStorage.getItem("consignee") === null
    ) {
      //   filteredArray = data.filter(
      //     (item: any) => item.country === mapDestination
      //   );

      if (props.pageInfo.tab === "between2days") {
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (country === "INDIA") {
          values = [5,4,6,3,10,12,11,18,7,2,2];
        } else if (country === "GERMANY") {
          values = [6, 5, 7, 4, 8, 10, 9, 14, 8, 3, 1];
        } else if (country === "JAPAN") {
          values = [7, 5, 8, 4, 10, 11, 10, 16, 8, 4, 2];
        } else if (country === "USA") {
          values = [9, 7, 10, 6, 12, 14, 13, 20, 9, 4, 2];
        } else {
          values = [8, 6, 9, 5, 11, 13, 12, 19, 8, 4, 1];
        }
      } else if (props.pageInfo.tab === "betweenWeek") {
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (country === "INDIA") {
          values = [7, 6, 8, 4, 11, 13, 12, 18, 4, 3, 1];
        } else if (country === "GERMANY") {
          values = [4, 5, 6, 83, 8, 9, 8, 13, 3, 2, 0];
        } else if (country === "JAPAN") {
          values = [6, 5, 7, 4, 9, 10, 10, 15, 3, 3, 1];
        } else if (country === "USA") {
          values = [8, 6, 9, 5, 11, 13, 12, 19, 4, 3, 1];
        } else {
          values = [7, 5, 8, 4, 10, 12, 11, 17, 4, 3, 1];
        }
      } else if (props.pageInfo.tab === "betweenMonth") {
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (country === "INDIA") {
          values = [7, 4, 8, 4, 10, 12, 11, 17, 4, 3, 0];
        } else if (country === "GERMANY") {
          values = [5, 3, 6, 3, 7, 9, 8, 12, 3, 2, 0];
        } else if (country === "JAPAN") {
          values = [5, 5, 6, 3, 8, 10, 9, 14, 3, 2, 0];
        } else if (country === "USA") {
          values = [7, 3, 8, 4, 11, 13, 12, 18, 4, 3, 0];
        } else {
          values = [6, 4, 7, 4, 10, 12, 11, 16, 3, 3, 0];
        }
      } else {
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (country === "INDIA") {
          values = [4, 3, 5, 1, 8, 10, 9, 15, 1, 1, 0];
        } else if (country === "GERMANY") {
          values = [3, 3, 4, 1, 6, 7, 6, 11, 1, 0, 0];
        } else if (country === "JAPAN") {
          values = [3, 3, 4, 1, 6, 8, 7, 12, 1, 1, 0];
        } else if (country === "USA") {
          values = [4, 3, 6, 1, 8, 10, 9, 16, 1, 1, 0];
        } else {
          values = [4, 3, 5, 1, 8, 9, 8, 14, 1, 1, 0];
        }
      }
      setIsFirstDataRender(false);
      console.log(filteredArray, "filterarray");
    } else if (
      sessionStorage.getItem("consignee") !== null &&
      sessionStorage.getItem("country") === null
    ) {
      //   filteredArray = data.filter(
      //     (item: any) => item.consigneeID === mapOrigin
      //   );
      if (props.pageInfo.tab === "between2days") {
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (consignee === "consignee001") {
          values = [20, 12, 20, 10, 31, 25, 25, 37, 20, 10, 0];
        } else {
          values = [15, 15, 20, 12, 20, 35, 30, 50, 20, 8, 7];
        }
      } else if (props.pageInfo.tab === "betweenWeek") {
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (consignee === "consignee001") {
          values = [13, 20, 10, 10, 29, 27, 23, 42, 10, 10, 2];
        } else {
          values = [20, 6, 28, 10, 20, 30, 30, 40, 8, 4, 2];
        }
      } else if (props.pageInfo.tab === "betweenMonth") {
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (consignee === "consignee001") {
          values = [10, 19, 10, 10, 20, 20, 1, 41, 10, 10, 0];
        } else {
          values = [20, 0, 25, 8, 26, 36, 50, 30, 7, 3, 0];
        }
      } else {
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (consignee === "consignee001") {
          values = [10, 10, 20, 3, 30, 24, 20, 28, 3, 0, 0];
        } else {
          values = [8, 5, 4, 2, 6, 20, 19, 40, 2, 4, 0];
        }
      }
      setIsFirstDataRender(false);
      console.log(filteredArray, "filterarray2");
    } else if (
      sessionStorage.getItem("country") === null &&
      sessionStorage.getItem("consignee") === null
    ) {
      //   legends = [
      //     "Empty PickUp",
      //     "Gate In",
      //     "Gate Out (Pre-Carriage)",
      //     "Export Clearance	",
      //     "Vessel Load",
      //     "Vessel Movement",
      //     "Vessel Discharge",
      //     "Import Clearance",
      //     "Gate Out (On-Carriage)",
      //     "At Warehouse",
      //     "Out For Delivery",
      //   ];
      //   setIsFirstDataRender(true);
      filteredArray = data;
      setIsFirstDataRender(true);
      legends = [
        ...new Set(filteredArray.map((item: any) => item.eventMapping)),
      ];
      console.log(legends, "eventss");

      for (let i = 0; i < legends.length; i++) {
        let eventCount = filteredArray.filter(
          (item: any) => item.eventMapping === legends[i]
        ).length;
        values.push(eventCount);
      }
      console.log(values, "values");
    } else {
      if (props.pageInfo.tab === "between2days") {
        console.log("hiiiii");
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [3,2,3,0,5,6,11,9,7,1,1];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 3, 4, 2, 4, 5, 4, 10, 2, 2, 1];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 4, 4, 2, 5, 5, 5, 8, 2, 2, 0];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [5, 4, 5, 3, 6, 7, 7, 10, 4, 2, 1];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 3, 6, 2, 5, 5, 6, 10, 3, 2, 0];
        } else if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [2,2,3,3,5,6,0,9,0,1,1];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [2, 2, 3, 2, 4, 5, 5, 4, 6, 1, 0];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [3, 1, 4, 2, 5, 6, 5, 8, 6, 2, 2];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [4, 3, 5, 3, 6, 7, 6, 10, 5, 2, 1];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [4, 3, 3, 3, 6, 7, 6, 9, 5, 2, 1];
        }
      } else if (props.pageInfo.tab === "betweenWeek") {
        console.log("hiiiii");
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 2, 6, 2, 10, 10, 10, 10, 4, 3, 1];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 3, 4, 2, 4, 5, 4, 10, 2, 2, 0];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 4, 4, 2, 5, 5, 5, 8, 2, 2, 0];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 2, 4, 5, 1, 10, 10, 10, 2, 1, 0];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 5, 4, 2, 5, 6, 10, 10, 2, 0, 1];
        } else if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [3, 4, 2, 2, 1, 3, 2, 8, 1, 1, 0];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [1, 1, 2, 1, 4, 4, 4, 3, 1, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [2, 1, 3, 2, 4, 5, 5, 7, 1, 1, 1];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [4, 4, 5, 0, 10, 3, 2, 9, 2, 2, 1];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [3, 0, 4, 2, 5, 6, 1, 7, 2, 3, 0];
        }
      } else if (props.pageInfo.tab === "betweenMonth") {
        console.log("hiiiii");
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 0, 4, 4, 5, 6, 6, 7, 2, 3, 0];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [2, 2, 0, 0, 4, 0, 4, 6, 0, 2, 0];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [0, 2, 3, 3, 4, 5, 3, 7, 0, 2, 0];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 3, 4, 2, 10, 10, 10, 10, 2, 3, 0];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 4, 3, 2, 1, 10, 1, 10, 3, 3, 0];
        } else if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [3, 4, 4, 0, 5, 6, 5, 10, 2, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [3, 1, 6, 3, 3, 9, 4, 6, 3, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [5, 3, 3, 0, 4, 5, 6, 7, 3, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [3, 0, 4, 2, 1, 3, 2, 8, 2, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [2, 0, 4, 2, 9, 2, 10, 6, 0, 0, 0];
        }
      } else {
        console.log("hiiiii");
        legends = [
          "Empty PickUp",
          "Gate In",
          "Gate Out (Pre-Carriage)",
          "Export Clearance	",
          "Vessel Load",
          "Vessel Movement",
          "Vessel Discharge",
          "Import Clearance",
          "Gate Out (On-Carriage)",
          "At Warehouse",
          "Out For Delivery",
        ];
        if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 2, 5, 0, 0, 5, 9, 10, 0, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [3, 2, 4, 1, 0, 0, 0, 1, 0, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [3, 2, 4, 1, 3, 4, 0, 10, 0, 1, 0];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 3, 3, 1, 4, 0, 0, 8, 1, 1, 0];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee001"
        ) {
          values = [4, 3, 5, 1, 0, 0, 0, 14, 1, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "INDIA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [0, 1, 0, 1, 8, 5, 0, 5, 1, 1, 0];
        } else if (
          sessionStorage.getItem("country") === "GERMANY" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [0, 1, 0, 0, 6, 7, 6, 10, 1, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "JAPAN" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [0, 1, 0, 0, 3, 4, 7, 2, 1, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "USA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [0, 0, 3, 0, 4, 10, 9, 8, 0, 0, 0];
        } else if (
          sessionStorage.getItem("country") === "CHINA" &&
          sessionStorage.getItem("consignee") === "consignee002"
        ) {
          values = [0, 0, 0, 0, 8, 9, 8, 0, 0, 1, 0];
        }
      }
      setIsFirstDataRender(false);
    }

    console.log(values, legends, "values");
    setMapLeggends(legends);
    setMapSerious(values);
  };
  const originalRows: any = [
    {
      country: "India",
      shipments: 170,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "China",
      shipments: 98,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Italy",
      shipments: 48,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Hamburger",
      shipments: 87,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
    {
      country: "Germany",
      shipments: 15,
      percentage: 6.0,
      avgdelayindays: 24,
      protein: 4.0,
    },
  ];
  const [columnDefs] = useState([
    {
      headerName: "HBL #",
      field: "hblNumber",
      filter: true,
      headerTooltip: "HBL #",
      tooltipField: "hblNumber",
    },
    {
      headerName: "SHIPPER NAME",
      field: "hblNumber",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "hblNumber",
    },
    {
      headerName: "COMMODITY TYPE",
      field: "commodityType",
      filter: true,
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
    },
    {
      headerName: "ITEM NAME",
      field: "itemName",
      filter: true,
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "DESCRIPTION",
      field: "itemDescription",
      filter: true,
      headerTooltip: "DESCRIPTION",
      tooltipField: "itemDescription",
    },
    {
      headerName: "QUANTITY",
      field: "hblNumber",
      filter: true,
      headerTooltip: "QUANTITY",
      tooltipField: "hblNumber",
    },
    {
      headerName: "ETA",
      field: "hblNumber",
      filter: true,
      headerTooltip: "ETA",
      tooltipField: "hblNumber",
    },
    {
      headerName: "CHANGE IN ETA",
      field: "hblNumber",
      filter: true,
      headerTooltip: "CHANGE IN ETA",
      tooltipField: "hblNumber",
    },
    {
      headerName: "DELAY IN DAYS",
      field: "hblNumber",
      filter: true,
      headerTooltip: "DELAY IN DAYS",
      tooltipField: "hblNumber",
    },
    {
      headerName: "DROP OF LOCATION",
      field: "dropoffCountry",
      filter: true,
      headerTooltip: "DROP OF LOCATION",
      tooltipField: "dropoffCountry",
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const requestSearch = (searchedVal: string) => {
    console.log(searchedVal, "searched value");
    const filteredRows = rows.filter((row: any) => {
      console.log(row, "row names");
      return row.countryName.toLowerCase().includes(searchedVal.toLowerCase());
    });

    setRows(filteredRows);
  };
  const handleChanges = (event: SelectChangeEvent) => {
    setIsChartShow(false);
    sessionStorage.setItem("consignee", event.target.value);
    console.log(event.target.value, "target");
    // setOrigin(event.target.value);
    let status = {
      filterOnConsigneeId: event.target.value,
    };
    setMapOrigin(event.target.value);
    graphData(event.target.value, null);
  };
  const handleChanges1 = (event: SelectChangeEvent) => {
    let obj = {
      status: props.pageInfo.tab,
      filterOnConsigneeId: event.target.value,
    };
    sessionStorage.setItem("country", event.target.value);
    setChartPayLoad(obj);
    setIsChartShow(true);
    setMapDestination(event.target.value);
    graphData(null, event.target.value);
    console.log(chartPayLoad, "chart1 payload");
  };
  const dataRefresh = () => {
    setMapDestination(null);
    setMapOrigin(null);
    graphData(null, null);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const handleCallback = (childData: any) => {
    console.log(childData, "childdata");
    setChildData(childData);
    setRowData(childData.carrier);

    props.parentCallback1(true);
    console.log(props, "page info");
    if (props.pageInfo.page === "shipmentDeviation") {
      let obj = {
        status: props.pageInfo.tab,
        filterOnCarrierId: childData.carrierName,
      };
      setReliablityInfo(obj);
    }
  };
  const filtersByCountry = (country: any) => {
    console.log(country, "country");
    setMapDestination(country);
  };
  const filterByCountry = () => {};

  return (
    <div ref={myRef}>
      {!props.data ? (
        <Grid>
          <Box className="title-info">
            {/* {shipmentsCount != undefined ? <Typography>Total Shipments ({shipmentsCount.totalShipments})</Typography> : <Typography>Total Shipments (0)</Typography>} */}
            {props.pageInfo.tab === "between2days" ? (
              <Typography>Total Shipments (442)</Typography>
            ) : props.pageInfo.tab === "betweenWeek" ? (
              <Typography>Total Shipments (394)</Typography>
            ) : props.pageInfo.tab === "betweenMonth" ? (
              <Typography>Total Shipments (362)</Typography>
            ) : (
              <Typography>Total Shipments (258)</Typography>
            )}
          </Box>
          <table className="common_table">
            <tr className="common-subRows">
              <th>Empty PickUp</th>
              <th>Gate In</th>
              <th>Gate Out (Pre-Carriage)</th>
              <th>Export Clearance</th>
              <th>Vessel Load</th>
              <th>Vessel Movement</th>
              <th>Vessel Discharge</th>
              <th>Import Clearance</th>
              <th>Gate Out (On-Carriage)</th>
              <th>At Warehouse</th>
              <th>Out For Delivery</th>
            </tr>
            {props.pageInfo.tab === "between2days" ? (
              shipmentsCount != undefined ? (
                <tr className="common-subRows common-subData">
                  <td>{dummyResp.data.summary.emptyPickUp}</td>
                  <td>{dummyResp.data.summary.gateIn}</td>
                  <td>{dummyResp.data.summary.gateOut}</td>
                  <td>{dummyResp.data.summary.exportclearance}</td>
                  <td>{dummyResp.data.summary.vesselLoad}</td>
                  <td>{dummyResp.data.summary.vesselMovement}</td>
                  <td>{dummyResp.data.summary.vesselDischarge}</td>
                  <td>{dummyResp.data.summary.importClearance}</td>
                  <td>{dummyResp.data.summary.gateOut}</td>
                  <td>{dummyResp.data.summary.atWareHouse}</td>
                  <td>{dummyResp.data.summary.outForDelivery}</td>
                  {/* 
                                    <td>{shipmentsCount.data.summary.emptyPickUp}</td>
                                    <td>{shipmentsCount.data.summary.gateIn}</td>
                                    <td>{shipmentsCount.data.summary.gateOut}</td>
                                    <td>{shipmentsCount.data.summary.exportclearance}</td>
                                    <td>{shipmentsCount.data.summary.vesselLoad}</td>
                                    <td>{shipmentsCount.data.summary.vesselMovement}</td>
                                    <td>{shipmentsCount.data.summary.vesselDischarge}</td>
                                    <td>{shipmentsCount.data.summary.importClearance}</td>
                                    <td>{shipmentsCount.data.summary.gateOut}</td>
                                    <td>{shipmentsCount.data.summary.atWareHouse}</td>
                                    <td>{shipmentsCount.data.summary.outForDelivery}</td> */}
                </tr>
              ) : (
                ""
              )
            ) : props.pageInfo.tab === "betweenWeek" ? (
              shipmentsCount != undefined ? (
                <tr className="common-subRows common-subData">
                  <td>{dummyResp.data.summary.emptyPickUp}</td>
                  <td>{dummyResp.data.summary.gateIn}</td>
                  <td>{dummyResp.data.summary.gateOut}</td>
                  <td>{dummyResp.data.summary.exportclearance}</td>
                  <td>{dummyResp.data.summary.vesselLoad}</td>
                  <td>{dummyResp.data.summary.vesselMovement}</td>
                  <td>{dummyResp.data.summary.vesselDischarge}</td>
                  <td>{dummyResp.data.summary.importClearance}</td>
                  <td>{dummyResp.data.summary.gateOutOnCarriage}</td>
                  <td>{dummyResp.data.summary.atWareHouse}</td>
                  <td>{dummyResp.data.summary.outForDelivery}</td>
                  {/* 
                                        <td>{shipmentsCount.data.summary.emptyPickUp}</td>
                                        <td>{shipmentsCount.data.summary.gateIn}</td>
                                        <td>{shipmentsCount.data.summary.gateOut}</td>
                                        <td>{shipmentsCount.data.summary.exportclearance}</td>
                                        <td>{shipmentsCount.data.summary.vesselLoad}</td>
                                        <td>{shipmentsCount.data.summary.vesselMovement}</td>
                                        <td>{shipmentsCount.data.summary.vesselDischarge}</td>
                                        <td>{shipmentsCount.data.summary.importClearance}</td>
                                        <td>{shipmentsCount.data.summary.gateOut}</td>
                                        <td>{shipmentsCount.data.summary.atWareHouse}</td>
                                        <td>{shipmentsCount.data.summary.outForDelivery}</td> */}
                </tr>
              ) : (
                ""
              )
            ) : props.pageInfo.tab === "betweenMonth" ? (
              shipmentsCount != undefined ? (
                <tr className="common-subRows common-subData">
                  <td>{dummyResp.data.summary.emptyPickUp}</td>
                  <td>{dummyResp.data.summary.gateIn}</td>
                  <td>{dummyResp.data.summary.gateOut}</td>
                  <td>{dummyResp.data.summary.exportclearance}</td>
                  <td>{dummyResp.data.summary.vesselLoad}</td>
                  <td>{dummyResp.data.summary.vesselMovement}</td>
                  <td>{dummyResp.data.summary.vesselDischarge}</td>
                  <td>{dummyResp.data.summary.importClearance}</td>
                  <td>{dummyResp.data.summary.gateOutOnCarriage}</td>
                  <td>{dummyResp.data.summary.atWareHouse}</td>
                  <td>{dummyResp.data.summary.outForDelivery}</td>
                  {/* 
                                            <td>{shipmentsCount.data.summary.emptyPickUp}</td>
                                            <td>{shipmentsCount.data.summary.gateIn}</td>
                                            <td>{shipmentsCount.data.summary.gateOut}</td>
                                            <td>{shipmentsCount.data.summary.exportclearance}</td>
                                            <td>{shipmentsCount.data.summary.vesselLoad}</td>
                                            <td>{shipmentsCount.data.summary.vesselMovement}</td>
                                            <td>{shipmentsCount.data.summary.vesselDischarge}</td>
                                            <td>{shipmentsCount.data.summary.importClearance}</td>
                                            <td>{shipmentsCount.data.summary.gateOut}</td>
                                            <td>{shipmentsCount.data.summary.atWareHouse}</td>
                                            <td>{shipmentsCount.data.summary.outForDelivery}</td> */}
                </tr>
              ) : (
                ""
              )
            ) : shipmentsCount != undefined ? (
              <tr className="common-subRows common-subData">
                <td>{dummyResp.data.summary.emptyPickUp}</td>
                <td>{dummyResp.data.summary.gateIn}</td>
                <td>{dummyResp.data.summary.gateOut}</td>
                <td>{dummyResp.data.summary.exportclearance}</td>
                <td>{dummyResp.data.summary.vesselLoad}</td>
                <td>{dummyResp.data.summary.vesselMovement}</td>
                <td>{dummyResp.data.summary.vesselDischarge}</td>
                <td>{dummyResp.data.summary.importClearance}</td>
                <td>{dummyResp.data.summary.gateOutOnCarriage}</td>
                <td>{dummyResp.data.summary.atWareHouse}</td>
                <td>{dummyResp.data.summary.outForDelivery}</td>
                {/* 
                                            <td>{shipmentsCount.data.summary.emptyPickUp}</td>
                                            <td>{shipmentsCount.data.summary.gateIn}</td>
                                            <td>{shipmentsCount.data.summary.gateOut}</td>
                                            <td>{shipmentsCount.data.summary.exportclearance}</td>
                                            <td>{shipmentsCount.data.summary.vesselLoad}</td>
                                            <td>{shipmentsCount.data.summary.vesselMovement}</td>
                                            <td>{shipmentsCount.data.summary.vesselDischarge}</td>
                                            <td>{shipmentsCount.data.summary.importClearance}</td>
                                            <td>{shipmentsCount.data.summary.gateOut}</td>
                                            <td>{shipmentsCount.data.summary.atWareHouse}</td>
                                            <td>{shipmentsCount.data.summary.outForDelivery}</td> */}
              </tr>
            ) : (
              ""
            )}
          </table>
        </Grid>
      ) : (
        ""
      )}
      <Grid container spacing={2} sx={{ mt: 0, display: "flex" }}>
        <Grid item xs={4}>
          <Typography variant="h6" sx={{ fontSize: "1rem" }}>
            {sessionStorage.getItem("role") != "consignee"
              ? "SHIPMENTS BY DESTINATION COUNTRY"
              : "SHIPMENTS BY ORIGIN COUNTRY"}
          </Typography>
          {isDataShow ? (
            <div>
              <Box sx={{ mt: 2 }}>
                <SearchBar
                  value={searched}
                  placeholder="Search By Country"
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
              </Box>

              <Paper sx={{ mt: 2 }}>
                <div>
                  {countryEvents.map((item: any, index: any) => {
                    return (
                      <Accordion
                        expanded={expanded === "panel" + index}
                        onChange={handleChange("panel" + index)}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography
                            className="tableCellData"
                            onClick={() => filtersByCountry(item.country)}
                          >
                            {item.country}
                          </Typography>
                          &nbsp;&nbsp;<span>({item.Percentage})</span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: 440, mt: 1 }}>
                            <Table aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell key={column.id}>
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              {item.events ? (
                                <TableBody>
                                  {item.events
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row: any) => {
                                      return (
                                        <TableRow
                                          hover
                                          role="checkbox"
                                          tabIndex={-1}
                                          key={row.code}
                                        >
                                          {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                              <TableCell
                                                key={column.id}
                                                style={{ padding: "10px" }}
                                              >
                                                {column.label === "Country" ? (
                                                  <a
                                                    className="tableCellData"
                                                    onClick={() =>
                                                      filterByCountry()
                                                    }
                                                  >
                                                    {value}
                                                  </a>
                                                ) : (
                                                  value
                                                )}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              ) : (
                                ""
                              )}
                            </Table>
                          </TableContainer>
                          {/* <TablePagination
                                                    rowsPerPageOptions={[15, 25, 100]}
                                                    component="div"
                                                    count={countryEvents.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                /> */}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </Paper>
            </div>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
              SHIPMENTS BY EVENTS TYPE
            </Typography>
            <IconButton sx={{ top: "-8px" }}>
              <CachedIcon onClick={dataRefresh} />
            </IconButton>
          </Box>
          <Grid container spacing={2} sx={{ display: "flex" }}>
            <Grid item xs={6}>
              {/* <FormControl fullWidth size="small">
                <InputLabel htmlFor="grouped-native-select">
                  Consignee
                </InputLabel>
                <Select
                  native
                  defaultValue="hi"
                  id="grouped-native-select"
                  MenuProps={{ disableScrollLock: true }}
                  label="Consignee"
                  onChange={handleChanges}
                >
                  <option aria-label="None" value="0">
                    Select
                  </option>
                  {shipmentsByOrigin.map((role: any) => {
                    return <option value={role}>{role}</option>;
                  })}
                </Select>
              </FormControl> */}
              <FormControl fullWidth size="small">
                <Typography>Consignee</Typography>
                <select
                  className="custom_select"
                  placeholder="Select Consignee"
                  onChange={(e: any) => handleChanges(e)}
                >
                  <option value="">Select Consignee</option>
                  {shipmentsByOrigin.map((role: any) => {
                    return <option value={role}>{role}</option>;
                  })}
                </select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {/* <FormControl fullWidth size="small">
                                <InputLabel htmlFor="grouped-native-select">Destination</InputLabel>
                                <Select native defaultValue="" id="grouped-native-select" label="Grouping" placeholder='Select Destination'
                                MenuProps={{disableScrollLock:true}}
                                 onChange={handleChanges1}>
                                    <option aria-label="None" value="0">Select</option>
                                    {countryEvents.map((item: any) => {
                                        return (
                                            <option value={item.country}>{item.country}</option>
                                        )
                                    })}
                                </Select>
                            </FormControl> */}
              <FormControl fullWidth size="small">
                <Typography>Destination</Typography>
                <select
                  className="custom_select"
                  placeholder="Select Destination"
                  onChange={(e: any) => handleChanges1(e)}
                >
                  <option value="">Select Destination</option>
                  {countryEvents.map((item: any) => {
                    return <option value={item.country}>{item.country}</option>;
                  })}
                </select>
              </FormControl>
            </Grid>
          </Grid>
          {mapSerious.length > 0 && mapleggends.length > 0 ? (
            <Paper sx={{ mt: 1 }}>
              <DeviationCharts
                parentCallback={handleCallback}
                data={chartPayLoad}
                mapInfo={{ xAxisData: mapleggends, yAxisData: mapSerious }}
                page={props.pageInfo.page}
                tab={props.pageInfo.tab}
                dataRender={isFirstDataReder}
              />
            </Paper>
          ) : (
            <Alert severity="info" sx={{ mt: 2 }}>
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
          {/* <Paper sx={{ mt: 1 }}>
                        {chartPayLoad ?
                            <Echarts parentCallback={handleCallback} data={chartPayLoad} page={props.pageInfo.page} /> : ''}
                    </Paper> */}
        </Grid>
      </Grid>
      {props.pageInfo.page === "shipmentDeviation" ? (
        <Box sx={{ mt: 3 }}>
          {reliablityInfo ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontSize: "1rem",
              }}
            >
              RELIABILITY TRENDS
            </Typography>
          ) : (
            ""
          )}
        </Box>
      ) : (
        " "
      )}
      {props.pageInfo.page === "shipmentDeviation" ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            {reliablityInfo ? (
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                }}
              >
                OVER ALL
              </Typography>
            ) : (
              ""
            )}
            {reliablityInfo ? (
              <DonutEcharts
                data={"overall"}
                parentCallback={handleCallback}
                data1={reliablityInfo}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
            {reliablityInfo ? (
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                }}
              >
                PICK UP
              </Typography>
            ) : (
              ""
            )}
            {reliablityInfo ? (
              <DonutEcharts
                data={"pickUp"}
                parentCallback={handleCallback}
                data1={reliablityInfo}
              />
            ) : (
              ""
            )}
          </Grid>
          {/* <Grid item xs={4}>
                        <Typography variant='h6' sx={{
                            textAlign: 'center',
                        }}>
                            DROP OFF
                        </Typography>
                        <DonutEcharts data={'dropOff'} parentCallback={handleCallback} />
                    </Grid> */}
        </Grid>
      ) : (
        ""
      )}

      <Grid sx={{ mt: 3 }} id="moreInfo">
        {childData ? (
          <Box>
            <Box>
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                SHIPMENTS
              </Typography>
            </Box>
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              rowSelection={"multiple"}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={"autoHeight"}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
            ></AgGridReact>
          </Box>
        ) : (
          ""
        )}
      </Grid>

      {/* <a href="#" id="roll_back" className="animate">
                <KeyboardArrowUpIcon />
            </a> */}
    </div>
  );
}
