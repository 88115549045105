import React, { useState, useEffect, useContext } from "react";
// import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Box } from "@mui/system";
import * as Yup from "yup";
import Paper from "@mui/material/Paper";
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Form, Formik, useFormik } from "formik";
import "../Layout/Login.css";
import { useLocation, useNavigate } from "react-router-dom";
import { DesgService } from "../Services/DesgService";
import { Endpoints } from "../Enviornment/Endpoints";

import LoadingButton from "@mui/lab/LoadingButton";
import { toast, ToastContainer } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
interface Values {
  userName: string;
  pwd: string;
}
const schemavalidation = Yup.object().shape({
  userName: Yup.string()
    .required("UserName is required")
    .min(3, "UserName Should Greater Than 3 Characters"),
  // .max(10, "UserName Should Less Than 10 Characters"),
  pwd: Yup.string()
    .required("Password is required")
    .min(4, "Password Should Greater Than 3 Characters")
    .max(10, "Password Should Less Than 10 Characters"),
});

function Login(props: any) {
  const [loader, setLoader] = useState(false);
  const [Role, setRole] = React.useState("");
  const [showpwd, SetShowpwd] = useState(false);
  const [isSuccess, SetIsSuccess] = useState(false);
  const navigate = useNavigate();
  const desgService = new DesgService();
  const [desg, setDesg] = useState<any>([]);
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      navigate("/");
    }
    console.log("renderssss");
    desgService.getDesignation().then((res) => {
      setDesg(res.data.primary);
    });
  }, []);

  const handleChanges = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    setRole(event.target.value);
  };
  const handlepwd = () => SetShowpwd(!showpwd);
  const handleSubmit = async (values: any) => {
    setLoader(true);
    const payload = {
      ...values,
    };

    setTimeout(() => {
      console.log(JSON.stringify(payload, null, 2));
    }, 1000);
    const body = {
      username: values.userName,
      password: values.pwd,
      grant_type: "password",
      client_id: Endpoints.CLIENT_ID,
      redirect_uri: Endpoints.URL,
    };
    desgService
      .login(body)
      .then(async (res: any) => {
        if (res.data.access_token) {
          sessionStorage.setItem("token", res.data.access_token);
          let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${res.data.access_token}`,
          };
          desgService.getLoggedInfo(Role, headers).then((res: any) => {
            if (res.data.status.code === "SUCCESS") {
              if (res.data.primary[0].activeStatus === "active") {
                console.log(res, "options");

                sessionStorage.setItem("role", res.data.primary[0].partyType);
                navigate("/");
              } else if (res.data.status.code === "error") {
                SetIsSuccess(true);
                console.log("error");
                toast.error(res.data.status.message);
                sessionStorage.clear();
                setLoader(false);
              }
            } else if (res.data.status.code === "error") {
              SetIsSuccess(true);
              console.log("error");
              toast.error(res.data.status.message);
              sessionStorage.clear();
              setLoader(false);
            }
          });
        }
      })
      .catch((e: any) => {
        if (e.response.status === 401) {
          setLoader(false);
          toast.error(e.response.data.error_description);
          console.log(e.response);
        }
      });
  };
  return (
    <Box className="app appbg" sx={{ flexGrow: 1, overflow: "auto" }}>
      <Grid container className="app2">
        <Grid item xs={8}>
          <Paper
            elevation={0}
            sx={{
              background: "transparent",
              display: "flex",
              paddingTop: 2,
              paddingLeft: 5,
            }}
          >
            <img
              src={require("../asserts/end_to_end.png")}
              className="images1"
              alt="real variable logo"
            />
          </Paper>
        </Grid>
        <Grid
          item
          xs={7}
          className="boxer"
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        >
          <Paper
            elevation={0}
            sx={{
              background: "transparent",
              width: "100%",
              height: "450px",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../asserts/SupplyChainImage.png")}
              className="images"
              alt="heroNode"
            />
            
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={4}
          sx={{
            display: "contents",
            justifyContent: "center",
            width: "100%",
            marginTop: -3,
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              width: "360px",
              height: "500px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                bgcolor: "white",
                width: "320px",
                height: "500px",
              }}
            >
              <Box sx={{ paddingTop: "20px" }}>
                <h4 className="header">Please Login To Continue</h4>
              </Box>
              <Box>
                <Formik
                  initialValues={{
                    userName: "",
                    pwd: "",
                    Role: "",
                  }}
                  validationSchema={schemavalidation}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, values, handleChange }) => (
                    <Form>
                      <FormControl
                        fullWidth
                        sx={{ marginTop: 5, marginBottom: 2 }}
                      >
                        <InputLabel id="select-Role">Role</InputLabel>
                        <Select
                          labelId="select-Role"
                          id="component-outlined"
                          value={Role}
                          label="Role"
                          placeholder="Select"
                          MenuProps={{disableScrollLock:true}}
                          onChange={handleChanges}
                        >
                          {desg.length
                            ? desg.map((value: any, index: any) => (
                              <MenuItem
                                value={value.designationName}
                                key="index"
                              >
                                {value.designationName}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                      <TextField
                        name="userName"
                        value={values.userName}
                        onChange={handleChange}
                        id="component-outlined"
                        label="UserName"
                        placeholder="UserName"
                        type="text"
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                        }}
                        error={
                          Boolean(touched.userName) && Boolean(errors.userName)
                        }
                        helperText={
                          Boolean(touched.userName) && errors.userName
                        }
                      />
                      <TextField
                        name="pwd"
                        value={values.pwd}
                        onChange={handleChange}
                        error={Boolean(touched.pwd) && Boolean(errors.pwd)}
                        helperText={Boolean(touched.pwd) && errors.pwd}
                        id="component-outlined"
                        label="Password"
                        type={showpwd ? "text" : "password"}
                        placeholder="Password"
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password Visibility"
                                onClick={handlepwd}
                              >
                                {showpwd ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <LoadingButton
                        loading={loader}
                        variant="contained"
                        type="submit"
                        sx={{
                          width: "100%",
                          p: 1.2,
                          mt: 3,
                          marginBottom: 5,
                          fontWeight: 500,
                        }}
                        className="submitbtn"
                      >
                        LOGIN
                      </LoadingButton>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography className="link">
                          <a className="forgot_pw">Forgot Your Password?</a>
                        </Typography>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
}

export default Login;
