import { colors } from "@material-ui/core"

const colorConfigs ={
    sidebar:{
        bg:'#0b51b7',
        color:'#fff',
        hoverBg:'#1e293a',
        activeBg:'#1e253a',
        zIndex: 9000
    },
    topbar:{
            bg: '#fff',
            color:'#000',

    },
    dashboard:{
        color: '#fff',
        bgColor:['#321fdb','#39f','#f9b115','#e55353','#f9b115','#e55353'],
        bgImage:['linear-gradient(45deg,var(--cui-primary-start,#321fdb) 0,var(--cui-primary-stop,#1f1498 100%))',
        'linear-gradient(45deg,var(--cui-info-start,#39f) 0,var(--cui-info-stop,#2982cc 100%))',
        'linear-gradient(45deg,var(--cui-warning-start,#f9b115) 0,var(--cui-warning-stop,#f6960b 100%))',
        'linear-gradient(45deg,var(--cui-danger-start,#e55353) 0,var(--cui-danger-stop,#d93737 100%))',
        'linear-gradient(45deg, rgba(12,207,57,1) 0%, rgba(4,164,9,0.9528186274509804) 13%)',
        'linear-gradient(45deg, rgba(176,166,75,0.8743872549019608) 27%, rgba(252,225,64,1) 99%)']

    },
    pageTitle: {
        color:'rgb(39, 26, 120)',
        borderBottom: '1px solid rgba(0,0,21,.175)',
        bgColor: 'rgba(0,0,21,.03)'
    },
    fulldialog: {
            bg:'#f5f4f7',

    },
    etaDeviations:{
            color:['#e8ad61','#e39253','#e2784e','#db5844','#ff0329']
    },
    mainBg: '#fff'
}
export default colorConfigs