import { Alert, AlertTitle, AppBar, Box, Button, Dialog, Slide, Stepper, Toolbar } from '@mui/material';
import React, { useRef, useState } from 'react'

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DetailsInfoDeviation from './Deviations/DetailsInfoDeviation';
import CommonETADeviationInfo from './CommonETADeviationInfo';
import CommonDeliveredShipmentsInfo from './CommonDeliveredShipmentsInfo';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DashboardDeviation(props: any) {
    const [value, setValue] = React.useState("2");
    const [height, setHeight] = useState({ scrollTop: 0 })
    const [status, setStatus] = useState('')
    const myRef = useRef<any>();
    const [childData, setChildData] = useState(false)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === '1') {
            setStatus('deliveredOnTime')
        } else if (newValue === '2') {
            setStatus('deliveredBetween2days')
        } else if (newValue === '3') {
            setStatus('deliveredBetweenWeek')
        } else if (newValue === '4') {
            setStatus('deliveredBetweenMonth')
        } else if (newValue === '5') {
            setStatus('deliveredGreaterThanMonth')
        }
    };
    const onScroll = () => {
        console.log("scroll",window.document.getElementById('moreInfo'))
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = myRef.current.scrollTop
        console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`)
        setHeight({
            scrollTop: window.pageYOffset
        })
        if (scrollTop > 80) {
            let a = document.getElementById('roll_back') as HTMLElement
            a.style.display = 'flex'
            return a;
        } else {
            let a = document.getElementById('roll_back') as HTMLElement
            a.style.display = 'none'

            return a;
        }
    }
    const handleCallback1 = (childData: any) => {
        console.log(childData, "child data")
        setChildData(childData)

        document.getElementById('moreInfo')?.scrollIntoView({behavior:'smooth'})
    }
    const scrollUp = () => {
        myRef.current.scrollTop = 0
    }
    return (
        <div className="dialog">
            <Dialog
                fullScreen
                open={props.onOpen}
                onClose={props.onClose}
                TransitionComponent={Transition}
                sx={{
                    "& .MuiDialog-root": {},
                    zIndex: 10000,
                }}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' component="div" sx={{ ml: 2, flex: 1 }}>
                            Delivered Shipments - Summary
                        </Typography>

                        <Button autoFocus color="inherit" onClick={props.onClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <div ref={myRef}
                    style={{ overflowY: 'scroll' }}
                    // onScroll={() => onScroll()}
                    >
                    <Box sx={{ width: "100%", typography: "body1", p: 3 }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList
                                    onChange={handleChange}
                                    aria-label="lab API tabs example"
                                >
                                    {/* <Tab label="On Time" value="1" /> */}
                                    <Tab label="< 2 Days " value="2" />
                                    <Tab label="> 2 Days to < 1 Week" value="3" />
                                    <Tab label="> 1 Week to < 1 Month" value="4" />
                                    <Tab label="> 1 Month" value="5" />
                                    <Tab label="Reliability Trends" value="6" />
                                </TabList>
                            </Box>
                            {/* <TabPanel value="1" sx={{ padding: '24px 0px' }}>
                                <CommonDeliveredShipmentsInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'deliveredOnTime'}} parentCallback1={handleCallback1}/>
                            </TabPanel> */}
                            <TabPanel value="2" sx={{ padding: '24px 0px' }}>
                            <CommonDeliveredShipmentsInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'deliveredBetween2days'}} parentCallback1={handleCallback1}/>
                                {/* <CommonETADeviationInfo data={'true'}  pageInfo={{page:'shipmentDeviation',tab:'deliveredBetween2days'}} parentCallback1={handleCallback1} /> */}
                            </TabPanel>
                            <TabPanel value="3" sx={{ padding: '24px 0px' }}>
                            <CommonDeliveredShipmentsInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'deliveredBetweenWeek'}} parentCallback1={handleCallback1}/>
                                {/* <CommonETADeviationInfo data={'true'}  pageInfo={{page:'shipmentDeviation',tab:'deliveredBetweenWeek'}} parentCallback1={handleCallback1} /> */}
                            </TabPanel>
                            <TabPanel value="4" sx={{ padding: '24px 0px' }}>
                            <CommonDeliveredShipmentsInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'deliveredBetweenMonth'}} parentCallback1={handleCallback1}/>
                                {/* <CommonETADeviationInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'deliveredBetweenMonth'}} parentCallback1={handleCallback1} /> */}
                            </TabPanel>
                            <TabPanel value="5" sx={{ padding: '24px 0px' }}>
                            <CommonDeliveredShipmentsInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'deliveredGreaterThanMonth'}} parentCallback1={handleCallback1}/>
                                {/* <CommonETADeviationInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'deliveredGreaterThanMonth'}} parentCallback1={handleCallback1} /> */}
                            </TabPanel>
                            <TabPanel value="6" sx={{ padding: '24px 0px' }}>
                                
                            <CommonDeliveredShipmentsInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'reliability'}} parentCallback1={handleCallback1}/>
                                {/* <CommonETADeviationInfo data={'true'} pageInfo={{page:'shipmentDeviation',tab:'deliveredGreaterThanMonth'}} parentCallback1={handleCallback1} /> */}
                            </TabPanel>
                        </TabContext>
                    </Box>
                    <Button  id="roll_back" className="animate" onClick={scrollUp}>
                        <KeyboardArrowUpIcon />
                    </Button>
                </div>
                
            </Dialog>
        </div>
    )
}
export default DashboardDeviation;
