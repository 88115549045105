import axios from "axios";
import { Endpoints } from "../Enviornment/Endpoints";
import { Environment_Realtraceb_Dev } from "../Enviornment/Environment_Realtraceb_Dev";
export class DesgService {
  // ---------------- Designation List ----------------
  getDesignation = () => {
    return axios.get(
      " https://api.ext.realtrace.realware.tech/master/designationList"
    );
  };
  // ------------------- Token Api ---------------------------
  login = (data: any) => {
    let formBody: any = [];
    for (let property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const basicHeaders = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const options = { headers: basicHeaders };
    const refreshTokenUrl = Endpoints.REFRESH_TOKEN;
    return axios.post(refreshTokenUrl, formBody, options);
  };
  // --------------------- Get Logged Info ----------------------
  getLoggedInfo = (desig: any, headers: any) => {
    let url = Endpoints.GET_USER_INFO;
    let config = {
      headers: headers,
      params: {
        designation: desig,
      },
    };
    return axios.get(url, config);
  };
  // -------------------- Po ACKNOWLEDGEMENT List ---------------------
  getPoAcknowledgementList = (data: any, headers: any) => {
    let url = Environment_Realtraceb_Dev.BASE_URL + "/purchaseOrderList";
    let config = {
      headers: headers,
      params: data,
    };
    return axios.get(url, config);
  };
  getPoOrderItemsList = (data: any, headers: any) => {
    let url = Environment_Realtraceb_Dev.BASE_URL + "/purchaseOrderItemList";
    let config = {
      headers: headers,
      params: data,
    };
    return axios.get(url, config);
  };

  getretrunOrderItemsList = (data: any, headers: any) => {
    let url = Environment_Realtraceb_Dev.BASE_URL + "/returnOrderItemList";
    let config = {
      headers: headers,
      params: data,
    };
    return axios.get(url, config);
  };
  getDashboardInfo = (headers: any, options: any) => {
    let url =
      "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/dashboardCounts";
    let config = {
      headers: headers,
      params: options,
    };
    return axios.get(url, config);
  };
}

// export default DesgService;
