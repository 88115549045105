import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";

import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import CrLineItems from "./CarrierLineItems";

import LegInfoVisual from "./carrierLeginfoVisual";
import { useNavigate, useOutletContext } from "react-router-dom";
import Full from "../../../Helpers/full";
import "../../../Styles.css";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import swal from "sweetalert";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function DeliveredInTransitShipments() {
  const nav = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [EventData, setEventData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [transitData, setTransitData] = useState<any>([]);
  const [openLineItems, setOpenLineItems] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad] = useOutletContext<any>();

  const handleData = (params: any) => {
    console.log("Event Screen Console....", params.data);
    setTransitData(params.data);
    setLineData(params.data);

    getEventData(
      params.data.containerNumber,
      params.data.hscode,
      params.data.invoiceNumber
    );
  };
  const handleLegData = (params: any) => {
    console.log("Event Screen Leg Information....", params.data.legInfo);
    setLegData(params.data.legInfo);
    setLineData(params.data);
    if (params.data.legInfo === undefined) {
      swal("info", "No Leg Information", "info");
    } else {
      setLegOpen(true);
    }
  };
  //UseReference Hook To Access Grid Api
  const gridRef = useRef<any>();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  };

  const getEventData = (data: any, hs: any, inv: any) => {
    axios
      .post(
        "https://api.ext.realtrace.realware.tech/po/eventQuery",
        {
          containerNumber: data,
          hscode: hs,
          invoiceNumber: inv,
        },
        { headers }
      )
      .then((evedata) => {
        if (evedata.data.primary.length > 0) {
          console.log("Event Data.....", evedata);
          setEventData(evedata);
          setOpenDetail(true);
          console.log("EVENTS  DATA.....");
        } else {
          // swal("Events", "No Event Data", "error");
        }
      });
  };
  const handlemap = () => {
    setNodata(false);
  };
  const handleLine = (params: any) => {
    setLineData(params.data);
    setOpenLineItems(true);
  };

  const [columnDefs] = useState([
    {
      headerName: "HBL NUMBER",
      field: "hblNumber",
      filter: true,
      headerTooltip: "HBL NUMBER",
      // tooltipField: "hblNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Tooltip title={"Click Here To View Events"} placement="bottom-start">
            <a
              style={{
                height: 20,
                width: "100%",
                lineHeight: 0.5,
                textAlign: "left",
                color: "blue",
              }}
              onClick={() => handleData(params)}
              className="btn btn-info"
            >
              {params.value}
            </a>
          </Tooltip>
        );
      },
    },
    {
      headerName: "INVOICE NUMBER",
      field: "invoiceNumber",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            onClick={() => handleLine(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "LEG INFORMATION",
      field: "legInfo",
      filter: true,
      headerTooltip: "LEG INFORMATION",
      tooltipField: "leginformatin",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            onClick={() => handleLegData(params)}
            className="btn btn-info"
          >
            LegInfo
          </a>
        );
      },
    },
    {
      headerName: "CONTAINER NUMBER",
      field: "containerNumber",
      filter: true,
      headerTooltip: "CONTAINER NUMBER",
      tooltipField: "containerNumber",
    },
    {
      headerName: "HS CODE",
      field: "hscode",
      filter: true,
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "BOOKING REFERENCE DATE",
      field: "invoiceDate",
      filter: true,
      headerTooltip: "BOOKING REFERENCE DATE",
      tooltipField: "invoiceDate",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CONTAINER TYPE",
      filter: true,
      headerTooltip: "CONTAINER TYPE",
      tooltipField: "containerType",
      field: "containerType",
      columnGroupShow: "closed",
    },

    {
      headerName: "CONTAINER PICKUP DEPOT",
      filter: true,
      headerTooltip: "CONTAINER PICKUP DEPOT",
      tooltipField: "containerType",
      field: "containerType",
      columnGroupShow: "closed",
    },
    {
      headerName: "CONTAINER SIZE",
      filter: true,
      headerTooltip: "CONTAINER SIZE",
      tooltipField: "containerSize",
      field: "containerSize",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CONTAINER TARE WEIGHT",
      filter: true,
      headerTooltip: "CONTAINER TARE WEIGHT",
      tooltipField: "containerTareWeight",
      field: "containerTareWeight",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CONTAINER PICKUP DEPOT",
      filter: true,
      headerTooltip: "CONTAINER PICKUP DEPOT",
      tooltipField: "containerPickUpDepot",
      field: "containerPickUpDepot",
      columnGroupShow: "closed",
    },
    // {
    //     headerName: "INVOICE DATE",
    //     field: "invoiceDate",
    //     filter: true,
    //     headerTooltip: "INVOICE DATE",
    //     tooltipField: "invoiceDate",
    //   },

    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      filter: "true",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
    },

    {
      headerName: "CARRIER REFERENCE",
      field: "carrierReference",
      filter: true,
      headerTooltip: "CARRIER REFERENCE",
      tooltipField: "carrierReference",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CARRIER ID",
      field: "carrierId",
      filter: true,
      headerTooltip: "CARRIER ID",
      tooltipField: "carrierId",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CARRIER NAME",
      field: "carrierName",
      filter: true,
      headerTooltip: "CARRIER NAME",
      tooltipField: "carrierName",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "PICK UP LOCATION",
      field: "containerPickUpDepot",
      filter: true,
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "containerPickUpDepot",
    },

    {
      headerName: "PICKUP DATE",
      filter: true,
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickUpDate",
      field: "pickUpDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "SHIPMENT VOLUME",
      filter: true,
      headerTooltip: "SHIPMENT VOLUME",
      field: "shipmentVolume",
      tooltipField: "shipmentVolume",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "SHIPMENT WEIGHT",
      filter: true,
      headerTooltip: "SHIPMENT WEIGHT",
      field: "shipmentWeight",
      tooltipField: "shipmentWeight",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "FORWARDER ID",
      filter: true,
      headerTooltip: "FORWARDER ID",
      tooltipField: "forwarderId",
      field: "forwarderId",
    },
    {
      headerName: "FORWARDER NAME",
      filter: true,
      headerTooltip: "FORWARDER NAME",
      tooltipField: "forwarderName",
      field: "forwarderName",
      columnGroupShow: "closed",
    },
    {
      headerName: "WEIGHT UOM",
      field: "weightUom",
      filter: true,
      headerTooltip: "WEIGHT UOM",
      tooltipField: "weightUom",
    },
    {
      headerName: "BOOKING REFERENCE NUMBER",
      filter: true,
      headerTooltip: "BOOKING REFERENCE NUMBER",
      tooltipField: "bookingReference",
      field: "bookingReference",
    },
    {
      headerName: "MBL ID",
      field: "mblNumber",
      filter: true,
      headerTooltip: "MBL ID",
      tooltipField: "mblNumber",
    },

    {
      headerName: "CONSIGNEE ID",
      filter: true,
      headerTooltip: "CONSIGNEE ID",
      field: "consigneeId",
      tooltipField: "consigneeId",
    },
    {
      headerName: "CONSIGNEE NAME",
      filter: true,
      headerTooltip: "CONSIGNEE NAME",
      field: "consigneeName",
      tooltipField: "consigneeName",
      columnGroupShow: "closed",
    },
    {
      headerName: "ETA",
      filter: true,
      headerTooltip: "ETA",
      tooltipField: "eta",
      field: "eta",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "ETD",
      filter: true,
      headerTooltip: "ETD",
      tooltipField: "etd",
      field: "etd",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "SHIPMENT ID",
      filter: true,
      headerTooltip: "SHIPMENT ID",
      tooltipField: "shipperId",
      field: "shipperId",
    },
    {
      headerName: "SHIPMENT NAME",
      filter: true,
      headerTooltip: "SHIPMENT NAME",
      tooltipField: "shipperName",
      field: "shipperName",
      columnGroupShow: "closed",
    },
    {
      headerName: "SHIPMENT DATE",
      filter: true,
      headerTooltip: "SHIPMENT DATE",
      tooltipField: "shipmentDate",
      field: "shipmentDate",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "SHIPMENT VOLUME",
      filter: true,
      headerTooltip: "SHIPMENT VOLUME",
      tooltipField: "shipmentVolume",
      field: "shipmentVolume",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "SHIPMENT WEIGHT",
      filter: true,
      headerTooltip: "SHIPMENT WEIGHT",
      tooltipField: "shipmentWeight",
      field: "shipmentWeight",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "MODE OF SHIPMENT",
      filter: true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeOfShipment",
      field: "modeOfShipment",
      columnGroupShow: "closed",
    },

    {
      headerName: "UPLOAD DOCS",
      filter: true,
      headerTooltip: "UPLOAD DOCS",
      tooltipField: "upload docs",
      field: "tranship",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button onClick={() => nav("/uploadDocumnets")}>Upload Doc</Button>
        );
      },
    },
  ]);

  //

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const url =
    "https://api.ext.realtrace.realware.tech/po/intransitListForDelivery";

  const [role, setRole] = useState(sessionStorage.getItem("role"));

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      type: role,
      status: "delivered",
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  useEffect(() => {
    setLoad(true);
  }, []);

  const getTransitList = () => {
    axios.get(url, config).then((res) => {
      if (res.data.primary.length === 0) {
        setLoad(false);
        setShowGrid(false);
      }
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
      // params.columnApi.autoSizeAllColumns(false);
    });
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    getTransitList();
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  // const headers = {
  //   Accept: "application/json",
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  // };

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              paginationPageSize={10}
              pagination={true}
              ref={gridRef}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
              rowSelection="multiple"
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <Full
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={EventData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {openLineItems ? (
            <CrLineItems
              onOpen={openLineItems}
              onClose={() => setOpenLineItems(false)}
              data={lineData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
          <div>
            {nodata ? (
              <Dialog open={nodata} onClose={handlemap}>
                <Box
                  sx={{
                    width: "350px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  {/* <img
                  src={require("../../asserts/globe1.jpg")}
                  width="200"
                  height="200"
                  alt="globe image"
                /> */}
                  <Typography variant="h6">No Events To Display</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handlemap}
                    sx={{ width: "25%" }}
                  >
                    OK
                  </Button>
                </Box>
              </Dialog>
            ) : null}
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default DeliveredInTransitShipments;
