import React, { useEffect, useMemo, useState } from 'react'
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { Alert, AlertTitle, Box } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
export default function Echarts(props: any) {
    const [shipmentInfo, setShipmentInfo] = useState(false)
    const [chartResp,setChartResp] = useState([])
    const [chartInfo, setChartInfo] = useState(false)
    const [data, setData] = useState([])
    const [yAxisData, setYAxisData] = useState([])
    const [avgMin,setAvgMin] = useState([])
    console.log(props, "pyruirooppppppp")
    let url = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/lifeCycleMonthTrend'
    // const data = props.data === 'months' ? ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] : ['Maersk', 'MSC', 'COSCO', 'CMACGM', 'Hapag-Lloyd']
    // const yAxisNames = props.data === 'months' ? ['Avg. Minutes', '% Under 3HRs'] : ['% Shipments', 'Avg. Delay in days']
    const yAxisNames = ['Shipments','Avg Response Time']
    const monthOnMonth = {
        months:['April','May','June','July','August','September','October','November','December','January','February','March'],
        bookingRequest:{
            shipments:[5400,3880,3449,3018,3880,3449,3880,4311,3449,4742,4311,4742],
            avgResp:[6.32,5.54,6.42,7.36,6.12,6.31,6.42,5.42,6.21,6.43,6.52,6.12]
        },
        acceptedRequest:{
            shipments:[4900,3654,3223,2792,3654,3223,3654,4086,3223,4517,4086],
            avgResp:[6.32,5.54,6.42,7.36,6.12,6.31,6.42,5.42,6.21,6.43,6.52,6.12]
        },
        confirmedRequest:{
            shipments:[4700,3634,3203,2702,3634,3203,3634,4066,3203,4497,4064,4990],
            avgResp:[6.32,5.54,6.42,7.36,6.12,6.31,6.42,5.42,6.21,6.43,6.52,6.12]
        }
    }
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            }
        },
        color: ['#004F92', '#e19907'],
        legend: {
            bottom: 0,
            data: yAxisNames
        },
        xAxis: [
            {
                type: 'category',
                data: data,
                axisPointer: {
                    type: 'shadow'
                },
            },

        ],
        yAxis: [
            {
                type: 'value',
                name: yAxisNames[0],
                min: 0,
                // max: 100,
                interval: 1000,
                axisLabel: {
                    formatter: '{value}'
                }
            },
            props.page != 'BookingLifeCycle' ? 
            {
                type: 'value',
                name: yAxisNames[1],
                min: 0,
                max: 10,
                interval:2,
                axisLabel: {
                    formatter: '{value}'
                }
            }:{
                type: 'value',
                name: yAxisNames[1],
              
            }
        ],
        series: [

            {
                name: yAxisNames[0],
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value: any) {
                        return (value as number);
                    }
                },
                // data: yAxisData
                data:props.pageInfo === 'bookingRequests' ? monthOnMonth.bookingRequest.shipments : 
                props.pageInfo === 'acceptedByForwarder' ? monthOnMonth.acceptedRequest.shipments : 
                monthOnMonth.confirmedRequest.shipments
                    // props.page === 'BookingLifeCycle' ? avgMin : yAxisData
                    
                    // [29.48, 25.93, 18.14, 14.72, 11.73]
            },
            {
                name: yAxisNames[1],
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: function (value: any) {
                        return (value as number);
                    }
                },
                data: monthOnMonth.acceptedRequest.avgResp
                // avgMin
                // props.pageInfo === 'bookingRequests' ? monthOnMonth.bookingRequest.avgResp:
                // props.pageInfo === 'acceptedByForwarder' ? monthOnMonth.acceptedRequest.avgResp:monthOnMonth.confirmedRequest.avgResp
                 

            }
        ],
    }
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const onEvents = {
        click: onChartClick,
    };
    function onChartClick(params: any) {
        console.log(params,"$params")
        shipmentsMoreInfoList(params)

    }
    const shipmentsMoreInfoList = (parmas:any) => {
        setShipmentInfo(true);
        chartResp.filter((item:any)=>{
            if(parmas.name === item.carrierName){
                props.parentCallback({status:true,carrier:item.shipmentsData, carrierName:parmas.name})
            }
        })
        
    }
    useEffect(() => {
        console.log(props, "propsssss")
        // if(props.page === 'shipmentDeviation'){
        //     deliveredShipmentByCarrierAnalytics(props.data)
        // }else{
        //     etaDeviationShipmentByCarrierData(props.data)
        // }

            // if(props.page === 'BookingLifeCycle'){
                
                monthOnmonthTrend(props.page)
            // }
        
    }, [])
    const monthOnmonthTrend = (status:any) =>{
        let obj = {
            status: status
        }
        config.params = obj
        let apiUrl:any;

        // if(props.pageInfo === 'confirmedByCarrier'){
        //     apiUrl = url
        // }else if(props.pageInfo === 'acceptedByForwarder'){
        //     apiUrl = url
        // }
        console.log(config.params,"config")
        axios.get(url, config).then((res1: any) => {
            let carrierInfo: any = []
            let percentageInfo: any = []
            let avgMindata :any =[]
            console.log(res1.data.data,"resppppp")
            setChartInfo(true)
           
            if (res1.data.data.length > 0) {
                let resp = [...res1.data.data].reverse()
                setChartResp(res1.data.data)
                for (let i = 0; i < resp.length; i++) {
                    carrierInfo.push(resp[i].month)
                    percentageInfo.push(resp[i].lessThan3HrApprovedPercentage)
                    avgMindata.push(resp[i].avgTimeCount)
                }
                setData(carrierInfo)
                setChartInfo(true)
            } else {
                setChartInfo(false)
            }

            setYAxisData(percentageInfo)
            setAvgMin(avgMindata)
            // setChartInfo(true)


        })
    }
    const deliveredShipmentByCarrierAnalytics = (status:any) =>{
        config.params = status
        axios.get(url, config).then((res1: any) => {
            let carrierInfo: any = []
            let percentageInfo: any = []
            if (res1.data.data.length > 0) {
                setChartResp(res1.data.data)
                for (let i = 0; i < res1.data.data.length; i++) {
                    carrierInfo.push(res1.data.data[i].carrierName)
                    percentageInfo.push(res1.data.data[i].percentage)
                }
                setData(carrierInfo)
                setChartInfo(true)
            } else {
                setChartInfo(false)
            }

            setYAxisData(percentageInfo)
            // setChartInfo(true)


        })
    }
    const etaDeviationShipmentByCarrierData = (status: any) => {
        config.params = status
        axios.get(url, config).then((res1: any) => {
            let carrierInfo: any = []
            let percentageInfo: any = []
            if (res1.data.data.length > 0) {
                console.log("hiii")
                setChartResp(res1.data.data)
                for (let i = 0; i < res1.data.data.length; i++) {
                    carrierInfo.push(res1.data.data[i].carrierName)
                    percentageInfo.push(res1.data.data[i].percentage)
                }
                setData(carrierInfo)
                setChartInfo(true)
            } else {
                setChartInfo(false)
            }

            setYAxisData(percentageInfo)
            // setChartInfo(true)


        })
    }
    return (
        <div>
            {/* {chartInfo ? */}
                <Box >

                    <ReactECharts option={options}
                        onEvents={onEvents} />

                </Box>
                {/* : (
                    <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        No Data Found
                    </Alert>
                )} */}

        </div>
    )
}
