import axios from "axios";
import swal from "sweetalert";

export function PostRequest(url: any, params: any) {
  console.log(params);
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  };
  axios
    .post(url, params, { headers })
    .then((res) => {
      console.log("131313131313", res);
      swal("Sucess", res.data.status.messsage, "success");
      return res.data;
    })
    .catch((error) => {
      console.log(" response error " + JSON.stringify(error));
      return error;
    });
}

export const getRequest = (url: any, params: any) => {
  console.log("Requested Url", url);
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: params,
  };
  console.log(config);
  axios
    .get(url, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("response Error");
    });
};
