import { Alert, AlertTitle, AppBar, Box, Button, Dialog, Grid, Slide, Step, StepContent, StepLabel, Stepper, Toolbar, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { getRequest, PostRequest } from '../Services/BaseService';
import { FirstDataRenderedEvent, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { GoogleMap, MarkerF, PolylineF, useJsApiLoader } from '@react-google-maps/api';
import MyMapComponent from "../Helpers/Maps";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Charts from '../Helpers/Charts';


import '../Styles.css'
import IntransitFileViewer from '../Helpers/IntransitFileViewer';
import CommonIntransitLineItems from './CommonIntransitLineItems';
import CommonIntransitLegInfo from './CommonIntransitLegInfo';
import CommonETADeviationInfo from './CommonETADeviationInfo';
import CommonInTransitInfo from './CommonInTransitInfo';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DashboardInTransit(props: any) {
    let icon: any = <LocationOnIcon sx={{ color: "red" }} />;
    const [value, setValue] = React.useState("1");
    const [subValue, setSubValue] = React.useState('1-1')
    const [isShow, setIsShow] = useState(false)
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const [isInvoiceShow, setIsInvoiceShow] = useState(false)
    const domLayout = "autoHeight";
    const [rowData, setRowData] = useState();
    const [hbl, setHBL] = useState();
    const [mbl, setMBL] = useState();
    const [forwarder, setForwarder] = useState();
    const [origin, setOrigin] = useState();
    const [destination, setDestination] = useState();
    const [EventData, setEventData] = useState<any>([]);
    const [openDetail, setOpenDetail] = useState(false);
    const [activeStep, setActiveStep] = useState()
    const [invoiceNo, setInvoiceNo] = useState();
    const [carrierRefNo, setCarrierRefNo] = useState();
    const [tabsInfoShow, setTabsInfoShow] = useState(false)
    const [intransitLineData, setIntransitLineData] = useState()
    let locations: { name: any; location: { lat: any; lng: any } }[] = [];
    const [eventsLocation, setEventsLocation] = useState(locations)
    const [height, setHeight] = useState({ scrollTop: 0 })
    const [legData, setLegData] = useState()
    const [isLegShow, setIsLegShow] = useState(false)
    const [childData, setChildData] = useState(false)
    let gridColumnApi: any;
    const myRef = useRef<any>();
    const handleCallback = (childData: any) => {
        console.log(childData, "chield data")
        setChildData(childData)
        const scrollY = myRef.current.clientHeight;
        console.log(scrollY, "scrolly11324")
        myRef.current.scrollTop = scrollY


        document.getElementById('moreInfo')?.scrollIntoView({ behavior: 'smooth' })

    }
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const handleData = (params: any) => {
        console.log(params, "params")
        setHBL(params.data.hblNumber);
        setMBL(params.data.mblNumber);
        setForwarder(params.data.forwarderId)
        setInvoiceNo(params.data.invoiceNumber)
        setOrigin(params.data.pickupCountry)
        setDestination(params.data.dropoffCountry)
        setIsInvoiceShow(false)
        setIsLegShow(false)
        const element = document.getElementById('events')
        console.log(element, "Element")
        if (element) {
            setIsShow(true)
            getEventData(
                params.data.containerNumber,
                params.data.hscode,
                params.data.invoiceNumber
            );

        }
    }
    const handleLine = (params: any) => {
        console.log(params, "$$pa")
        setCarrierRefNo(params.data.carrierReference)
        setInvoiceNo(params.data.invoiceNumber)
        setIntransitLineData(params.data)
        setOrigin(params.data.pickupCountry)
        setDestination(params.data.dropoffCountry)
        setIsShow(false)
        setIsLegShow(false)
        const element = document.getElementById('invoice_id')
        element?.scroll(0, 1000)
        console.log(element, "Element")
        if (element) {
            setIsInvoiceShow(true)
            window.scroll({
                top: element.scrollTop,
                behavior: 'smooth',
            })
            document.getElementById('invoice_id')?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
            const scrollY = myRef.current.scrollTop;
            setHeight({
                scrollTop: scrollY
            })
            console.log(myRef, "myRef")
            if (myRef.current.clientHeight > 80) {

                let a = document.getElementById('roll_back') as HTMLElement
                a.style.display = 'flex'
                return a;
            } else {
                let a = document.getElementById('roll_back') as HTMLElement
                a.style.display = 'none'

                return a;
            }

        }

    };
    const handleLegData = (params: any) => {
        setHBL(params.data.hblNumber);
        setMBL(params.data.mblNumber);
        setLegData(params.data.legInfo);
        setIsShow(false)
        setIsInvoiceShow(false)
        const element = document.getElementById('leg')
        console.log(element, "Element")
        if (element) {
            setIsLegShow(true)
            document.getElementById('leg')?.scrollIntoView({ behavior: 'smooth' })
            const scrollY = myRef.current.scrollTop;
            setHeight({
                scrollTop: scrollY
            })
            console.log(myRef, "myRef")
            if (myRef.current.clientHeight > 80) {

                let a = document.getElementById('roll_back') as HTMLElement
                a.style.display = 'flex'
                return a;
            } else {
                let a = document.getElementById('roll_back') as HTMLElement
                a.style.display = 'none'

                return a;
            }

        }
    }
    const getEventData = (data: any, hs: any, inv: any) => {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        };
        axios
            .post(
                "https://api.ext.realtrace.realware.tech/po/eventQuery",
                {
                    containerNumber: data,
                    hscode: hs,
                    invoiceNumber: inv,
                },
                { headers }
            )
            .then((evedata) => {
                if (evedata.data.primary.length > 0) {
                    console.log("Event Data.....", evedata);
                    setEventData(evedata.data.primary);
                    setOpenDetail(true);
                    console.log("EVENTS  DATA.....");
                    setActiveStep(evedata.data.primary.length)
                    for (let i = 0; i < evedata.data.primary.length; i++) {
                        if (evedata.data.primary[i].originLatitide > 0) {
                            let obj = {
                                name: evedata.data.primary[i].origin,
                                location: {
                                    lat: parseFloat(evedata.data.primary[i].originLatitide),
                                    lng: parseFloat(evedata.data.primary[i].originLongitude),
                                },
                            };
                            locations.push(obj);
                            let obj2 = {
                                name: evedata.data.primary[i].destination,
                                location: {
                                    lat: parseFloat(evedata.data.primary[i].desitinationLatitude),
                                    lng: parseFloat(evedata.data.primary[i].destinationLongitude),
                                },
                            };
                            locations.push(obj2);
                            break;
                        }
                    }
                    console.log(locations, "locations")
                    setEventsLocation(locations)
                    document.getElementById('events')?.scrollIntoView({ behavior: 'smooth' })
                    // console.log(a, "scroll")
                    const scrollY = myRef.current.scrollTop;
                    setHeight({
                        scrollTop: scrollY
                    })
                    console.log(myRef, "myRef")
                    if (myRef.current.clientHeight > 80) {

                        let a = document.getElementById('roll_back') as HTMLElement
                        a.style.display = 'flex'
                        return a;
                    } else {
                        let a = document.getElementById('roll_back') as HTMLElement
                        a.style.display = 'none'

                        return a;
                    }


                } else {
                    // swal("Events", "No Event Data", "error");
                }
            });
    };
    const onScroll = () => {
        // console.log(" scroll", height.scrollTop)
        console.log("scroll", window.document.getElementById('moreInfo'))
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = myRef.current.scrollTop
        console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`)
        setHeight({
            scrollTop: window.pageYOffset
        })
        if (scrollTop > 80) {
            let a = document.getElementById('roll_back') as HTMLElement
            a.style.display = 'flex'
            return a;
        } else {
            let a = document.getElementById('roll_back') as HTMLElement
            a.style.display = 'none'
            // if(scrollTop < 10) {
            //     setIsShow(false)
            // }

            return a;
        }

    }
    const scrollUp = () => {
        myRef.current.scrollTop = 0
    }
    const [columnDefs] = useState([
        {
            headerName: "HBL NUMBER",
            field: "hblNumber",
            filter: true,
            headerTooltip: "HBL NUMBER",
            // tooltipField: "hblNumber",
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <Tooltip title={"Click Here To View Events"} placement="bottom-start">
                        <a
                            style={{
                                height: 20,
                                width: "100%",
                                lineHeight: 0.5,
                                textAlign: "left",
                                color: "blue",
                            }}
                            onClick={() => handleData(params)}
                            className="btn btn-info"
                        >
                            {params.value}
                        </a>
                    </Tooltip>
                );
            },
        },
        {
            headerName: "INVOICE NUMBER",
            field: "invoiceNumber",
            filter: true,
            headerTooltip: "INVOICE NUMBER",
            tooltipField: "invoiceNumber",
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <a
                        style={{
                            height: 20,
                            width: "100%",
                            lineHeight: 0.5,
                            color: "blue",
                        }}
                        onClick={() => handleLine(params)}
                        className="btn btn-info"
                    >
                        {params.value}
                    </a>
                );
            },
        },
        {
            headerName: "LEG INFORMATION",
            field: "legInfo",
            filter: true,
            headerTooltip: "LEG INFORMATION",
            tooltipField: "leginformatin",
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <a
                        style={{
                            height: 20,
                            width: "100%",
                            lineHeight: 0.5,
                            color: "blue",
                        }}
                        onClick={() => handleLegData(params)}
                        className="btn btn-info"
                    >
                        LegInfo
                    </a>
                );
            },
        },
        {
            headerName: "INVOICE DATE",
            field: "invoiceDate",
            filter: true,
            headerTooltip: "INVOICE DATE",
            tooltipField: "invoiceDate",
        },
        {
            headerName: "PURCHASE ORDER #",
            field: "purchaseOrderNumber",
            filter: "true",
            headerTooltip: "PURCHASE ORDER #",
            tooltipField: "purchaseOrderNumber",
        },
        {
            headerName: "MBL #",
            field: "mblNumber",
            filter: true,
            headerTooltip: "MBL #",
            tooltipField: "mblNumber",
        },

        {
            headerName: "COMMODITY TYPE",
            filter: true,
            headerTooltip: "COMMODITY TYPE",
            tooltipField: "commodityType",
            field: "commodityType",
        },
        {
            headerName: "ITEM NAME",
            field: "itemName",
            filter: true,
            headerTooltip: "PACKAGE TYPE",
            tooltipField: "itemName",
        },
        {
            headerName: "ITEM DESCRIPTION",
            field: "itemDescription",
            filter: true,
            headerTooltip: "PACKAGE TYPE",
            tooltipField: "itemDescription",
        },

        {
            headerName: "HS CODE",
            field: "hscode",
            filter: true,
            headerTooltip: "HS CODE",
            tooltipField: "hscode",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "HS CODE DESCRIPTION",
            field: "hscodeDescription",
            filter: true,
            headerTooltip: "HS CODE DESCRIPTION",
            tooltipField: "hscodeDescription",
        },
        {
            headerName: "SHIPMENT VOLUME",
            filter: true,
            headerTooltip: "SHIPMENT VOLUME",
            field: "shipmentVolume",
            tooltipField: "shipmentVolume",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "SHIPMENT WEIGHT",
            filter: true,
            headerTooltip: "SHIPMENT WEIGHT",
            field: "shipmentWeight",
            tooltipField: "shipmentWeight",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "UOM",
            field: "Uom",
            filter: true,
            headerTooltip: "UOM",
            tooltipField: "Uom",
        },

        // {
        //   headerName: "FORWARDER BOOKING REFERENCE DATE",
        //   field: "invoiceDate",
        //   filter: true,
        //   headerTooltip: "FORWARDER BOOKING REFERENCE DATE",
        //   tooltipField: "invoiceDate",
        //   cellClass: "ag-left-aligned-cell",
        // },
        {
            headerName: "CONTAINER NUMBER",
            field: "containerNumber",
            filter: true,
            headerTooltip: "CONTAINER NUMBER",
            tooltipField: "containerNumber",
        },
        {
            headerName: "CONTAINER TYPE",
            filter: true,
            headerTooltip: "CONTAINER TYPE",
            tooltipField: "containerType",
            field: "containerType",
            columnGroupShow: "closed",
        },

        {
            headerName: "EMPTY CONTAINER PICKUP DEPOT",
            filter: true,
            headerTooltip: "CONTAINER PICKUP DEPOT",
            tooltipField: "containerPickUpDepot",
            field: "containerPickUpDepot",
            columnGroupShow: "closed",
        },
        {
            headerName: "CONTAINER SIZE",
            filter: true,
            headerTooltip: "CONTAINER SIZE",
            tooltipField: "containerSize",
            field: "containerSize",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "CONTAINER TARE WEIGHT",
            filter: true,
            headerTooltip: "CONTAINER TARE WEIGHT",
            tooltipField: "containerTareWeight",
            field: "containerTareWeight",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },

        // {
        //     headerName: "INVOICE DATE",
        //     field: "invoiceDate",
        //     filter: true,
        //     headerTooltip: "INVOICE DATE",
        //     tooltipField: "invoiceDate",
        //   },

        {
            headerName: "CARRIER REFERENCE",
            field: "carrierReference",
            filter: true,
            headerTooltip: "CARRIER REFERENCE",
            tooltipField: "carrierReference",

            //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
        },
        {
            headerName: "CARRIER ID",
            field: "carrierId",
            filter: true,
            headerTooltip: "CARRIER ID",
            tooltipField: "carrierId",

            //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
        },
        {
            headerName: "CARRIER NAME",
            field: "carrierName",
            filter: true,
            headerTooltip: "CARRIER NAME",
            tooltipField: "carrierName",

            //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
        },
        {
            headerName: "PICK UP LOCATION",
            field: "pickupLocation",
            filter: true,
            headerTooltip: "PICK UP LOCATION",
            tooltipField: "pickupLocation",
        },

        {
            headerName: "PICKUP DATE",
            filter: true,
            headerTooltip: "PICKUP DATE",
            tooltipField: "pickUpDate",
            field: "pickUpDate",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "PICKUP COUNTRY",
            filter: true,
            headerTooltip: "PICKUP COUNTRY",
            tooltipField: "pickupCountry",
            field: "pickupCountry",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "PICKUP ZIPCODE",
            filter: true,
            headerTooltip: "PICKUP ZIPCODE",
            tooltipField: "pickupZipCode",
            field: "pickupZipCode",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "CONSIGNEE ID",
            filter: true,
            headerTooltip: "CONSIGNEE ID",
            field: "consigneeId",
            tooltipField: "consigneeId",
        },
        {
            headerName: "CONSIGNEE NAME",
            filter: true,
            headerTooltip: "CONSIGNEE NAME",
            field: "consigneeName",
            tooltipField: "consigneeName",
            columnGroupShow: "closed",
        },
        {
            headerName: "DROP OFF LOCATION",
            field: "dropoffLocation",
            filter: true,
            headerTooltip: "DROPP OFF LOCATION",
            tooltipField: "dropoffLocation",
        },
        {
            headerName: "DROP OFF COUNTRY",
            field: "dropoffCountry",
            filter: true,
            headerTooltip: "DROP OFF COUNTRY",
            tooltipField: "dropoffCountry",
        },
        {
            headerName: "DROP OFF ZIPCODE",
            field: "dropoffZipCode",
            filter: true,
            headerTooltip: "DROP OFF ZIPCODE",
            tooltipField: "dropoffZipCode",
        },

        {
            headerName: "FORWARDER ID",
            filter: true,
            headerTooltip: "FORWARDER ID",
            tooltipField: "forwarderId",
            field: "forwarderId",
        },
        {
            headerName: "FORWARDER NAME",
            filter: true,
            headerTooltip: "FORWARDER NAME",
            tooltipField: "forwarderName",
            field: "forwarderName",
            columnGroupShow: "closed",
        },

        {
            headerName: "FORWARDER BOOKING REFERENCE NUMBER",
            filter: true,
            headerTooltip: " FORWARDER BOOKING REFERENCE NUMBER",
            tooltipField: "bookingReference",
            field: "bookingReference",
        },
        {
            headerName: "CARRIER ID",
            filter: true,
            headerTooltip: "CARRIER ID",
            tooltipField: "carrierId",
            field: "carrierId",
        },
        {
            headerName: "CARRIER NAME",
            filter: true,
            headerTooltip: "CARRIER NAME",
            tooltipField: "carrierName",
            field: "carrierName",
            columnGroupShow: "closed",
        },

        {
            headerName: "CARRIER BOOKING REFERENCE NUMBER",
            field: "carrierReference",
            filter: true,
            headerTooltip: "CARRIER BOOKING REFERENCE NUMBER",
            tooltipField: "carrierReference",
        },

        {
            headerName: "INCO TERMS",
            filter: true,
            headerTooltip: "INCO TERMS",
            tooltipField: "incoterms",
            field: "incoterms",
        },

        {
            headerName: "MODE OF SHIPMENT",
            filter: true,
            headerTooltip: "MODE OF SHIPMENT",
            tooltipField: "modeOfShipment",
            field: "modeOfShipment",
            columnGroupShow: "closed",
        },
        {
            headerName: "ETD",
            filter: true,
            headerTooltip: "ETD",
            tooltipField: "etd",
            field: "etd",
            cellClass: "ag-left-aligned-cell",
        },

        {
            headerName: "ETA",
            filter: true,
            headerTooltip: "ETA",
            tooltipField: "eta",
            field: "eta",
            cellClass: "ag-left-aligned-cell",
        },
    ]);
    const sideBar = {
        toolPanels: [
            {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                },
            },
            {
                id: "filters",
                labelKey: "filters",
                labelDefault: "Filters",
                iconKey: "menu",
                toolPanel: "agFiltersToolPanel",
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                },
            },
        ],
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            floatingFilter: true,
            searchable: true,
            resizable: true,
            sortable: true,
            filter: true,
            domLayout: "autoHeight",
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        // params.columnApi.autoSizeAllColumns(false);
        gridColumnApi = params.columnApi;
    }, []);
    const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
        // params.columnApi.autoSizeAllColumns(false);

        var allColumnIds: any = [];
        gridColumnApi.getAllColumns().forEach(function (column: any) {
            allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds);
    }, []);
    return (
        <div className="dialog" >
            <Dialog
                fullScreen
                open={props.onOpen}
                onClose={props.onClose}
                TransitionComponent={Transition}

                sx={{
                    "& .MuiDialog-root": {},
                    zIndex: 10000,

                }}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' component="div" sx={{ ml: 2, flex: 1 }}>
                            In-Transit Shipments - Summary
                        </Typography>

                        <Button autoFocus color="inherit" onClick={props.onClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <div ref={myRef}
                    style={{ overflowY: 'scroll' }}
                // onScroll={() => onScroll()}
                >
                    <Box sx={{ width: "100%", typography: "body1", p: 3 }} >
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList
                                    // onChange={handleChange}
                                    onChange={handleChange}
                                    aria-label="lab API tabs example"
                                >
                                    {(sessionStorage.getItem('role') === 'shipper' ||sessionStorage.getItem('role') === 'forwarder')  ? <Tab label="Delivery Today" value="1" /> : <Tab label="Arriving Today" value="1" />}

                                    <Tab label="Next Day" value="2" />
                                    <Tab label="Next 7 Days" value="3" />

                                </TabList>
                            </Box>
                            <TabPanel value="1" sx={{ pt: 3, pb: 3, pl: 0, pr: 0 }}>
                                <CommonInTransitInfo parentCallback1={handleCallback} pageInfo={{ page: 'intransit', tab: 'arrivingToday' }} />
                                {/* <CommonETADeviationInfo parentCallback1={handleCallback} pageInfo={{ page: 'intransit', tab: 'arrivingToday' }} /> */}
                            </TabPanel>
                            <TabPanel value="2" sx={{ pt: 3, pb: 3, pl: 0, pr: 0 }}>
                                <CommonInTransitInfo parentCallback1={handleCallback} pageInfo={{ page: 'intransit', tab: 'arrivingNextDay' }} />
                                {/* <CommonETADeviationInfo parentCallback1={handleCallback} pageInfo={{ page: 'intransit', tab: 'arrivingNextDay' }} /> */}
                            </TabPanel>
                            <TabPanel value="3" sx={{ pt: 3, pb: 3, pl: 0, pr: 0 }}>
                            <CommonInTransitInfo parentCallback1={handleCallback} pageInfo={{ page: 'intransit', tab: 'arrivingNext7Days' }} />
                                {/* <CommonETADeviationInfo parentCallback1={handleCallback} pageInfo={{ page: 'intransit', tab: 'arrivingNext7Days' }} /> */}
                            </TabPanel>

                        </TabContext>
                        {/* {tabsInfoShow ?
                            <Box id="tabsinfo" sx={{ minHeight: 600 }}>
                                <AgGridReact
                                    className='ag-theme-balham'
                                    animateRows={true}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    domLayout={domLayout}
                                    columnDefs={columnDefs}
                                    sideBar={sideBar}
                                    onGridReady={onGridReady}
                                    paginationPageSize={10}
                                    pagination={true}
                                    colResizeDefault={'shift'}
                                    onFirstDataRendered={onFirstDataRendered}
                                ></AgGridReact>

                                <Box id="events">
                                    {
                                        isShow ?
                                            (
                                                <Box sx={{ width: "100%", typography: "body1", pt: 3, pb: 3, pl: 0, Pr: 0 }}>
                                                    <Grid container>
                                                        <Grid item xs={3}>
                                                            <Typography variant='h6'>HBL # : <span className="dialogTitle">{hbl}</span></Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='h6'>MBL # : <span className="dialogTitle blueColor">{mbl}</span></Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='h6'>FORWARDER REF. # : <span className="dialogTitle greyColor">{forwarder}</span></Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    flexDirection: "row",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <LocationOnIcon fontSize="large" sx={{ color: "#f4c919" }} />
                                                                    <p style={{ fontSize: "14px" }}>{origin}</p>
                                                                </Box>
                                                                &nbsp;
                                                                <Box sx={{ marginBottom: "18px", marginRight: "15px" }}>
                                                                    <span>
                                                                        <ArrowRightAltIcon fontSize="large" />
                                                                    </span>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <LocationOnIcon sx={{ color: "red" }} fontSize="large" />
                                                                    <p style={{ fontSize: "14px" }}>
                                                                        {" "}
                                                                        {destination}
                                                                    </p>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{ mt: 1 }}>
                                                        <Grid item xs={3} sx={{
                                                            mt: 2,
                                                            width: "30%",
                                                            height: 600,
                                                            overflowY: "auto",
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    paddingBottom: 3,
                                                                    textAlign: "left",
                                                                    fontSize: "18px",
                                                                }}
                                                            >
                                                                EVENTS
                                                            </Typography>
                                                            <Stepper
                                                                activeStep={activeStep}
                                                                orientation="vertical"
                                                                sx={{
                                                                    display: "flex",
                                                                    border: "2px soild red",
                                                                }}
                                                            >
                                                                {EventData.map((step: any, index: any) => (
                                                                    <Step key={index}>
                                                                        <StepLabel
                                                                            optional={
                                                                                index === step.length ? (
                                                                                    <div className="blink">
                                                                                        <Typography className="blink" variant="caption">
                                                                                            Last step
                                                                                        </Typography>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    color: "black",
                                                                                    fontWeight: "bold",
                                                                                    textTransform: "capitalize",
                                                                                }}
                                                                            >
                                                                                {step.shipmentEventName}
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    color: "black",
                                                                                    // fontWeight: "bold",
                                                                                    // textTransform: "capitalize",
                                                                                }}
                                                                            >
                                                                                {step.shipmentEventDate}
                                                                            </Typography>
                                                                        </StepLabel>
                                                                        <StepContent>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    color: "black",
                                                                                    fontWeight: "300px",
                                                                                    textTransform: "capitalize",
                                                                                }}
                                                                            >
                                                                                {step.description}
                                                                            </Typography>
                                                                        </StepContent>
                                                                    </Step>
                                                                ))}
                                                            </Stepper>
                                                        </Grid>
                                                        <Grid item xs={9} id='map'>
                                                            <TabContext value={subValue}>
                                                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                                    <TabList
                                                                        onChange={handleChange1}
                                                                        aria-label="lab API tabs example"
                                                                    >
                                                                        <Tab label="Maps" value="1-1" />
                                                                        <Tab label="Track Temperature" value="2-1" />
                                                                        <Tab label="Shipment Documents" value="3-1" />
                                                                    </TabList>
                                                                </Box>
                                                                <TabPanel value="1-1" >
                                                                    <MyMapComponent
                                                                        locations={eventsLocation}
                                                                        sx={{ height: "fit-content", width: '68vw' }}
                                                                    />
                                                                </TabPanel>
                                                                <TabPanel value="2-1">
                                                                    <Charts />
                                                                </TabPanel>
                                                                <TabPanel value="3-1">
                                                                    <IntransitFileViewer data={invoiceNo} />
                                                                </TabPanel>
                                                            </TabContext>
                                                        </Grid>
                                                    </Grid>

                                                </Box>
                                            )


                                            : ''
                                    }
                                </Box>
                                <Box id="invoice_id" >

                                    {isInvoiceShow ?
                                        (<Box sx={{ width: "100%", typography: "body1", pt: 3, pb: 3, pl: 0, Pr: 0 }} >
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography variant='h6'>INVOICE REF. # : <span className="dialogTitle">{invoiceNo}</span></Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant='h6'>CARRIER REF. # : <span className="dialogTitle blueColor">{carrierRefNo}</span></Typography>
                                                </Grid>

                                              
                                            </Grid>
                                            <CommonIntransitLineItems data={intransitLineData} status={"accepted"} sx={{ mt: 3 }} />
                                        </Box>) : ''}
                                </Box>
                                <Box id="leg">
                                    {
                                        isLegShow ?
                                            (
                                                <Box sx={{ width: "100%", typography: "body1", pt: 3, pb: 3, pl: 0, Pr: 0, }} >
                                                    <Grid container>
                                                        <Grid item xs={3}>
                                                            <Typography variant='h6'>HBL # : <span className="dialogTitle">{hbl}</span></Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='h6'>MBL # : <span className="dialogTitle blueColor">{mbl}</span></Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <CommonIntransitLegInfo data={legData} />
                                                </Box>

                                            ) : ''
                                    }
                                </Box>
                            </Box>
                            : ''} */}


                    </Box>



                    <Button id="roll_back" className="animate" onClick={scrollUp}>
                        <KeyboardArrowUpIcon />
                    </Button>
                </div>

            </Dialog>
        </div>
    )
}
export default DashboardInTransit;



