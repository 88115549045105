import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography,
  Toolbar,
  AppBar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Paper,
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import React, { CSSProperties, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Form, Formik, FormikProps } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import sizeConfigs from "../../Config/sizeConfigs";
import colorConfigs from "../../Config/colorConfig";
import { Bars } from "react-loader-spinner";
import swal from "sweetalert";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function SubH(props: any) {
  const location = useLocation();
  const [age, setAge] = React.useState('');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  useEffect(() => {
    console.log("SUB HEADER CALLING.....................");
    setFd(null);
    setTd(null);
    setFormat("");
    props.getdates("", "", "");
  }, [location]);
  const [fromDate, setFd] = React.useState<Dayjs | any>(null);
  const [toDate, setTd] = React.useState<Dayjs | any>(null);
  const [format, setFormat] = useState<any>("YTD");

  // console.log(".............344344", props.loader);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#ffffff");

  const handleChanges = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    setFormat(event.target.value);
  };

  const handlefd = (newValue: Dayjs | any) => {
    setFd(newValue);
    // console.log(newValue);
  };
  const handletd = (newValue: Dayjs | any) => {
    setTd(newValue);
  };

  const onSubmit = () => {
    // console.log("2345678909876543", fromDate, toDate);
    if (fromDate === null && toDate === null) {
      swal("info", "Please Select From Date And To Date", "info");
    } else if (fromDate === null) {
      swal("info", "Please Select From Date", "info");
    } else if (toDate === null) {
      swal("info", "Please Select To Date", "info");
    } else {
      props.getdates(fromDate, toDate, format);
    }
  };

  const onSubmit2 = () => {
    if (format === "CUSTOM") {
      if (fromDate === null && toDate === null) {
        swal("info", "Please Select From Date And To Date", "info");
      } else if (fromDate === null) {
        swal("info", "Please Select From Date", "info");
      } else if (toDate === null) {
        swal("info", "Please Select To Date", "info");
      } else {
        props.getdates(fromDate, toDate, format);
      }
    } else {
      props.getdates("", "", format);
      setFd(null);
      setTd(null);
    }
  };

  const Reset = () => {
    props.getdates("", "");
    setFd(null);
    setTd(null);
  };

  const [filterOpen, SetFilterOpen] = useState(false);
  const handleFilters = () => SetFilterOpen(!filterOpen);
  const handleSubmit = () => { }
  const handleetd = () => { }
  const handleChange = () => { }
  return (
    <Box
      sx={{
        justifyContent: "space-between",
        color: colorConfigs.topbar.color,
        display: "flex",
      }}
    >
      <Box
        sx={{
          // justifyContent: "space-between",
          color: colorConfigs.topbar.color,
          display: "flex",
        }}
      >
        <Typography
          sx={{
            textTransform: "capitalize",
            fontSize: sizeConfigs.pageTitle.fontSize,

            letterSpacing: sizeConfigs.pageTitle.letterSpacing,
            color: colorConfigs.pageTitle.color,
            fontWeight: sizeConfigs.pageTitle.fontWeight,
            marginRight: location.pathname !== '/sea_rates' ? "15px" : '0px',
          }}
        >
          {location.pathname === "/"
            ? "Dashboard"
            : location.pathname !== '/sea_rates' ? location.pathname.slice(1).replaceAll("_", " ") : ''}
        </Typography>
        {location.pathname !== '/sea_rates' ? <Bars
          height="30"
          width="30"
          color="#2a76f4"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={props.loader}
        /> : ''}

      </Box>

      {location.pathname === "/" ? (
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{ fontSize: "0.85rem", fontWeight: "400", fontFamily: "roboto" }}
          >
            FILTER BY : &nbsp;
          </Typography>

          <FormControl
            size="small"
            sx={{ width: "150px", marginRight: "15px" }}
          >
            <InputLabel id="demo-simple-select-label">
              {format ? format : "YTD"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={format}
              label="Age"
              defaultValue="YTD"
              onChange={handleChanges}
              MenuProps={{disableScrollLock:true}}

            >
              <MenuItem value={"YTD"}>YTD</MenuItem>
              <MenuItem value={"QTD"}>QTD</MenuItem>
              <MenuItem value={"MTD"}>MTD</MenuItem>
              <MenuItem value={"CUSTOM"}>CUSTOM</MenuItem>
            </Select>
          </FormControl>

          {format === "CUSTOM" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box sx={{ marginRight: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="From Date"
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={handlefd}
                    renderInput={(params: any) => (
                      <TextField {...params} required size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ marginRight: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="To Date"
                    inputFormat="DD/MM/YYYY"
                    value={toDate}
                    onChange={handletd}
                    minDate={fromDate}
                    renderInput={(params: any) => (
                      <TextField {...params} required size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          ) : null}
          <Button
            sx={{ marginRight: "10px" }}
            variant="contained"
            color="success"
            onClick={() => onSubmit2()}
          >
            Submit
          </Button>
        </Box>
      ) : null}
      {location.pathname === "/" ||
        location.pathname.includes("upload") ? null : location.pathname !== '/sea_rates' ? (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              sx={{ fontSize: "1.0rem", fontWeight: "400", fontFamily: "roboto" }}
            >
              FILTER BY : &nbsp;
            </Typography>
            <Box sx={{ marginRight: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  onChange={handlefd}
                  renderInput={(params: any) => (
                    <TextField {...params} required size="small" />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginRight: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={handletd}
                  minDate={fromDate}
                  renderInput={(params: any) => (
                    <TextField {...params} required size="small" />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              color="success"
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
            <Button variant="contained" color="inherit" onClick={() => Reset()}>
              Reset
            </Button>
          </Box>
        ) : ''}
      {/* {location.pathname === '/sea_rates' ? (
        
      ) : ''} */}

      {/* <Drawer
        anchor={"right"}
        open={filterOpen}
        onClose={handleFilters}
        sx={{
          "& .MuiDrawer-paper": {
            width: "500px !important",
          },
        }}
      >
        <Box>
          <Formik
            initialValues={{
              email: "",
              color: "red",
              firstName: "",
              lastName: "",
            }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {(props: FormikProps<any>) => (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: 2,
                    marginLeft: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginRight: "10px",
                    }}
                  >
                    <Typography sx={{ color: "black" }}>
                      APPLY FILTERS{" "}
                      <IconButton>
                        <FilterAltIcon />
                      </IconButton>
                    </Typography>{" "}
                   
                    <IconButton>
                      <CloseIcon onClick={handleFilters} />
                    </IconButton>
                  </Box>
                </Box>
                <Grid item xs={12} sx={{ display: "flex", margin: 2 }}>
                  <Grid item xs={6} sx={{ margin: 1 }}>
                    <label>From Date :</label>
                    <TextField
                      name="Fromdate"
                      id="outlined-helper-text"
                      type="date"
                      sx={{
                        width: "100%",
                        marginTop: "2px",
                      }}
                    />
                  </Grid>
                  <Grid sx={{ margin: 1 }}>
                    <label>To Date :</label>
                    <TextField
                      name="Fromdate"
                      id="outlined-helper-text"
                      type="date"
                      sx={{
                        marginTop: "2px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    marginLeft: 3,
                    justifyContent: "start",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{
                      p: 1.2,
                      marginBottom: 5,
                      fontWeight: 500,
                      width: "150px",
                    }}
                    className="submitbtn"
                  >
                    SUBMIT
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Drawer> */}
    </Box>

    // </Box>
  );
}

export const SubHeader = React.memo(SubH);
