import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { InputLabel, Paper } from "@material-ui/core";
// import "./custom.css";
// import DatePicker from 'material-ui';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import Dialog from "@mui/material/Dialog";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { Environment_Realtraceb_Dev } from "../Enviornment/Environment_Realtraceb_Dev";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Divider, TextField, Tooltip } from "@mui/material";
// import CrLineItems from "../Components/Carrier/CarrierLineItems";
import { Typography } from "@material-ui/core";
import Full from "../../../Helpers/full";
import LegInfoVisual from "../Carrier/carrierLeginfoVisual";
// import { AnyMxRecord } from "dns";
import "./custom.css";
import swal from "sweetalert";
import { useNavigate, useOutletContext } from "react-router-dom";
import CrLineItems from "../Carrier/CarrierLineItems";
import TooltipCom from "../../../Helpers/ToolTipComponent";

// import "./Car_ack.css";
// import Full from "../Layout/full";
// import LegInfoVisual from "../Components/Carrier/carrierLeginfoVisual";
// import swal from "sweetalert";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function ImportCustomClearenceList() {
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  //datepickers
  const [gad, setGad] = React.useState<Dayjs | any>(null);
  const [gid, setGid] = React.useState<Dayjs | any>(null);
  const [mtd, setMtd] = React.useState<Dayjs | any>(null);
  const [god, setGod] = React.useState<Dayjs | any>(null);

  const [gat, setGat] = React.useState<Dayjs | any>(null);
  const [git, setGit] = React.useState<Dayjs | any>(null);
  const [mtt, setMtt] = React.useState<Dayjs | any>(null);
  const [got, setGot] = React.useState<Dayjs | any>(null);

  const handleGad = (newValue: Dayjs | any) => {
    let data = newValue.$d;
    // let date = newValue.$D + "-" + "" + newValue.$M + "-" + newValue.$y;
    // console.log(date);
    var responseDate = moment(data).format("YYYY-MM-DD");
    console.log(responseDate);
    setGad(newValue);
    // SetEtaa(responseDate);
  };

  const handleGid = (newValue: Dayjs | any) => {
    setGid(newValue);
    let data = newValue.$d;
    // console.log(date);
    var responseDate = moment(data).format("YYYY-MM-DD");
    console.log(responseDate);
    // SetEtdd(responseDate);
  };
  const handleMtd = (newValue: Dayjs | any) => {
    setMtd(newValue);
  };
  const handleGod = (newValue: Dayjs | any) => {
    setGod(newValue);
  };

  const handleGat = (newValue: Dayjs | any) => {
    // var responseDate = moment(newValue.$d).format("HH:mm");
    setGat(newValue);
  };
  const handleGit = (newValue: Dayjs | any) => setGit(newValue);
  const handleMtt = (newValue: Dayjs | any) => setMtt(newValue);
  const handleGot = (newValue: Dayjs | any) => setGot(newValue);

  const nav = useNavigate();
  function navigation() {
    nav("/upload_documents");
  }
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [EventData, setEventData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [PoData, setPoData] = useState<any>([]);

  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);

    setOpenDetail(true);
  };

  // const handleData = (params: any) => {
  //   console.log("Event Screen Console....", params.data);
  //   // setPoData(params.data);
  //   getEventData(
  //     params.data.containerNumber,
  //     params.data.hscode,
  //     params.data.invoiceNumber
  //   );
  // };
  const handleLegData = (params: any) => {
    console.log("Event Screen Leg Information....", params.data.legInfo);
    setLegData(params.data.legInfo);

    setLegOpen(true);
  };
  //UseReference Hook To Access Grid Api
  const gridRef = useRef<any>();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  };

  const getEventData = (data: any, hs: any, inv: any) => {
    axios
      .post(
        "https://api.ext.realtrace.realware.tech/po/eventQuery",
        {
          containerNumber: data,
          hscode: hs,
          invoiceNumber: inv,
        },
        { headers }
      )
      .then((evedata) => {
        console.log("Event Data.....", evedata);
        setEventData(evedata);
        setOpenDetail(true);
        console.log("EVENTS  DATA.....");
      });
  };

  const [columnDefs] = useState([
    {
      //   headerName: "SELECT SHIPMENTS",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      // hide: sessionStorage.getItem("role") === "carrier" ? false : true,
    },

    {
      headerName: "INVOICE NUMBER",
      field: "invoiceNumber",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button
            sx={{ height: 20, width: "100%", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      headerName: "INVOICE Date",
      field: "invoiceDate",
      filter: true,
      headerTooltip: "INVOICE Date",
      tooltipField: "invoiceDate",
    },
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      filter: "true",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
    },

    {
      headerName: "HBL NUMBER",
      field: "hblNumber",
      filter: true,
      headerTooltip: "HBL NUMBER",
      tooltipField: "hblNumber",
    },
    {
      headerName: "MBL NUMBER",
      field: "mblNumber",
      filter: true,
      headerTooltip: "MBL NUMBER",
      tooltipField: "mblNumber",
    },
    {
      headerName: "COMMODITY TYPE ",
      field: "commodityType",
      filter: true,
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
    },

    {
      headerName: "HS CODE",
      field: "hscode",
      filter: true,
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
    },
    {
      headerName: "HS CODE DESCRIPTION ",
      field: "hscodeDescription",
      filter: true,
      headerTooltip: "HS CODE DESCRIPTION",
      tooltipField: "hscodeDescription",
    },

    {
      headerName: "SHIPMENT DETAILS",
      children: [
        {
          headerName: "SHIPPER ID",
          filter: true,
          headerTooltip: "SHIPPER ID",
          tooltipField: "shipperId",
          field: "shipperId",
        },
        {
          headerName: "SHIPPER NAME",
          filter: true,
          headerTooltip: "SHIPPER NAME",
          tooltipField: "shipperName",
          field: "shipperName",
          columnGroupShow: "closed",
        },

        {
          headerName: "SHIPMENT WEIGHT",
          filter: true,
          headerTooltip: "SHIPMENT WEIGHT",
          tooltipField: "shipmentWeight",
          field: "shipmentWeight",
          columnGroupShow: "closed",
        },
        {
          headerName: "WEIGHT UOM",
          field: "weightUom",
          filter: true,
          headerTooltip: "WEIGHT UOM",
          tooltipField: "weightUom",
        },
        {
          headerName: "SHIPMENT LENGTH",
          filter: true,
          headerTooltip: "SHIPMENT LENGTH",
          tooltipField: "length",
          field: "length",
          columnGroupShow: "closed",
        },
        {
          headerName: "SHIPMENT WIDTH",
          filter: true,
          headerTooltip: "SHIPMENT WIDTH",
          tooltipField: "width",
          field: "width",
          columnGroupShow: "closed",
        },

        {
          headerName: "SHIPMENT HEIGHT",
          filter: true,
          headerTooltip: "SHIPMENT HEIGHT",
          tooltipField: "height",
          field: "height",
          columnGroupShow: "closed",
        },
      ],
    },

    // {
    //     headerName: "INVOICE DATE",
    //     field: "invoiceDate",
    //     filter: true,
    //     headerTooltip: "INVOICE DATE",
    //     tooltipField: "invoiceDate",
    //   },

    {
      headerName: "PICK UP DETAILS",
      children: [
        {
          headerName: "PICK UP LOCATION",
          field: "pickupLocation",
          filter: true,
          headerTooltip: "PICK UP LOCATION",
          tooltipField: "pickupLocation",
        },

        {
          headerName: "PICKUP DATE",
          filter: true,
          headerTooltip: "PICKUP DATE",
          tooltipField: "pickUpDate",
          field: "pickUpDate",
          columnGroupShow: "closed",
          cellClass: "ag-left-aligned-cell",
        },
        {
          headerName: "PICKUP COUNTRY",
          filter: true,
          headerTooltip: "PICKUP COUNTRY",
          tooltipField: "pickupCountry",
          field: "pickupCountry",
          columnGroupShow: "closed",
          cellClass: "ag-left-aligned-cell",
        },
        {
          headerName: "PICKUP ZIPCODE",
          filter: true,
          headerTooltip: "PICKUP ZIPCODE",
          tooltipField: "pickupZipCode",
          field: "pickupZipCode",
          columnGroupShow: "closed",
          cellClass: "ag-left-aligned-cell",
        },
      ],
    },

    {
      headerName: "CONSIGNEE DETAILS",
      children: [
        {
          headerName: "CONSIGNEE ID",
          filter: true,
          headerTooltip: "CONSIGNEE ID",
          field: "consigneeId",
          tooltipField: "consigneeId",
        },
        {
          headerName: "CONSIGNEE NAME",
          filter: true,
          headerTooltip: "CONSIGNEE NAME",
          field: "consigneeName",
          tooltipField: "consigneeName",
          columnGroupShow: "closed",
        },
      ],
    },
    {
      headerName: "DROP OFF LOCATION",
      field: "dropoffLocation",
      filter: true,
      headerTooltip: "DROP OFF LOCATION",
      tooltipField: "dropoffLocation",
    },
    {
      headerName: "DROP OFF COUNTRY",
      field: "dropoffCountry",
      filter: true,
      headerTooltip: "DROP OFF COUNTRY",
      tooltipField: "dropoffCountry",
    },
    {
      headerName: "DROP OFF ZIPCODE",
      field: "dropoffZipCode",
      filter: true,
      headerTooltip: "DROP OFF ZIPCODE",
      tooltipField: "dropoffZipCode",
    },
    {
      headerName: "FORWARDER DETAILS",
      children: [
        {
          headerName: "FORWARDER ID",
          filter: true,
          headerTooltip: "FORWARDER ID",
          tooltipField: "forwarderId",
          field: "forwarderId",
        },
        {
          headerName: "FORWARDER NAME",
          filter: true,
          headerTooltip: "FORWARDER NAME",
          tooltipField: "forwarderName",
          field: "forwarderName",
          columnGroupShow: "closed",
        },
      ],
    },

    {
      headerName: "CARRIER ID",
      field: "carrierId",
      filter: true,
      headerTooltip: "CARRIER ID",
      tooltipField: "carrierId",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CARRIER NAME",
      field: "carrierName",
      filter: true,
      headerTooltip: "CARRIER NAME",
      tooltipField: "carrierName",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CONTAINER DETAILS",
      children: [
        {
          headerName: "CONTAINER NUMBER",
          field: "containerNumber",
          filter: true,
          headerTooltip: "CONTAINER NUMBER",
          tooltipField: "containerNumber",
        },
        {
          headerName: "CONTAINER TYPE",
          filter: true,
          headerTooltip: "CONTAINER TYPE",
          tooltipField: "containerType",
          field: "containerType",
          columnGroupShow: "closed",
        },

        {
          headerName: "CONTAINER PICKUP DEPOT",
          filter: true,
          headerTooltip: "CONTAINER PICKUP DEPOT",
          tooltipField: "containerType",
          field: "containerType",
          columnGroupShow: "closed",
        },
        {
          headerName: "CONTAINER SIZE",
          filter: true,
          headerTooltip: "CONTAINER SIZE",
          tooltipField: "containerSize",
          field: "containerSize",
          columnGroupShow: "closed",
        },
        {
          headerName: "CONTAINER TARE WEIGHT",
          filter: true,
          headerTooltip: "CONTAINER TARE WEIGHT",
          tooltipField: "containerTareWeight",
          field: "containerTareWeight",
          columnGroupShow: "closed",
        },
      ],
    },
    {
      headerName: "MODE OF SHIPMENT",
      filter: true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeOfShipment",
      field: "modeOfShipment",
      columnGroupShow: "closed",
    },
    {
      headerName: "INCO TERMS",
      filter: true,
      headerTooltip: "INCO TERMS",
      tooltipField: "incoterms",
      field: "incoterms",
      columnGroupShow: "closed",
    },
    {
      headerName: "ETD",
      field: "etd",
      filter: true,
      headerTooltip: "ETD",
      tooltipField: "etd",
    },
    {
      headerName: "ETA",
      field: "eta",
      filter: true,
      headerTooltip: "ETA",
      tooltipField: "eta",
    },

    {
      headerName: "UPLOAD DOCS",
      filter: true,
      headerTooltip: "UPLOAD DOCS",
      tooltipField: "upload docs",
      field: "tranship",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button
            onClick={() => {
              navigation();
            }}
          >
            Upload Doc
          </Button>
        );
      },
    },
  ]);

  //
  const [date, setDate] = useState("");
  const formik = useFormik({
    initialValues: {
      truckNumber: "",
      driverName: "",
      driverNumber: "",
      gateAppointmentDate: "",
      gateAppointmentTime: "",
      gateInDate: "",
      gateInTime: "",
      mountOnTruckDate: "",
      mountOnTruckTime: "",
      gateOutDate: date,
      gateOutTime: "",
      eventGateAppointmentDate: "",
      eventGateInDate: "",
      eventMountOnTruckDate: "",
      eventGateOutDate: "",
    },
    onSubmit: (values, { resetForm }) => {
      let obj = {
        truckNumber: values.truckNumber,
        driverName: values.driverName,
        driverNumber: values.driverNumber,

        gateAppointmentDate: moment(gad).format("YYYY-MM-DD"),
        gateAppointmentTime: moment(gat.$d).format("HH:mm"),
        gateInDate: moment(gid).format("YYYY-MM-DD"),
        gateInTime: moment(git.$d).format("HH:mm"),
        mountOnTruckDate: moment(mtd).format("YYYY-MM-DD"),
        mountOnTruckTime: moment(mtt.$d).format("HH:mm"),
        gateOutDate: moment(god).format("YYYY-MM-DD"),
        gateOutTime: moment(got.$d).format("HH:mm"),
        eventGateAppointmentDate: "",
        eventGateInDate: "",
        eventMountOnTruckDate: "",
        eventGateOutDate: "",
      };
      obj["eventGateAppointmentDate"] =
        moment(gad).format("YYYY-MM-DD") +
        "T" +
        values.gateAppointmentTime +
        ":00.000Z";
      obj["eventGateInDate"] =
        moment(gid).format("YYYY-MM-DD") + "T" + values.gateInTime + ":00.000Z";
      obj["eventMountOnTruckDate"] =
        moment(mtd).format("YYYY-MM-DD") +
        "T" +
        values.mountOnTruckTime +
        ":00.000Z";
      obj["eventGateOutDate"] =
        moment(god).format("YYYY-MM-DD") +
        "T" +
        values.gateOutTime +
        ":00.000Z";
      console.log("Payload for cha custom clearence", obj);
      handlesubmit(obj);
      resetForm({
        values: {
          truckNumber: "",
          driverName: "",
          driverNumber: "",
          gateAppointmentDate: "",
          gateAppointmentTime: "",
          gateInDate: "",
          gateInTime: "",
          mountOnTruckDate: "",
          mountOnTruckTime: "",
          gateOutDate: date,
          gateOutTime: "",
          eventGateAppointmentDate: "",
          eventGateInDate: "",
          eventMountOnTruckDate: "",
          eventGateOutDate: "",
        },
      });
    },
  });
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const url =
    "https://api.ext.realtrace.realware.tech/po/customsTruckInsertList";
  const updateurl =
    "https://api.ext.realtrace.realware.tech/po/updateTruckDetails";
  // const [date, setDate] = useState<any>("");

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    cbrStatus: "open",
    // fbrStatus: "open,processed",
    brStatus: "open",
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      type: "cha",
      chaType: "import",
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  useEffect(() => {
    setLoad(true);
    axios.get(url, config).then((res) => {
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  }, [fromDate, toDate]);

  //gateout-shipmnets
  //eta and etd in all reports after carrier confirmation

  function closeUpdate() {
    setGad(null);
    setGid(null);
    setMtd(null);
    setGod(null);
    setGat(null);
    setGit(null);
    setMtt(null);
    setGot(null);
    setOpenUpdate(false);
  }

  const handlesubmit = (datas: any) => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    if (selectedRowsData.length > 0) {
      // setShow(false);
      console.log("Selected rows...", selectedRowsData);
      let payload: any = { data: [] };
      selectedRowsData.map((data: any, index: number) => {
        let obj = {
          invoiceNumber: data.invoiceNumber,

          bookingReference: data.bookingReference,
          carrierId: data.carrierId,
          carrierName: data.carrierName,
          containerId: data.containerNumber,
          hblNumber: data.hblNumber,
          mblNumber: data.mblNumber,
          shipperId: data.shipperId,
          shipperName: data.shipperName,
          consigneeId: data.consigneeId,
          consigneeName: data.consigneeName,
          hscode: data.hscode,
          itemName: data.itemName,
          itemDescription: data.itemDescription,
          ...datas,
          carrierReference: data.carrierReference,
        };
        payload.data.push(obj);
      });
      console.log("Update data....", payload);
      // PostRequest(updateurl, payload);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };
      axios.post(updateurl, payload, { headers }).then((sucees: any) => {
        console.log(sucees);
        axios.get(url, config).then((res) => {
          setRowData(res.data.primary);
        });
        swal("Success", sucees.data.primary, "success");
        closeUpdate();
      });
      console.log("leg payload", payload);
    }
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  const [poNumber, setPoNumber] = useState<any>([]);
  const handleUpdate = () => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    if (selectedRowsData.length === 0) {
      swal("info", "Please Select shipments", "info");
    } else {
      let pos: any = [];
      selectedRowsData.map((ele: any, index: number) => {
        pos.push(ele.purchaseOrderNumber);
      });
      setPoNumber(pos);
      console.log("1234567", pos);
      setOpenUpdate(true);
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "5px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            disabled={show}
          >
            Update
          </Button>
        </div>
        <Box className="ag-theme-balham">
          <AgGridReact
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            domLayout={domLayout}
            columnDefs={columnDefs}
            sideBar={sideBar}
            onGridReady={onGridReady}
            ref={gridRef}
            rowSelection="multiple"
            paginationPageSize={5}
            pagination={true}
            onFirstDataRendered={onFirstDataRendered}
          ></AgGridReact>
        </Box>
        <div className="dialog">
          {openDetail ? (
            <Full
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={EventData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        {openUpdate ? (
          <Dialog fullWidth open={openUpdate} onClose={closeUpdate}>
            <Box
              sx={{
                margin: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                marginRight: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography variant="h6">
                {poNumber.length > 1 ? (
                  <Tooltip title={<TooltipCom data={poNumber} />}>
                    <p>Truck Details: 1 or More Invoice Numbers</p>
                  </Tooltip>
                ) : (
                  <Typography>Truck Details: {poNumber[0]}</Typography>
                )}
              </Typography>
              <Button onClick={closeUpdate}>
                <CloseIcon />
              </Button>
            </Box>
            <Divider />
            <form onSubmit={formik.handleSubmit} className="form">
              <Box sx={{ margin: "10px" }}>
                <Box sx={{ marginBottom: "25px" }} className="input_render">
                  <Box sx={{ marginRight: "10px" }}>
                    <TextField
                      sx={{ width: "264px" }}
                      size="small"
                      variant="outlined"
                      id="truck-number"
                      name="truckNumber"
                      label="Truck Number"
                      value={formik.values.truckNumber}
                      onChange={formik.handleChange}
                      required
                    />
                  </Box>

                  <Box>
                    <TextField
                      sx={{ width: "264px" }}
                      variant="outlined"
                      id="driver-name"
                      name="driverName"
                      label="Driver Name"
                      value={formik.values.driverName}
                      onChange={formik.handleChange}
                      size="small"
                      required
                    />
                  </Box>
                </Box>

                <Box>
                  <Box
                    sx={{
                      marginRight: "15px",
                      marginBottom: "25px",
                      width: "100%",
                    }}
                  >
                    <TextField
                      //   sx={{ width: "264px" }}
                      variant="outlined"
                      id="driver-mobilenumber"
                      name="driverNumber"
                      label="Driver Mobile Number"
                      value={formik.values.driverNumber}
                      onChange={formik.handleChange}
                      size="small"
                      required
                      fullWidth
                    />
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "25px" }} className="input_render">
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Gate Appointement Date"
                        inputFormat="DD/MM/YYYY"
                        value={gad}
                        onChange={handleGad}
                        renderInput={(params: any) => (
                          <TextField {...params} required size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label=" Gate In Appointment Time"
                        value={gat}
                        onChange={handleGat}
                        renderInput={(params: any) => (
                          <TextField {...params} required size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "25px" }} className="input_render">
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label=" Gate In Date"
                        inputFormat="DD/MM/YYYY"
                        value={gid}
                        onChange={handleGid}
                        renderInput={(params: any) => (
                          <TextField {...params} required size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label=" Gate In  Time"
                        value={git}
                        onChange={handleGit}
                        renderInput={(params: any) => (
                          <TextField {...params} required size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "25px" }} className="input_render">
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="MountOn Truck Date"
                        inputFormat="DD/MM/YYYY"
                        value={mtd}
                        onChange={handleMtd}
                        renderInput={(params: any) => (
                          <TextField {...params} required size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="MountOn Truck Time"
                        value={mtt}
                        onChange={handleMtt}
                        renderInput={(params: any) => (
                          <TextField {...params} required size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "25px" }} className="input_render">
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="GateOut Date"
                        inputFormat="DD/MM/YYYY"
                        value={god}
                        onChange={handleGod}
                        renderInput={(params: any) => (
                          <TextField {...params} required size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="GateOut Time"
                        value={got}
                        onChange={handleGot}
                        renderInput={(params: any) => (
                          <TextField {...params} required size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "end",
                  }}
                >
                  <Button type="submit" color="primary">
                    update
                  </Button>
                </Box>
              </Box>
            </form>
          </Dialog>
        ) : null}
      </Paper>
      <div className="dialog">
        {openDetail ? (
          <CrLineItems
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={PoData}
            status={"accepted"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ImportCustomClearenceList;
