import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import router from './Routings/Routing';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey('CompanyName=Real Variable Digital AssetServices Private Limited,LicensedGroup=Real Variable,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-032136,SupportServicesEnd=22_March_2024_[v2]_MTcxMTA2NTYwMDAwMA==c97f15e3f038787af26a2fef71e443ae');
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
<React.StrictMode>
  <CssBaseline/>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
