import { Alert, AlertTitle, AppBar, Box, Button, Dialog, Slide, Stepper, Toolbar } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from 'axios';
import { getRequest } from '../Services/BaseService';
import { AgGridReact } from 'ag-grid-react';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DashboardCountInfo(props: any) {
    console.log(props.data, "data props")
    const [tabHeaders, setTabHeaders] = useState([])
    const [columnDefs] = useState([
        {
            headerName: "INVOICE NUMBER",
            filter: true,
            headerTooltip: "INVOICE NUMBER",
            tooltipField: "invoiceNumber",
            field: "invoiceNumber",
            //   cellRenderer: (params: ICellRendererParams) => {
            //     return (
            //       <a
            //         style={{ color: "blue", lineHeight: 0.5 }}
            //         onClick={() => handleData(params)}
            //         className="btn btn-info"
            //       >
            //         {params.value}
            //       </a>
            //     );
            //   },
        },
        {
            headerName: "INVOICE DATE",
            field: "brInvoiceDate",
            filter: true,
            headerTooltip: "INVOICE DATE",
            tooltipField: "brInvoiceDate",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "PURCHASE ORDER #",
            field: "purchaseOrderNumber",
            filter: "true",
            headerTooltip: "PURCHASE ORDER #",
            tooltipField: "purchaseOrderNumber",
        },

        {
            headerName: "FORWARDER ID",
            filter: true,
            headerTooltip: "FORWARDER ID",
            tooltipField: "forwarderID",
            field: "forwarderID",
        },
        {
            headerName: "FORWARDER NAME",
            filter: true,
            headerTooltip: "FORWARDER NAME",
            tooltipField: "forwarderName",
            field: "forwarderName",
            columnGroupShow: "closed",
        },
        {
            headerName: "FORWARDER BOOKING REFERENCE",
            filter: true,
            headerTooltip: "FORWARDER BOOKING REFERENCE",
            tooltipField: "forwarderBookingReference",
            field: "forwarderBookingReference",
            columnGroupShow: "closed",
        },
        {
            headerName: "PICK UP LOCATION",
            field: "pickupLocation",
            filter: true,
            headerTooltip: "PICK UP LOCATION",
            tooltipField: "pickupLocation",
        },

        {
            headerName: "PICKUP COUNTRY",
            filter: true,
            headerTooltip: "PICKUP COUNTRY",
            tooltipField: "pickupLocation",
            field: "pickupCountry",
            columnGroupShow: "closed",
        },
        {
            headerName: "PICKUP DATE",
            filter: true,
            headerTooltip: "PICKUP DATE",
            tooltipField: "brPickupDate",
            field: "brPickupDate",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "PICKUP ZIP CODE",
            filter: true,
            headerTooltip: "PICKUP ZIP CODE",
            tooltipField: "brPickupDate",
            field: "pickupZipCode",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "CONSIGNEE ID",
            filter: true,
            headerTooltip: "CONSIGNEE ID",
            tooltipField: "consigneeID",
            field: "consigneeID",
        },
        {
            headerName: "CONSIGNEE NAME",
            filter: true,
            headerTooltip: "CONSIGNEE NAME",
            tooltipField: "consigneeName",
            field: "consigneeName",
            columnGroupShow: "closed",
        },
        {
            headerName: "CONSIGNEE EMAIL ADDRESS",
            filter: true,
            headerTooltip: "CONSIGNEE EMAIL ADDRESS",
            tooltipField: "consigneeEamilAddress",
            field: "consigneeEamilAddress",
        },
        {
            headerName: "CONSIGNEE CONTACT NUMBER",
            filter: true,
            headerTooltip: "CONSIGNEE CONTACT NUMBER",
            tooltipField: "consingneeContactNumber",
            field: "consingneeContactNumber",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "DROPOFF LOCATION",
            filter: true,
            headerTooltip: "DROPOFF LOCATION",
            tooltipField: "dropoffLocation",
            field: "dropoffLocation",
        },
        {
            headerName: "DROPOFF COUNTRY",
            filter: true,
            headerTooltip: "DROPOFF COUNTRTY",
            tooltipField: "dropoffCountry",
            field: "dropoffCountry",
            columnGroupShow: "closed",
        },
        {
            headerName: "DROPOFF ZIPCODE",
            filter: true,
            headerTooltip: "DROPOFF ZIPCODE",
            tooltipField: "dropoffZipCode",
            field: "dropoffZipCode",
            columnGroupShow: "closed",
            cellClass: "ag-left-aligned-cell",
        },

        {
            headerName: "CHA ID",
            filter: true,
            headerTooltip: "CHA ID",
            tooltipField: "chaDetails.chaID",
            field: "chaDetails.chaID",
        },
        {
            headerName: "CHA NAME",
            filter: true,
            headerTooltip: "CHA NAME",
            tooltipField: "chaDetails.chaName",
            field: "chaDetails.chaName",
            columnGroupShow: "closed",
        },
        {
            headerName: "SHIPPER ID",
            filter: true,
            headerTooltip: "SHIPPER ID",
            tooltipField: "createdBy",
            field: "createdBy",
            columnGroupShow: "closed",
        },
        {
            headerName: "SHIPPER  NAME",
            filter: true,
            headerTooltip: "SHIPPER NAME",
            tooltipField: "createdByName",
            field: "createdByName",
            columnGroupShow: "closed",
        },
        {
            headerName: "FBR STATUS",
            filter: true,
            headerTooltip: "FBR STATUS",
            tooltipField: "fbrStatus",
            field: "fbrStatus",
        },
        {
            headerName: "MODE OF SHIPMENT ",
            filter: true,
            headerTooltip: "MODE OF SHIPMENT",
            tooltipField: "modeofShipment",
            field: "modeofShipment",
        },
        {
            headerName: "REJECTED BY",
            filter: true,
            headerTooltip: "REJECTED BY",
            tooltipField: "modifiedBy",
            field: "modifiedBy",
        },
        {
            headerName: "REJECTED DATE",
            filter: true,
            headerTooltip: "REJECTED DATE",
            tooltipField: "brModifiedDate",
            field: "brModifiedDate",
            cellClass: "ag-left-aligned-cell",
        },

    ]);
    const sideBar = {
        toolPanels: [
            {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                },
            },
            {
                id: "filters",
                labelKey: "filters",
                labelDefault: "Filters",
                iconKey: "menu",
                toolPanel: "agFiltersToolPanel",
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                },
            },
        ],
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            floatingFilter: true,
            searchable: true,
            resizable: true,
            sortable: true,
            filter: true,
            domLayout: "autoHeight",
        };
    }, []);
    const [rowData, setRowData] = useState([]);
    const domLayout = "autoHeight";
    useEffect(() => {
        if (props.data.title === 'shipmentOrders') {
            let headers: any = [];
            headers.push({ 'header': 'Pending Purchase Orders' }, { 'header': 'Accepted Purchase Orders' }, { 'header': 'Rejected Purchase Orders' })
            setTabHeaders(headers)
            shipmentsOrdersInfo();
        }
    }, [])
    const [value, setValue] = React.useState("1");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const shipmentsOrdersInfo = () => {
        let req = {
            status: 'pending'
        }
        let res = getRequest('https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/getOrdersData', req)
        console.log(res, "respppp")
    }
    return (
        <div className="dialog">
            <Dialog
                fullScreen
                open={props.onOpen}
                onClose={props.onClose}
                TransitionComponent={Transition}
                sx={{
                    "& .MuiDialog-root": {},
                    zIndex: 10000,
                }}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' component="div" sx={{ ml: 2, flex: 1 }}>
                            SHIPMENT ORDERS
                        </Typography>

                        <Button autoFocus color="inherit" onClick={props.onClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box sx={{ width: "100%", typography: "body1", p: 3 }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab label="Pending Purchase Orders" value="1" />
                                <Tab label="Accepted Purchase Orders" value="2" />
                                <Tab label="Rejected Purchase Orders" value="3" />

                            </TabList>
                        </Box>

                        <TabPanel value='1'>
                                <AgGridReact
                                    className="ag-theme-balham"
                                    animateRows={true}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    domLayout={domLayout}
                                    columnDefs={columnDefs}
                                    sideBar={sideBar}
                                    pagination={true}
                                    paginationPageSize={10}
                                ></AgGridReact>
                            {/* ) : (
                                <Alert severity="info">
                                    <AlertTitle>Info</AlertTitle>
                                    No Data Found
                                </Alert>
                            )} */}
                        </TabPanel>


                        <TabPanel value="2">
                            <Alert severity="info">
                                <AlertTitle>Info</AlertTitle>
                                No Data Found
                            </Alert>
                        </TabPanel>
                        <TabPanel value="3">
                            <Alert severity="info">
                                <AlertTitle>Info</AlertTitle>
                                No Data Found
                            </Alert>
                        </TabPanel>

                    </TabContext>
                </Box>
            </Dialog>
        </div>
    )
}
export default DashboardCountInfo;
