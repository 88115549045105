import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";

import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import CrLineItems from "./CarrierLineItems";

import LegInfoVisual from "./carrierLeginfoVisual";
import { useNavigate, useOutletContext } from "react-router-dom";
import Full from "../../../Helpers/full";
import "../../../Styles.css";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import swal from "sweetalert";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function IntransitEventDisplay() {
  const nav = useNavigate();
  const [showgrid, setShowGrid] = useState(true);
  const [nodata, setNodata] = useState(false);
  const [EventData, setEventData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [transitData, setTransitData] = useState<any>([]);
  const [openLineItems, setOpenLineItems] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [hscode, setHscode] = useState("");
  const [itemname, setItemName] = useState("");
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  const dummyData = {
    "InTransitEvents":[
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 85371000,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Gate Appointment for Container Pickup",
      "Shipment Event Date": "2023-02-02T06:23:38.985Z",
      "Origin": "Port&nbsp;of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 85371000,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Truck Gate In for Container Pickup",
      "Shipment Event Date": "2023-02-03T06:23:58.985Z",
      "portOfOrigin": "Port of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 85371000,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Empty Container Gate Out",
      "Shipment Event Date": "2023-02-04T06:24:58.985Z",
      "portOfOrigin": "Port of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 85371000,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Truck Arrival at Customer Facility",
      "Shipment Event Date": "2023-02-05T06:25:58.985Z",
      "portOfOrigin": "Port of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 85371000,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Container Stuffed",
      "Shipment Event Date": "2023-02-05T07:26:30.985Z",
      "Origin": "Port of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 73181600,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Gate Appointment for Container Pickup",
      "Shipment Event Date": "2023-02-02T06:23:38.985Z",
      "portOfOrigin": "Port of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 73181600,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Truck Gate In for Container Pickup",
      "Shipment Event Date": "2023-02-03T06:23:58.985Z",
      "portOfOrigin": "Port of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 73181600,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Empty Container Gate Out",
      "Shipment Event Date": "2023-02-04T06:24:58.985Z",
      "portOfOrigin": "Port of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
     {
      "Booking Ref #": "FBK-0903",
      "carrierReference": "CRR-0903",
      "carrierId": "carrier001",
      "carrierName": "MAERSK",
      "containerNumber": "CNTR-101",
      "invoiceNumber": "INV-0903",
      "hscode": 73181600,
      "hblNumber": "HBL-002",
      "mblNumber": "MBL-002",
      "vessel": "VSL-1102",
      "vesselName": "MAERSK",
      "voyage": "VYG-1102",
      "shipperId": "shipper001",
      "shipperName": "Hammand Manufacturers",
      "consigneeId": "consignee001",
      "consigneeName": "BMW India",
      "Event Type": "Pre Carriage",
      "Shipment Event Name": "Truck Arrival at Customer Facility",
      "Shipment Event Date": "2023-02-05T06:25:58.985Z",
      "portOfOrigin": "Port of Duisburg",
      "Destination": "Chennai Port",
      "Change in ETA": "2023-01-02T06:23:38.985Z",
      "Remarks": "No",
      "OriginLatitude": 51.435146,
      "OriginLongitude": 6.762692,
      "DestinationLatitude": 13.08441,
      "DestinationLongitude": 80.2899
     },
    ]
    }
  const handleData = (params: any) => {
    console.log("Event Screen Console....", params.data);
    setTransitData(params.data);
    setLineData(params.data);
    setOpenDetail(true);
  };
  const handleLegData = (params: any) => {
    
    console.log("Event Screen Leg Information....", params.data.legInfo);
    setLegData(params.data.legInfo);
    setLineData(params.data);
    setLegOpen(true)
    // if (params.data.legInfo === undefined) {
    //   swal("info", "No Leg Information", "info");
    // } else {
    //   setLegOpen(true);
    // }
  };
  //UseReference Hook To Access Grid Api
  const gridRef = useRef<any>();

  const handlemap = () => {
    setNodata(false);
  };

  useEffect(() => {
    setLoad(true);
    // -------------- data -----------------
    axios.get(url, config).then((res) => {
      setShowGrid(true);
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        
      }
      setLoad(false);
      setRowData(res.data.primary);

      // let jsonObject = res.data.primary.map(JSON.stringify);
              
      // console.log(jsonObject);
        
      // let uniqueSet = new Set(jsonObject);
      // let uniqueArray = Array.from(uniqueSet).map(JSON.parse);


      
      // console.log(uniqueArray);
    });
    // setRowData(dummyData.InTransitEvents)
    // setLoad(false);
  }, [fromDate, toDate]);

  const handleLine = (params: any) => {
    setLineData(params.data);
    setOpenLineItems(true);
    setHscode(params.data.hscode);
    setItemName(params.data.itemName);
  };

  const [columnDefs] = useState([
    {
      headerName: "HBL NUMBER",
      field: "hblNumber",
      filter: true,
      headerTooltip: "HBL NUMBER",
      // tooltipField: "hblNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Tooltip title={"Click Here To View Events"} placement="bottom-start">
            <a
              style={{
                height: 20,
                width: "100%",
                lineHeight: 0.5,
                textAlign: "left",
                color: "blue",
              }}
              onClick={() => handleData(params)}
              className="btn btn-info"
            >
              {params.value}
            </a>
          </Tooltip>
        );
      },
    },
    {
      headerName: "INVOICE NUMBER",
      field: "invoiceNumber",
      filter: true,
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            onClick={() => handleLine(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "LEG INFORMATION",
      field: "legInfo",
      filter: true,
      headerTooltip: "LEG INFORMATION",
      tooltipField: "leginformatin",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            onClick={() => handleLegData(params)}
            className="btn btn-info"
          >
            LegInfo
          </a>
        );
      },
    },
    {
      headerName: "MBL #",
      field: "mblNumber",
      filter: true,
      headerTooltip: "MBL #",
      tooltipField: "mblNumber",
    },
    // ----------------------data ------------
    // {
    //   headerName: "COMMODITY TYPE",
    //   filter: true,
    //   headerTooltip: "COMMODITY TYPE",
    //   tooltipField: "commodityType",
    //   field: "commodityType",
    // },
    {
      headerName: "DRUG TYPE",
      filter: true,
      headerTooltip: "DRUG TYPE",
      tooltipField: "commodityType",
      field: "commodityType",
    },
    {
      headerName: "NDC",
      field: "itemName",
      filter: true,
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "itemName",
    },
    {
      headerName: "DRUG NAME",
      field: "itemDescription",
      filter: true,
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "drug name",
    },

    {
      headerName: "HS CODE",
      field: "hscode",
      filter: true,
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "HS CODE DESCRIPTION",
      field: "hscodeDescription",
      filter: true,
      headerTooltip: "HS CODE DESCRIPTION",
      tooltipField: "hscodeDescription",
    },
    
    {
      headerName: "SHIPMENT LENGTH",
      filter: true,
      headerTooltip: "SHIPMENT LENGTH",
      field: "shipmentWeight",
      tooltipField: "shipmentWeight",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "SHIPMENT WIDTH",
      filter: true,
      headerTooltip: "SHIPMENT WIDTH",
      field: "shipmentWeight",
      tooltipField: "shipmentWeight",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "SHIPMENT HEIGHT",
      filter: true,
      headerTooltip: "SHIPMENT HEIGHT",
      field: "shipmentWeight",
      tooltipField: "shipmentWeight",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "SHIPMENT WEIGHT",
      filter: true,
      headerTooltip: "SHIPMENT WEIGHT",
      field: "shipmentWeight",
      tooltipField: "shipmentWeight",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "WEIGHT UOM",
      field: "weightUom",
      filter: true,
      headerTooltip: "UOM",
      tooltipField: "weightUom",
    },

    
    {
      headerName: "CONTAINER NUMBER",
      field: "containerNumber",
      filter: true,
      headerTooltip: "CONTAINER NUMBER",
      tooltipField: "containerNumber",
    },
    {
      headerName: "CONTAINER TYPE",
      field: "containerType",
      filter: true,
      headerTooltip: "CONTAINER TYPE",
      tooltipField: "containerType",
    },
    {
      headerName: "CONTAINER SIZE",
      field: "containerSize",
      filter: true,
      headerTooltip: "CONTAINER SIZE",
      tooltipField: "containerSize",
    },
    {
      headerName: "CONTAINER TARE WEIGHT",
      field: "payloadCapacity",
      filter: true,
      headerTooltip: "CONTAINER TARE WEIGHT",
      tooltipField: "payloadCapacity",
    },
    {
      headerName: "CONTAINER GROSS WEIGHT",
      field: "containerGrossWeight",
      filter: true,
      headerTooltip: "CONTAINER GROSS WEIGHT",
      tooltipField: "containerGrossWeight",
    },
    {
      headerName: "CONTAINER NET WEIGHT",
      field: "containerNetWeight",
      filter: true,
      headerTooltip: "CONTAINER NET WEIGHT",
      tooltipField: "containerNetWeight",
    },
    {
      headerName: "EMPTY CONTAINER PICKUP DEPOT",
      field: "containerPickUpDepot",
      filter: true,
      headerTooltip: "EMPTY CONTAINER PICKUP DEPOT",
      tooltipField: "containerPickUpDepot",
    },

    {
      headerName: "EMPTY CONTAINER PICKUP DATE",
      field: "containerPickUpDepot",
      filter: true,
      headerTooltip: "EMPTY CONTAINER PICKUP DATE",
      tooltipField: "containerPickUpDepot",
    },

    {
      headerName: "EMPTY CONTAINER PICKUP TIME",
      field: "containerPickUpDepot",
      filter: true,
      headerTooltip: "EMPTY CONTAINER PICKUP TIME",
      tooltipField: "containerPickUpDepot",
    },
    {
      headerName: "FORWARDER ID",
      filter: true,
      headerTooltip: "FORWARDER ID",
      tooltipField: "forwarderId",
      field: "forwarderId",
    },
    {
      headerName: "FORWARDER NAME",
      filter: true,
      headerTooltip: "FORWARDER NAME",
      tooltipField: "forwarderName",
      field: "forwarderName",
      columnGroupShow: "closed",
    },

    {
      headerName: "FORWARDER BOOKING REFERENCE NUMBER",
      filter: true,
      headerTooltip: " FORWARDER BOOKING REFERENCE NUMBER",
      tooltipField: "bookingReference",
      field: "bookingReference",
    },
    

    {
      headerName: "CARRIER REFERENCE",
      field: "carrierReference",
      filter: true,
      headerTooltip: "CARRIER REFERENCE",
      tooltipField: "carrierReference",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CARRIER ID",
      field: "carrierId",
      filter: true,
      headerTooltip: "CARRIER ID",
      tooltipField: "carrierId",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CARRIER NAME",
      field: "carrierName",
      filter: true,
      headerTooltip: "CARRIER NAME",
      tooltipField: "carrierName",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "SHIPPER ID",
      field: "shipperId",
      filter: true,
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "SHIPPER NAME",
      field: "shipperName",
      filter: true,
      headerTooltip: "SHIPPER NAME",
      tooltipField: "shipperName",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    // {
    //   headerName: "PICK UP LOCATION",
    //   field: "pickupLocation",
    //   filter: true,
    //   headerTooltip: "PICK UP LOCATION",
    //   tooltipField: "pickupLocation",
    // },
    {
      headerName: "PICK UP LOCATION(GLN)",
      field: "pickupLocation",
      filter: true,
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
    },

    {
      headerName: "PICKUP DATE",
      filter: true,
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickUpDate",
      field: "pickUpDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP COUNTRY",
      filter: true,
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupCountry",
      field: "pickupCountry",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP ZIPCODE",
      filter: true,
      headerTooltip: "PICKUP ZIPCODE",
      tooltipField: "pickupZipCode",
      field: "pickupZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CONSIGNEE ID",
      filter: true,
      headerTooltip: "CONSIGNEE ID",
      field: "consigneeId",
      tooltipField: "consigneeId",
    },
    {
      headerName: "CONSIGNEE NAME",
      filter: true,
      headerTooltip: "CONSIGNEE NAME",
      field: "consigneeName",
      tooltipField: "consigneeName",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROP OFF LOCATION(GLN)",
      field: "dropoffLocation",
      filter: true,
      headerTooltip: "DROPP OFF LOCATION",
      tooltipField: "dropoffLocation",
    },
    // {
    //   headerName: "DROP OFF LOCATION",
    //   field: "dropoffLocation",
    //   filter: true,
    //   headerTooltip: "DROPP OFF LOCATION",
    //   tooltipField: "dropoffLocation",
    // },
    {
      headerName: "DROP OFF COUNTRY",
      field: "dropoffCountry",
      filter: true,
      headerTooltip: "DROP OFF COUNTRY",
      tooltipField: "dropoffCountry",
    },
    {
      headerName: "DROP OFF ZIPCODE",
      field: "dropoffZipCode",
      filter: true,
      headerTooltip: "DROP OFF ZIPCODE",
      tooltipField: "dropoffZipCode",
    },

    {
      headerName: "PORT OF ORIGIN",
      filter: true,
      tooltipField: "portOfOrigin",
      field: "portOfOrigin",
      headerTooltip: "PORT OF ORIGIN",
    },
    {
      headerName: "VESSEL ID",
      filter: true,
      tooltipField: "vessel",
      field: "vessel",
      headerTooltip: "VESSEL ID",
    },
    {
      headerName: "VESSEL NAME",
      filter: true,
      tooltipField: "vesselName",
      field: "vesselName",
      headerTooltip: "VESSEL NAME",
    },
    {
      headerName: "VOYAGE #",
      filter: true,
      tooltipField: "voyage",
      field: "voyage",
      headerTooltip: "VOYAGE #",
    },
    {
      headerName: "DEPARTURE TERMINAL",
      filter: true,
      tooltipField: "departureTerminal",
      field: "departureTerminal",
      headerTooltip: "DEPARTURE TERMINAL",
    },
    {
      headerName: "VGA CUT OFF TIME",
      filter: true,
      tooltipField: "vgaCutOffTime",
      field: "vgaCutOffTime",
      headerTooltip: "VGA CUT OFF TIME",
    },

    {
      headerName: "VESSEL DEPARTURE TIME",
      filter: true,
      tooltipField: "vesselDepartureTime",
      field: "vesselDepartureTime",
      headerTooltip: "VESSEL DEPARTURE TIME",
    },
    {
      headerName: "OCEAN TRANSIT TIME",
      filter: true,
      headerTooltip: "OCEAN TRANSIT TIME",
      tooltipField: "oceanTransitTime",
      field: "oceanTransitTime",
    },

    {
      headerName: "PORT OF  DISCHARGE",
      filter: true,
      tooltipField: "portOfDischarge",
      field: "portOfDischarge",
      headerTooltip: "PORT OF  DISCHARGE",
    },

    {
      headerName: "DISCHARGE TERMINAL",
      filter: true,
      tooltipField: "dischargeTerminal",
      field: "dischargeTerminal",
      headerTooltip: "DISCHARGE TERMINAL",
    },
    {
      headerName: "ETD",
      filter: true,
      headerTooltip: "ETD",
      tooltipField: "etd",
      field: "etd",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "ETA",
      filter: true,
      headerTooltip: "ETA",
      tooltipField: "eta",
      field: "eta",
      cellClass: "ag-left-aligned-cell",
    },
    

    {
      headerName: "INCO TERMS",
      filter: true,
      headerTooltip: "INCO TERMS",
      tooltipField: "incoterms",
      field: "incoterms",
    },

    {
      headerName: "MODE OF SHIPMENT",
      filter: true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeOfShipment",
      field: "modeOfShipment",
      columnGroupShow: "closed",
    },
  ]);

  //

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const url = "https://api.ext.realtrace.realware.tech/po/intransitList";

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    cbrStatus: "open",
    // fbrStatus: "open,processed",
    brStatus: "open",
  };
  const [role, setRole] = useState(sessionStorage.getItem("role"));

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      type: role,
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>([]);

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              paginationPageSize={10}
              pagination={true}
              ref={gridRef}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <Full
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={EventData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {openLineItems ? (
            <CrLineItems
              onOpen={openLineItems}
              onClose={() => setOpenLineItems(false)}
              data={lineData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
          <div>
            {nodata ? (
              <Dialog open={nodata} onClose={handlemap}>
                <Box
                  sx={{
                    width: "350px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  {/* <img
                  src={require("../../asserts/globe1.jpg")}
                  width="200"
                  height="200"
                  alt="globe image"
                /> */}
                  <Typography variant="h6">No Events To Display</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handlemap}
                    sx={{ width: "25%" }}
                  >
                    OK
                  </Button>
                </Box>
              </Dialog>
            ) : null}
          </div>
        </div>
      </Paper>

      {/* <div className="dialog">
        {openDetail ? (
          <Full
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={EventData}
            status={"open"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div> */}
    </div>
  );
}

export default IntransitEventDisplay;
