import React, { useEffect, useMemo, useState } from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { Alert, AlertTitle, Box } from "@mui/material";
import axios from "axios";
import moment from "moment";
export default function DeviationCharts(props: any) {
  const [shipmentInfo, setShipmentInfo] = useState(false);
  const [chartResp, setChartResp] = useState([]);
  const [chartInfo, setChartInfo] = useState(false);
  const [data, setData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [avgMin, setAvgMin] = useState([]);
  console.log(props, "proopjjjjpppppp");
  const yAxisNames = props.mapInfo ? ["Shipments"] : "";
  const YAxisValues =
    props.tab === "arrivingToday" && props.dataRender
      ? [478, 664, 187]
      : props.tab === "arrivingNextDay" && props.dataRender
        ? [66, 127, 487, 293]
        : props.tab === "arrivingNext7Days" && props.dataRender
          ? [94, 128, 113, 197, 432, 101, 89, 27]
          : props.tab === "between2days" && props.dataRender
            ? [35, 27, 40, 22, 51, 60, 55, 87, 40, 18, 7]
            : props.tab === "betweenWeek" && props.dataRender
              ? [33, 26, 38, 20, 49, 57, 53, 82, 18, 14, 4]
              : props.tab === "betweenMonth" && props.dataRender
                ? [30,19,35,18,46,56,51,77,17,13,0]
                : props.tab === "greaterThanMont" && props.dataRender ?[18,15,24,5,36,44,39,68,5,4,0]: props.mapInfo.yAxisData;
  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    color: ["#004F92", "#e19907"],
    legend: {
      bottom: 0,
      data: yAxisNames,
      type: "scroll",
    },
    xAxis: [
      {
        type: "category",
        data: props.mapInfo.xAxisData,
        splitLine: { show: false },
        axisPointer: {
          type: "shadow",
        },
        label: {
          margin: 5,
        },
        axisLabel: {
          interval: 0,
          rotate: 30, //If the label names are too long you can manage this by rotating the label.
        },
        // name: yAxisNames[0]
      },
    ],
    yAxis: [
      {
        type: "value",
        name: yAxisNames[0],
        min: 0,
        // max: 100,
        interval: 100,
        axisLabel: {
          formatter: "{value}",
        },
      },
    ],
    series: [
      {
        // name: yAxisNames[0],
        type: "bar",
        tooltip: {
          valueFormatter: function (value: any) {
            return value as number;
          },
        },
        data:
          props.tab === "arrivingToday"
            ? YAxisValues
            : props.tab === "arrivingNextDay"
              ? YAxisValues
              : props.tab === "arrivingNext7Days"
                ? YAxisValues
                : props.tab === "between2days"
                  ? YAxisValues
                  : props.tab === "betweenWeek"
                    ? YAxisValues
                    : props.tab === "betweenMonth"
                      ? YAxisValues
                      :props.tab === "greaterThanMont" ? YAxisValues: [18,15,24,5,36,44,39,68,5,4,0],
      },
      {
        name: yAxisNames[1],
        data: props.mapInfo.xAxisData,
      },
    ],
    grid: {
      bottom: "50%",
    },
  };
  const onEvents = {
    click: onChartClick,
  };
  function onChartClick(params: any) {
    console.log(params, "$params");
    shipmentsMoreInfoList(params);
  }
  const shipmentsMoreInfoList = (parmas: any) => {
    setShipmentInfo(true);
    chartResp.filter((item: any) => {
      if (parmas.name === item.carrierName) {
        props.parentCallback({
          status: true,
          carrier: item.shipmentsData,
          carrierName: parmas.name,
        });
      }
    });
  };
  return (
    <div>
      <Box>
        <ReactECharts option={options} onEvents={onEvents} />
      </Box>
    </div>
  );
}
