import { useState } from "react";

import { Box } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tab } from "@mui/material";

import { Typography } from "@material-ui/core";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import ImportCustomClearenceList from "./importCustomList";
import GateOutShipments from "./GateOutShipments";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function ImportAndGateOut() {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) =>
    setValue(newValue);

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "0px",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              display: "flex !important ",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Tab label="Truck Details" value="1" />
            <Tab label="Gate Out Shipments" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{padding:'5px'}}>
          <ImportCustomClearenceList />
        </TabPanel>
        <TabPanel value="2" sx={{padding:'5px'}}>
          <GateOutShipments />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default ImportAndGateOut;
