import { AgGridReact } from "ag-grid-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";

import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Tooltip } from "@mui/material";

import CrLineItems from "./CarrierLineItems";

import DialogContentText from "@mui/material/DialogContentText";

import LegInfo from "./LegsInfo";
import swal from "sweetalert";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function AddLegs() {
  const [open, setOpen] = useState(false);
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const gridRef = useRef<any>();
  const [openTrans, setOpenTrans] = useState(false);
  const [tranship, setTranship] = useState("");
  const [legmsg, setLegMsg] = useState<boolean>(false);
  const [mbl, setMbl] = useState<any>([]);
  let noleginvoice = "";
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);

    setOpenDetail(true);
  };

  function handletreanship() {
    let data = gridRef.current.api.getSelectedRows();
    if (data.length <= 0) {
      swal("info", "Please Select Shipments", "info");
    } else {
      noleginvoice = "";
      let mblnum: any = [];
      let flag = 0;
      data.map((obj: any, index: number) => {
        mblnum.push(obj.mblNumber);
        if (obj.hasOwnProperty("legInfo")) {
          noleginvoice = noleginvoice + " " + `${obj.invoiceNumber}`;
          setLegMsg(true);
          flag = 1;
        }
      });
      console.log("Legs With Invoice Data.....", noleginvoice);
      if (flag === 0) {
        let no = data.findIndex(
          (key: any) => key.tranship.toLowerCase() === "no"
        );
        let yes = data.findIndex(
          (key: any) => key.tranship.toLowerCase() === "yes"
        );
        console.log("Validation msg", no, yes);
        console.log(data);
        if (yes === -1 || no === -1) {
          setTranship(data);
          setOpenTrans(true);
          setMbl(mblnum);
        } else {
          swal("warning", "Select Either Yes or No Tranships !", "warning");
          // setOpen(true);
        }
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
    setLegMsg(false);
  };
  const [columnDefs] = useState([
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },

    {
      headerName: "MBL #",
      filter: true,
      headerTooltip: "MBL #",
      tooltipField: "mblNumber",
      field: "mblNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Tooltip title={"Add Legs Information"}>
            <a
              style={{
                height: 20,
                width: "100%",
                lineHeight: 0.5,
                color: "blue",
              }}
              onClick={() => handleData(params)}
              className="btn btn-info"
            >
              {params.value}
            </a>
          </Tooltip>
        );
      },
    },
    {
      headerName: "CARRIER REFERENCE #",
      field: "carrierReference",
      filter: true,
      headerTooltip: "CARRIER REFERENCE #",
      tooltipField: "carrierReference",
    },
    {
      headerName: "VESSEL ID",
      field: "vesselId",
      filter: true,
      headerTooltip: "VESSEL ID",
      tooltipField: "vesselId",
    },
    {
      headerName: "VESSEL NAME",
      field: "vesselName",
      filter: true,
      headerTooltip: "VESSEL NAME",
      tooltipField: "vesselName",
    },
    {
      headerName: "ORIGIN COUNTRY",
      field: "origin",
      filter: "true",
      headerTooltip: "origin",
      tooltipField: "origin",
    },
    {
      headerName: "DESTINATION COUNTRY",
      filter: true,
      headerTooltip: "DESTINATION",
      tooltipField: "destination",
      field: "destination",
    },
    {
      headerName: "PORT OF ORIGIN",
      field: "portOfOrigin",
      filter: "true",
      headerTooltip: "portOfOrigin",
      tooltipField: "portOfOrigin",
    },
    {
      headerName: "PORT OF DESTINATION",
      filter: true,
      headerTooltip: "DESTINATION",
      tooltipField: "portOfDischarge",
      field: "portOfDischarge",
    },
    {
      headerName: "ETD",
      field: "etd",
      filter: true,
      headerTooltip: "ETD",
      tooltipField: "etd",
    },

    {
      headerName: "ETA",
      field: "eta",
      filter: true,
      headerTooltip: "eta",
      tooltipField: "eta",
    },
    {
      headerName: "TRANSHIP",
      field: "tranship",
      filter: true,
      headerTooltip: "TRANSHIP",
      tooltipField: "tranship",
    },
  ]);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const url = "https://api.ext.realtrace.realware.tech/po/mblListInCarrier";

  // let options = {
  //   start: 0,
  //   end: 10,
  //   prevStart: "",
  //   objectEnd: "",
  //   cbrStatus: "open,processed",
  //   brStatus: "accepted",
  // };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    // params: options,
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //call function
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  useEffect(() => {}, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    axios.get(url, config).then((res) => {
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  }, []);

  const autosize = useCallback(() => {
    console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    console.log(gridRef.current.columnApi);
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.maincontent}>
        <div className="ag-theme-balham">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => handletreanship()}
            >
              Add Legs
            </Button>
          </div>

          <AgGridReact
            ref={gridRef}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            domLayout={domLayout}
            columnDefs={columnDefs}
            sideBar={sideBar}
            onGridReady={onGridReady}
            paginationPageSize={10}
            pagination={true}
            // onFirstDataRendered={onFirstDataRendered}
            rowSelection="multiple"
          ></AgGridReact>
        </div>
      </Container>
      <div className="dialog">
        {openDetail ? (
          <CrLineItems
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={PoData}
            status={"accepted"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {openTrans ? (
          <LegInfo
            onOpen={openTrans}
            onClose={() => setOpenTrans(false)}
            data={tranship}
            mbl={mbl}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Either Yes or No Tranships</DialogTitle>
        <DialogContentText>
          Please Select either Shipments with tranship yes or no,you can't
          select both
        </DialogContentText>
      </Dialog>
      <ToastContainer style={{ zIndex: 10000 }} />
      {legmsg ? (
        <Dialog open={legmsg} onClose={handleClose}>
          <DialogTitle>
            Invoice Numbers {noleginvoice[0]} Has Leg Data
          </DialogTitle>
          {/* <DialogContentText>
            Please Select either Shipments with tranship yes or no,you can't
            select both
          </DialogContentText> */}
        </Dialog>
      ) : null}
    </div>
  );
}

export default AddLegs;
