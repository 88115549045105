import React, { useEffect, useState } from 'react'

import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { Box, Grid, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Echarts from './BarWithLinesEcharts';
export default function BarEcharts(props: any) {
    console.log("3457898765457890-097654578909876", props)
    let url = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/LifeCycleTopResponsesGraph'
    let url1 = 'https://api.ext-preprocessor.realtrace.realware.tech/api/v1/dashboard/LifeCycleReponseInThreeHrGraph'
    const [shipmentInfo, setShipmentInfo] = useState(false)
    const [carrierInfo, setCarrierInfo] = useState<any>()
    const [carrierPercentage, setCarrierPercentage] = useState<any>()
    const [bookingRespTime, setBookingRespTime] = useState()
    const [bookingRespHrs, setBookingRespHrs] = useState()
    const [bookingRespPercentage, setBookingRespPercentage] = useState()
    const [bookingRespIn3Hrs, setBookingRespIn3Hrs] = useState()
    const [bookingInfo, setBookingInfo] = useState<any>()
    const [alignment, setAlignment] = React.useState('currentMonth');
    const onEvents = {
        click: onChartClick,
    };
    const ChartData = {
        fwd: {
            name: ['fwd-001', 'fwd-002',
                'fwd-003',
                'fwd-004',
                'fwd-005']
        },
        carrier: {
            name: ['CRR-001', 'CRR-002', 'CRR-003', 'CRR-004', 'CRR-005']
        },
        brData: {
            totalShipments: [1342,
                1002,
                1066,
                949,
                1041,
            ],
            pendingShipments: [97,
                106,
                95,
                99,
                103,
            ]
        },
        acceptedByfwd: {
            totalShipments: [1342,
                1002,
                1066,
                949,
                1041,
            ],
            pendingShipments: [97,
                106,
                95,
                99,
                103,
            ]
        },
        ConfirmedByCarrier: {
            totalShipments: [1342,
                1002,
                1066,
                949,
                1041,
            ],
            pendingShipments: [97,
                106,
                95,
                99,
                103,
            ]
        }

    }
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
        let obj = {
            status: props.pageInfo,
            dateFilterOn: newAlignment
        }
        bookingRequests(obj)

    };
    function onChartClick(params: any) {

        // shipmentsMoreInfoList()

    }
    const shipmentsMoreInfoList = () => {
        setShipmentInfo(true);
        props.parentCallback(true)

    }
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    let options1 = {

        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: props.pageInfo != 'bookingRequests' ? ['Avg. Booking Response Time', 'City Beta', 'City Gamma'] : ['Total Bookings'],
            bottom: 0,


        },
        grid: {
            left: 100
        },
        xAxis: {
            type: 'value',
            // name:props.pageInfo != 'bookingRequests' ? 'Avg. Booking Response Time': 'Total Bookings',


        },
        yAxis: {
            type: 'category',
            inverse: true,
            data: ChartData.fwd.name
            //  props.pageInfo !== 'confirmedByCarrier' ? bookingRespTime : ChartData.carrier.name

        },
        series: [
            {
                name: props.pageInfo != 'bookingRequests' ? 'Avg. Booking Response Time' : 'Total Bookings',
                type: 'bar',
                data: ChartData.brData.totalShipments,
                //  props.pageInfo === 'bookingRequests' ? bookingRespHrs : props.pageInfo === 'acceptedByForwarder' ? ChartData.acceptedByfwd.totalShipments : ChartData.ConfirmedByCarrier.totalShipments,
                
                markPoint: {
                    symbolSize: 1,
                    symbolOffset: [0, '50%'],
                    label: {
                        formatter: '{a|{a}\n}{b|{b} }{c|{c}}',
                        backgroundColor: 'rgb(242,242,242)',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        borderRadius: 4,
                        padding: [4, 10],
                        lineHeight: 26,
                        // shadowBlur: 5,
                        // shadowColor: '#000',
                        // shadowOffsetX: 0,
                        // shadowOffsetY: 1,
                        position: 'right',
                        distance: 20,
                        rich: {
                            a: {
                                align: 'center',
                                color: '#fff',
                                fontSize: 18,
                                textShadowBlur: 2,
                                textShadowColor: '#000',
                                textShadowOffsetX: 0,
                                textShadowOffsetY: 1,
                                textBorderColor: '#333',
                                textBorderWidth: 2
                            },
                            b: {
                                color: '#333'
                            },
                            c: {
                                color: '#ff8811',
                                textBorderColor: '#000',
                                textBorderWidth: 1,
                                fontSize: 22
                            }
                        }
                    },

                }
            },

        ]
    };
    let options2 = {
        color: ['#e19907'],
        grid: {
            left: 100,

        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: props.pageInfo != 'bookingRequests' ? ['Booking Response in < 3 HR', 'City Beta', 'City Gamma'] : ['Pending Bookings'],
            bottom: 0,


        },

        xAxis: {
            type: 'value',
            // name:props.pageInfo != 'bookingRequests' ? 'Booking Response in < 3 HR' : 'Pending Bookings',
            min: 0,
            // max: 100,
            interval: 25,

        },
        yAxis: {
            type: 'category',
            inverse: true,
            data: ChartData.fwd.name
            // props.pageInfo !== 'confirmedByCarrier' ? bookingRespPercentage : ChartData.carrier.name

        },
        series: [
            {
                name: props.pageInfo != 'bookingRequests' ? 'Booking Response in < 3 HR' : 'Pending Bookings',
                type: 'bar',
                data: ChartData.brData.pendingShipments,
                // props.pageInfo === 'bookingRequests' ? bookingRespIn3Hrs : props.pageInfo === 'acceptedByForwarder' ? ChartData.acceptedByfwd.pendingShipments : ChartData.ConfirmedByCarrier.pendingShipments,

                // label: seriesLabel,
                markPoint: {
                    symbolSize: 1,
                    symbolOffset: [0, '50%'],
                    label: {
                        formatter: '{a|{a}\n}{b|{b} }{c|{c}}',
                        backgroundColor: 'rgb(242,242,242)',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        borderRadius: 4,
                        padding: [4, 10],
                        lineHeight: 26,
                        // shadowBlur: 5,
                        // shadowColor: '#000',
                        // shadowOffsetX: 0,
                        // shadowOffsetY: 1,
                        position: 'right',
                        distance: 20,
                        rich: {
                            a: {
                                align: 'center',
                                color: '#fff',
                                fontSize: 18,
                                textShadowBlur: 2,
                                textShadowColor: '#000',
                                textShadowOffsetX: 0,
                                textShadowOffsetY: 1,
                                textBorderColor: '#333',
                                textBorderWidth: 2
                            },
                            b: {
                                color: '#333'
                            },
                            c: {
                                color: '#ff8811',
                                textBorderColor: '#000',
                                textBorderWidth: 1,
                                fontSize: 22
                            }
                        }
                    },

                }
            },

        ]
    };
    let obj = {
        status: props.pageInfo,
        dateFilterOn: alignment
    }
    useEffect(() => {
        console.log(props, "propssss")
        bookingRequests(obj)
    }, [])
    const bookingRequests = (status: any) => {
        config.params = status
        axios.get(url, config).then((res) => {
            setBookingInfo(res.data.data)
            console.log(res.data.data, "widgets1");
            let array: any = []
            let array1: any = []
            res.data.data.filter((item: any) => {
                // sessionStorage.getItem('role') === 'forwarder' ? 
                props.pageInfo === 'confirmedByCarrier' ?
                array.push(item.carrierId) :
                    array.push(item.forwarderId)
                props.pageInfo === 'bookingRequests' ? array1.push(item.totalBookingsCount) : array1.push(item.AvgBookingResponseTimeInHrs)
            })
            setBookingRespTime(array)
            setBookingRespHrs(array1)
        });
        axios.get(url1, config).then((res) => {
            setCarrierPercentage(res.data.data)
            let array: any = [];
            let array1: any = []
            res.data.data.filter((item: any) => {
                // sessionStorage.getItem('role') === 'forwarder' ? 
                props.pageInfo === 'confirmedByCarrier' ?
                array.push(item.carrierId) : array.push(item.forwarderId)
                

                props.pageInfo === 'bookingRequests' ? array1.push(item.totalPendingBookingsCount) : array1.push(item.lessThan3HrApprovedPercentage)
            })
            setBookingRespPercentage(array)
            setBookingRespIn3Hrs(array1)
            console.log(res.data.data, "widgets2");
        });

    }
    return (
        <div>
            {/* {props.data === 'avgTime' ? */}
            {/* {carrierInfo != undefined ?
                <ReactECharts option={options1} /> : ''} */}
            {/* {props.data === 'avgHr' ? */}
            {/* {carrierPercentage != undefined ?

                <ReactECharts option={options2}  onEvents={onEvents}/> : ''} */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                {props.pageInfo === 'bookingRequests' ?
                    <Box>
                        {sessionStorage.getItem('role') === 'shipper' || sessionStorage.getItem('role') === 'consignee' || sessionStorage.getItem('role') === 'carrier' ?
                            <Typography variant='h6' sx={{ fontSize: '1rem' }}>BOOKING REQUESTS BY FORWARDER</Typography> :
                            <Typography variant='h6' sx={{ fontSize: '1rem' }}>TOP 5 CARRIER RESPONSES</Typography>}
                    </Box>
                    : props.pageInfo === 'acceptedByForwarder' ?
                        <Box>
                            <Typography variant='h6' sx={{ fontSize: '1rem' }}>TOP 5 BOOKING RESPONSES</Typography>
                        </Box> :
                        <Box>
                            <Typography variant='h6' sx={{ fontSize: '1rem' }}>TOP 5 CARRIER RESPONSES</Typography>
                        </Box>

                }

                <Box component="form" sx={{ display: 'flex' }} >
                    {/* <Typography variant='h6' sx={{ fontSize: '1rem' }}>RELIABILITY: &nbsp;</Typography> */}
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"

                    >
                        <ToggleButton value="last12Months" sx={{ padding: '5px 11px' }}>Last 12 Months</ToggleButton>
                        <ToggleButton value="last6Months" sx={{ padding: '5px 11px' }}>Last 6 Months</ToggleButton>
                        <ToggleButton value="last3Months" sx={{ padding: '5px 11px' }}>Last 3 Months</ToggleButton>
                        <ToggleButton value="currentMonth" sx={{ padding: '5px 11px' }}>Current Month</ToggleButton>
                    </ToggleButtonGroup>

                </Box>

            </Box>
            <Grid container spacing={2} sx={{ display: 'flex', mt: 0 }}>
                <Grid item md={6} lg={6} sm={6}>
                    <Paper>
                        {/* {props.data === 'avgTime' ? <ReactECharts option={options1} /> : ''} */}
                        {/* {props.data === 'totalBookings' ?  */}
                        {alignment ?
                            <ReactECharts option={options1} /> : ''}
                        {/* : ''} */}
                    </Paper>
                </Grid>
                <Grid item md={6} lg={6} sm={6}>
                    <Paper>
                        {/* {props.data === 'avgHr' ? <ReactECharts option={options2} onEvents={onEvents} /> : ''} */}
                        {/* {props.data === 'pendingBookings' ?  */}
                        {alignment ?
                            <ReactECharts option={options2} onEvents={onEvents} /> : ''}                         {/* : ''} */}
                    </Paper>
                </Grid>
            </Grid>
            {props.pageInfo != 'bookingRequests' ?
            <Grid sx={{mt:2}}>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ fontSize: '1rem' }}>MONTH ON MONTH TREND</Typography>
                    <Paper>
                        {alignment != undefined ?
                            <Echarts data={'months'} page={props.pageInfo} pageInfo={props.pageInfo.tab} /> : ''}
                    </Paper>

                </Grid>
            </Grid>: ''}




        </div>
    )
}
