import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import {
  ColumnResizedEvent,
  FirstDataRenderedEvent,
  ICellRendererParams,
} from "ag-grid-community";
import "../../../Styles.css";
import { DialogActions, DialogTitle, Tooltip } from "@material-ui/core";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import ItemDetail from "../../../Helpers/ItemDetail";
import sizeConfigs from "../../../Config/sizeConfigs";
import colorConfigs from "../../../Config/colorConfig";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import { Console } from "console";
// import SubHeader from "../../common/SubHeader";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    // marginTop: "0px",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    marginTop: "0px",
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function PoAccept() {
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();

  const [showgrid, setShowGrid] = useState(true);
  const handleData = (params: any) => {
    setPoData(params.data);
    setOpenDetail(true);
  };

  const [state, setState] = useState();
  const nav = useNavigate();

  //----------- table headers ---------------------

  const managedoc = (params: any) => {
    // console.log(params.data.purchaseOrderNumber);
    setState(params.data.purchaseOrderNumber);
    nav("/manage_documents", { state: params.data.purchaseOrderNumber });
  };

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  const [columnDefs] = useState([
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      filter: true,
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
      cellClass: "ag-left-aligned-cell",

      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{ lineHeight: 0.5, color: "blue" }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "PURCHASE ORDER VERSION #",
      field: "purchaseOrderVersion",
      filter: true,
      headerTooltip: "PURCHASE ORDER VERSION #",
      tooltipField: "purchaseOrderVersion",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PURCHASE ORDER DATE",
      field: "poDate",
      filter: true,
      headerTooltip: "PURCHASE ORDER DATE",
      tooltipField: "poDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER ID",
      field: "customerId",
      filter: true,
      headerTooltip:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER ID",
      tooltipField: "customerId",
    },
    {
      headerName:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER NAME"
          : "CUSTOMER NAME",
      field: "customerName",
      filter: true,
      headerTooltip:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER NAME"
          : "CUSTOMER NAME",
      tooltipField: "customerName",
    },
    {
      headerName: "SHIP TO ADDRESS",
      field: "shipToAddress",
      filter: true,
      headerTooltip: "SHIP TO ADDRESS",
      tooltipField: "shipToAddress",
    },
    {
      headerName: "ACCEPTED BY",
      field: "updatedBy",
      filter: true,
      headerTooltip: "ACCEPTED BY",
      tooltipField: "updatedBy",
    },
    {
      headerName: "ACCEPTED DATE",
      field: "updatedDate",
      filter: true,
      headerTooltip: "ACCEPTED DATE",
      tooltipField: "updatedDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "ViewDocs",
      filter: true,
      headerTooltip: "CREATED BY",
      cellRenderer: (params: ICellRendererParams) => {
        return <Button onClick={() => managedoc(params)}>View Doc</Button>;
      },
    },
  ]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const url = Environment_Realtraceb_Dev.BASE_URL + "/purchaseOrderList";

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    poStatus: "accepted",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      flexDirection: "column",
      display: "flex",
      domLayout: "autoHeight",
    };
  }, []);

  // console.log(fromDate, toDate);

  //call function
  const [rowData, setRowData] = useState([]);
  const classes = useStyles();
  const domLayout = "autoHeight";

  useEffect(() => {
    console.log("PO ACCEPT CALLING ..................");
    setLoad(true);
    axios.get(url, config).then((res) => {
      setShowGrid(true);
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setRowData(res.data.primary);
      setLoad(false);
      // console.log(res.data.primary);
    });
  }, [fromDate, toDate, showgrid]);
  const gridRef = useRef<any>();

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
              ref={gridRef}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Box>

        <div className="dialog">
          {openDetail ? (
            <ItemDetail
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={PoData}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
}

export default PoAccept;
