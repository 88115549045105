// React Imports
import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import * as React from "react";

//API Imports
import axios from "axios";
// AgGrid Imports
import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

//Dialogue imports
import { DialogActions, DialogTitle } from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

//Extra Imports
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

// Icons Imports
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";

// Material Ui Common Imports
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";

import { TransitionProps } from "@mui/material/transitions";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { PostRequest } from "../../../Services/BaseService";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "../../../Styles.css";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";

//Transition for Dialogue
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//Predefined Styles For AG-Grid
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    padding: "15px",

    // fontWeight: "400",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
//Componenets Starts Here

function CrLineItems(props: any) {
  const [dummydata]=useState([
    {
      "docReference":"INV-24A00608_CRR-24MR04033_FBK-24A08",
      "carrierReference":"CRR-24MR04033",
      'mblNumber':'MBL1024',
      "invoiceNumber":"INV-24A00608",
      "commodityType":"Auto Spare Part",
      "pickupCountry":"JAPAN",
      "dropoffCountry":"INDIA",
      "hscode":"73269099",
      "hscodeDescription":"BATTERY 95959514 FOR MOTOR VEHICLE (CHEVROLET CAR) (CAPTIVECONSUMPTION)",
      "itemName":"BM-44",
      "itemDescription":"Auto Spare Part",
      "hblNumber":"HBL-24MR",
      "lineShipStatus":"part shipment",
      "lineDelStatus":"delayed",
      "packageId":"INV-24A00608_73269099_BM-44_Auto Spare Part",
      "carrierId":"carrier001",
      "carrierName":"carrier 001",
      "packageDetails":{"numberofPackages":1,"type":"CARTON","weight":5.5,"length":"20FT","height":"20FT","width":"40FT","specialInstructions":"Not Applicable"},"hazardousGoods":"Not Applicable","brStatus":"accepted","bookingCreationDate":"2023-03-24T00:00:00.000Z","createdBy":"fwd-001","createdByName":"forwarder 001","createdDate":"2023-03-24T19:06:11.000Z","modifiedBy":"fwd-001","modifiedByName":"forwarder 001","modifiedDate":"2023-03-24T19:06:11.000Z","bookingReference":"FBK-24A08","updatedAt":"2023-03-27T09:18:19.001Z","cbrCreatedDate":"2023-03-24",
      "eta":'12/12/2022',"etd":'12/11/2022'
    }
  ])
  // console.log(props.data);
  // Variables
  const classes = useStyles();
  const AR_url =
    Environment_Realtraceb_Dev.BASE_URL + "/updateCarrierStatusItr";
  const url = Environment_Realtraceb_Dev.BASE_URL + "/carrierItemList";
  //UseReference Hook To Access Grid Api
  const gridRef = useRef<any>();
  //UseState Hooks
  const [rowData, setRowData] = useState<any>([]);
  //Codes to send TO API
  const [containercode, setcontainercode] = useState("");
  const [hscode, setHscode] = useState("");
  const [mblcode, setMblCode] = useState("");
  const [remarks, setRemarks] = useState("");
  const [cr, setCr] = useState("");
  //used to set qr code image
  const [link, setLink] = useState("");
  const qrimage = "https://api.ext.realtrace.realware.tech/qrImage/";
  //Used To Open Dialogues
  const [QrOpen, SetQrOpen] = useState(false);
  const [hblOpen, setHblOpen] = useState(false);
  const [remarkOpen, setRemarkOpen] = useState(false);
  //Used to Identify Which Screen Like accept reject or pending
  const [status, setStatus] = useState("");

  const [carrierObj, setCarrierObj] = useState<any>([]);
  const location = useLocation();

  let payload = {
    docReference: props.data.docReference,
    brStatus: props.status,
  };
  let payload_intrans = {
    docReference: props.data.docReference,
    brStatus: props.status,
    itemName: props.data.itemName,
    hscode: props.data.hscode,
  };
  let options =
    location.pathname === "/intransit_shipments" ||
    location.pathname === "/confirmed_by_carrier"
      ? payload_intrans
      : payload;

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

  //Functions To Get MBL REMARK AND CONTAINER NUMBER
  const handleChangeMbl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMblCode(event.target.value);
  };

  const handleChangeReamark = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemarks(event.target.value);
  };

  const handleChangecontainerNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setcontainercode(event.target.value);
  };

  //Function to get bbl and container number on pressing Accept in lines
  const handleAccept = () => {
    setStatus("accepted");
    setHblOpen(true);
  };
  //Function to get Remarks on pressing Accept in lines
  const handleReject = () => {
    setStatus("rejected");
    setRemarkOpen(true);
  };
  //qrscanner code
  const getLink = () => {
    let link = props.data.qrImage.split("/");
    let qr = qrimage + link[3];
    setLink(qr);
  };

  const [name, setName] = useState();

  //AG GRID Api Column Defination
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  //AG grid SideBar settings
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  //Ag grid column defination

  // const [columnDefs] = useState([
  //   {
  //     headerName: "SELECT SHIPMENTS",
  //     headerCheckboxSelection: true,
  //     checkboxSelection: true,
  //     hide:
  //       props.status === "open" && sessionStorage.getItem("role") === "carrier"
  //         ? false
  //         : true,
  //   },
  //   {
  //     headerName: "HBL #",
  //     field: "hblNumber",
  //     filter: true,
  //     headerTooltip: "HBL #",
  //     tooltipField: "hblNumber",
  //     // hide: location.pathname == "/pending_carrier_confirmation" ? true : false,
  //   },
  //   {
  //     headerName: "MBL #",
  //     field: "mblNumber",
  //     filter: true,
  //     headerTooltip: "MBL #",
  //     tooltipField: "mblNumber",
  //     hide: location.pathname == "/pending_carrier_confirmation" ? true : false,
  //   },

  //   {
  //     headerName: "INVOICE NUMBER",
  //     filter: true,
  //     headerTooltip: "INVOICE NUMBER",
  //     tooltipField: "invoiceNumber",
  //     field: "invoiceNumber",
  //     hide: true,
  //   },
  //   // {
  //   //   headerName: "COMMODITY TYPE",
  //   //   filter: true,
  //   //   headerTooltip: "COMMODITY TYPE",
  //   //   tooltipField: "commodityType",
  //   //   field: "commodityType",
  //   // },
  //   // {
  //   //   headerName: "ITEM NAME",
  //   //   field: "itemName",
  //   //   filter: true,
  //   //   headerTooltip: "PACKAGE TYPE",
  //   //   tooltipField: "itemName",
  //   //   hide: sessionStorage.getItem("role") == "cha" ? true : false,
  //   // },
  //   // {
  //   //   headerName: "ITEM DESCRIPTION",
  //   //   field: "itemDescription",
  //   //   filter: true,
  //   //   headerTooltip: "PACKAGE TYPE",
  //   //   tooltipField: "itemDescription",
  //   //   hide: sessionStorage.getItem("role") == "cha" ? true : false,
  //   // },
  //   {
  //     headerName: "DRUG TYPE",
  //     filter: true,
  //     headerTooltip: "DRUG TYPE",
  //     tooltipField: "commodityType",
  //     field: "commodityType",
  //   },
  //   {
  //     headerName: "NDC",
  //     field: "itemName",
  //     filter: true,
  //     headerTooltip: "NDC",
  //     tooltipField: "itemName",
  //     hide: sessionStorage.getItem("role") == "cha" ? true : false,
  //   },
  //   {
  //     headerName: "DRUG NAME",
  //     field: "itemDescription",
  //     filter: true,
  //     headerTooltip: "DRUG NAME",
  //     tooltipField: "itemDescription",
  //     hide: sessionStorage.getItem("role") == "cha" ? true : false,
  //   },

  //   {
  //     headerName: "HS CODE",
  //     filter: true,
  //     headerTooltip: "HS CODE",
  //     tooltipField: "hscode",
  //     field: "hscode",
  //     cellClass: "ag-left-aligned-cell",
  //   },
  //   {
  //     headerName: "HS CODE DESCRIPTION",
  //     field: "hscodeDescription",
  //     filter: true,
  //     headerTooltip: "HS CODE DESCRIPTION",
  //     tooltipField: "hscodeDescription",
  //   },

  //   // {
  //   //   headerName:
  //   //     props.status === "rejected"
  //   //       ? "REJECTED BY"
  //   //       : props.status === "open"
  //   //       ? "CREATED BY"
  //   //       : "ACCEPTED BY",
  //   //   filter: true,
  //   //   headerTooltip:
  //   //     props.status === "rejected"
  //   //       ? "REJECTED BY"
  //   //       : props.status === "open"
  //   //       ? "CREATED BY"
  //   //       : "ACCEPTED BY",
  //   //   tooltipField: "createdBy",
  //   //   field: "createdBy",
  //   // },
  //   // {
  //   //   headerName:
  //   //     props.status === "rejected"
  //   //       ? "REJECTED DATE"
  //   //       : props.status === "open"
  //   //       ? "CREATED DATE"
  //   //       : "ACCEPTED DATE",
  //   //   filter: true,
  //   //   headerTooltip:
  //   //     props.status === "rejected"
  //   //       ? "REJECTED DATE"
  //   //       : props.status === "open"
  //   //       ? "CREATED DATE"
  //   //       : "ACCEPTED DATE",
  //   //   tooltipField: "cbrCreatedDate",
  //   //   field: "cbrCreatedDate",
  //   // },
  //   // {
  //   //   headerName: "CARRIER ID",
  //   //   filter: true,
  //   //   headerTooltip: "CARRIER ID",
  //   //   tooltipField: "carrierId",
  //   //   field: "carrierId",
  //   // },
  //   // {
  //   //   headerName: "CARRIER NAME",
  //   //   filter: true,
  //   //   headerTooltip: "CARRIER NAME",
  //   //   tooltipField: "carrierName",
  //   //   field: "carrierName",
  //   // },

  //   {
  //     headerName: "NUMBER OF PACKAGES",
  //     field: "packageDetails.numberofPackages",
  //     filter: true,
  //     headerTooltip: "NUMBER OF PACKAGES",
  //     tooltipField: "number of packages",
  //     cellClass: "ag-left-aligned-cell",
  //   },
  //   {
  //     headerName: "PACKAGE TYPE",
  //     field: "packageDetails.type",
  //     filter: true,
  //     headerTooltip: "PACKAGE TYPE",
  //     tooltipField: "packagetype",
  //   },
  //   {
  //     headerName: "PACKAGE WEIGHT",
  //     field: "packageDetails.weight",
  //     filter: true,
  //     headerTooltip: "PACKAGE WEIGHT",
  //     tooltipField: "packageweight",
  //     cellClass: "ag-left-aligned-cell",
  //   },
  //   {
  //     headerName: "PACKAGE LENGTH",
  //     field: "packageDetails.length",
  //     filter: true,
  //     headerTooltip: "PACKAGE LENGTH",
  //     tooltipField: "packagelength",
  //     cellClass: "ag-left-aligned-cell",
  //   },
  //   {
  //     headerName: "PACKAGE WIDTH",
  //     field: "packageDetails.width",
  //     filter: true,
  //     headerTooltip: "PACKAGE WIDTH",
  //     tooltipField: "packagewidth",
  //     cellClass: "ag-left-aligned-cell",
  //   },
  //   {
  //     headerName: "PACKAGE HEIGHT",
  //     field: "packageDetails.height",
  //     filter: true,
  //     headerTooltip: "PACKAGE HEIGHT",
  //     tooltipField: "packageheight",
  //     cellClass: "ag-left-aligned-cell",
  //   },

  //   {
  //     headerName: "PACKAGE SPECIAL INSTRUCTIONS",
  //     field: "packageDetails.specialInstructions",
  //     filter: true,
  //     headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
  //     tooltipField: "packagespecialinstructions",
  //   },
  //   {
  //     headerName: "CONTAINER NUMBER",
  //     field: "containerNumber",
  //     filter: true,
  //     headerTooltip: "CONTAINER NUMBER",
  //     tooltipField: "containerNumber",
  //     hide: sessionStorage.getItem("role") == "cha" ? false : true,
  //   },
  //   {
  //     headerName: "CONTAINER TYPE",
  //     filter: true,
  //     headerTooltip: "CONTAINER TYPE",
  //     tooltipField: "containerType",
  //     field: "containerType",
  //     columnGroupShow: "closed",
  //     hide: sessionStorage.getItem("role") == "cha" ? false : true,
  //   },
  //   {
  //     headerName: "CONTAINER SIZE",
  //     filter: true,
  //     headerTooltip: "CONTAINER SIZE",
  //     tooltipField: "containerSize",
  //     field: "containerSize",
  //     columnGroupShow: "closed",
  //     cellClass: "ag-left-aligned-cell",
  //     hide: sessionStorage.getItem("role") == "cha" ? false : true,
  //   },
  //   {
  //     headerName: "CONTAINER TARE WEIGHT",
  //     filter: true,
  //     headerTooltip: "CONTAINER TARE WEIGHT",
  //     tooltipField: "containerTareWeight",
  //     field: "containerTareWeight",
  //     columnGroupShow: "closed",
  //     cellClass: "ag-left-aligned-cell",
  //     hide: sessionStorage.getItem("role") == "cha" ? false : true,
  //   },
  //   {
  //     headerName: "HAZARDOUS GOODS",
  //     filter: true,
  //     headerTooltip: "HAZARDOUS GOODS",
  //     tooltipField: "hazardousGoods",
  //     field: "hazardousGoods",
  //   },
  //   {
  //     headerName: "ETD",
  //     hide: props.status === "accepted" ? false : true,
  //     field: "etd",
  //     filter: true,
  //     headerTooltip: "ETD",
  //     tooltipField: "etd",
  //     cellClass: "ag-left-aligned-cell",
  //   },
  //   {
  //     headerName: "ETA",
  //     hide: props.status === "accepted" ? false : true,
  //     field: "eta",
  //     filter: true,
  //     headerTooltip: "ETA",
  //     tooltipField: "eta",
  //     cellClass: "ag-left-aligned-cell",
  //   },
  //   {
  //     headerName: "SHIPMENT LINE STATUS",
  //     field: "lineShipStatus",
  //     filter: true,
  //     headerTooltip: "SHIPMENT LINE STATUS",
  //     tooltipField: "lineShipStatus",
  //   },

  //   {
  //     headerName: "DISPATCH STATUS",
  //     field: "lineDelStatus",
  //     filter: true,
  //     headerTooltip: "DISPATCH STATUS",
  //     tooltipField: "lineDelStatus",
  //     cellRenderer: (params: ICellRendererParams) => {
  //       if (params.value === "Delay") {
  //         return <ReportProblemIcon sx={{ color: "red" }} />;
  //       } else {
  //         return <p>{params.value}</p>;
  //       }
  //     },
  //   },

  //   {
  //     headerName: "REJECTED REASON",
  //     field: "rejectedReason",
  //     hide: props.status === "rejected" ? false : true,
  //     filter: true,
  //     headerTooltip: "REJECTED REASON",
  //     tooltipField: "rejectedReason",
  //     cellRenderer: (params: ICellRendererParams) => {
  //       let output;
  //       if (params.value.length > 8) {
  //         output = params.value.substring(0, 9);
  //         output = output + "....";
  //       } else {
  //         output = params.value;
  //       }
  //       return (
  //         <Tooltip title={params.value} placement="bottom-start">
  //           <Button sx={{ color: "tomato" }}>{output}</Button>
  //         </Tooltip>
  //       );
  //     },
  //   },
  // ]);
  const [columnDefs] = useState([
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      hide:
        props.status === "open" &&
        (sessionStorage.getItem("role") === "forwarder"|| sessionStorage.getItem("role") === "carrier")
          ? false
          : true,
    },
   
    {
          headerName: "HBL #",
          field: "hblNumber",
          filter: true,
          headerTooltip: "HBL #",
          tooltipField: "hblNumber",
          // hide: location.pathname == "/pending_carrier_confirmation" ? true : false,
        },
        {
          headerName: "MBL #",
          field: "mblNumber",
          filter: true,
          headerTooltip: "MBL #",
          tooltipField: "mblNumber",
          hide: location.pathname == "/pending_carrier_confirmation" ? true : false,
        },
    // {
    //   headerName: "COMMODITY TYPE",
    //   field: "commodityType",
    //   filter: true,
    //   headerTooltip: "COMMODITY TYPE",
    //   tooltipField: "commodityType",
    // },
    {
      headerName: "DRUG TYPE",
      field: "commodityType",
      filter: true,
      headerTooltip: "DRUG TYPE",
      tooltipField: "commodityType",
    },
    {
      headerName: " NDC",
      field: "itemName",
      filter: true,
      headerTooltip: "NDC",
      tooltipField: "itemName",
    },
    {
      headerName: "DRUG NAME",
      field: "itemDescription",
      filter: true,
      headerTooltip: "DRUG NAME",
      tooltipField: "itemDescription",
    },
    {
      headerName: "BATCH NUMBER",
      filter: true,
      field: "lines.batchNumber",
      headerTooltip: "BATCH NUMBER",
      tooltipField: "batch number",
    },
    {
      headerName: "LOT NUMBER",
      filter: true,
      field: "lines.lotNumber",
      headerTooltip: "LOT NUMBER",
      tooltipField: "lotNumber",
    },
    {
      headerName: "SERIAL NUMBER",
      filter: true,
      field: "lines.serialNumber",
      headerTooltip: "SERIAL NUMBER",
      tooltipField: "serialNumber",
    },
    {
      headerName: "SSCC",
      filter: true,
      field: "lines.sscc",
      headerTooltip: "SSCC",
      tooltipField: "serialNumber",
    },
    {
      headerName: "GRAI",
      filter: true,
      field: "lines.grai",
      headerTooltip: "GRAI",
      tooltipField: "serialNumber",
    },
    // {
    //   headerName: "FORWARD BOOKING REFERENCE",
    //   field: "forwarderBookingReference",
    //   filter: true,
    //   headerTooltip: "Forward Booking Reference",
    //   tooltipField: "forwarderBookingReference",
    // },
    // {
    //   headerName: "FORWARDER NAME",
    //   field: "forwarderName",
    //   filter: true,
    //   headerTooltip: "FORWARDER NAME",
    //   tooltipField: "forwarderName",
    // },

    {
      headerName: "HS CODE",
      field: "hscode",
      filter: true,
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "HS CODE DESCRIPTION",
      field: "hscodeDescription",
      filter: true,
      headerTooltip: "HS CODE DESCRIPTION",
      tooltipField: "hscodeDescription",
    },
    {
      headerName: "NUMBER OF PACKAGES",
      field: "packageDetails.numberofPackages",
      filter: true,
      headerTooltip: "NUMBER OF PACKAGES",
      tooltipField: "number of packages",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE TYPE",
      field: "packageDetails.type",
      filter: true,
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "packagetype",
    },
    {
      headerName: "PACKAGE WEIGHT",
      field: "packageDetails.weight",
      filter: true,
      headerTooltip: "PACKAGE WEIGHT",
      tooltipField: "packageweight",
    },
    {
      headerName: "PACKAGE LENGTH",
      field: "packageDetails.length",
      filter: true,
      headerTooltip: "PACKAGE LENGTH",
      tooltipField: "packagelength",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE WIDTH",
      field: "packageDetails.width",
      filter: true,
      headerTooltip: "PACKAGE WIDTH",
      tooltipField: "packagewidth",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE HEIGHT",
      field: "packageDetails.height",
      filter: true,
      headerTooltip: "PACKAGE HEIGHT",
      tooltipField: "packageheight",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE SPECIAL INSTRUCTIONS",
      field: "packageDetails.specialInstructions",
      filter: true,
      headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
      tooltipField: "packagespecialinstructions",
    },
    {
      headerName: "HAZARDOUS GOODS",
      field: "hazardousGoods",
      filter: true,
      headerTooltip: "hazardousGoods",
      tooltipField: "HAZARDOUS GOODS",
    },
    {
      headerName: "SHIPMENT LINE STATUS",
      field: "lineShipStatus",
      filter: true,
      headerTooltip: "SHIPMENT LINE STATUS",
      tooltipField: "lineShipStatus",
    },

    {
      headerName: "REQUESTED DATE OF DELIVERY",
      field: "brRequestedDateOfDelivery",
      filter: true,
      headerTooltip: "brRequestedDateOfDelivery",
      tooltipField: "REQUEST DATE OF DELIVERY",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "DISPATCH STATUS",
      field: "lineDelStatus",
      filter: true,
      headerTooltip: "DISPATCH STATUS",
      tooltipField: "lineDelStatus",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === "delayed") {
          return (
            <Tooltip title={params.value}>
              <ReportProblemIcon sx={{ color: "red" }} />
            </Tooltip>
          );
        } else {
          return <p>{params.value}</p>;
        }
      },
    },

    {
      headerName: "REJECTED REASON",
      hide: props.status === "rejected" ? false : true,
      tooltipField: "rejectedReason",
      field: "rejectedReason",
      filter: true,
      headerTooltip: "REJECTED REASON",
      cellRenderer: (params: ICellRendererParams) => {
        let output;
        if (params.value.length > 8) {
          output = params.value.substring(0, 9);
          output = output + "....";
        } else {
          output = params.value;
        }
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Button style={{ color: "tomato" }}>{output}</Button>
          </Tooltip>
        );
      },
    },
  ]);
  const [containerError, setContainerError] = useState<boolean>(false);
  const [mblError, setMblError] = useState<boolean>(false);

  //FUNCTION TO Call shipments
  function getCarrierLines() {
    axios.get(url, config).then((res) => {
      console.log("configaration..", config);
      setRowData(res.data.primary)
    });
  }

  //HERE WE ARE CALLING API
  useEffect(() => {
    // getLink();
    getCarrierLines();  
  }, []);

  const getdata = () => {
    if (mblcode && containercode) {
      let data = gridRef.current.api.getSelectedRows();
      console.log("selected Data",data)
      let list: {
        hscode: any;
        docReference: any;
        mblNumber: string;
        containerNumber: string;
        brStatus: any;
      }[] = [];
      setHblOpen(false);
      data.map((item: any, index: number) => {
        let obj = {
          hscode: item.hscode,
          docReference: props.data.docReference,
          mblNumber: mblcode,
          containerNumber: containercode,
          brStatus: "accepted",
        };
        list.push(obj);
      });
      let obj = {
        data: list,
      };
      
      PostRequest(AR_url, obj);
      getCarrierLines();
      props.onClose();
      swal('success',"Shipments has Been Accepted",'success').then((res)=>{
        if(res==true){
          props.onClose()
        }
      })
      gridRef.current.api.console.log(obj);
      setMblCode("");
      setcontainercode("");
    } else if (mblcode == "" && containercode == "") {
      setMblError(true);
      setContainerError(true);
    } else if (mblcode == "") {
      setMblError(true);
    } else {
      setContainerError(true);
    }
  };
  const getdataforReject = () => {
    let data = gridRef.current.api.getSelectedRows();
    let list: {
      hscode: any;
      docReference: any;
      rejectedReason: string;
      brStatus: any;
    }[] = [];
    setHblOpen(false);
    data.map((item: any, index: number) => {
      let obj = {
        hscode: item.hscode,
        docReference: props.data.docReference,
        rejectedReason: remarks,
        brStatus: "rejected",
      };
      list.push(obj);
    });
    let obj = {
      data: list,
    };
    setRemarkOpen(false);
    PostRequest(AR_url, obj);
    getCarrierLines();
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    getCarrierLines();
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <Dialog
      fullScreen
      open={props.onOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-root": {},
        zIndex: 10000,
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1,fontSize:'1rem' }} variant="h6" component="div">
            INVOICE REF. #{" "}
            <span className="dialogTitle">{props.data.invoiceNumber}</span>
            &nbsp; &nbsp; CARRIER REF. #{" "}
            <span className="dialogTitle blueColor">
              {props.data.carrierReference}
            </span>
          </Typography>

          <Button autoFocus color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "15px",
            justifyContent: "space-between",
            backgroundColor: "#f5f4f7",
          }}
        >
          <Box sx={{ height: "fit-content", width: "20%" }}>
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              INVOICE DETAILS
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Invoice Number
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.invoiceNumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Invoice Date
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.invoiceDate?props.data.invoiceDate:"12/02/2000"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Consignee ID
                </Typography>
                <Typography
                  // className="dialogTitle greyColor"
                  sx={{ fontSize: "14px" }}
                >
                
                  {props.data.consigneeId}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              height: "fit-content",
              width: "20%",
            }}
          >
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              CARRIER DETAILS
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Carrier ID
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.carrierId}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Carrier Name
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.carrierName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Mode Of Shipment
                </Typography>
                <Typography
                  // className="dialogTitle greyColor"
                  sx={{ fontSize: "14px" }}
                >
                  {props.data.modeofShipment?props.data.modeofShipment:"ocean"}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* <Box
            sx={{
              // height: "100%",
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              // width: "20%",
              flexDirection: "column",

              height: "fit-content",
              width: "fit-content",
            }}
          >
            <Box>
              <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
                ORIGIN &nbsp; &nbsp; TO &nbsp; &nbsp; DESTINATION
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <LocationOnIcon fontSize="large" sx={{ color: "#f4c919" }} />
                <p style={{ fontSize: "14px" }}>{props.data.pickupCountry}</p>
              </Box>
              &nbsp;
              <Box sx={{ marginBottom: "20px", marginRight: "15px" }}>
                <span>-----{">"}</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <LocationOnIcon sx={{ color: "red" }} fontSize="large" />
                <p style={{ fontSize: "14px" }}> {props.data.dropoffCountry}</p>
              </Box>
            </Box>
          </Box> */}

          <Box
            sx={{
              // height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box className="pointerIcon">
                {/* <LocationOnIcon
                  fontSize="large"
                  sx={{ color: "#f4c919", width: "1.5em", height: "1.5em" }}
                /> */}
                <div className="countryText">{props.data.pickupCountry?props.data.pickupCountry:'HAMBURG'}</div>
                <span className="countryType">(Origin)</span>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  margin: "5px 0px",
                  color: "#555",
                }}
              >
                {/* <ArrowRightAltIcon sx={{ width: "2em", height: "2em",marginBottom:'50px',marginRight: '35px'}} /> */}
              </Box>
              <Box className="pointerIcon">
                {/* <LocationOnIcon
                  sx={{ color: "red", width: "1.5em", height: "1.5em" }}
                  fontSize="large"
                /> */}
                <div className="countryText destinationText">
                  {props.data.dropoffCountry?props.data.dropoffCountry:"HOUSTOUN"}
                </div>
                <span className="countryType">(Destination)</span>
              </Box>
            </Box>
          </Box>

          {/* <Box
            sx={{
              height: "fit-content",
              width: "20%",
            }}
          >
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              SCAN QR
            </Typography>
            <img src={link} alt="scann qr " width={100} height={"auto"} />
          </Box> */}
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingTop: "20px",
            height: "fit-content",
          }}
        >
          <div className={classes.root}>
            {/* <Container maxWidth="xl" className={classes.maincontent}> */}
            <div className="ag-theme-balham">
              <Typography variant="h6">SHIPMENTS</Typography>
              <div className="align">
                {props.status === "open" &&
                sessionStorage.getItem("role") === "carrier" ? (
                  <div className="actionBtn">
                    <Button
                      style={{
                        height: "fit-content",
                        width: "fit-content",
                        lineHeight: 0.5,
                        color: "white",
                        backgroundColor: "#28a745",
                        marginRight: "3px",
                      }}
                      onClick={() => handleAccept()}
                      className="btn btn-info"
                      startIcon={<CheckCircleIcon />}
                    >
                      Accept
                    </Button>
                    <Button
                      style={{
                        height: "fit-content",
                        width: "fit-content",
                        lineHeight: 0.5,
                        color: "white",
                        backgroundColor: "#95535a",
                      }}
                      onClick={() => handleReject()}
                      className="btn btn-info"
                      startIcon={<CancelIcon />}
                    >
                      Reject
                    </Button>
                  </div>
                ) : null}
              </div>

              <AgGridReact
                ref={gridRef}
                animateRows={true}
                rowSelection={"multiple"}
                defaultColDef={defaultColDef}
                rowData={rowData}
                domLayout={"autoHeight"}
                columnDefs={columnDefs}
                sideBar={sideBar}
                pagination={true}
                paginationPageSize={10}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                // onVirtualColumnsChanged={autosize}
              >
                </AgGridReact>  

{/* <Alert severity="info">
        <AlertTitle>Info</AlertTitle>
        No Data Found
      </Alert> */}
            </div>
            {/* </Container> */}
          </div>
        </Box>
      </Box>

      {/*MBL OPEN DIALOGUE */}
      <Dialog
        open={hblOpen}
        onClose={() => SetQrOpen(!hblOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
          padding: 20,
        }}
      >
        <DialogTitle>
          MBL Number For Carrier ID #{props.data.carrierReference}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ width: "500px" }}>
            Please Enter Mbl Number
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter MBL"
            type="email"
            fullWidth
            variant="standard"
            value={mblcode}
            onChange={handleChangeMbl}
          />
          <span style={{ color: "red" }}>
            {mblError ? null : "Enter Mbl Code"}
          </span>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter Container"
            type="email"
            fullWidth
            variant="standard"
            value={containercode}
            onChange={handleChangecontainerNumber}
          />
          <span style={{ color: "red" }}>
            {containerError ? null : "Enter Container Code"}
          </span>
        </DialogContent>
        <DialogActions>
          <Button onClick={getdata}>Submit</Button>
          <Button
            onClick={() => {
              setHblOpen(false);
              setcontainercode("");
              setMblCode("");
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* REAMRKS DIALOGUE */}
      <Dialog
        open={remarkOpen}
        onClose={() => SetQrOpen(!remarkOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
          width: "100%",
        }}
      >
        <DialogTitle>
          Remarks For Carrier ID #{props.data.carrierReference}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ width: "500px" }}>
            Please Enter Remarks
          </DialogContentText>

          <TextField
            multiline={true}
            rows={4}
            minRows={10}
            autoFocus
            margin="dense"
            id="name"
            label="Enter Remarks"
            type="email"
            fullWidth
            variant="outlined"
            value={remarks}
            onChange={handleChangeReamark}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={getdataforReject}>Submit</Button>
          <Button
            onClick={() => {
              setRemarkOpen(false);
              setRemarks("");
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

export default CrLineItems;
