import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./Legsinfo.css";
import {
  Button,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
// import {  } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import "../../../Styles.css";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// import "./carrierLegInfo.css";
function LegInfoVisual(props: any) {
  console.log("LegData Visual screen Leg info.....", props);
  const [leginfo,setLegInfo]=useState([
    {
      legID:1,
      vessel:'VES10298',
      vesselName:'scorpion',
      voyage:'VGA676',
      eta:'12/12/2022',
      etd:'12/11/2022'
    }
  ])
  return (
    <Dialog
      fullScreen
      open={props.onOpen}
      onClose={props.onClose}
      sx={{
        backgroundColor: "none",
        "& .MuiDialog-root": {},
        zIndex: 10000,
        minWidth: "lg",
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ display: "flex", width: "70%", alignItems: "center" }}>
            <Typography sx={{ ml: 2, flex: 1,fontSize:'1rem' }} variant="h6" component="div">
              LEG INFORMATION - HBL # :
              <span className="dialogTitle">{props.line.hblNumber}</span>{" "}
              &nbsp;&nbsp; MBL # :{" "}
              <span className="dialogTitle blueColor">
                {props.line.mblNumber}
              </span>{" "}
              &nbsp;&nbsp;
            </Typography>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <LocationOnIcon fontSize="medium" sx={{ color: "#f4c919" }} />
                <p style={{ fontSize: "14px" }}>{props.line.pickupCountry?props.line.pickupCountry:"HAMBURG"}</p>
              </Box>
              &nbsp;
              <Box sx={{ marginBottom: "18px", marginRight: "15px" }}>
                <span>
                  <ArrowRightAltIcon fontSize="large" />
                </span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <LocationOnIcon sx={{ color: "red" }} fontSize="medium" />
                <p style={{ fontSize: "14px" }}> {props.line.dropoffCountry?props.line.dropoffCountry:"HOUSTON"}</p>
              </Box>
            </Box>
          </Box>
          <Button
            autoFocus
            color="inherit"
            onClick={props.onClose}
            sx={{ marginLeft: "auto" }}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: "25px" }}>
        {leginfo.map((value: any, index: number) => {
          console.log(value);
          return (
            
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <Box className="head">
                <h4 className="leg-header">
                  #{index + 1}: LegId - {value.legID}
                </h4>
              </Box>
              <Box className="data">
                <Box className="body">
                  <Box className="key">
                    <p className="leg-key">Vessel ID : </p>
                    <p className="leg-key">Vessel Name :</p>
                    <p className="leg-key">Voyage :</p>
                  </Box>
                  <Box className="value">
                    <p className="leg-value">{value.vessel}</p>
                    <p className="leg-value">{value.vesselName}</p>
                    <p className="leg-value">{value.voyage}</p>
                  </Box>
                </Box>

                <Box className="body">
                  <Box className="key">
                    <p className="leg-key">
                      Estimated Time Of Departure(ETD) :
                    </p>
                    <p className="leg-key">Estimated Time Of Arrival (ETA):</p>
                  </Box>
                  <Box className="value">
                    <p className="leg-value">{value.etd}</p>
                    <p className="leg-value">{value.eta}</p>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "20%",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>

                    <Box className="pointerIcon">
                      {/* <LocationOnIcon
                        fontSize="large"
                        sx={{
                          color: "#f4c919",
                          width: "1.5em",
                          height: "1.5em",
                        }}
                      /> */}

                      <div className="countryText">{value.origin?value.origin:"HAMBURG"}</div>
                      <span className="countryType">(Origin)</span>
                    </Box>

                    <Box
                      sx={{
                        justifyContent: "center",
                        // margin: "5px 0px",
                        color: "#555",
                        marginRight:'26px'
                      }}
                    >
                      {/* <ArrowRightAltIcon sx={{ width: "2em", height: "2em" }} /> */}
                    </Box>

                    <Box className="pointerIcon" sx={{marginRight:'10px'}}>
                      {/* <LocationOnIcon
                        sx={{ color: "red", width: "1.5em", height: "1.5em" }}
                        fontSize="large"
                      /> */}

                      <div className="countryText destinationText">
                        {value.destination?value.destination:"HOUSTON"}
                      </div>
                      <span className="countryType">(Destination)</span>
                    </Box>

                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Dialog>
  );
}

export default LegInfoVisual;
