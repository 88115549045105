import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import colorConfigs from "../Config/colorConfig";
import sizeConfigs from "../Config/sizeConfigs";
import TabData from "./Routes/appRoutes";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionCharts, { options } from "fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Chart from "react-apexcharts";
import { statements } from "@babel/template";
import { bottom } from "@popperjs/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DashboardInTransit from "../Helpers/DashboardInTransit";
import ItemDetail from "../Helpers/ItemDetail";
import Full from "../Helpers/full";
import DashboardBookingLifeCycle from "../Helpers/DashboardBookingLifeCycle";
import DashboardDeviation from "../Helpers/DashboardDeviation";
import { getRequest, PostRequest } from "../Services/BaseService";
import { DesgService } from "../Services/DesgService";
import DashboardCountInfo from "../Helpers/DashboardCountInfo";
import { SubHeader } from "./common/SubHeader";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import ETADeviationInfo from "../Helpers/ETADeviations/ETADeviationInfo";
import '../Styles.css'
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);
type Props = {};

function Dashboard({}: Props) {
  const [inTransitOpenDialog, setIntransitOpenDialog] = useState(false);
  const [bookingOpenDialog, setBookingOpenDialog] = useState(false);
  const [deviationOpenDialog, setDeviationOpenDialog] = useState(false);
  const [dashboardCountInfoDialog, setDashboardCountInfoDialog] =
    useState(false);
  const [dashboardDataCount, setDashboardCounts] = useState<any>("");
  const [preCarriage, setPreCarriage] = useState<any>("");
  const [exportClearance, setExportClearance] = useState<any>("");
  const [onVessel, setOnVessel] = useState<any>("");
  const [importClearnace, setImportClearance] = useState<any>("");
  const [onCarriage, setOncarriage] = useState<any>("");
  const [deviated, setDeviated] = useState<any>("");
  const [delivered, setDelivered] = useState<any>("");
  const [inTransitShipments, setInTransitShipments] = useState<any>([]);
  const [shipmentLifeCycle, setShipmentLifeCycle] = useState<any>([]);
  const [shipmentEtaDeviation, setShipmentEtaDeviation] = useState<any>([]);
  const [load, setLoad, fromDate, toDate, format] = useOutletContext<any>();
  const [etaDeviationDialog,setEtaDeviationDialog] = useState<any>(false)
  const [deliveredShipmentDeviation, setDeliveredShipmentDeviation] =
    useState<any>([]);
  const [showIntransit, setShowIntransit] = useState(false);
  const [shipmentMoreInfo, setShipmentMoreInfo] = useState<any>();
  const desgService = new DesgService();
  let dashboard: any;
  let state: any = {};
  useEffect(() => {
    setLoad(true);
    let options = {
      filterOn: format ? format : "YTD",
      startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
      endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    };
    console.log("................", options);
    desgService
      .getDashboardInfo(headers, options)
      .then((res: any) => {
        setLoad(false);
        dashboard = res.data.data;
        setDashboardCounts(dashboard.ordersCount.totalOrders);
        setPreCarriage(dashboard.preCarriageCount);
        setExportClearance(dashboard.exportclearanceCount);
        setOnVessel(dashboard.onVesselCount);
        setImportClearance(dashboard.importclearanceCount);
        setOncarriage(dashboard.onCarraigeCount);
        setDeviated(dashboard.etaDeviationCount);
        setDelivered(dashboard.deliveredCount);
        setInTransitShipments(
        //   [
        //   dashboard.inTransitEventShipment.arrivingToday,
        //   dashboard.inTransitEventShipment.arrivingNextDay,
        //   dashboard.inTransitEventShipment.arrivingNext7Days,
        //   dashboard.inTransitEventShipment.onTimeIntransitShipment,
        // ]
        [1329,973,1181]
        );
        setShipmentLifeCycle(
        //   [
        //   dashboard.pieChart.totalPendingBookingRequestsCount,
        //   dashboard.pieChart.forwaderAcceptedCount,
        //   dashboard.pieChart.carrierConfirmedCount,
        // ]
        [500,200,4700]
        );
        setShipmentEtaDeviation(dashboard.inTransitEventShipmentDeviation);
        setDeliveredShipmentDeviation([
          {
            name: "ETA Deviation",
            // data: [
            //   dashboard.deliveredShipmentDeviation.deliveredOnTime,
            //   dashboard.deliveredShipmentDeviation.deliveredBetween2days,
            //   dashboard.deliveredShipmentDeviation.deliveredBetweenWeek,
            //   dashboard.deliveredShipmentDeviation.deliveredBetweenMonth,
            //   dashboard.deliveredShipmentDeviation.deliveredGreaterThanMonth,
            // ],
            data:[403,394,262,158]
          },
        ]);

        intransitCount();
      })
      .catch((e: any) => {
        setLoad(false);
      });
  }, [fromDate, toDate, format]);
  const intransitCount = () => {
    console.log(inTransitShipments, "111intransit count");
   setShowIntransit(true);
  };
  const shipmentOrdersMoreInfo = (data: any) => {
    setShipmentMoreInfo(data);
    setDashboardCountInfoDialog(true);
  };


  return (
    <Box sx={{ overflowX: "hidden" ,mt:2, mb:5}}>
      <Grid container spacing={2}>
        <Grid item lg={9}>
          <Grid
            item
            justifyContent="center"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
              <Paper elevation={0} className="card">
                <Box className="card-body">
                  <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={9}>
                      <Typography className="dashboard-title">Shipment Orders</Typography>
                      <Typography
                        variant="h3"
                        className="dashboard_info"
                        sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                      >
                        {/* {dashboardDataCount} */}
                        {4700}

                        {/* onClick={() => shipmentOrdersMoreInfo({ title: 'shipmentOrders', status: 'pending' })} */}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ alignSelf: "center" }}>
                      <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                        <img
                          src={require("../asserts/Col-RealTrace-SVG-Icons/Orders.png")}
                          className="ti ti-users font-24 align-self-center text-muted"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
              <Paper elevation={0} className="card">
                <Box className="card-body">
                  <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={9}>
                      <Typography className="dashboard-title">Pre-Carriage</Typography>
                      <Typography
                        variant="h3"
                        className="dashboard_info"
                        sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                      >
                        {/* {preCarriage} */}
                       {1624}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ alignSelf: "center" }}>
                      <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                        <img
                          src={require("../asserts/Col-RealTrace-SVG-Icons/Pre-Carriage.png")}
                          className="ti ti-users font-24 align-self-center text-muted"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
              <Paper elevation={0} className="card">
                <Box className="card-body">
                  <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={9}>
                      <Typography className="dashboard-title">Export Clearance</Typography>
                      <Typography
                        variant="h3"
                        className="dashboard_info"
                        sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                      >
                        {/* {exportClearance} */}
                       {412}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ alignSelf: "center" }}>
                      <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                        <img
                          src={require("../asserts/Col-RealTrace-SVG-Icons/Export-declaration.png")}
                          className="ti ti-users font-24 align-self-center text-muted"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
              <Paper elevation={0} className="card">
                <Box className="card-body">
                  <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={9}>
                      <Typography className="dashboard-title">On Vessel</Typography>
                      <Typography
                        variant="h3"
                        className="dashboard_info"
                        sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                      >
                        {/* {onVessel} */}
                        {454}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ alignSelf: "center" }}>
                      <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                        <img
                          src={require("../asserts/Col-RealTrace-SVG-Icons/On Vessel.png")}
                          className="ti ti-users font-24 align-self-center text-muted"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
              <Paper elevation={0} className="card">
                <Box className="card-body">
                  <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={9}>
                      <Typography className="dashboard-title">Import Clearance</Typography>
                      <Typography
                        variant="h3"
                        className="dashboard_info"
                        sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                      >
                        {/* {importClearnace} */}
                        {402}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ alignSelf: "center" }}>
                      <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                        <img
                          src={require("../asserts/Col-RealTrace-SVG-Icons/Import-Declaration.png")}
                          className="ti ti-users font-24 align-self-center text-muted"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
              <Paper elevation={0} className="card">
                <Box className="card-body">
                  <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={9}>
                      <Typography className="dashboard-title">WH & On Carriage</Typography>
                      <Typography
                        variant="h3"
                        className="dashboard_info"
                        sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                      >
                        {/* {onCarriage} */}
                        {591}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ alignSelf: "center" }}>
                      <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                        <img
                          src={require("../asserts/Col-RealTrace-SVG-Icons/On Carriage.png")}
                          className="ti ti-users font-24 align-self-center text-muted"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
              <Paper elevation={0} className="card">
                <Box className="card-body">
                  <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={9}>
                      <Typography className="dashboard-title">Deviated</Typography>
                      <Typography
                        variant="h3"
                        className="dashboard_info"
                        sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                      >
                        {/* {deviated} */}
                        {1488}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ alignSelf: "center" }}>
                      <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                        <img
                          src={require("../asserts/Col-RealTrace-SVG-Icons/Deviate-Shipment.png")}
                          className="ti ti-users font-24 align-self-center text-muted"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
              <Paper elevation={0} className="card">
                <Box className="card-body">
                  <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={9}>
                      <Typography className="dashboard-title">Delivered</Typography>
                      <Typography
                        variant="h3"
                        className="dashboard_info"
                        sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                      >
                        {/* {delivered} */}
                        {1217}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ alignSelf: "center" }}>
                      <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                        <img
                          src={require("../asserts/Col-RealTrace-SVG-Icons/Delivered.png")}
                          className="ti ti-users font-24 align-self-center text-muted"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <Paper elevation={0} className="card">
                <Box className="card-header">
                  <Grid
                    item
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography variant="h4" className="card-title" sx={{fontWeight:'bold'}}>
                        Booking Life Cycle
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        variant="h4"
                        className="card-title"
                        sx={{ color: "#7081b9", fontSize: "12px" }}
                      >
                        <RemoveRedEyeIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setBookingOpenDialog(true)}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="card-body">
                  <Box>
                    {/* <Chart options={state2.options} series={state2.options.series} type="pie" className="donut" width={250} height={330} /> */}
                    <Chart
                      type="pie"
                      width={250}
                      height={330}
                      series={shipmentLifeCycle}
                      options={{
                        colors: ["#2a76f4", "#94baf9", "#6098f5"],
                        legend: {
                          show: true,

                          position: bottom,
                          horizontalAlign: "left",
                          fontSize: "12px",
                          fontFamily: "Roboto",
                          fontWeight: 700,
                          labels: {
                            colors: "rgb(112 129 185)",
                            // useSeriesColors: boolean
                          },
                        },

                        dataLabels: {
                          enabled: true,
                        },
                        labels: [
                          "Booking Requests",
                          "Accepted By Forwarder",
                          "Confirmed By Carrier",
                        ],
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={8}>
              <Paper elevation={0} className="card">
                <Box className="card-header">
                  <Grid
                    item
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography variant="h4" className="card-title" sx={{fontWeight:'bold'}}>
                        Delivered Shipments - Deviation
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        variant="h4"
                        className="card-title"
                        sx={{ color: "#7081b9", fontSize: "12px" }}
                      >
                        <RemoveRedEyeIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setDeviationOpenDialog(true)}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="card-body">
                  <Box>
                    {/* <Chart options={state1.options} series={state1.series} type="bar" width={580} height={280} /> */}
                    <Chart
                      type="bar"
                      width={580}
                      height={280}
                      series={deliveredShipmentDeviation}
                      options={{
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            columnWidth: "25%",
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          colors: ["transparent"],
                        },
                        xaxis: {
                          categories: [
                            // "On Time",
                            "< 2 D",
                            "> 2 D to < 1 W",
                            "> 1 W to < 1 M",
                            "> 1 M",
                          ],
                          title: {
                            text: "Time",
                          },
                        },
                        yaxis: {
                          title: {
                            text: "Shipments",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        grid: {
                          xaxis: {
                            lines: {
                              show: false,
                              //   offsetX: number
                              //   offsetY?: number
                            },
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3}>
          <Paper elevation={0} className="card">
            <Box className="card-header">
              <Grid
                item
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography variant="h4" className="card-title" sx={{fontWeight:'bold'}}>
                    In-Transit Shipments
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    variant="h4"
                    className="card-title"
                    sx={{ color: "#7081b9", fontSize: "12px" }}
                  >
                    <RemoveRedEyeIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIntransitOpenDialog(true)}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className="card-body">
              {showIntransit ? (
                <Box>
                  {/* <Chart options={state.options} className="donut" width={260} height={500} /> */}
                  <Chart
                    type="donut"
                    width={260}
                    height={500}
                    className="donut"
                    series={inTransitShipments}
                    options={{
                      noData: { text: "Empty Data" },
                      labels: [
                        sessionStorage.getItem('role') !== 'shipper' ? "Arriving Today" :'Delivering Today',
                        "Next Day",
                        "Next 7 Days",
                        // "On Time",
                      ],
                      colors: ["#2a76f4", "#94baf9", "#6098f5", "#d9e6fd"],
                      legend: {
                        show: true,

                        position: bottom,
                        // horizontalAlign: 'left',
                        fontSize: "12px",
                        fontFamily: "Roboto",
                        fontWeight: 700,
                        labels: {
                          colors: "rgb(112 129 185)",
                          // useSeriesColors: boolean
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      plotOptions: {
                        pie: {
                          donut: {
                            size: "80%",
                            labels: {
                              show: true,
                              total: {
                                show: true,
                                label: "In-Transit Shipments",
                              },
                            },
                          },
                        },
                      },
                    }}
                  />
                </Box>
              ) : (
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  No Data Found
                </Alert>
              )}
              <Box sx={{ pt: 3, textAlign: "left" }}>
                <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                  <Grid>
                    <Typography sx={{fontWeight:'bold'}}>ETA Deviations</Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant="h4"
                      className="card-title"
                      sx={{ color: "#7081b9", fontSize: "12px" }}
                    >
                      <RemoveRedEyeIcon sx={{cursor:'pointer'}}
                        onClick={() => setEtaDeviationDialog(true)}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  {/* <Table sx={{ width:'100%' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Criteria</TableCell>
                                                <TableCell># of Shipments</TableCell>
                                                <TableCell>Deviation (%)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {TabData.map((row,index) => (
                                                <TableRow
                                                    key={index}
                                                   
                                                >
                                                    <TableCell>
                                                        {row.parentnav}
                                                    </TableCell>
                                                    <TableCell>{row.parentnav}</TableCell>
                                                    <TableCell>{row.parentnav}</TableCell>
                                                    
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table> */}
                  <table className="etaDeviations">
                    <tr>
                      <th>Criteria</th>
                      <th style={{ width: "100px" }}># Shipments</th>
                      <th>(%)</th>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[0],
                          }}
                        >
                          &#60;2 days
                        </span>
                      </td>
                      {/* <td>{shipmentEtaDeviation.between2days}</td> */}
                      <td>442</td>
                      {/* <td>{shipmentEtaDeviation.between2daysPercentage}</td> */}
                      <td>31</td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[1],
                          }}
                        >
                          &#62;2 days to &#60;1 Week
                        </span>
                      </td>
                      {/* <td>{shipmentEtaDeviation.betweenWeek}</td> */}
                      <td>394</td>
                      {/* <td>{shipmentEtaDeviation.betweenWeekPercentage}</td> */}
                      <td>26</td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[2],
                          }}
                        >
                          &#62;1 Week to &#60;1 Month
                        </span>
                      </td>
                      {/* <td>{shipmentEtaDeviation.betweenMonth}</td> */}
                      <td>362</td>
                      {/* <td>{shipmentEtaDeviation.betweenMonthPercentage}</td> */}
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[3],
                          }}
                        >
                          &#62;1 Month
                        </span>
                      </td>
                      {/* <td>{shipmentEtaDeviation.greaterThanMonth}</td> */}
                      <td>258</td>
                      {/* <td>{shipmentEtaDeviation.greaterThanMonthPercentage}</td> */}
                      <td>17</td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[4],
                          }}
                        >
                          No Update
                        </span>
                      </td>
                      <td>32</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="eta-text-bg">Totals</span>
                      </td>
                      {/* <td>{shipmentEtaDeviation.totalEtaDeviationCount}</td> */}
                      <td>1488</td>
                      <td>100</td>
                      {/* <td>{shipmentEtaDeviation.TotalEtaPercentage}</td> */}
                    </tr>
                  </table>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <div className="dialog">
        {inTransitOpenDialog ? (
          <DashboardInTransit
            onOpen={inTransitOpenDialog}
            onClose={() => setIntransitOpenDialog(false)}
            status={"open"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {bookingOpenDialog ? (
          <DashboardBookingLifeCycle
            onOpen={bookingOpenDialog}
            onClose={() => setBookingOpenDialog(false)}
            status={"open"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {deviationOpenDialog ? (
          <DashboardDeviation
            onOpen={deviationOpenDialog}
            onClose={() => setDeviationOpenDialog(false)}
            status={"open"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {dashboardCountInfoDialog ? (
          <DashboardCountInfo
            onOpen={dashboardCountInfoDialog}
            onClose={() => setDashboardCountInfoDialog(false)}
            status={"open"}
            data={shipmentMoreInfo}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {etaDeviationDialog ? (
          <ETADeviationInfo
            onOpen={etaDeviationDialog}
            onClose={() => setEtaDeviationDialog(false)}
            status={"open"}
          
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </Box>
  );
}
export default Dashboard;
