import { Accordion, AccordionDetails, AccordionSummary, AppBar, Autocomplete, Box, Breadcrumbs, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, Slide, Slider, Stack, Step, StepButton, StepLabel, Stepper, TextField, Theme, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import '../../../Styles.css'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
    DateTimePicker,
    DatePicker,
    LocalizationProvider,
    DesktopDatePicker,
} from "@mui/x-date-pickers";
import * as Yup from "yup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TransitionProps } from '@mui/material/transitions';
import AnchorOutlinedIcon from '@mui/icons-material/AnchorOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import dayjs, { Dayjs } from "dayjs";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import axios from 'axios';
import { SeaRatesServices } from '../../../Services/SeaRatesService';
import { LanTwoTone } from '@mui/icons-material';
import moment from 'moment';
import { Bars } from "react-loader-spinner";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SeaRatesMap from './SeaRatesMap';
import SeaRateCard from './SeaRateCard';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
const schemavalidation = Yup.object().shape({
    userName: Yup.string()
        .required("UserName is required")
        .min(3, "UserName Should Greater Than 3 Characters"),
    // .max(10, "UserName Should Less Than 10 Characters"),
    pwd: Yup.string()
        .required("Password is required")
        .min(4, "Password Should Greater Than 3 Characters")
        .max(10, "Password Should Less Than 10 Characters"),
});
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        fontSize: "10px",
        // fontWeight: "bold",
        "& .MuiDialog-root": {
            borderRadius: "100px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
            zIndex: "9999 !important",
        },
    },
    maincontent: {},
    tab: {
        backgroundColor: "#f1f1f1",
        marginBottom: "10px",
    },
    content: {
        '& .MuiAccordionSummary-content': {
            margin: '0 !important',
        },
    },

}));
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const chipsData = ['FEATURED', 'CHEAPER', 'FASTER']
export default function SearatesInfo(props: any) {
    const { register, handleSubmit, reset, getValues } = useForm<any>({
        defaultValues: {
            phone: '',
            name: '',
            eMail: '',
            description: '',
        }
    });
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(0);
    const [value, setValue] = React.useState('Truck');
    const [shipment, setShipment] = useState('fcl')
    const [container, setContainer] = useState('')
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [checked, setChecked] = React.useState(false);
    const [isSubmit, setSubmit] = useState(false)
    const [transporatationName, setTransportationName] = useState('sea')
    const [toggleValue, setToggleValue] = useState<any>('tariff')
    const [typeOfContainer, setTypeOfContainer] = useState<any>("ST20")
    const [loadDate, setLoadDate] = React.useState<Dayjs | any>(null);
    const [isViewDetailsShow, setViewDetailsShow] = useState(false)
    const [sliderValue, setSliderValue] = useState(1)
    const [sliderValue1, setSliderValue1] = useState(1);
    const [open1, setOpen1] = React.useState(false);
    const [options, setOptions] = React.useState<any>([]);
    const loading = open1 && options.length === 0;
    const [level, setLevel] = useState<any>(0)
    const [loader, setLoader] = useState(false)
    const [activeStep, setActiveStep] = React.useState(2);
    const [selectProduct, setSelectProduct] = useState('')
    const [sliderUnits, setSliderUnits] = useState('TON')
    const [slider1Units, setSlider1Units] = useState('CBM')
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    let arr: any = []
    let arr1: any = []
    let hsCodesData: any = [];
    let containerType: any = 'ST20'
    const [open, setOpen] = React.useState(props.data);
    const [productName, setProductName] = useState("");
    const [origin, setOrigin] = useState<any>({})
    const [dest, setdest] = useState<any>({})
    const seaRatesServices = new SeaRatesServices()
    const [OriginLatLang, setOriginLatLang] = useState<any>()
    const [ratesResp, setRatesResp] = useState<any>([])
    const [products, setProducts] = useState<any>([])
    const [subProducts, setSubProducts] = useState<any>([])
    const [openDialog, setOpenDialog] = useState(false)
    const [isPickUpActive, setPickUpActive] = useState(false)
    const [isPortOfOriginActive, setPortOfOriginActive] = useState(false)
    const [isPortOfDischargeActive, setPortOfDischargeActive] = useState(false)
    const [isDeliveryActive, setDeliveryActive] = useState(false)
    const [PRoductsByHsCode, setProductsByHsCode] = useState<any>(['Main Categories'])
    const [totalBookNowPrice, setTotalBookNowPrice] = useState('')
    const [totalCo2Count, setTotalCo2Count] = useState('')
    const seafclresp = {
        "data": {
            "shipment": [
                {
                    "shipmentId": "10586438",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 17204,
                        "name": "Port Everglades",
                        "code": "ChIJW6IPGb2q2YgR7hkgfFscvXc",
                        "countryCode": "US",
                        "lat": 26.066611640665,
                        "lng": -80.118827819824
                    },
                    "cityTo": {
                        "id": 50145,
                        "name": "Hazira",
                        "code": "ChIJ_W1Cmqut4TsR_bnYlU-pLaA",
                        "countryCode": "IN",
                        "lat": 21.1076,
                        "lng": 72.651155
                    },
                    "portFrom": {
                        "id": 17204,
                        "name": "Port Everglades",
                        "code": "USPEF",
                        "countryCode": "US",
                        "lat": 26.066611640665,
                        "lng": -80.118827819824,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 50145,
                        "name": "Hazira",
                        "code": "INHZA",
                        "countryCode": "IN",
                        "lat": 21.1076,
                        "lng": 72.651155,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "ZIMU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/13.jpg",
                            "containerCode": "ST20",
                            "validTo": "2023-12-31",
                            "containerType": "20 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1040,
                            "originalCurrency": "USD",
                            "price": 1040,
                            "distance": "16192.15 km",
                            "transitTime": "33 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2671705,
                            "co2Price": 9,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 450,
                                    "originalCurrency": "USD",
                                    "price": 450,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 965,
                                "originalCurrency": "USD",
                                "price": 965,
                                "distance": "7.48 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 20526,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 165,
                                "originalCurrency": "USD",
                                "price": 165,
                                "distance": "1.36 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3733,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10586439",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 17204,
                        "name": "Port Everglades",
                        "code": "ChIJW6IPGb2q2YgR7hkgfFscvXc",
                        "countryCode": "US",
                        "lat": 26.066611640665,
                        "lng": -80.118827819824
                    },
                    "cityTo": {
                        "id": 50145,
                        "name": "Hazira",
                        "code": "ChIJ_W1Cmqut4TsR_bnYlU-pLaA",
                        "countryCode": "IN",
                        "lat": 21.1076,
                        "lng": 72.651155
                    },
                    "portFrom": {
                        "id": 17204,
                        "name": "Port Everglades",
                        "code": "USPEF",
                        "countryCode": "US",
                        "lat": 26.066611640665,
                        "lng": -80.118827819824,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 50145,
                        "name": "Hazira",
                        "code": "INHZA",
                        "countryCode": "IN",
                        "lat": 21.1076,
                        "lng": 72.651155,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "MSC",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/1.jpg",
                            "containerCode": "ST20",
                            "validTo": "2023-12-31",
                            "containerType": "20 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 2366,
                            "originalCurrency": "USD",
                            "price": 2366,
                            "distance": "16192.15 km",
                            "transitTime": "33 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2671705,
                            "co2Price": 9,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 450,
                                    "originalCurrency": "USD",
                                    "price": 450,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 11115,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 965,
                                "originalCurrency": "USD",
                                "price": 965,
                                "distance": "7.48 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 20526,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 165,
                                "originalCurrency": "USD",
                                "price": 165,
                                "distance": "1.36 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3733,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                }
            ],
            "default": {
                "services": [
                    "ocean"
                ],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const sealclresp = {
        "data": {
            "shipment": [],
            "default": {
                "services": [],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const seafcl1resp = {
        "data": {
            "shipment": [
                {
                    "shipmentId": "10593346",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "MSC",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/1.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1833,
                            "originalCurrency": "USD",
                            "price": 1833,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593347",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "COSU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/5.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 996,
                            "originalCurrency": "USD",
                            "price": 996,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593348",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "YMLU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/18.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 644,
                            "originalCurrency": "USD",
                            "price": 644,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593349",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "HLCU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/7.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1172,
                            "originalCurrency": "USD",
                            "price": 1172,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593350",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "ONE",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/134.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 884,
                            "originalCurrency": "USD",
                            "price": 884,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593351",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "OOCL",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/17.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 622,
                            "originalCurrency": "USD",
                            "price": 622,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593352",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 127667,
                        "name": "Savannah",
                        "code": "ChIJa5vVuVie-4gRHY6XayJTmPs",
                        "countryCode": "US",
                        "lat": 32.0872834,
                        "lng": -81.1037494
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 17854,
                        "name": "Savannah",
                        "code": "USSAV",
                        "countryCode": "US",
                        "lat": 32.08657923003535,
                        "lng": -81.09974009138546,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "Evergreen",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/6.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 773,
                            "originalCurrency": "USD",
                            "price": 773,
                            "distance": "7532.26 km",
                            "transitTime": "18 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 1657097,
                            "co2Price": 6,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1290,
                                "originalCurrency": "USD",
                                "price": 1290,
                                "distance": "1.25 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 4583,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                }
            ],
            "default": {
                "services": [
                    "fees [import]",
                    "truck [import]",
                    "ocean"
                ],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const seafcl2resp = {
        "data": {
            "shipment": [
                {
                    "shipmentId": "10593995",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120870,
                        "name": "Oakland, CA, USA",
                        "code": "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
                        "countryCode": "US",
                        "lat": 37.8043637,
                        "lng": -122.2711137
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16967,
                        "name": "Oakland",
                        "code": "USOAK",
                        "countryCode": "US",
                        "lat": 37.831434265460146,
                        "lng": -122.29210554173378,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "HLCU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/7.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3422,
                            "originalCurrency": "USD",
                            "price": 3422,
                            "distance": "15602.27 km",
                            "transitTime": "32 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3432499,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 964,
                                "originalCurrency": "USD",
                                "price": 964,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593996",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120870,
                        "name": "Oakland, CA, USA",
                        "code": "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
                        "countryCode": "US",
                        "lat": 37.8043637,
                        "lng": -122.2711137
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16967,
                        "name": "Oakland",
                        "code": "USOAK",
                        "countryCode": "US",
                        "lat": 37.831434265460146,
                        "lng": -122.29210554173378,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "CMA CGM",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/15.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3395,
                            "originalCurrency": "USD",
                            "price": 3395,
                            "distance": "15602.27 km",
                            "transitTime": "32 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3432499,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 964,
                                "originalCurrency": "USD",
                                "price": 964,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593997",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120870,
                        "name": "Oakland, CA, USA",
                        "code": "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
                        "countryCode": "US",
                        "lat": 37.8043637,
                        "lng": -122.2711137
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16967,
                        "name": "Oakland",
                        "code": "USOAK",
                        "countryCode": "US",
                        "lat": 37.831434265460146,
                        "lng": -122.29210554173378,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "MSC",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/1.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 4994,
                            "originalCurrency": "USD",
                            "price": 4994,
                            "distance": "15602.27 km",
                            "transitTime": "32 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3432499,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 964,
                                "originalCurrency": "USD",
                                "price": 964,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593998",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120870,
                        "name": "Oakland, CA, USA",
                        "code": "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
                        "countryCode": "US",
                        "lat": 37.8043637,
                        "lng": -122.2711137
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16967,
                        "name": "Oakland",
                        "code": "USOAK",
                        "countryCode": "US",
                        "lat": 37.831434265460146,
                        "lng": -122.29210554173378,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "YMLU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/18.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 2822,
                            "originalCurrency": "USD",
                            "price": 2822,
                            "distance": "15602.27 km",
                            "transitTime": "32 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3432499,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 964,
                                "originalCurrency": "USD",
                                "price": 964,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10593999",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120870,
                        "name": "Oakland, CA, USA",
                        "code": "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
                        "countryCode": "US",
                        "lat": 37.8043637,
                        "lng": -122.2711137
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16967,
                        "name": "Oakland",
                        "code": "USOAK",
                        "countryCode": "US",
                        "lat": 37.831434265460146,
                        "lng": -122.29210554173378,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "ONE",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/134.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3010,
                            "originalCurrency": "USD",
                            "price": 3010,
                            "distance": "15602.27 km",
                            "transitTime": "32 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3432499,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 964,
                                "originalCurrency": "USD",
                                "price": 964,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                }
            ],
            "default": {
                "services": [
                    "fees [import]",
                    "truck [import]",
                    "truck [export]",
                    "fees [export]",
                    "ocean"
                ],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const seafcl3resp ={
        "data": {
            "shipment": [
                {
                    "shipmentId": "10594112",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120987,
                        "name": "Houston, TX, USA",
                        "code": "ChIJAYWNSLS4QIYROwVl894CDco",
                        "countryCode": "US",
                        "lat": 29.7604267,
                        "lng": -95.3698028
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15332,
                        "name": "Houston",
                        "code": "USHOU",
                        "countryCode": "US",
                        "lat": 29.745752378204,
                        "lng": -95.2871564229,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "MSC",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/1.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 2858,
                            "originalCurrency": "USD",
                            "price": 2858,
                            "distance": "9634.11 km",
                            "transitTime": "21 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2119504,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1127,
                                "originalCurrency": "USD",
                                "price": 1127,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594113",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120987,
                        "name": "Houston, TX, USA",
                        "code": "ChIJAYWNSLS4QIYROwVl894CDco",
                        "countryCode": "US",
                        "lat": 29.7604267,
                        "lng": -95.3698028
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15332,
                        "name": "Houston",
                        "code": "USHOU",
                        "countryCode": "US",
                        "lat": 29.745752378204,
                        "lng": -95.2871564229,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "ONE",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/134.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1930,
                            "originalCurrency": "USD",
                            "price": 1930,
                            "distance": "9634.11 km",
                            "transitTime": "21 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2119504,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1127,
                                "originalCurrency": "USD",
                                "price": 1127,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594114",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120987,
                        "name": "Houston, TX, USA",
                        "code": "ChIJAYWNSLS4QIYROwVl894CDco",
                        "countryCode": "US",
                        "lat": 29.7604267,
                        "lng": -95.3698028
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15332,
                        "name": "Houston",
                        "code": "USHOU",
                        "countryCode": "US",
                        "lat": 29.745752378204,
                        "lng": -95.2871564229,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "COSU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/5.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1395,
                            "originalCurrency": "USD",
                            "price": 1395,
                            "distance": "9634.11 km",
                            "transitTime": "21 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2119504,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1127,
                                "originalCurrency": "USD",
                                "price": 1127,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594115",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120987,
                        "name": "Houston, TX, USA",
                        "code": "ChIJAYWNSLS4QIYROwVl894CDco",
                        "countryCode": "US",
                        "lat": 29.7604267,
                        "lng": -95.3698028
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15332,
                        "name": "Houston",
                        "code": "USHOU",
                        "countryCode": "US",
                        "lat": 29.745752378204,
                        "lng": -95.2871564229,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "CMA CGM",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/15.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1440,
                            "originalCurrency": "USD",
                            "price": 1440,
                            "distance": "9634.11 km",
                            "transitTime": "21 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2119504,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1127,
                                "originalCurrency": "USD",
                                "price": 1127,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594116",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120987,
                        "name": "Houston, TX, USA",
                        "code": "ChIJAYWNSLS4QIYROwVl894CDco",
                        "countryCode": "US",
                        "lat": 29.7604267,
                        "lng": -95.3698028
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15332,
                        "name": "Houston",
                        "code": "USHOU",
                        "countryCode": "US",
                        "lat": 29.745752378204,
                        "lng": -95.2871564229,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "Evergreen",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/6.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1313,
                            "originalCurrency": "USD",
                            "price": 1313,
                            "distance": "9634.11 km",
                            "transitTime": "21 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2119504,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1127,
                                "originalCurrency": "USD",
                                "price": 1127,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594117",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120987,
                        "name": "Houston, TX, USA",
                        "code": "ChIJAYWNSLS4QIYROwVl894CDco",
                        "countryCode": "US",
                        "lat": 29.7604267,
                        "lng": -95.3698028
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15332,
                        "name": "Houston",
                        "code": "USHOU",
                        "countryCode": "US",
                        "lat": 29.745752378204,
                        "lng": -95.2871564229,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "HLCU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/7.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1172,
                            "originalCurrency": "USD",
                            "price": 1172,
                            "distance": "9634.11 km",
                            "transitTime": "21 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2119504,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1127,
                                "originalCurrency": "USD",
                                "price": 1127,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594118",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120987,
                        "name": "Houston, TX, USA",
                        "code": "ChIJAYWNSLS4QIYROwVl894CDco",
                        "countryCode": "US",
                        "lat": 29.7604267,
                        "lng": -95.3698028
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15332,
                        "name": "Houston",
                        "code": "USHOU",
                        "countryCode": "US",
                        "lat": 29.745752378204,
                        "lng": -95.2871564229,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "OOCL",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/17.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 726,
                            "originalCurrency": "USD",
                            "price": 726,
                            "distance": "9634.11 km",
                            "transitTime": "21 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2119504,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1127,
                                "originalCurrency": "USD",
                                "price": 1127,
                                "distance": "1 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 3660,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                }
            ],
            "default": {
                "services": [
                    "fees [import]",
                    "truck [import]",
                    "truck [export]",
                    "fees [export]",
                    "ocean"
                ],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const seafcl4resp = {
        "data": {
            "shipment": [
                {
                    "shipmentId": "10594281",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120867,
                        "name": "Los Angeles, CA, USA",
                        "code": "ChIJE9on3F3HwoAR9AhGJW_fL-I",
                        "countryCode": "US",
                        "lat": 34.0522342,
                        "lng": -118.2436849
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15873,
                        "name": "Long Beach",
                        "code": "USLGB",
                        "countryCode": "US",
                        "lat": 33.738580009113406,
                        "lng": -118.19332268118747,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "HLCU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/7.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3829,
                            "originalCurrency": "USD",
                            "price": 3829,
                            "distance": "14948.49 km",
                            "transitTime": "30 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3288668,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1466,
                                "originalCurrency": "USD",
                                "price": 1466,
                                "distance": "44.51 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 162888,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594282",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120867,
                        "name": "Los Angeles, CA, USA",
                        "code": "ChIJE9on3F3HwoAR9AhGJW_fL-I",
                        "countryCode": "US",
                        "lat": 34.0522342,
                        "lng": -118.2436849
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15873,
                        "name": "Long Beach",
                        "code": "USLGB",
                        "countryCode": "US",
                        "lat": 33.738580009113406,
                        "lng": -118.19332268118747,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "CMA CGM",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/15.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3395,
                            "originalCurrency": "USD",
                            "price": 3395,
                            "distance": "14948.49 km",
                            "transitTime": "30 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3288668,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1466,
                                "originalCurrency": "USD",
                                "price": 1466,
                                "distance": "44.51 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 162888,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594283",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120867,
                        "name": "Los Angeles, CA, USA",
                        "code": "ChIJE9on3F3HwoAR9AhGJW_fL-I",
                        "countryCode": "US",
                        "lat": 34.0522342,
                        "lng": -118.2436849
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15873,
                        "name": "Long Beach",
                        "code": "USLGB",
                        "countryCode": "US",
                        "lat": 33.738580009113406,
                        "lng": -118.19332268118747,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "MSC",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/1.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3297,
                            "originalCurrency": "USD",
                            "price": 3297,
                            "distance": "14948.49 km",
                            "transitTime": "30 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3288668,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1466,
                                "originalCurrency": "USD",
                                "price": 1466,
                                "distance": "44.51 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 162888,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594284",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120867,
                        "name": "Los Angeles, CA, USA",
                        "code": "ChIJE9on3F3HwoAR9AhGJW_fL-I",
                        "countryCode": "US",
                        "lat": 34.0522342,
                        "lng": -118.2436849
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15873,
                        "name": "Long Beach",
                        "code": "USLGB",
                        "countryCode": "US",
                        "lat": 33.738580009113406,
                        "lng": -118.19332268118747,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "YMLU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/18.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 2879,
                            "originalCurrency": "USD",
                            "price": 2879,
                            "distance": "14948.49 km",
                            "transitTime": "30 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3288668,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1466,
                                "originalCurrency": "USD",
                                "price": 1466,
                                "distance": "44.51 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 162888,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594285",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120867,
                        "name": "Los Angeles, CA, USA",
                        "code": "ChIJE9on3F3HwoAR9AhGJW_fL-I",
                        "countryCode": "US",
                        "lat": 34.0522342,
                        "lng": -118.2436849
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 15873,
                        "name": "Long Beach",
                        "code": "USLGB",
                        "countryCode": "US",
                        "lat": 33.738580009113406,
                        "lng": -118.19332268118747,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "ONE",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/134.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3010,
                            "originalCurrency": "USD",
                            "price": 3010,
                            "distance": "14948.49 km",
                            "transitTime": "30 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 3288668,
                            "co2Price": 11,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1466,
                                "originalCurrency": "USD",
                                "price": 1466,
                                "distance": "44.51 km",
                                "transitTime": "1 day",
                                "interpolation": false,
                                "co2": 162888,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                }
            ],
            "default": {
                "services": [
                    "fees [import]",
                    "truck [import]",
                    "truck [export]",
                    "fees [export]",
                    "ocean"
                ],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const seafcl5resp = {
        "data": {
            "shipment": [
                {
                    "shipmentId": "10594372",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120990,
                        "name": "New Orleans, LA, USA",
                        "code": "ChIJZYIRslSkIIYRtNMiXuhbBts",
                        "countryCode": "US",
                        "lat": 29.9510658,
                        "lng": -90.0715323
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16569,
                        "name": "New Orleans",
                        "code": "USMSY",
                        "countryCode": "US",
                        "lat": 29.950000435006977,
                        "lng": -90.06305881089573,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "HLCU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/7.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3923,
                            "originalCurrency": "USD",
                            "price": 3923,
                            "distance": "9182.7 km",
                            "transitTime": "20 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2020194,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1422,
                                "originalCurrency": "USD",
                                "price": 1422,
                                "distance": "0.83 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3038,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594373",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120990,
                        "name": "New Orleans, LA, USA",
                        "code": "ChIJZYIRslSkIIYRtNMiXuhbBts",
                        "countryCode": "US",
                        "lat": 29.9510658,
                        "lng": -90.0715323
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16569,
                        "name": "New Orleans",
                        "code": "USMSY",
                        "countryCode": "US",
                        "lat": 29.950000435006977,
                        "lng": -90.06305881089573,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "MSC",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/1.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 3570,
                            "originalCurrency": "USD",
                            "price": 3570,
                            "distance": "9182.7 km",
                            "transitTime": "20 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2020194,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1422,
                                "originalCurrency": "USD",
                                "price": 1422,
                                "distance": "0.83 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3038,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594374",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120990,
                        "name": "New Orleans, LA, USA",
                        "code": "ChIJZYIRslSkIIYRtNMiXuhbBts",
                        "countryCode": "US",
                        "lat": 29.9510658,
                        "lng": -90.0715323
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16569,
                        "name": "New Orleans",
                        "code": "USMSY",
                        "countryCode": "US",
                        "lat": 29.950000435006977,
                        "lng": -90.06305881089573,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "ONE",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/134.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1844,
                            "originalCurrency": "USD",
                            "price": 1844,
                            "distance": "9182.7 km",
                            "transitTime": "20 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2020194,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1422,
                                "originalCurrency": "USD",
                                "price": 1422,
                                "distance": "0.83 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3038,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594375",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120990,
                        "name": "New Orleans, LA, USA",
                        "code": "ChIJZYIRslSkIIYRtNMiXuhbBts",
                        "countryCode": "US",
                        "lat": 29.9510658,
                        "lng": -90.0715323
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16569,
                        "name": "New Orleans",
                        "code": "USMSY",
                        "countryCode": "US",
                        "lat": 29.950000435006977,
                        "lng": -90.06305881089573,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "COSU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/5.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1547,
                            "originalCurrency": "USD",
                            "price": 1547,
                            "distance": "9182.7 km",
                            "transitTime": "20 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2020194,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1422,
                                "originalCurrency": "USD",
                                "price": 1422,
                                "distance": "0.83 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3038,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594376",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120990,
                        "name": "New Orleans, LA, USA",
                        "code": "ChIJZYIRslSkIIYRtNMiXuhbBts",
                        "countryCode": "US",
                        "lat": 29.9510658,
                        "lng": -90.0715323
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16569,
                        "name": "New Orleans",
                        "code": "USMSY",
                        "countryCode": "US",
                        "lat": 29.950000435006977,
                        "lng": -90.06305881089573,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "CMA CGM",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/15.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1743,
                            "originalCurrency": "USD",
                            "price": 1743,
                            "distance": "9182.7 km",
                            "transitTime": "20 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2020194,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1422,
                                "originalCurrency": "USD",
                                "price": 1422,
                                "distance": "0.83 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3038,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594377",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120990,
                        "name": "New Orleans, LA, USA",
                        "code": "ChIJZYIRslSkIIYRtNMiXuhbBts",
                        "countryCode": "US",
                        "lat": 29.9510658,
                        "lng": -90.0715323
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16569,
                        "name": "New Orleans",
                        "code": "USMSY",
                        "countryCode": "US",
                        "lat": 29.950000435006977,
                        "lng": -90.06305881089573,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "YMLU",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/18.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1758,
                            "originalCurrency": "USD",
                            "price": 1758,
                            "distance": "9182.7 km",
                            "transitTime": "20 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2020194,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1422,
                                "originalCurrency": "USD",
                                "price": 1422,
                                "distance": "0.83 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3038,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594378",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120990,
                        "name": "New Orleans, LA, USA",
                        "code": "ChIJZYIRslSkIIYRtNMiXuhbBts",
                        "countryCode": "US",
                        "lat": 29.9510658,
                        "lng": -90.0715323
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16569,
                        "name": "New Orleans",
                        "code": "USMSY",
                        "countryCode": "US",
                        "lat": 29.950000435006977,
                        "lng": -90.06305881089573,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "Evergreen",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/6.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 1430,
                            "originalCurrency": "USD",
                            "price": 1430,
                            "distance": "9182.7 km",
                            "transitTime": "20 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2020194,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1422,
                                "originalCurrency": "USD",
                                "price": 1422,
                                "distance": "0.83 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3038,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                },
                {
                    "shipmentId": "10594379",
                    "transportationMode": "ocean",
                    "incoterms": null,
                    "currency": "USD",
                    "cityFrom": {
                        "id": 120990,
                        "name": "New Orleans, LA, USA",
                        "code": "ChIJZYIRslSkIIYRtNMiXuhbBts",
                        "countryCode": "US",
                        "lat": 29.9510658,
                        "lng": -90.0715323
                    },
                    "cityTo": {
                        "id": 120761,
                        "name": "Hamburg, Germany",
                        "code": "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
                        "countryCode": "DE",
                        "lat": 53.5510846,
                        "lng": 9.9936819
                    },
                    "portFrom": {
                        "id": 16569,
                        "name": "New Orleans",
                        "code": "USMSY",
                        "countryCode": "US",
                        "lat": 29.950000435006977,
                        "lng": -90.06305881089573,
                        "inaccessible": false
                    },
                    "portTo": {
                        "id": 1650,
                        "name": "Hamburg",
                        "code": "DEHAM",
                        "countryCode": "DE",
                        "lat": 53.547720036227,
                        "lng": 9.969406438026787,
                        "inaccessible": false
                    },
                    "freight": [
                        {
                            "shippingLine": "OOCL",
                            "logo": "https:\/\/www.searates.com\/design\/images\/freight\/sealine_cort\/17.jpg",
                            "containerCode": "ST40",
                            "validTo": "2023-12-31",
                            "containerType": "40 Standard",
                            "quantity": 1,
                            "linerTerms": "LIFO",
                            "originalPrice": 810,
                            "originalCurrency": "USD",
                            "price": 810,
                            "distance": "9182.7 km",
                            "transitTime": "20 days",
                            "transportFrom": null,
                            "transportTo": null,
                            "alternative": false,
                            "overdue": false,
                            "co2": 2020194,
                            "co2Price": 7,
                            "comment": null,
                            "rateOwner": false,
                            "indicative": null,
                            "portFeesFrom": [
                                {
                                    "abbr": "OTHC",
                                    "title": "Original Terminal Handling Charge",
                                    "text": "This service covers the cost of handling a container at the origin port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 0,
                                    "originalCurrency": "USD",
                                    "price": 0,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": true
                                },
                                {
                                    "abbr": "EXP",
                                    "title": "Export service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "portFeesTo": [
                                {
                                    "abbr": "DTHC",
                                    "title": "Destination Terminal Handling Charge",
                                    "text": "This service covers the cost of the handling of a container at the destination port or terminal. This service is applicable to all shipments.",
                                    "originalPrice": 500,
                                    "originalCurrency": "USD",
                                    "price": 500,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                },
                                {
                                    "abbr": "IMP",
                                    "title": "Import service",
                                    "text": null,
                                    "originalPrice": 150,
                                    "originalCurrency": "USD",
                                    "price": 150,
                                    "perLot": false,
                                    "co2": 14820,
                                    "included": null
                                }
                            ],
                            "truckFrom": {
                                "originalPrice": 1422,
                                "originalCurrency": "USD",
                                "price": 1422,
                                "distance": "0.83 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 3038,
                                "comment": null,
                                "rateOwner": false
                            },
                            "truckTo": {
                                "originalPrice": 306,
                                "originalCurrency": "EUR",
                                "price": 337,
                                "distance": "1.65 km",
                                "transitTime": "1 day",
                                "interpolation": true,
                                "co2": 6039,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railFrom": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "railTo": {
                                "originalPrice": null,
                                "originalCurrency": null,
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "interpolation": null,
                                "co2": null,
                                "comment": null,
                                "rateOwner": false
                            },
                            "dryFrom": null,
                            "dryTo": null,
                            "bargeFrom": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            },
                            "bargeTo": {
                                "price": null,
                                "distance": null,
                                "transitTime": null,
                                "validTo": null,
                                "currency": null,
                                "co2": null,
                                "port": {
                                    "id": null,
                                    "name": null,
                                    "code": null,
                                    "countryCode": null,
                                    "lat": null,
                                    "lng": null,
                                    "inaccessible": null
                                }
                            }
                        }
                    ]
                }
            ],
            "default": {
                "services": [
                    "fees [import]",
                    "truck [import]",
                    "truck [export]",
                    "fees [export]",
                    "ocean"
                ],
                "bookingViaEmail": false,
                "advertising": false
            },
            "identity": null
        }
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen1(false);
        setOpenDialog(false)
    };
    const handleStep = (index: any) => {
        console.log(index)
        setActiveStep(index);
    }
    const typesOfData = [
        {
            transportationType: transporatationName,
            shipmentType: shipment,
        }
    ]
    const sliderHandleChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };
    const sliderHandleChange1 = (event: Event, newValue: number | number[]) => {
        setSliderValue1(newValue as number);
    };
    // const handleSubmit = () => { }
    const handleChange = (e: any) => {
        console.log(e.target.value, "value")
        setShipment(e.target.value)

    }
    const handleChangeaccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    React.useEffect(() => {
        let active = true;
        seaRatesServices.getGraphqlProducts().then((res: any) => {
            setProducts(res.data.data.hs_codes_list)
        })
        if (!loading) {
            return undefined;
        } (async () => {

            // await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...products]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open1) {
            setOptions([]);
        }
    }, [open1]);

    const submit = () => {
        setSubmit(true)
        if (!sessionStorage.getItem('seaRates_Token')) {
            //---------------------- platform token api --------------
            seaRatesServices.getPlatFormToken().then((res: any) => {
                sessionStorage.setItem('seaRates_Token', res.data['s-token'])
            })
        }
        latlngAddress()

    }
    const latlngAddress = () => {
        console.log(typeOfContainer, typeof (origin), "container type")
        geocodeByAddress(origin.value.description).then((results: any) => getLatLng(results[0])).then((data) => {
            setOriginLatLang(data)
            let RequestObj = {
                originLat: data.lat,
                originLng: data.lng,

            }
            arr.push(RequestObj)
            console.log(origin,"gaaaaaaaaaaaaaaaaa")
            getLatLngData()
        });
        geocodeByAddress(dest.value.description).then((results: any) => getLatLng(results[0])).then((data) => {
            let RequestObj = {
                destLat: data.lat,
                destLng: data.lng,

            }
            arr1.push(RequestObj)
            console.log(dest,"datttaaaaaaaaaaaaaaaaaa")
            getLatLngData()
        });
    }
    const getLatLngData = () => {
        // ---------------------- graphql Rates ---------------------
        if (transporatationName === 'sea') {
            if (shipment === 'fcl') {
                let RequestObject = {
                    shipment: shipment,
                    originLat: arr[0].originLat,
                    originLng: arr[0].originLng,
                    destinationLat: arr1[0].destLat,
                    destinationLng: arr1[0].destLng,
                    containerType: containerType,
                    transport: transporatationName,
                    date: loadDate
                }
                console.log(RequestObject, "request obj")
                setLoader(true)
                let PortOfFrom: any = []
                if(origin.label === 'Port Everglades, Eller Drive, Fort Lauderdale, FL, USA'){
                    setRatesResp(seafclresp.data.shipment)
                }else if (origin.label === 'Savannah, GA, USA'){
                    setRatesResp(seafcl1resp.data.shipment)
                }else if(origin.label === 'Oakland, CA, USA'){
                    setRatesResp(seafcl2resp.data.shipment)
                }else if(origin.label === 'Houston, TX, USA'){
                    setRatesResp(seafcl3resp.data.shipment)
                }else if(origin.label === 'Los Angeles, CA, USA'){
                    setRatesResp(seafcl4resp.data.shipment)
                }else if(origin.label === 'New Orleans, LA, USA'){
                    setRatesResp(seafcl5resp.data.shipment)
                }else{
                    setRatesResp([])
                }
                
                let totalKgs: any = [];
                for (let i = 0; i < seafclresp.data.shipment.length; i++) {
                    totalKgs.push(seafclresp.data.shipment[i].freight[0].co2 / 1000 + seafclresp.data.shipment[i].freight[0].truckFrom.co2 / 1000 + seafclresp.data.shipment[i].freight[0].truckTo.co2 / 1000 + seafclresp.data.shipment[i].freight[0].portFeesFrom[0].co2 / 1000 + seafclresp.data.shipment[i].freight[0].portFeesTo[0].co2 / 1000)

                    PortOfFrom.push(seafclresp.data.shipment[i].portFrom.name)
                    PortOfFrom.push(seafclresp.data.shipment[i].portFrom.code)
                }
                seaRatesServices.getGraphqlRates(RequestObject).then((res: any) => {
                    console.log(res, "resp")
                    setLoader(false)
                    setRatesResp(res.data.data.shipment)
                    // let totalKgs: any = [];
                    // for (let i = 0; i < res.data.data.shipment.length; i++) {
                    //     totalKgs.push(res.data.data.shipment[i].freight[0].co2 / 1000 + res.data.data.shipment[i].freight[0].truckFrom.co2 / 1000 + res.data.data.shipment[i].freight[0].truckTo.co2 / 1000 + res.data.data.shipment[i].freight[0].portFeesFrom[0].co2 / 1000 + res.data.data.shipment[i].freight[0].portFeesTo[0].co2 / 1000)

                    //     PortOfFrom.push(res.data.data.shipment[i].portFrom.name)
                    //     PortOfFrom.push(res.data.data.shipment[i].portFrom.code)
                    // }
                    console.log(PortOfFrom, "total kgs")
                })
            } else if (shipment === 'lcl') {
                let RequestObject = {
                    shipment: shipment,
                    originLat: arr[0].originLat,
                    originLng: arr[0].originLng,
                    destinationLat: arr1[0].destLat,
                    destinationLng: arr1[0].destLng,
                    weight: sliderValue + '000',
                    volume: sliderValue1,
                    transport: transporatationName,
                    date: loadDate
                }
                console.log(RequestObject, "request obj")
                setRatesResp(sealclresp.data.shipment)
                seaRatesServices.getGraphqlRates(RequestObject).then((res: any) => {
                    setRatesResp(res.data.data.shipment)
                    console.log(res.data.data.shipment, "shipment")
                })
            }
        }

    }
    const addChip = (value: any, index: any) => {
        console.log(index, "index", value)
        setActiveIndex(index)
    }
    const readyToLoad = (newValue: Dayjs | any) => {
        let data = newValue.$d;
        var responseDate = moment(data).format("YYYY-MM-DD");
        console.log(responseDate);
        setLoadDate(responseDate);

    };
    const transportationChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        console.log(newAlignment, "namee")
        setTransportationName(newAlignment);

    };
    const TypeOfContainerChange = (
        event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value, "target value")
        setTypeOfContainer(event.target.value);
        containerType = event.target.value
        console.log(containerType, "typeofcontainer")
        latlngAddress()
    };
    const viewDetails = (index: any) => {
        setViewDetailsShow(!isViewDetailsShow)
    }
    const valuehandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSliderValue(Number(event.target.value));
    };
    const valuehandleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSliderValue1(Number(event.target.value));
    };
    const hsCodeDetails = () => {
        console.log("scroll", products)
        setSubProducts(products)
        setOpenDialog(true)
    }
    const onSelectProducts = (item: any) => {
        console.log(PRoductsByHsCode, "hs data1111111111")
        let levelCount = level + 1
        hsCodesData.push(item.description)
        console.log(hsCodesData, "hs data")
        setLevel(levelCount)
        setProductsByHsCode((oldArray: any) => [...oldArray, item.description])
        let objRequest = {
            level: levelCount,
            hsCode: item.code
        }
        seaRatesServices.getrGraphqlProductsByHsCode(objRequest).then((res: any) => {
            console.log("scroll res", res.data.data)
            setProducts(res.data.data.hs_codes_list)
        })
    }
    const handleSelect = (item: any) => {
        console.log(item, "item")
        let objRequest = {
            hsCode: item
        }
        seaRatesServices.getrGraphqlProductsByHsCode(objRequest).then((res: any) => {
            setOpenDialog(false)
            setProducts(res.data.data.hs_autocomplete)

        })
    }
    const OnpickupClick = (item: any) => {
        console.log(item, "pickup click")
        setPickUpActive(!isPickUpActive)

        setPickUpActive((state) => {
            console.log(state, "state")
            console.log(isPickUpActive, "ispickup")
            if (state) {
                console.log(totalBookNowPrice, "total price")
                setPortOfOriginActive(true)
                if (totalBookNowPrice != '') {
                    let price: any = !isPortOfOriginActive ? item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice : 0
                    let co2: any = !isPortOfOriginActive ? item.freight[0].portFeesFrom[0].co2 / 1000 : 0
                    console.log(co2, "co22222222")
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].truckFrom.price + price)
                    setTotalCo2Count(Number(totalCo2Count) + item.freight[0].truckFrom.co2 / 1000 + co2)

                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].truckFrom.co2 / 1000 + item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(co2)
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].truckFrom.price + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)

                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].truckFrom.price)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].truckFrom.co2 / 1000)
                setTotalCo2Count(co2)
            }
            return state
        })
    }
    const onPortOfOriginClick = (item: any) => {
        setPortOfOriginActive(!isPortOfOriginActive)
        setPortOfOriginActive((state) => {
            if (state) {
                if (totalBookNowPrice != '') {
                    let co2: any = item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(Number(totalCo2Count) + co2)
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(co2)
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].portFeesFrom[0].co2 / 1000)
                setTotalCo2Count(co2)
            }
            return state
        })
    }
    const onPortOfDischargeClick = (item: any) => {
        setPortOfDischargeActive(!isPortOfDischargeActive)
        setPortOfDischargeActive((state) => {
            if (state) {
                if (totalBookNowPrice != '') {
                    let co2: any = Number(totalCo2Count) + item.freight[0].portFeesTo[0].co2 / 1000
                    setTotalCo2Count(co2)
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].portFeesTo[0].co2 / 1000
                    setTotalCo2Count(co2)
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].portFeesTo[0].co2 / 1000)
                setTotalCo2Count(co2)
            }
            return state
        })
    }
    const onPortOfDeliveryClick = (item: any) => {
        setDeliveryActive(!isDeliveryActive)
        setDeliveryActive((state) => {
            // if(state){
            //     setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].truckTo[0].originalPrice + item.freight[0].truckTo[1].originalPrice)
            // }else{
            //     let price:any = Number(totalBookNowPrice) - (item.freight[0].originalPrice + item.freight[0].truckTo[0].originalPrice + item.freight[0].truckTo[1].originalPrice)
            //     setTotalBookNowPrice(price)
            // }
            return state
        })
    }
    const PickUpHandleChange = (item: any) => {
        setPickUpActive(!isPickUpActive)
        OnpickupClick(item)
    }
    const portOfOriginHandleChange = (item: any) => {
        setPortOfOriginActive(!isPortOfOriginActive)
        onPortOfOriginClick(item)
    }
    const portOfDischargeHandleChange = (item: any) => {
        setPortOfDischargeActive(!isPortOfDischargeActive)
        onPortOfDischargeClick(item)
    }
    const portOfDeliveryHandleChange = (item: any) => {
        setDeliveryActive(!isDeliveryActive)
        onPortOfDeliveryClick(item)
    }
    const toggleHandleChange = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string[],
    ) => {
        console.log(newFormats, "new formats")
        setToggleValue(newFormats)
    }
    const requestFormSubmit = (values: any) => {

        console.log("valuesssss", values)

        let formData: any = {

            product: selectProduct,

            phoneNumber: values.phone,

            name: values.name,

            email: values.eMail,

            description: values.description || '',

            transportationBy: transporatationName || '',

            originOfShipment: origin.label || '',

            destinationOfShipment: dest.label || '',

            readyToLoad: loadDate || '',

            typeOfShipment: shipment || '',

            typeOfContainer: typeOfContainer,

            weight: sliderValue,

            volume: sliderValue1,

            weightUnits: sliderUnits,

            volumeUnits: slider1Units

        }

        console.log(formData, "formdatataaa")

        axios.post('https://api.ext-preprocessor.realtrace.realware.tech/api/v1/seaRates/requestForm', formData).then((res: any) => {

            if (res.data.status) {

                toast.success(res.data.message)

            } else {

                toast.error(res.data.message)

            }

        })

        setSelectProduct("")

    }

    const handleChangeProduct = (values: any) => {

        setSelectProduct(values.description)

        // console.log(prodct)

    }
    return (
        <div className='root'>
            <Paper elevation={0} className='grid-header'>
                <Grid container spacing={2} >
                    <Grid xs={12} item lg={4} sm={6}>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>TRANSPORTATION BY</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={transporatationName}
                            size='small'
                            exclusive
                            onChange={transportationChange}
                            aria-label="Platform"
                            fullWidth
                            sx={{ backgroundColor: '#fff' }}
                        >
                            <ToggleButton value="sea">SEA</ToggleButton>
                            <ToggleButton value="land" >LAND</ToggleButton>
                            <ToggleButton value="air" >AIR</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid xs={12} item lg={4} sm={6} className='tabFilter'>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>ORIGIN OF SHIPMENT</Typography>
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q"
                            apiOptions={{ language: 'en' }}
                            selectProps={{
                                onChange: setOrigin,
                                placeholder: 'Select Origin',
                            }}
                        />

                    </Grid>
                    <Grid xs={12} item lg={4} sm={6} className='tabFilter'>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>DESTINATION OF SHIPMENT</Typography>
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q"
                            apiOptions={{ language: 'en' }}
                            selectProps={{
                                onChange: setdest,
                                placeholder: 'Select Destionation',

                            }}

                        />
                    </Grid>
                    <Grid xs={12} item lg={4} sm={6} className='tabFilter'>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>READY TO LOAD</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DesktopDatePicker
                                label="Date"
                                inputFormat="DD/MM/YYYY"
                                value={loadDate}
                                onChange={readyToLoad}

                                renderInput={(params: any) => (
                                    <TextField {...params} required size="small" fullWidth sx={{ backgroundColor: '#fff !important' }} />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} lg={4} sm={6} item className='tabFilter'>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>TYPE OF SHIPMENT</Typography>
                        <FormControl fullWidth size="small" sx={{ backgroundColor: '#fff !important' }}>
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            {transporatationName === 'sea' ?
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={shipment}
                                    label="shipment"
                                    onChange={handleChange}
                                    sx={{ fontSize: '14px' }}
                                    MenuProps={{ disableScrollLock: true }}
                                >
                                    <MenuItem value="fcl">FCL</MenuItem>
                                    <MenuItem value="lcl">LCL</MenuItem>
                                    <MenuItem value='BULK'>BULK</MenuItem>
                                </Select> : transporatationName === 'land' ?
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={shipment}
                                        label="shipment"
                                        onChange={handleChange}
                                        sx={{ fontSize: '14px' }}
                                        MenuProps={{ disableScrollLock: true }}
                                    >
                                        <MenuItem value="FTL">FTL</MenuItem>
                                        <MenuItem value="LTL">LTL</MenuItem>
                                        <MenuItem value='FCL'>FCL</MenuItem>
                                        <MenuItem value='FCL'>FCL</MenuItem>
                                    </Select> : ''
                            }
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" sx={{ padding: '5px', marginTop: '25px', textTransform: 'capitalize', color: '#f1f1f1' }} onClick={submit} size="small">
                            Search
                        </Button>
                    </Grid>

                </Grid>
            </Paper>
            {isSubmit ?
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {shipment === 'fcl' ?
                        <Grid xs={3} item className='tabFilter'>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant='h6' className='seaRates_Filter_Headers'>TYPE OF CONTAINER</Typography>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        value={typeOfContainer}
                                        name="controlled-radio-buttons-group"
                                        onChange={TypeOfContainerChange}
                                        className='seaRates_Filter_Content'
                                        sx={{
                                            '& .MuiTypography-root': {
                                                fontSize: '14px !important'
                                            }
                                        }}
                                    >
                                        <FormControlLabel value="ST20" control={<Radio />} label="20' Standard" />
                                        <FormControlLabel value="ST40" control={<Radio />} label="40' Standard" />
                                        <FormControlLabel value="HQ40" control={<Radio />} label="40' High Cube" />
                                        <FormControlLabel value="REF20" control={<Radio />} label="20' Refrigerated" />
                                        <FormControlLabel value="REF40" control={<Radio />} label="40' Refrigerated" />
                                        <FormControlLabel value="HQ45" control={<Radio />} label="45' High Cube" />
                                    </RadioGroup>
                                </FormControl>
                            </Paper>
                        </Grid>
                        :
                        <Grid item xs={3}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant='h6'>{shipment === 'lcl' ? 'Cargo Details' : shipment === 'BULK' ? 'Total Shipments' : ''}</Typography>
                                {shipment === 'lcl' ?
                                    <Box>
                                        <Box sx={{ display: 'flex', mt: 2 }}>
                                            <Typography sx={{ width: '100px' }} className='seaRates_Filter_Headers'>Weight</Typography>
                                            <Box className="custome_Input_dropdown">
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    id="truck-number"
                                                    name="truckNumber"
                                                    value={sliderValue}
                                                    type="number"

                                                    inputProps={{ maxLength: 15 }}
                                                    onChange={valuehandleChange}
                                                    sx={{
                                                        width: '80px',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important',

                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: '5px !important'

                                                        }
                                                    }}
                                                />
                                                <FormControl >
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value='TON'
                                                        onChange={handleChange}
                                                        size="small"
                                                        sx={{
                                                            width: '80px',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none !important',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                backgroundColor: '#e9f0f7',
                                                                padding: '5px !important'

                                                            }
                                                        }}
                                                        MenuProps={{ disableScrollLock: true }}
                                                    >
                                                        <MenuItem value='TON'>TON</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        <Slider
                                            size="small"
                                            value={sliderValue}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                            onChange={sliderHandleChange}
                                            min={1}
                                            max={15}
                                        />
                                        <Box sx={{ display: 'flex', mt: 2 }}>
                                            <Typography sx={{ width: '100px' }} className='seaRates_Filter_Headers'>Volume</Typography>
                                            <Box className="custome_Input_dropdown">
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    id="truck-number"
                                                    name="truckNumber"

                                                    type="number"
                                                    onChange={valuehandleChange1}
                                                    value={sliderValue1}
                                                    sx={{
                                                        width: '80px',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important',

                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: '5px !important'

                                                        }
                                                    }}
                                                />
                                                <FormControl >
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value='CBM'
                                                        onChange={handleChange}
                                                        size="small"
                                                        sx={{
                                                            width: '80px',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none !important',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                backgroundColor: '#e9f0f7',
                                                                padding: '5px !important'

                                                            }
                                                        }}
                                                        MenuProps={{ disableScrollLock: true }}
                                                    >
                                                        <MenuItem value='CBM'>CBM</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        <Slider
                                            size="small"
                                            value={sliderValue1}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                            onChange={sliderHandleChange1}
                                            min={1}
                                            max={15}
                                        />
                                    </Box>

                                    : shipment === 'BULK' ?
                                        <Box>

                                        </Box>
                                        : ''}


                            </Paper>
                        </Grid>

                    }
                    <Grid item xs={9}>
                        {loader ? <Bars
                            height="30"
                            width="30"
                            color="#2a76f4"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        /> :
                            ratesResp.length > 0 ?
                                <div>
                                    <Box sx={{ borderRadius: '30px', backgroundColor: '#e9f0f7', justifyContent: 'space-evenly' }}>
                                        <Stack direction="row" spacing={1}>
                                            {
                                                chipsData.map((value, index) => (
                                                    <Chip label={value}
                                                        key={index}
                                                        onClick={() => addChip(value, index)}
                                                        size='small'
                                                        id={`chip_${value}`}
                                                        className={index === activeIndex ? 'activeChips' : 'inActiveChip'}
                                                    />
                                                ))
                                            }
                                        </Stack>
                                    </Box>
                                    {ratesResp.map((item: any, index: any) => (
                                        <SeaRateCard item={item} index={index}/>
                                    ))
                                    }

                                </div>
                                : <Paper>
                                    <Box sx={{ p: 2, }}>
                                        <Typography>
                                            No rates are currently available. But please fill just a few fields and get an individual offer!
                                        </Typography>
                                        <Typography variant='h6'>
                                            Quick Request
                                        </Typography>
                                    </Box>
                                    <Divider flexItem sx={{ mx: 0.5, my: 1 }} />
                                    <form onSubmit={

                                        handleSubmit((values) => { console.log(values); requestFormSubmit(values); reset() })
                                    }>
                                        <Grid container spacing={2} sx={{ px: 2 }}>
                                            <Grid item xs={6}>
                                                <Box sx={{ display: 'flex', }}>
                                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>PRODUCT</Typography>
                                                    <a onClick={() => hsCodeDetails()} style={{ marginLeft: '70%' }}>
                                                        <Typography className='hsCodes'>HS Codes</Typography>
                                                    </a>
                                                </Box>
                                                <Autocomplete
                                                    id="asynchronous-demo"
                                                    size='small'
                                                    fullWidth
                                                    sx={{ marginTop: '-9px !important' }}
                                                    open={open1}
                                                    onOpen={() => {
                                                        setOpen1(true);
                                                    }}
                                                    onClose={() => {
                                                        setOpen1(false)
                                                    }}
                                                    onChange={(event, value) => handleChangeProduct(value)}
                                                    isOptionEqualToValue={(option: any, value: any) => option.description === value.description}
                                                    getOptionLabel={(option) => option.description}
                                                    options={options}
                                                    loading={loading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            value={productName}
                                                            placeholder='Select products'
                                                            {...register('produtName')}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Full Name</Typography>
                                                <Box sx={{ width: '100%', mt: 1 }}>
                                                    <TextField fullWidth {...register("name")} type='text' id="component-outlined" size='small' placeholder=' Full name' label=" Full name" />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Phone</Typography>
                                                <Box sx={{ width: '100%', mt: 1 }}>
                                                    <TextField fullWidth {...register("phone")} type='number' size='small' placeholder=' Phone Number' variant='outlined' label=" Phone Number" />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Email</Typography>
                                                <Box sx={{ width: '100%', mt: 1 }}>
                                                    <TextField fullWidth {...register("eMail")} type="email" size='small' placeholder=' Email ' variant='outlined' label=" Email " />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Description</Typography>
                                                <Box sx={{ width: '100%', }}>
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        multiline={true}
                                                        fullWidth
                                                        rows={4}
                                                        minRows={10}
                                                        autoFocus
                                                        margin="dense"
                                                        id="name"
                                                        label="Description"
                                                        type="email"
                                                        {...register('description')}
                                                        variant="outlined"
                                                    />
                                                </Box>

                                            </Grid>
                                        </Grid>
                                        <Button type='submit' variant='contained' sx={{m:2}}>submit</Button>
                                    </form>
                                </Paper>
                        }
                    </Grid>

                </Grid> : ''}

            <Dialog
                fullWidth
                maxWidth="md"
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"

            >

                <DialogTitle id="responsive-dialog-title">
                    HS CODES
                </DialogTitle>
                <DialogContent>
                    <Box className="hscodes_header">
                        {/* <Breadcrumbs aria-label="breadcrumb" className='breadCrumbs'>
                                <Typography>Main Categories</Typography>
                                <Box sx={{display:'flex'}}>
                                    {PRoductsByHsCode.map((item:any)=>(
                                        <Typography>{item}</Typography>
                                    ))}
                                </Box>
                            </Breadcrumbs> */}
                        <Breadcrumbs aria-label='breadcrumb' className='breadCrumbs'>
                            {PRoductsByHsCode &&
                                PRoductsByHsCode.map((b: any, index: any) =>
                                    index !== PRoductsByHsCode.length - 1 ? (
                                        <Box key={b}>
                                            <Typography>{b}</Typography>
                                        </Box>
                                    ) : (
                                        <Typography key={b} color='text.primary'>
                                            {b}
                                        </Typography>
                                    ),
                                )}
                        </Breadcrumbs>
                        <Box className="hscodes_content">
                            {products.map((item: any) => (
                                <Box className="hscodes_info" onClick={() => onSelectProducts(item)}>
                                    <Typography>{item.description}</Typography>
                                    <Typography>{item.code}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    {PRoductsByHsCode.length > 1 ?
                        <Button onClick={() => handleSelect(PRoductsByHsCode[PRoductsByHsCode.length - 1])} variant="contained">
                            Select
                        </Button> : ''}
                </DialogActions>
            </Dialog>
        </div >
    )
}
