import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Popover,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { makeStyles, Theme } from "@material-ui/core/styles";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import TextField from "@mui/material/TextField";
import "./Br_line.css";
import "../../../Styles.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

// import "./ItemDetail.css";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import axios from "axios";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { getRequest, PostRequest } from "../../../Services/BaseService";
import { render } from "@testing-library/react";
import { DialogActions, DialogTitle, Tooltip } from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation, useOutletContext } from "react-router-dom";
import swal from "sweetalert";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    padding: "15px",

    // fontWeight: "400",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

//Transition for For Full Screen Dialogue

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BrLineItems = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const location = useLocation();
  const [showgrid, setShowGrid] = useState(true);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const gridRef = useRef<any>();

  const aurl = Environment_Realtraceb_Dev.BASE_URL + "/updateBrStatusItr  ";
  const [link, setLink] = useState("");
  const [load, setLoad] = useOutletContext<any>();
  const [br, setBr] = useState("");
  const [hblcode, setHblCode] = useState("");
  const [remarks, setRemarks] = useState("");
  const [itemdesc, setItemdesc] = useState("");
  const [hscode, setHscode] = useState("");
  const [rowData, setRowData] = useState([]);
  const [qr, setQr] = useState("");
  const [QrOpen, SetQrOpen] = useState(false);
  const [hblOpen, setHblOpen] = useState(false);
  const [remarkOpen, setRemarkOpen] = useState(false);
  const [sno, setSno] = useState("");
  const [inv, setInv] = useState("");
  const [lot, setLot] = useState("");
  const [error, seterror] = useState(false);
  const qrimage = "https://api.ext.realtrace.realware.tech/qrImage/";

  //FORM VALIDATION
  const config2 = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  // console.log("BR Line Items.....", props.data);

  const handleChangeHbl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHblCode(event.target.value);
  };

  const handleChangeReamark = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (remarks.length < 10) seterror(true);
    setRemarks(event.target.value);
  };
  const acceptOptions = {
    hblNumber: hblcode,
    itemDescription: itemdesc,
    docReference: props.data.docReference,
    hscode: hscode,
    brStatus: "accepted",
  };

  const rejectOptions = {
    hscode: hscode,
    itemDescription: itemdesc,
    docReference: props.data.docReference,

    brStatus: "rejected",
  };

  const getdataforReject = () => {
    let data = gridRef.current.api.getSelectedRows();
    let list: {
      hscode: any;
      itemDescription: any;
      docReference: any;
      rejectedReason: string;
      brStatus: any;
    }[] = [];
    setHblOpen(false);
    data.map((item: any, index: number) => {
      let obj = {
        hscode: item.hscode,
        itemDescription: item.itemDescription,
        docReference: props.data.docReference,
        rejectedReason: remarks,
        brStatus: "rejected",
      };
      list.push(obj);
    });
    let obj = {
      data: list,
    };
    setRemarkOpen(false);
    PostRequest(aurl, obj);
    getlines();
 
    setHblCode("");
  };

  const handleAccept = () => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    // console.log("hiiiiiii", selectedRowsData);
    if (selectedRowsData.length <= 0) {
      // console.log("datttttaaaa");
      alert("Please Select Shipments");
    } else {
      setHblOpen(true);
    }
  };

  const handleAcceptt = (params: any) => {
    setHblOpen(false);
    PostRequest(aurl, acceptOptions);
    getlines();
  };

  const handleRejectt = (params: any) => {
    alert("rejected");
    setRemarkOpen(false);
    PostRequest(aurl, rejectOptions);
    getlines();
  };

  const [poNumber, setPoNumber] = useState<any>([]);
  const handleReject = () => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    if (selectedRowsData.length === 0) {
      swal("info", "Please Select shipments", "info");
    } else {
      let pos: any = [];
      selectedRowsData.map((ele: any, index: number) => {
        pos.push(ele.hscode);
      });
      setPoNumber(pos);
      console.log("1234567", pos);
      setRemarkOpen(true);
    }
  };

  const getLink = () => {
    let link = props.data.qrImage.split("/");
    let qr = qrimage + link[3];
    setLink(qr);
  };
  const [serial, setSerial] = useState("");

  const showQr = (params: any) => {
    console.log(params);
    let link = params.value.split("/");
    let qr = qrimage + link[3];
    //console.log("899689698", qr);
    setBr(params.data.batchNumber);
    setSerial(params.data.serialNumber);
    setQr(qr);
    SetQrOpen(true);
    setSno(params.data.serialNumber);
    setInv(params.data.invoiceNumber);
    setLot(params.data.lotNumber);
  };

  //Multi selected checkBox Data
  const getdata = () => {
    let data = gridRef.current.api.getSelectedRows();
    let list: {
      hscode: any;
      itemDescription: any;
      docReference: any;
      hblNumber: string;
      brStatus: any;
    }[] = [];
    setHblOpen(false);
    data.map((item: any, index: number) => {
      let obj = {
        hscode: item.hscode,
        itemDescription: item.itemDescription,
        docReference: props.data.docReference,
        hblNumber: hblcode,
        brStatus: "accepted",
      };
      list.push(obj);
    });
    let obj = {
      data: list,
    };
    PostRequest(aurl, obj);
    swal("success", "Shipments Lines Has Been Confirmed", "success").then((result)=>{if(result===true){props.onClose()}})
    // getlines();
    // console.log(obj);
    // getlines();
    // setHblCode("");
    // swal("success", "Shipments Lines Has Been Confirmed", "success").then((result)=>{if(result===true){props.onClose()}})
    // getlines();
    // props.onClose()
  };

  const [columnDefs] = useState([
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      hide:
        props.status === "open" &&
        sessionStorage.getItem("role") === "forwarder"
          ? false
          : true,
    },
    {
      headerName: "QR CODE",
      field: "qrImage",
      filter: true,
      headerTooltip: "QR SCANNER",
      tooltipField: "qrscanner",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Box>
            <a onClick={() => showQr(params)}>
              <img
                src={require("../../../asserts/Qr_Scanner.jpg")}
                alt="QR SCANNER"
                width="20"
              />
            </a>
          </Box>
        );
      },
    },
    {
      headerName: "HBL #",
      field: "hblNumber",
      filter: true,
      headerTooltip: "HBL #",
      tooltipField: "hblNumber",
      hide:
        location.pathname === "/rejected_by_forwarder" ||
        location.pathname === "/pending_forwarder_confirmation"
          ? true
          : false,
    },
    // {
    //   headerName: "COMMODITY TYPE",
    //   field: "commodityType",
    //   filter: true,
    //   headerTooltip: "COMMODITY TYPE",
    //   tooltipField: "commodityType",
    // },
    {
      headerName: "DRUG TYPE",
      field: "commodityType",
      filter: true,
      headerTooltip: "DRUG TYPE",
      tooltipField: "commodityType",
    },
    {
      headerName: " NDC",
      field: "itemName",
      filter: true,
      headerTooltip: "NDC",
      tooltipField: "itemName",
    },
    {
      headerName: "DRUG NAME",
      field: "itemDescription",
      filter: true,
      headerTooltip: "DRUG NAME",
      tooltipField: "itemDescription",
    },
    {
      headerName: "BATCH NUMBER",
      filter: true,
      field: "batchNumber",
      headerTooltip: "BATCH NUMBER",
      tooltipField: "batch number",
    },
    {
      headerName: "LOT NUMBER",
      filter: true,
      field: "lotNumber",
      headerTooltip: "LOT NUMBER",
      tooltipField: "lotNumber",
    },
    {
      headerName: "SERIAL NUMBER",
      filter: true,
      field: "serialNumber",
      headerTooltip: "SERIAL NUMBER",
      tooltipField: "serialNumber",
    },
    {
      headerName: "SSCC",
      filter: true,
      field: "sscc",
      headerTooltip: "SSCC",
      tooltipField: "serialNumber",
    },
    {
      headerName: "GRAI",
      filter: true,
      field: "grai",
      headerTooltip: "GRAI",
      tooltipField: "serialNumber",
    },
    // {
    //   headerName: "FORWARD BOOKING REFERENCE",
    //   field: "forwarderBookingReference",
    //   filter: true,
    //   headerTooltip: "Forward Booking Reference",
    //   tooltipField: "forwarderBookingReference",
    // },
    // {
    //   headerName: "FORWARDER NAME",
    //   field: "forwarderName",
    //   filter: true,
    //   headerTooltip: "FORWARDER NAME",
    //   tooltipField: "forwarderName",
    // },

    {
      headerName: "HS CODE",
      field: "hscode",
      filter: true,
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "HS CODE DESCRIPTION",
      field: "hscodeDescription",
      filter: true,
      headerTooltip: "HS CODE DESCRIPTION",
      tooltipField: "hscodeDescription",
    },
    {
      headerName: "NUMBER OF PACKAGES",
      field: "packageDetails.numberofPackages",
      filter: true,
      headerTooltip: "NUMBER OF PACKAGES",
      tooltipField: "number of packages",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE TYPE",
      field: "packageDetails.type",
      filter: true,
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "packagetype",
    },
    {
      headerName: "PACKAGE WEIGHT",
      field: "packageDetails.weight",
      filter: true,
      headerTooltip: "PACKAGE WEIGHT",
      tooltipField: "packageweight",
    },
    {
      headerName: "PACKAGE LENGTH",
      field: "packageDetails.length",
      filter: true,
      headerTooltip: "PACKAGE LENGTH",
      tooltipField: "packagelength",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE WIDTH",
      field: "packageDetails.width",
      filter: true,
      headerTooltip: "PACKAGE WIDTH",
      tooltipField: "packagewidth",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE HEIGHT",
      field: "packageDetails.height",
      filter: true,
      headerTooltip: "PACKAGE HEIGHT",
      tooltipField: "packageheight",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE SPECIAL INSTRUCTIONS",
      field: "packageDetails.specialInstructions",
      filter: true,
      headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
      tooltipField: "packagespecialinstructions",
    },
    {
      headerName: "HAZARDOUS GOODS",
      field: "hazardousGoods",
      filter: true,
      headerTooltip: "hazardousGoods",
      tooltipField: "HAZARDOUS GOODS",
    },
    {
      headerName: "SHIPMENT LINE STATUS",
      field: "lineShipStatus",
      filter: true,
      headerTooltip: "SHIPMENT LINE STATUS",
      tooltipField: "lineShipStatus",
    },

    {
      headerName: "REQUESTED DATE OF DELIVERY",
      field: "brRequestedDateOfDelivery",
      filter: true,
      headerTooltip: "brRequestedDateOfDelivery",
      tooltipField: "REQUEST DATE OF DELIVERY",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "DISPATCH STATUS",
      field: "lineDelStatus",
      filter: true,
      headerTooltip: "DISPATCH STATUS",
      tooltipField: "lineDelStatus",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === "delayed") {
          return (
            <Tooltip title={params.value}>
              <ReportProblemIcon sx={{ color: "red" }} />
            </Tooltip>
          );
        } else {
          return <p>{params.value}</p>;
        }
      },
    },

    {
      headerName: "REJECTED REASON",
      hide: props.status === "rejected" ? false : true,
      tooltipField: "rejectedReason",
      field: "rejectedReason",
      filter: true,
      headerTooltip: "REJECTED REASON",
      cellRenderer: (params: ICellRendererParams) => {
        let output;
        if (params.value.length > 8) {
          output = params.value.substring(0, 9);
          output = output + "....";
        } else {
          output = params.value;
        }
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Button style={{ color: "tomato" }}>{output}</Button>
          </Tooltip>
        );
      },
    },
  ]);

  let listurl = Environment_Realtraceb_Dev.BASE_URL + "/bookingRequestItemList";
  const status = props.status;

  let options = {
    docReference: props.data.docReference,
    brStatus: status,
  };

  const classes = useStyles();

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };
  useEffect(() => {
    getLink();
  }, []);
  // useCallback(() => getlines(), []);
  const getlines = () => {
    console.log("I am Getting Called.....")
    axios.get(listurl, config).then((res) => {
      if (res.data.primary.length === 0) {
        setShowGrid(false);
      }
      setRowData(res.data.primary);
     
    });
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      floatingFilter: true,
      searchable: true,
      resizable: true,
      sortable: true,
      filter: true,
      domLayout: "autoHeight",
    };
  }, []);
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };
  const domLayout = "autoHeight";

  function autosize() {
    let allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().forEach(function (column: any) {
      allColumnIds.push(column.colId);
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    //console.log(gridRef.current.columnApi);
  }

  const onGridReady = useCallback((params: GridReadyEvent) => {
    getlines();
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <Dialog
      fullScreen
      open={props.onOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-root": {},
        zIndex: 10000,
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 ,fontSize: '1rem'}} variant="h6" component="div">
            INVOICE # :{" "}
            <span className="dialogTitle">{props.data.invoiceNumber}</span>{" "}
            &nbsp;&nbsp; BOOKING REF. # :{" "}
            <span className="dialogTitle blueColor">
              {props.data.forwarderBookingReference}
            </span>
          </Typography>
          <Button autoFocus color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "20px",
            justifyContent: "space-between",
            backgroundColor: "#f5f4f7",
          }}
        >
          <Box sx={{ height: "fit-content", width: "20%" }}>
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              INVOICE DETAILS
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Invoice Number
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.invoiceNumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Invoice Date
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.brInvoiceDate}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Consignee ID
                </Typography>
                <Typography
                  // className="dialogTitle greyColor"
                  sx={{ fontSize: "14px" }}
                >
                  {props.data.consigneeID}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              height: "fit-content",
              width: "20%",
            }}
          >
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              FORWARDER DETAILS
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Forwarder ID
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.forwarderID}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Forwarder Name
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {" "}
                  {props.data.forwarderName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Mode Of Shipment
                </Typography>
                <Typography
                  // className="dialogTitle greyColor"
                  sx={{ fontSize: "14px" }}
                >
                  {props.data.modeofShipment}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              // height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box className="pointerIcon">
                {/* <LocationOnIcon
                  fontSize="large"
                  sx={{ color: "#f4c919", width: "1.5em", height: "1.5em" }}
                /> */}
                <div className="countryText">{props.data.pickupCountry}</div>
                <span className="countryType">(Origin)</span>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  margin: "5px 0px",
                  color: "#555",
                }}
              >
                {/* <ArrowRightAltIcon sx={{ width: "2em", height: "2em" }} /> */}
              </Box>
              <Box className="pointerIcon">
                {/* <LocationOnIcon
                  sx={{ color: "red", width: "1.5em", height: "1.5em" }}
                  fontSize="large"
                /> */}
                <div className="countryText destinationText">
                  {props.data.dropoffCountry}
                </div>
                <span className="countryType">(Destination)</span>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              height: "fit-content",
              width: "20%",
            }}
          >
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              SCAN QR
            </Typography>
            <img src={link} alt="scann qr " width={100} height={"auto"} />
          </Box>
        </Box>
        <Grid
          xs={12}
          sx={{
            px: 2,
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h6">SHIPMENTS</Typography>
          </Grid>
          <Grid item xs={6}>
            {props.status === "open" &&
            sessionStorage.getItem("role") === "forwarder" ? (
              <div className="actionBtn">
                <Button
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    lineHeight: 0.5,
                    color: "white",
                    backgroundColor: "#28a745",
                    marginRight: "3px",
                  }}
                  onClick={() => handleAccept()}
                  className="btn btn-info"
                  startIcon={<CheckCircleIcon />}
                >
                  Accept
                </Button>
                <Button
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    lineHeight: 0.5,
                    color: "white",
                    backgroundColor: "#95535a",
                  }}
                  onClick={() => handleReject()}
                  className="btn btn-info"
                  startIcon={<CancelIcon />}
                >
                  Reject
                </Button>
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Box sx={{ p: 2 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              ref={gridRef}
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              rowSelection={"multiple"}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={10}
              onFirstDataRendered={onFirstDataRendered}
              // onVirtualColumnsChanged={autosize}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Data Found
            </Alert>
          )}
        </Box>
      </Box>

      <Dialog
        open={QrOpen}
        onClose={() => SetQrOpen(!QrOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
        }}
      >
        <Box sx={{ padding: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Scan QR Code
            </Typography>

            <CloseIcon
              onClick={() => SetQrOpen(!QrOpen)}
              sx={{ color: "blue", cursor: "pointer" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={qr} alt="scann qr " width={270} height={250} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Batch # :</span>
            <span> {br}</span>
            &nbsp; &nbsp; <span style={{ fontWeight: "bold" }}>Serial # :</span>
            <span>{serial}</span>
          </Box>
        </Box>
      </Dialog>
      {/* HBL OPEN DIALOGUE */}
      <Dialog
        open={hblOpen}
        onClose={() => SetQrOpen(!hblOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
          padding: 20,
        }}
      >
        <DialogTitle>
          HBL Number For Booking Reference #
          {props.data.forwarderBookingReference}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ width: "500px" }}>
            Please Enter Hbl Number
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter HBL"
            type="email"
            fullWidth
            variant="standard"
            value={hblcode}
            onChange={handleChangeHbl}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={getdata}>Submit</Button>
          <Button
            onClick={() => {
              setHblOpen(false);
              setHblCode("");
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* REAMRKS DIALOGUE */}

      <Dialog
        open={remarkOpen}
        onClose={() => SetQrOpen(!remarkOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
          width: "100%",
        }}
      >
        <DialogTitle>
          Remarks For Booking Reference # {props.data.forwarderBookingReference}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ width: "500px" }}>
            Please Enter Remarks
          </DialogContentText>

          <TextField
            multiline={true}
            rows={4}
            minRows={10}
            autoFocus
            margin="dense"
            id="name"
            label="Enter Remarks"
            type="email"
            fullWidth
            variant="outlined"
            value={remarks}
            onChange={handleChangeReamark}
            sx={{}}
            required
            error={error}
          />
          {error ? (
            <Box>
              <Typography>Enter Valid Remarks</Typography>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={getdataforReject}>Submit</Button>
          <Button
            onClick={() => {
              setRemarkOpen(false);
              setRemarks("");
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default BrLineItems;
